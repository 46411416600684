import {COLORS, SFCOLORS} from "./constants";
const d3 = require("d3");

function calculateColor(value) {
  return value >= 0 && value < 30
    ? SFCOLORS.HIGHLY_DISENGAGED
    : value >= 30 && value < 45
      ? SFCOLORS.DISENGAGED
      : value >= 45 && value < 60
        ? SFCOLORS.NEUTRAL
        : value >= 60 && value < 70
          ? SFCOLORS.ENGAGED
          : SFCOLORS.HIGHLY_ENGAGED;
}

function makeDataObjects(objs) {
  return objs.map((d) => Object.assign({}, d)).map((d) => Object.create(d));
}

const enableFiltering = true;

function filterLinks(links, avg) {
  if (!enableFiltering) {
    return links;
  }
  const threshold = avg / 5;
  return links
    .filter((l) => l.value > threshold)
    .map((l) => ({
      ...l,
      value: Math.pow(l.value / avg, 4),
    }));
}

function render(params) {
  const width = params.width;
  const height = params.height;
  const report = {
    ...params.report,
    links: filterLinks(params.report.links, params.unfilteredLinkAvg),
  };
  const makeDisplay = (d) => params.makeDisplay(d).name;
  const selectNode = (d) => params.onSelect(d);
  const setSelectedNodeContext = (d) => params.onSetNodeContext(d);

  const links = makeDataObjects(report.links);
  const nodes = makeDataObjects(report.nodes);

  const simulation = d3
    .forceSimulation(nodes)
    .force(
      "link",
      d3
        .forceLink(links)
        .id((d) => d.id)
        .distance(makeLinkDistance(links, width, height))
    )
    .force("collider", d3.forceCollide(25).strength(0.05))
    .force("charge", d3.forceManyBody().strength(-0.05))
    .force("center", d3.forceCenter(width / 2, height / 2).strength(0.25));

  const handleClick = (event, data) => {
    if (data.external) return;

    const withMeta = !!(event.ctrlKey || event.metaKey);

    params.onClick(data, withMeta);
  };

  const handleOpenContextMenu = (event, data) => {
    event.preventDefault();
    setSelectedNodeContext(Object.getPrototypeOf(data));
  }


  const svg = d3
    .select(params.container)
    .select("svg")
    .attr("class", "graph-svg")
    .attr("viewBox", [0, 0, width, height]);

  const link = svg
    .append("g")
    .selectAll("line")
    .data(links)
    .join("line")
    .attr("stroke", COLORS.CHART_GREEN)
    .attr("stroke-opacity", (d) => Math.sqrt(d.value / 10))
    .attr("stroke-width", 2);

  const getNodeClass = (d) =>
    d.active ?
      d.isManager ?
        "node manager" :
        "node" :
      "node inactive";

  const node = svg
    .selectAll(".node")
    .data(nodes)
    .join("g")
    .attr("class", getNodeClass)
    .attr("cursor", "pointer")
    .on("click", handleClick)
    .on("contextmenu", handleOpenContextMenu)
    .call(drag(simulation));

  const isConnected = (n1, n2) => {
    if (n1 === n2) {
      return false;
    }

    return (
      links.filter(
        (l) =>
          (l.source === n1 && l.target === n2) ||
          (l.source === n2 && l.target === n1)
      ).length > 0
    );
  };

  const handleOver = (e, d) => {
    svg.classed("graph-hover", true);
    node.classed("primary", (n) => n === d);

    node.selectAll(".primary circle");

    node.classed("secondary", (n) => isConnected(n, d));

    selectNode(Object.getPrototypeOf(d));

    link
      .classed("primary", (l) => l.source === d || l.target === d)
      .filter(".primary")
      .raise();
  };

  buildBubble(node, 30).on("mouseover", handleOver);
  buildStayFactor(node, 36);
  buildBubbleLabel(node, makeDisplay, 14, true).on("mouseover", handleOver);

  simulation.on("tick", () => {
    link
      .attr("x1", (d) => d.source.x)
      .attr("y1", (d) => d.source.y)
      .attr("x2", (d) => d.target.x)
      .attr("y2", (d) => d.target.y);

    node.attr("transform", function (d) {
      return "translate(" + d.x + "," + d.y + ")";
    });
  });

  return {
    node: svg.node(),
    stop: function() {
      simulation.on("tick", () => {});
      simulation.stop();
      simulation.tick();
      d3.select(params.container).select('svg').selectAll('*').remove();
    }
  }
}

function getRange(width, height) {
  return Math.max(width / 3, height / 3);
}

function makeLinkDistance(links, width, height) {
  const extent = d3.extent(links, (d) => d.value);
  const base = Math.ceil(Math.log10(extent[1] - extent[0]));
  const range = getRange(width, height);
  const scale = d3.scaleLog().domain(extent).range([range, 0]).base(base);

  return (l) => {
    if (
      l.target.external ||
      l.source.external ||
      l.target.group === 0 ||
      l.source.group === 0
    ) {
      return scale(l.value) + range / 2;
    }
    return scale(l.value);
  };
}

function buildBubbleLabel(node, text, fontSize, withVariation) {
  if (!fontSize) {
    fontSize = 10;
  }

  const label = node
    .append("text")
    .attr("fill", COLORS.LIGHT)
    .text(text)
    .attr("font-size", fontSize)
    .attr("font-weight", 600)
    .attr("text-anchor", "middle")
    .attr("transform", (d) => {
      if (withVariation && d.active && d.stayFactor && d.stayFactor.variation !== 0) {
        return "translate(0, " + -fontSize / 4 + ")";
      } else {
        return "translate(0, " + fontSize / 2 + ")";
      }
    });

  const triangle = d3.symbol().type(d3.symbolTriangle).size(fontSize);

  if (!withVariation) {
    return label;
  }

  const colorFill = (d) =>
    d.stayFactor.variation > 0 ? COLORS.CHART_LIME_GREEN : COLORS.CHART_RED;

  node
    .filter((d) => d.active && d.stayFactor && d.stayFactor.variation !== 0)
    .append("path")
    .attr("class", "triangle")
    .attr("d", triangle)
    .attr("fill", colorFill)
    .attr("transform", (d) => {
      let transform =
        "translate(" +
        -fontSize * 0.8 +
        ", " +
        fontSize * (2 / 3) +
        ") scale(2.5, 1.5)";

      if (d.stayFactor.variation < 0) {
        transform += "rotate(180)";
      }

      return transform;
    });

  node
    .filter((d) => d.active && d.stayFactor && d.stayFactor.variation !== 0)
    .append("text")
    .attr("fill", COLORS.LIGHT)
    .attr("font-size", fontSize / 1.2)
    .text((d) => Math.abs(Math.round(d.stayFactor.variation)) + "%")
    .attr("transform", "translate(" + fontSize / 5 + ", " + fontSize + ")");

  return label;
}

function buildBubble(selection, radius) {
  return selection.append("circle").attr("r", radius).attr("stroke-width", 3);
}

function buildStayFactor(selection, radius) {
  const border = 3;

  const barc = d3
    .arc()
    .startAngle(0)
    .innerRadius(radius)
    .outerRadius(radius - border)
    .cornerRadius(20)
    .endAngle(Math.PI * 2);

  const farc = d3
    .arc()
    .startAngle(0)
    .innerRadius(radius)
    .outerRadius(radius - border)
    .cornerRadius(20)
    .endAngle((d) => {
      if (!d.stayFactor) {
        return 0;
      }

      return Math.PI + (d.stayFactor.current * Math.PI) / 100;
    });

  const g = selection.append("g");

  const meter = g.append("g").attr("class", "progress-meter");

  meter
    .append("path")
    .attr("class", "background")
    .attr("fill", "#f5f5f5")
    .attr("fill-opacity", 0)
    .attr("d", barc);

  const foreground = meter
    .filter((d) => d.stayFactor && d.active)
    .append("path")
    .attr("class", "foreground")
    .attr("fill-opacity", 1)
    .attr("stroke-opacity", 1)
    .attr("stroke", (d) => calculateColor(d.stayFactor.current))
    .attr("fill", (d) => calculateColor(d.stayFactor.current))
    .attr("d", farc);
}

function drag(simulation) {
  function dragstarted(event) {
    if (!event.active) simulation.alphaTarget(0.3).restart();
    event.subject.fx = event.subject.x;
    event.subject.fy = event.subject.y;
  }

  function dragged(event) {
    event.subject.fx = event.x;
    event.subject.fy = event.y;
  }

  function dragended(event) {
    if (!event.active) simulation.alphaTarget(0);
    event.subject.fx = null;
    event.subject.fy = null;
  }

  return d3
    .drag()
    .on("start", dragstarted)
    .on("drag", dragged)
    .on("end", dragended);
}

export default render;
