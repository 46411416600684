import auth from './auth';
import { aggregatorPost } from './utils';

type LinkTokenRequest = {
  integration: string,
  orgName: string,
  userEmail: string,
  category: string
}

class MergeIntegrationsService {

  getLinkToken(integration: string, category: string) {
    const employee = auth.me.get()!!;
    const request = {
      userEmail: employee.email,
      orgName: employee.organizationName,
      integration,
      category
    } as LinkTokenRequest;

    return aggregatorPost<string>("/api/v1/integrations/merge/linktoken", request);
  }

  finalizeConnection(integration: string, publicToken: string) {
    return aggregatorPost<string>("/api/v1/integrations/merge/connect", { publicToken, integration });
  }
}

export default new MergeIntegrationsService();
