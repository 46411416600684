import React from "react";
import Avatar from "components/utils/Avatar";
import { ReactComponent as ArrowUp } from "icons/arrow-up.svg";
import { ReactComponent as ArrowDown } from "icons/arrow-down.svg";
import Badge from "components/generics/Badge";
import { Link } from "react-router-dom";
import { GraphNode } from "model/lexi";

import * as S from "./styles";

export type NetworkListItemProps = {
  data: GraphNode;
  context: "teams" | "employees" | "departments";
  active?: boolean;
  grayscale?: boolean;
};

const NetworkListItem = ({
  data,
  context,
  active = false,
  grayscale = false,
}: NetworkListItemProps) => {
  const { id, name, stayFactor } = data;

  return (
    <S.Wrapper>
      <Link
        to={`/${context}/${id}`}
        className="w-full flex items-center justify-between gap-3"
      >
        <S.InfoWrapper active={active}>
          <Avatar size={1}
                  name={name}
                  active={active}
                  grayscale={grayscale}
          />
          {name}
        </S.InfoWrapper>

        {
          !active ?
            <Badge label={"Deactivated"} rounded />
            :
            stayFactor && (
              <S.ScoreWrapper>
                <S.CurrentScore
                  score={stayFactor.current}
                  hasVariation={!!stayFactor.variation}
                >
                  {Math.round(stayFactor.current)}
                </S.CurrentScore>
                {
                  Math.round(Math.abs(stayFactor.variation)) === 0 ?
                    <S.VariationScore variation={0}>
                      0%
                    </S.VariationScore>
                    :
                    <S.VariationScore variation={stayFactor.variation}>
                      {Math.round(stayFactor.variation) > 0 ? <ArrowUp /> : <ArrowDown />}
                      {Math.round(Math.abs(stayFactor.variation))}%
                    </S.VariationScore>
                }
              </S.ScoreWrapper>
            )
        }
      </Link>
    </S.Wrapper>
  );
};

export default NetworkListItem;
