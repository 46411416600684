import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "components/generics/Button";

import * as S from "./styles";

export type NavProps = {
  items: {
    label: string;
    icon: React.ReactNode;
    url: string;
  }[];
  showSave?: boolean;
  onSave?: () => void;
};

export default function DetailsNavbar({ items, showSave, onSave }: NavProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <S.Wrapper>
      <S.ButtonsWrapper>
        {items.map((item) => (
          <Button
            iconposition={"left"}
            size="large"
            icon={item.icon}
            onClick={() => pathname !== item.url && navigate(item.url)}
            className={`${S.DetailsNavbar} ${
              pathname === item.url && S.Selected
            }`}
          >
            {item.label}
          </Button>
        ))}
      </S.ButtonsWrapper>

      {showSave && !!onSave && (
        <S.RightButtonWrapper>
          <Button btntype="primary" $shadow={true} onClick={() => onSave()} className={"rounded-[15px]"}>
            Save
          </Button>
        </S.RightButtonWrapper>
      )}
    </S.Wrapper>
  );
}
