import React from 'react';
import {State, useState} from "@hookstate/core";
import {arrayMove, sortableKeyboardCoordinates} from "@dnd-kit/sortable";
import {Dashboard} from "../../model/dashboards";
import {
  DragCancelEvent,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import dashboardsService from "../../services/dashboards";

export function GetSensors() {
    return useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
}

export function HandleDragStart(event: any, activeId: State<string | null>) {
    const {active} = event;
    activeId.set(active.id);
}

export function HandleDragOver(event: any, dashboard: State<Dashboard>) {
    const {active, over} = event;

    if (active.id !== over.id) {
        dashboard.dashboardQueries.set((items) => {
            const oldIndex = items.findIndex(value => value.sortOrder === Number(active.id));
            const newIndex = items.findIndex(value => value.sortOrder === Number(over.id));

            return arrayMove(items, oldIndex, newIndex);
        });
    }
}

export function HandleDragEnd(event: any, activeId: State<string | null>, dashboard: State<Dashboard>) {
    //set the new sortOrder on the state
    dashboard.dashboardQueries.set((items) => {
        return items.map((query, index) => ({...query, sortOrder: index}));
    });
    activeId.set(null);
}

export const useDashboardQueriesDrag = (dashboard: State<Dashboard>) => {
  const dashboardItems = useState(dashboard.dashboardQueries);
  const draggedId = useState<string|null>(null);

  const HandleDragStart = (event: DragStartEvent) => {
    const {active} = event;
    draggedId.set(active.id)
  }

  const HandleDragOver = (event: DragOverEvent) => {
    const {active, over} = event;

    if (over && over.id !== active.id) {
      dashboardItems.set((items) => {
        const oldIndex = items.findIndex(value => value.sortOrder === Number(active.id));
        const newIndex = items.findIndex(value => value.sortOrder === Number(over.id));

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
  const HandleDragEnd = (event: DragEndEvent) => {
    dashboardItems.set(items => items.map((query, index)=>({...query, sortOrder: index})));
    draggedId.set(null);
  }

  const HandleDragEndWithSave = (event: DragEndEvent, canUpdateDashboard: boolean) => {
    const isSorted = dashboardItems.every((dq, index) => dq.sortOrder.value === index);
    HandleDragEnd(event)
    if (!isSorted && canUpdateDashboard) {
      dashboardsService.saveDashboard({...dashboard.value, dashboardQueries: dashboardItems.value});
    }
  }

  const HandleDragCancel = (event: DragCancelEvent) => {
    draggedId.set(null)
  }
  return {
    dashboardItems: dashboardItems,
    draggedId: draggedId,
    HandleDragStart,
    HandleDragOver,
    HandleDragEnd,
    HandleDragEndWithSave,
    HandleDragCancel
  }
}