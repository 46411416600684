import React from "react";
import { Link } from "react-router-dom";
import * as S from "./styles";

import { SubmenuProps } from "model/submenu";

const SubmenuItem = ({
  label,
  description,
  path,
  action,
  highlighted,
  icon,
}: SubmenuProps) => {

  const inner = <S.Wrapper onClick={() => action ? action() : undefined}>
    <S.IconWrapper>{icon}</S.IconWrapper>

    <S.Content>
      <S.Label highlighted={highlighted || !!description ? 1 : 0}>{label}</S.Label>
      <S.Description>{description}</S.Description>
    </S.Content>
  </S.Wrapper>;


  if (path) {
    return <Link to={path}>
      {inner}
    </Link>
  } else {
    return inner;
  }
}

export default SubmenuItem;
