import React from "react";
import { InstalledIntegrationInfoDto } from "model/integration";
import { getImageSource } from "utils/component";
import config from "../../../../../config";

import * as S from "./styles";

export type IntegrationLogoProps = {
  integration: InstalledIntegrationInfoDto;
  size?: "small" | "medium" | "large";
  rounded?: boolean;
};

const IntegrationLogo = ({
                           integration,
                           size = "medium",
                           rounded,
                         }: IntegrationLogoProps) => {

  return (
    <S.Wrapper size={size} rounded={rounded}>
      <S.Logo src={getImageSource(integration.provider === 'builtin' ? config.aggregatorUrl + integration.logo : integration.logo)}
              alt={integration.name}
      />
    </S.Wrapper>
  );
};

export default IntegrationLogo;
