import tw from "tailwind-styled-components";
import { DropdownProps } from ".";

type OpenProps = {
  open?: boolean;
  fullscreen?: number;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
} & Pick<DropdownProps, "direction">;

const contentModifiers = {
  open: `
    opacity-1;
    pointer-events-auto;
    translate-y-0;
  `,

  close: `
    opacity-0

    pointer-events-none
    translate-y-[-2rem]
  `,

  center: `
    translate-x-0
  `,

  left: `
    translate-x-[-92%]
    left-0
  `,

  right: `
    translate-x-[92%]
    right-0
  `,

  fullscreen: `
    fixed
    w-full
    left-0
    right-0
  `,
};

export const Wrapper = tw.div`
  relative
  w-max
  flex
  flex-col
`;

export const TitleWrapper = tw.div`
  relative
  flex
  items-center
  cursor-pointer
`;

export const Title = tw.span`
  text-pl-grayscale-black
  flex
  items-start
`;

export const Content = tw.div<OpenProps>`
  ${({ open, direction, fullscreen }) => `
    flex
    flex-col
    bg-pl-grayscale-white
    text-pl-grayscale-black
    mt-[3.5rem]
    transition-smooth
    shadow
    min-w-[15rem]
    z-10
    rounded-2xl

    ${open ? contentModifiers.open : contentModifiers.close}
    ${!!direction && contentModifiers[direction]}
    ${!!fullscreen ? contentModifiers.fullscreen : "absolute"}
  `}
`;

export const Icon = tw.div<OpenProps>`
  ${({ open }) => `
    ml-2
    text-pl-grayscale-gray
    ${open ? "transform rotate-180" : ""} w-5 h-5
  `}
`;
