import {Skill} from "./skill";
import {EmployeeIntegration, IndexedEmployeeIntegration} from "./integration";
import {SelectSearchOption} from "../components/utils/SelectSearch";
import {Department} from "./department";

export enum Role {
    ADMIN = "admin",
    MANAGER = "manager",
    EMPLOYEE = "employee",
    EMPLOYEE_WITH_DASHBOARD = "employee_with_dashboard",
}

export const ROLES_LABEL_VALUE_PAIR = [
    {
        value: Role.EMPLOYEE,
        label: "Employee"
    },
    {
        value: Role.EMPLOYEE_WITH_DASHBOARD,
        label: "Employee with Peoplelogic Access"
    },
    {
        value: Role.MANAGER,
        label: "Team Manager"
    },
    {
        value: Role.ADMIN,
        label: "Peoplelogic Admin"
    }
];

export type Employee = {
    id: number,
    name: string,
    welcomeName: string,
    email: string,
    role: Role,
    avatar?: string | null,
    joinDate?: Date | null,
    active: boolean,
    endDate?: Date | null,
    primaryTeam?: Team,
    additionalTeams?: Team[],
    manager?: Employee,
    managedTeams?: Team[],
    jobTitle: string,
    linkedin: string,
    location?: Location,
    organizationName: string,
    skills?: Skill[],
    integrations?: EmployeeIntegration[]
}

export type AddToTeam = {
  teamId: number;
  managerId: number;
};

export type Team = {
    id: number,
    name: string,
    manager?: Employee,
    teamType?: string,
    employees?: Employee[],
    skills?: Skill[],
    active?: boolean,
    size?: number
    department?: Department,
}

export type TeamFormData = {
    id?: number,
    name: string,
    managerId?: number,
    teamType: string,
    employees: Employee[],
    employeesSelected: SelectSearchOption[],
    skills: string[],
    active: boolean,
    departmentId?: number,
}

export enum FilterStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    ALL = 'all'
}

export type Location = {
    id: number,
    name: string,
    timeZone: string,
    offset: string,
    startTime: string,
    endTime: string
}

export type Me = {
    profile: Employee,
    token: string,
    tenantId: string,
    csrf: {
        header: string,
        token: string
    }
}

export type UserSetupCheckItem = {
    checkItem: string,
    checked: boolean
}

export type EmployeeFormData = {
    id: number | null,
    avatar?: string,
    role: string,
    name: string,
    joinDateStr: string,
    email: string,
    jobTitle?: string,
    linkedin?: string,
    location?: number,
    employeeIntegrations: EmployeeIntegration[],
    indexedIntegrations?: IndexedEmployeeIntegration[],
    primaryTeamId?: number,
    managerId?: number,
    skills?: string[]
}

export type NotificationSettings = {
    employeeId: number,
    alerts: boolean,
    frequency: string | null,
    email: boolean
    slack: boolean,
    channels: string[]
}

export const NotificationsFrequency = [
  {
    value: "ONCE_WEEKLY",
    label: "Weekly Report"
  },
  {
    value: "ONCE_BI_WEEKLY",
    label: "Bi-weekly Report"
  },
  {
    value: "ONCE_MONTHLY",
    label: "Monthly Report"
  }
];
