import { DateTime } from "luxon";
import { ChartLabelValuePair } from "model/chart";
import { UserMessage } from "model/chat-session";
import {
  DataCollection,
  DataFunction,
  GraphReport,
  LineChartReport,
  LineChartSeries,
  QueryFilter,
  QueryItem,
  QueryResponse,
  QueryValue,
  ReportQuery,
  ReportType,
  SortDirection,
  SortItem,
  TableReport,
  TimeSeries,
} from "model/lexi";
import getOrSet from "../utils/localCache";
import { lexiFetch } from "./utils";

function isDateTime(obj: any): obj is DateTime {
  return obj.endOf !== undefined;
}

function isLineChart(obj: any): obj is LineChartReport {
  return (
    obj.xAxisLabel !== undefined &&
    obj.xAxisValues !== undefined &&
    obj.series !== undefined
  );
}

function isTableReport(obj: any): obj is TableReport {
  return obj.columns !== undefined && obj.data !== undefined;
}

function isGraphReport(obj: any): obj is GraphReport {
  return obj.nodes !== undefined && obj.links !== undefined;
}

class DateSetter {
  query: LexiQuery;
  reportQuery: ReportQuery;
  property: "start" | "end";

  constructor(
    query: LexiQuery,
    reportQuery: ReportQuery,
    property: "start" | "end"
  ) {
    this.query = query;
    this.reportQuery = reportQuery;
    this.property = property;
  }

  setDate(date: DateTime) {
    this.reportQuery[this.property] = date;
    return this.query;
  }

  today() {
    return this.daysAgo(0);
  }

  daysAgo(days: number) {
    return this.setDate(DateTime.now().minus({ days }).startOf("day"));
  }
}

class LexiQuery {
  private reportQuery: ReportQuery;

  constructor(type: ReportType) {
    this.reportQuery = { type: type, includeExternal: false };
  }

  includeExternal(includeExternal: boolean) {
    this.reportQuery.includeExternal = includeExternal;
    return this;
  }

  collection(collection: DataCollection) {
    this.reportQuery.collection = collection;
    return this;
  }

  timeSeries(timeSeries: TimeSeries) {
    this.reportQuery.timeSeries = timeSeries;
    return this;
  }

  out(field: string) {
    return this.addOut({ field: field });
  }

  avg(field: string) {
    return this.addOut({
      field: field,
      function: "AVG",
    });
  }

  sum(field: string) {
    return this.addOut({
      field: field,
      function: "SUM",
    });
  }

  count(field: string) {
    return this.addOut({
      field: field,
      function: "COUNT",
    });
  }

  groupBy(field: string) {
    return this.addGroup({ field: field });
  }

  sortBy(field: string, dataFunction: DataFunction, dir: SortDirection) {
    return this.addSort({
      queryItem: {
        field: field,
        function: dataFunction,
      },
      direction: dir,
    });
  }

  compareDates(datetime: DateTime) {
    return this.addCompareDates(datetime.toISO({ includeOffset: false }));
  }

  forEmployeesList(ids: number[]) {
    return this.addFilter({
      left: { field: "EMPLOYEE" },
      operator: "in",
      right: { value: { list: this.numberArraytoQueryValues(ids) } },
    });
  }

  forEmployee(id: number) {
    return this.addFilter({
      left: { field: "EMPLOYEE" },
      operator: "eq",
      right: { value: { number: id } },
    });
  }

  forEmployees(ids: number[]) {
    return this.addFilter({
      left: { field: "EMPLOYEE" },
      operator: "in",
      right: { value: { list: ids.map((id) => ({ number: id })) } },
    })
      .addOut({ field: "EMPLOYEE" })
      .addGroup({ field: "EMPLOYEE" });
  }

  forEmployeesNotGrouped(ids: number[]) {
    return this.addFilter({
      left: { field: "EMPLOYEE" },
      operator: "in",
      right: { value: { list: ids.map((id) => ({ number: id })) } },
    });
  }

  forTeam(id: number) {
    return this.addFilter({
      left: { field: "TEAM" },
      operator: "eq",
      right: { value: { number: id } },
    });
  }

  forTeams(ids: number[]) {
    return this.addFilter({
      left: { field: "TEAM" },
      operator: "in",
      right: { value: { list: ids.map((id) => ({ number: id })) } },
    })
      .addOut({ field: "TEAM" })
      .addGroup({ field: "TEAM" });
  }

  forTeamsNotGrouped(ids: number[]) {
    return this.addFilter({
      left: { field: "TEAM" },
      operator: "in",
      right: { value: { list: ids.map((id) => ({ number: id })) } },
    });
  }

  forDepartment(id: number) {
    return this.addFilter({
      left: { field: "DEPARTMENT" },
      operator: "eq",
      right: { value: { number: id } },
    });
  }

  forDepartments(ids: number[]) {
    return this.addFilter({
      left: { field: "DEPARTMENT" },
      operator: "in",
      right: { value: { list: ids.map((id) => ({ number: id })) } },
    })
      .addOut({ field: "DEPARTMENT" })
      .addGroup({ field: "DEPARTMENT" });
  }

  private numberArraytoQueryValues(values: number[]): QueryValue[] {
    return values.map((val) => {
      return { number: val } as QueryValue;
    });
  }

  private addOut(item: QueryItem) {
    this.reportQuery.outputs = this.reportQuery.outputs || [];
    this.reportQuery.outputs.push(item);
    return this;
  }

  private addFilter(filter: QueryFilter) {
    this.reportQuery.filters = this.reportQuery.filters || [];
    this.reportQuery.filters.push(filter);
    return this;
  }

  private addGroup(item: QueryItem) {
    this.reportQuery.groups = this.reportQuery.groups || [];
    this.reportQuery.groups.push(item);
    return this;
  }

  private addSort(item: SortItem) {
    this.reportQuery.sorts = this.reportQuery.sorts || [];
    this.reportQuery.sorts.push(item);
    return this;
  }

  private addCompareDates(datetime: string) {
    this.reportQuery.compareDates = this.reportQuery.compareDates || [];
    this.reportQuery.compareDates.push(datetime);
    return this;
  }

  get start() {
    return new DateSetter(this, this.reportQuery, "start");
  }

  get end() {
    return new DateSetter(this, this.reportQuery, "end");
  }

  fetch() {
    return defaultService.lexiFetch(this.build());
  }

  async _fetchLineChartReport(): Promise<LineChartReport> {
    const response = (await this.fetch()) as QueryResponse;

    if (!response || !response.report || !isLineChart(response.report)) {
      return {} as LineChartReport;
    }

    return response.report;
  }

  async fetchAsSingleSeries() {
    const report = await this._fetchLineChartReport();

    const xAxis = report.xAxisValues;
    const singleSeries = report.series[0];

    return xAxis.map((key) => singleSeries.values[key as string] || 0);
  }

  async fetchAsSeriesMap<K extends string | number>(
    keyBuilder: (s: LineChartSeries) => K
  ) {
    const report = await this._fetchLineChartReport();

    const xAxis = report.xAxisValues;

    return Object.fromEntries(
      report.series.map((series) => [
        keyBuilder(series),
        xAxis.map((key) => series.values[key as string] || 0),
      ])
    ) as Record<K, any[]>;
  }

  async fetchAsMultiSeries(): Promise<LineChartReport> {
    const response = (await this.fetch()) as QueryResponse;

    if (response === null) {
      console.log("Unable to fetch data.");
      //had to return something so that the rest of the page renders...
      return {} as LineChartReport;
    }

    if (!response.report || !isLineChart(response.report)) {
      console.log("not a line chart report", response.report);
      return {} as LineChartReport;
    }
    return response.report;
  }

  async fetchAsTableReport(): Promise<TableReport> {
    const response = (await this.fetch()) as QueryResponse;

    if (response === null) {
      console.log("Unable to fetch data.");
      //had to return something so that the rest of the page renders...
      return {} as TableReport;
    }

    if (!response.report || !isTableReport(response.report)) {
      console.log("not a table report", response.report);
      throw new Error("Not a table report");
    }
    return response.report;
  }

  async fetchAsGraphReport(): Promise<GraphReport> {
    const response = (await this.fetch()) as QueryResponse;

    if (response === null) {
      console.log("Unable to fetch data.");
      //had to return something so that the rest of the page renders...
      return {} as GraphReport;
    }

    if (!response.report || !isGraphReport(response.report)) {
      console.log("not a graph report", response.report);
      return {} as GraphReport;
    }
    return response.report;
  }

  async fetchActivityByTypeAsTableReport(): Promise<ChartLabelValuePair[]> {
    const report = await this.fetchAsTableReport();

    const labelCol = report.columns.filter(col => col.group)[0]?.alias;
    const countCol = report.columns.filter(col => !col.group)[0]?.alias;

    if (!labelCol || !countCol) {
      return [];
    }

    const data = report.data;
    return data.map((queryData) => {
      return {
        label: queryData[labelCol],
        value: queryData[countCol],
      };
    });
  }

  private parseDate(date?: DateTime | string) {
    if (!date) {
      return undefined;
    }
    if (isDateTime(date)) {
      return date.toISO({ includeOffset: false });
    } else {
      return date as string;
    }
  }

  private build(): ReportQuery {
    return {
      ...this.reportQuery,
      start: this.parseDate(this.reportQuery.start),
      end: this.parseDate(this.reportQuery.end),
    };
  }
}

class LexiService {
  lexiChatFetch(userMessage: UserMessage) {
    return lexiFetch(userMessage, "/conversation");
  }

  lexiFetch(query: ReportQuery) {
    return getOrSet(
      "lexi",
      query,
      () => lexiFetch(query, "/query") as Promise<QueryResponse>,
      1800
    );
  }

  getQuery(query: ReportType | "daily") {
    switch (query) {
      case "daily":
        return new LexiQuery("lineChart").timeSeries("daily");

      default:
        return new LexiQuery(query);
    }
  }
}

const defaultService = new LexiService();
export default defaultService;
