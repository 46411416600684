import React, { useEffect, useRef } from 'react';
import useOnScreen from '../../utils/onscreen';
import { Generator } from './types';
import {useState} from "@hookstate/core";

export default function WaitForOnScreen({builder}: {builder: Generator}) {

  const wasEverVisible = useState<boolean>(false);
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    if (isVisible.value) {
      wasEverVisible.set(true);
    }
  }, [isVisible]);

  return <div ref={ref}>{ wasEverVisible && builder() }</div>
}
