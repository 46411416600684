import {CHART_COLORS} from "utils/color";
import {CHART_WHITE} from "../constants";

export const tooltipStyle = (index: number) => {
  let divStyle = 'background:' + CHART_WHITE;
  divStyle += '; border-color:' + CHART_COLORS[index];
  divStyle += '; border-width: 2px';
  divStyle += '; padding: 8px';
  divStyle += '; border-radius: 10px';
  divStyle += '; gap: 8px';
  divStyle += '; display: flex';
  divStyle += '; vertical-align: middle';
  return divStyle;
}

export const legendStyle = (index: number) => {
  let avatarStyle = 'background:' + CHART_COLORS[index];
  avatarStyle += '; border-width: 1px';
  avatarStyle += '; border-color:' + CHART_COLORS[index];
  avatarStyle += '; border-radius: 100%';
  avatarStyle += '; padding: 1px';
  avatarStyle += '; padding-top: 8px';
  avatarStyle += '; width: 18px';
  avatarStyle += '; height: 18px';
  avatarStyle += '; background-color:' + CHART_COLORS[index];
  return avatarStyle;
}

export const nameStyle = () => {
  let nameStyle = 'background:' + CHART_WHITE;
  nameStyle += '; font-size: 13px';
  nameStyle += '; font-weight: bold';
  nameStyle += '; vertical-align: middle';
  return nameStyle;
}

export const valueStyle = () => {
  let deltaStyle = 'font-size: 13px';
  deltaStyle += '; font-weight: bold';
  deltaStyle += '; vertical-align: middle';
  deltaStyle += '; vertical-align: middle';
  deltaStyle += '; text-align: center';
  deltaStyle += '; display: flex';
  return deltaStyle;
}