import React from "react";
import { State, useState } from "@hookstate/core";
import Button from "components/generics/Button";
import { Tab } from "components/utils/types";

import * as S from "./styles";

export type VerticalTabbedMenuProps = {
  tabs: Tab[];
  onSave?: () => any;
  showCancel?: boolean;
  showSave?: boolean;
  showButtons?: boolean;
  indexState?: State<number>;
  clicableTabs?: boolean;
};

const VerticalTabbedMenu = ({
  tabs,
  onSave,
  showCancel = true,
  showSave = true,
  showButtons = true,
  indexState,
  clicableTabs = true,
}: VerticalTabbedMenuProps) => {
  const selectedNavIndex = useState(indexState ? indexState.value : 0);

  const renderSelectedTab = () => tabs[selectedNavIndex.value].builder();

  return (
    <S.Wrapper>
      <S.MenuWrapper>
        {tabs.map((item, index) => (
          <S.MenuItemWrapper
            key={`${item.title}-${index}`}
            onClick={() => {
              if (clicableTabs) {
                selectedNavIndex.set(index);
              }
            }}
          >
            <S.TitleWrapper isSelected={index === selectedNavIndex.value}>
              {!!item.icon && item.icon}
              {item.title}
            </S.TitleWrapper>

            {item.subtitle && <S.Subtitle>{item.subtitle}</S.Subtitle>}
          </S.MenuItemWrapper>
        ))}
      </S.MenuWrapper>

      <S.ContentWrapper>
        {renderSelectedTab()}

        {showButtons && (
          <S.ActionWrapper>
            {showCancel ? (
              <Button $shadow={true} onClick={() => 0}>
                Cancel
              </Button>
            ) : (
              <span></span>
            )}

            <S.FixedActionWrapper>
              {selectedNavIndex.value > 0 && (
                <Button
                  $shadow={true}
                  onClick={() =>
                    selectedNavIndex.set(selectedNavIndex.value - 1)
                  }
                >
                  Previous
                </Button>
              )}
              {selectedNavIndex.value < tabs.length - 1 ? (
                <Button
                  $shadow={true}
                  btntype="primary"
                  onClick={() =>
                    selectedNavIndex.set(selectedNavIndex.value + 1)
                  }
                >
                  Next
                </Button>
              ) : (
                showSave &&
                !!onSave && (
                  <Button $shadow={true} btntype="primary" onClick={() => onSave()}>
                    Save
                  </Button>
                )
              )}
            </S.FixedActionWrapper>
          </S.ActionWrapper>
        )}
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default VerticalTabbedMenu;
