import { Integration } from "model/integration";
import { getImageSource } from "utils/component";
import * as S from "./styles";
import config from "../../../config";
import React from "react";

export const IntegrationNameRenderer = ({
                                          integration,
                                        }: {
  integration: Integration;
}) => {

  const renderIntegrationName = () => {
    return <S.IntegrationNameText
      className={`${integration.active ? "text-pl-grayscale-black" : "text-pl-grayscale-midgray"}`}>
      {integration.name}
    </S.IntegrationNameText>
  }

  return (
    <S.IntegrationNameWrapper>
      <S.IntegrationLogoWrapper>
        <img
          src={getImageSource(integration.provider && integration.provider === 'builtin' ? config.aggregatorUrl + integration.logo : integration.logo)}
          alt={integration.name}
        />
      </S.IntegrationLogoWrapper>

      <S.IntegrationNameTextWrapper>
        {
          integration.supportUrl ?
            <button onClick={() => window.open(integration.supportUrl, "_blank")}>
              {
                renderIntegrationName()
              }
            </button>
            :
            renderIntegrationName()
        }
      </S.IntegrationNameTextWrapper>
    </S.IntegrationNameWrapper>
  );
};
