import tw from "tailwind-styled-components";

export const Wrapper = tw.div``;

export const TitleWrapper = tw.div`
  bg-pl-grayscale-white
  p-4
  flex
  w-full
  items-center
  justify-between
  rounded-2xl
  border
`;

export const SearchWrapper = tw.div`
  max-w-[370px]
`;

export const DescriptionWrapper = tw.div`
  bg-pl-grayscale-white
  p-4
  flex
  w-full
  items-center
  justify-between
  rounded-2xl
  border
  font-semibold
  text-md
  text-pl-primary-green-dark
  mb-5
`;

export const FilterListWrapper = tw.div`
  flex
  items-center
  flex-wrap
  gap-3
  my-5
`;

export const ListWrapper = tw.div`
  grid
  gap-5
  md:grid-cols-3
  sm:grid-cols-2
  grid-cols-1
`;

export const Empty = tw.div`
  text-center
  col-span-3
`;

export const FooterWrapper = tw.div`
  flex
  items-center
  justify-between
  mt-8
`;

export const TotalItems = tw.div`
  text-sm
  text-pl-grayscale-gray
`;

export const Pagination = tw.div``

export const FilterItem = `
  capitalize
  text-pl-grayscale-gray
  border-pl-grayscale-lightgray
  hover:bg-pl-primary-green-light
  hover:text-pl-primary-green-default
`;

export const SelectedFilterItem = `
  bg-pl-primary-green-light
  border-pl-primary-green-light
  text-pl-primary-green-default
`;
