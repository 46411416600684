import { none, useState } from "@hookstate/core";
import BlurredContainer from "components/generics/BlurredContainer";
import Button from "components/generics/Button";
import * as _ from "lodash";
import reportMock from "components/ui/NetworkGraph/mock";
import { NetworkGraphProps } from "model/graph";
import {GraphNode} from "model/lexi";
import React, { useEffect, useRef } from "react";
import employeeService from "services/employeeService";
import stayfactor from "services/stayfactor";
import teamService from "services/teamService";
import { render } from "./graphRenderer";
import NetworkList from "./NetworkList";
import auth from "services/auth";
import {PrivacyLevel} from "model/organization";
import privacyLevelService from "services/privacyLevelService";
import {getStayFactorValueAndDelta} from "utils/stayFactor";
import {getNumDaysBetweenDates} from "utils/math";
import {PeriodSelectorState} from "components/utils/PeriodSelector";

import * as S from "./styles";

const NetworkGraph = ({ report, context }: NetworkGraphProps) => {
  const d3Container = useRef<HTMLDivElement | null>(null);
  const hasData = useState<Boolean>(true);
  const selectedItem = useState<GraphNode | null>(null);
  const contextSelectedItem = useState<GraphNode | null>(null);
  const isContextMenuOpen = useState(false);
  const stopSwitch = useState<{ stop: () => any }>({
    stop: () => {},
  });
  const isModified = useState(false);
  const reportState = useState(_.cloneDeep(report));
  const originalReport = useState(_.cloneDeep(report));

  const selectedPeriod = PeriodSelectorState.selectedPeriod.value;

  const privacyLevel = auth.privacyLevel.value as PrivacyLevel;

  useEffect(() => {
    if (!report.nodes.length) {
      hasData.set(false);
      reportState.set(reportMock.report);
      selectedItem.set(reportMock.report.nodes[0]);
    }
  }, []);

  const unfilteredLinkAvg =
    report.links.map((l) => l.value).reduce((a, b) => a + b, 0) /
    report.links.length;

  const renderChart = () => {
    stopSwitch.get({noproxy: true}).stop();

    const newStop = render({
      container: d3Container.current as HTMLDivElement,
      context: context,
      width: 500,
      height: 300,
      report: _.cloneDeep(reportState.get({noproxy: true})),
      unfilteredLinkAvg,
      onSelect: (item: GraphNode) => selectedItem.set(item),
      onSetNodeContext: (item: GraphNode) => {
        contextSelectedItem.set(item);
        isContextMenuOpen.set(true);
      },
      onClick: (item: GraphNode, withMeta: boolean) => {
        if (!withMeta && contextSelectedItem.get()) {
          contextSelectedItem.set(item);
          return;
        }

        const type = item.id === item.group ? "teams" : "employees";
        const url = `/${type}/${item.id}`;

        window.open(url, withMeta ? "_blank" : "_self");
      },
    });
    stopSwitch.set(newStop);
  };

  useEffect(() => {
    if (!d3Container.current) {
      return;
    }

    if (hasData.value) {
      Promise.all(
        reportState.nodes.map(async (nodeState) => {
          if (nodeState.external.value) {
            return;
          }

          let employee;
          let team;
          const isTeamNode = nodeState.value.group === nodeState.value.id;
          if (isTeamNode) {
            team = await teamService.getTeam(nodeState.value.id);
          } else {
            employee = await employeeService.getEmployeeInfo(nodeState.value.id);
          }

          if (employee && context === 'employees' && !privacyLevelService.hasAccess(privacyLevel)) {
            nodeState.name.set(employee.name);
          }

          const role =
            employee ? employee.role : undefined;
          nodeState.isManager.set(role === 'manager');

          // show active nodes or inactive employees in last 2 weeks
          if (isTeamNode) {
            if (team) {
              nodeState.active.set(team.active);
              nodeState.toShow.set(team.active);
            }
          } else {
            if (employee) {
              nodeState.active.set(employee.active);
              nodeState.toShow.set(true);
              if (!employee.active && employee.endDate) {
                const numDaysDeactivation = getNumDaysBetweenDates(new Date(employee.endDate), new Date());
                nodeState.toShow.set(numDaysDeactivation <= 14);
              }
            }
          }

          const sf =
            isTeamNode
              ? await stayfactor.getStayFactorTimelineForTeam(
                  nodeState.value.id, selectedPeriod
                )
              : await stayfactor.getStayFactorTimelineForEmployee(
                  nodeState.value.id, selectedPeriod
                );

          if (!sf || sf.length === 0 || (sf.length === 1 && sf[0] === 0)) {
            return;
          }

          const stayFactor = getStayFactorValueAndDelta(sf);
          nodeState.stayFactor.set({
            current: stayFactor.currentValue,
            variation: stayFactor.deltaPercentage,
          });
        })
      ).then(() => {
        // filter by show parameter
        const inactiveNodes = reportState.nodes.value
            .reduce((acc, n, i) => n.toShow ? acc : {[i]: none, ...acc}, {});

        reportState.nodes.merge(inactiveNodes);

        const activeNodes = reportState.nodes.value.filter(n => n.toShow);
        const nodesIds = activeNodes.map(n => n.id) as number[];


        const inactiveLinks = reportState.links.value
            .reduce((acc, l, i) =>
                nodesIds.includes(l.source) && nodesIds.includes(l.target) ? acc : {[i]: none, ...acc}
                , {});

        reportState.links.merge(inactiveLinks);
        originalReport.set(reportState.get({noproxy: true}));
        renderChart();
      });
    } else {
      renderChart();
    }
  }, [d3Container]);

  useEffect(() => {
    if (!contextSelectedItem) {
      isContextMenuOpen.set(false);
    }
  }, [contextSelectedItem]);

  const handleIsolateNode = () => {
    const linksToClear = {};

    reportState.links.forEach((link, index) => {
      if (
        contextSelectedItem.value?.id != link.value?.source &&
        contextSelectedItem.value?.id != link.value?.target
      ) {
        linksToClear[index] = none;
      }
    });

    reportState.links.merge(linksToClear);

    isModified.set(true);
    isContextMenuOpen.set(false);
    renderChart();
  };

  const handleRemoveNode = () => {
    for (let i = reportState.nodes.length - 1; i >= 0; i--) {
      const node = reportState.nodes[i].value;
      if (contextSelectedItem.value?.id === node.id) {
        reportState.set((prevState) => {
          const newState = { ...prevState };
          newState.nodes.splice(i, 1);

          for (let j = newState.links.length - 1; j >= 0; j--) {
            const link = newState.links[j];
            if (link.source === node.id || link.target === node.id) {
              newState.links.splice(j, 1);
            }
          }

          return newState;
        });
      }
    }

    isModified.set(true);
    isContextMenuOpen.set(false);
    renderChart();
  };

  const handleReset = () => {
    reportState.set(originalReport.get({noproxy: true}));
    isModified.set(false);
    renderChart();
  };

  return (
    <BlurredContainer blur={!hasData.value}>
      <S.Wrapper>
        <NetworkList
          items={reportState}
          selected={selectedItem}
          context={context}
        />
        <S.GraphAreaWrapper>
          <S.GraphWrapper
            ref={d3Container}
            onClick={() => {
              selectedItem.set(null);
              contextSelectedItem.set(null);
            }}
          >
            <svg id="svg-canvas" width="500px" height="300px" />
          </S.GraphWrapper>

          {!!contextSelectedItem.value && (
            <S.ContextMenuWrapper
              isOpen={!!contextSelectedItem.get() && isContextMenuOpen.get()}
            >
              <S.ContextMenuTitle>
                {contextSelectedItem.get()?.name}
              </S.ContextMenuTitle>

              <S.ContextMenuOptions>
                <Button
                  btntype="primary"
                  size="small"
                  onClick={handleIsolateNode}
                >
                  Isolate
                </Button>
                <Button
                  btntype="error"
                  size="small"
                  onClick={handleRemoveNode}
                  disabled={reportState.nodes.length < 2}
                >
                  Remove
                </Button>
                {isModified.get() && (
                  <Button btntype="neutral" size="small" onClick={handleReset}>
                    Reset
                  </Button>
                )}
              </S.ContextMenuOptions>
            </S.ContextMenuWrapper>
          )}
        </S.GraphAreaWrapper>
      </S.Wrapper>
    </BlurredContainer>
  );
};

export default NetworkGraph;
