import tw from "tailwind-styled-components";

export const Wrapper = tw.section`
  bg-pl-grayscale-white
  flex
  justify-between
  px-2
  py-2
  rounded-[20px]
  shadow
  h-[56px]
`;

export const ButtonsWrapper = tw.div`
  py-1
  flex
  items-center
  gap-2
`;

export const RightButtonWrapper = tw.div`
  px-6
`;

export const DetailsNavbar = `
  !bg-transparent
  !font-medium
  !rounded-[15px]
  !text-pl-grayscale-gray
  border-none
  text-sm
  !h-[40px]
  !px-4
  hover:!bg-pl-primary-green-light
  hover:!text-pl-primary-green-default
`;

export const Selected = `
  !bg-pl-primary-green-light
  !text-pl-primary-green-default
`;
