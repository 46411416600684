// todo: fetch colors from tailwind config, see https://tailwindcss.com/docs/configuration#referencing-in-java-script

export const CHART_RED = "#ef4444"; //tailwind red-500
export const CHART_GREEN = "#10b981"; //tailwind green-500
export const CHART_LIME_GREEN = "#38934c";
export const CHART_WHITE = "#ffffff";
export const CHART_ORANGE = "#cc8746";
export const CHART_BLACK = "#000000";
export const CHART_LIGHT_BLACK = "#00000066";

export const CHART_PLGREEN = "#00ACC2";
export const CHART_PLRED = "#FB6C71";
export const CHART_PLORANGE = "#FF8718";
