import { NetworkGraphProps } from "model/graph";
import { GraphReport } from "model/lexi";
import lexi from "./lexi";

class GraphService {

  async getNetworkGraphForDepartment(teamsIds: number[]): Promise<NetworkGraphProps> {
    const graphReport: GraphReport = await this.getOnaForDepartmentTeams(teamsIds);

    return { report: graphReport, context: "teams" };
  }

  getOnaForDepartmentTeams(teamsIds: number[]) {
    const query = this.getLexiQueryForTeams();

    return this.getGraphReportForDepartmentTeams(query, teamsIds);
  }

  async getGraphReportForDepartmentTeams(query: any, teamsIds: number[]) {
    const graphReport: GraphReport = await query.fetchAsGraphReport();

    return {
      nodes: graphReport.nodes.filter((node) => teamsIds.includes(node.id)),
      links: graphReport.links.filter(
        (link) => teamsIds.includes(link.source) && teamsIds.includes(link.target)
      ),
    };
  }


  async getNetworkGraphForTeams(): Promise<NetworkGraphProps> {
    const graphReport = await this.getOnaByForTeams();

    let teamGraph: GraphReport = graphReport;

    return { report: teamGraph, context: "teams" };
  }

  getOnaByForTeams() {
    const query = this.getLexiQueryForTeams();
    return this.getInternalGraphReport(query);
  }

  getLexiQueryForTeams() {
    let query = lexi
      .getQuery("graph")
      .out("TEAM")
      .start.daysAgo(20)
      .groupBy("TEAM");
    return query;
  }

  async getNetworkGraphPropsByTeam(teamId: number): Promise<NetworkGraphProps> {
    const graphReport = await this.getOnaByTeam(teamId);

    let teamGraph: GraphReport = {
      nodes: [],
      links: [],
    };

    teamGraph.nodes = graphReport.nodes;
    teamGraph.links = graphReport.links;

    return { report: teamGraph, context: "teams" };
  }

  getOnaByTeam(teamId: number, numDays?: number) {
    const query = this.getLexiQuery(teamId, 20);

    return this.getInternalGraphReport(query);
  }

  getOna(numDays?: number) {
    const query = this.getLexiQuery(0, numDays);

    return this.getInternalGraphReport(query);
  }

  getLexiQuery(teamId: number, numDays?: number) {
    let query = lexi.getQuery("graph");
    if (numDays) {
      query = query.start.daysAgo(numDays);
    }

    if (teamId > 0) {
      query = query.forTeam(teamId);
    }

    return query;
  }

  async getInternalGraphReport(query?: any) {
    const graphReport: GraphReport = await query.fetchAsGraphReport();

    return {
      nodes: graphReport.nodes.filter((node) => node.id > 0),
      links: graphReport.links.filter(
        (link) => link.source > 0 && link.target > 0
      ),
    };
  }
}

export default new GraphService();
