import React from "react";
import Spinner from "components/utils/Spinner";
import Logo from "components/generics/Logo";

import * as S from "./styles";

const PreAuthOverlay = () => (
  <S.Wrapper>
    <S.Content>
      <Logo src="/img/logo-sm.png" alt="Pre-Auth logo" />

      <S.SpinnerWrapper>
        <Spinner size="large" color="light" />
      </S.SpinnerWrapper>
    </S.Content>
  </S.Wrapper>
);

export default PreAuthOverlay;
