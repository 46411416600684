import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  w-full
  flex
  items-center
`;

export const TabLabel = tw.div`
  pr-3
  text-base
  font-bold
`;
