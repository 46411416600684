import React from "react";
import { createState, State, useHookstate } from "@hookstate/core";
import { openConfirmationModal } from "components/utils/Modal/ConfirmationModal";
import teamService from "services/teamService";
import employeeService from "services/employeeService";
import { Employee, Team } from "model/employee";
import { Department } from "../model/department";
import departmentService from "../services/departmentService";
import { Dashboard } from "model/dashboards";
import dashboardsService from "services/dashboards";
import { Integration } from "../model/integration";
import integrationService from "../services/integrationService";

type BulkSelectType = {
  employeeIds: Array<number>;
  teamIds: Array<number>;
  departmentIds: Array<number>;
  integrationIds: Array<number>;
  locationIds: Array<number>;
  dashboardIds: Array<number>;
};
const selectedState = createState<BulkSelectType>({
  employeeIds: [],
  teamIds: [],
  departmentIds: [],
  integrationIds: [],
  locationIds: [],
  dashboardIds: [],
});

const useCommonBulkSelect = (selected: State<number[]>) => {
  const isSelected = (id: number) => {
    return selected.value.includes(id);
  };

  const toggleSelected = (id: number) => {
    selected.set((prevState) => {
      if (prevState.includes(id)) {
        return [...prevState.filter((stateId) => stateId !== id)];
      } else {
        return [...prevState, id];
      }
    });
  };

  const clearSelection = () => {
    selected.set([]);
  };

  return {
    isSelected,
    toggleSelected,
    clearSelection,
  };
};

export const useBulkSelectEmployees = () => {
  const selected = useHookstate(selectedState.employeeIds);
  const { isSelected, toggleSelected, clearSelection } =
    useCommonBulkSelect(selected);

  const changeSingleEmployeeStatus = async (
    newStatus: boolean,
    id: number,
    employeesState: State<Employee[]>,
    onClearSelection: () => void
  ) => {
    await employeeService.updateEmployeeStatus(id, newStatus);

    const index = employeesState.value.findIndex(
      (department) => department.id === id
    );

    employeesState[index].active.set(newStatus);
    onClearSelection();
  };

  const changeMultipleEmployeeStatus = (
    newStatus: boolean,
    selectedIds: number[],
    employeesState: State<Employee[]>,
    onClearSelection: () => void
  ) => {
    selectedIds.forEach((id) => {
      changeSingleEmployeeStatus(
        newStatus,
        id,
        employeesState,
        onClearSelection
      );
    });
  };

  const deleteMultipleEmployee = async (
    selectedIds: number[],
    employeesState: State<Employee[]>,
    onClearSelection: () => void
  ) => {
    await employeeService.deleteMultipleEmployee(selectedIds);

    const employees = employeesState.value;
    const remainingEmployees = employees.filter((employee) => !selectedIds.includes(employee.id));
    employeesState.set(remainingEmployees);
    onClearSelection();
  };

  const handleUpdateEmployeeStatus = (
    status,
    selectedIds: number[],
    employeesState: State<Employee[]>,
    onClearSelection: () => void
  ) => {
    if (selectedIds.length === 1) {
      changeSingleEmployeeStatus(
        status,
        selectedIds[0],
        employeesState,
        onClearSelection
      );
    } else {
      changeMultipleEmployeeStatus(
        status,
        selectedIds,
        employeesState,
        onClearSelection
      );
    }
  };
const handleDeleteEmployees = (
    selectedIds: number[],
    employeesState: State<Employee[]>,
    onClearSelection: () => void
  ) => {
      deleteMultipleEmployee(
        selectedIds,
        employeesState,
        onClearSelection
      );
  };

  const activateSelectedEmployees = (
    selectedIds: number[],
    employeesState: State<Employee[]>,
    onClearSelection: () => void
  ) => {
    const numRecords = selectedIds.length;
    if (numRecords > 0) {
      const title =
        numRecords === 1 ? "Activate Employee" : "Activate Employees";
      const message =
        numRecords === 1
          ? "This action will activate this employee. Are you sure?"
          : "This action will activate multiple employees. Are you sure?";

      openConfirmationModal(
        message,
        title,
        () =>
          handleUpdateEmployeeStatus(
            true,
            selectedIds,
            employeesState,
            onClearSelection
          ),
        "No",
        "Yes"
      );
    }
  };

  const deactivateSelectedEmployees = (
    selectedIds: number[],
    employeesState: State<Employee[]>,
    onClearSelection: () => void
  ) => {
    const numRecords = selectedIds.length;
    if (numRecords > 0) {
      const title =
        numRecords === 1 ? "Deactivate Employee" : "Deactivate Employees";
      const message =
        numRecords === 1
          ? "This action will deactivate this employee. Are you sure?"
          : "This action will deactivate multiple employees. Are you sure?";

      openConfirmationModal(
        message,
        title,
        () =>
          handleUpdateEmployeeStatus(
            false,
            selectedIds,
            employeesState,
            onClearSelection
          ),
        "No",
        "Yes"
      );
    }
  };

  const deleteSelectedEmployees = (
    selectedIds: number[],
    employeesState: State<Employee[]>,
    onClearSelection: () => void
  ) => {
    const numRecords = selectedIds.length;
    if (numRecords > 0) {
      const title =
        numRecords === 1 ? "Delete Employee" : "Delete Employees";
      const message =
        numRecords === 1
          ? "Are you sure you want to delete this employee? You can’t undo this action and any data associated with it will be gone forever."
          : "Are you sure you want to delete this employees? You can’t undo this action and any data associated with it will be gone forever.";

      openConfirmationModal(
        message,
        title,
        () =>
          handleDeleteEmployees(
            selectedIds,
            employeesState,
            onClearSelection
          ),
        "No",
        "Yes"
      );
    }
  };
  return {
    isSelected,
    toggleSelected,
    clearSelection,
    activateSelectedEmployees,
    deactivateSelectedEmployees,
    deleteSelectedEmployees,
  };
};

export const useBulkSelectTeams = () => {
  const selected = useHookstate(selectedState.teamIds);
  const { isSelected, toggleSelected, clearSelection } =
    useCommonBulkSelect(selected);

  const changeSingleTeamStatus = async (
    newStatus: boolean,
    id: number,
    teamsState: State<Team[]>,
    onClearSelection: () => void
  ) => {
    await teamService.updateTeamStatus(id, newStatus);

    const index = teamsState.value.findIndex(
      (department) => department.id === id
    );
    const teams = teamsState.value;

    teams[index].active = newStatus;
    teamsState.set(teams);
    onClearSelection();
  };

  const changeMultipleTeamStatus = (
    newStatus: boolean,
    selectedIds: number[],
    teamsState: State<Team[]>,
    onClearSelection: () => void
  ) => {
    selectedIds.forEach((id) => {
      changeSingleTeamStatus(newStatus, id, teamsState, onClearSelection);
    });
  };

  const deleteMultipleTeam = async (
      selectedIds: number[],
      teamsState: State<Team[]>,
      onClearSelection: () => void
  ) => {
    await teamService.deleteMultipleTeam(selectedIds);

    const teams = teamsState.value;
    const remainingTeams = teams.filter((team) => !selectedIds.includes(team.id));
    teamsState.set(remainingTeams);
    onClearSelection();
  };

  const handleUpdateTeamsStatus = (
    status: boolean,
    selectedIds: number[],
    teamsState: State<Team[]>,
    onClearSelection: () => void
  ) => {
    if (selectedIds.length === 1) {
      changeSingleTeamStatus(
        status,
        selectedIds[0],
        teamsState,
        onClearSelection
      );
    } else {
      changeMultipleTeamStatus(
        status,
        selectedIds,
        teamsState,
        onClearSelection
      );
    }
  };

  const handleDeleteTeams = (
      selectedIds: number[],
      teamsState: State<Team[]>,
      onClearSelection: () => void
  ) => {
    deleteMultipleTeam(
        selectedIds,
        teamsState,
        onClearSelection
    );
  };

  const activateSelectedTeams = (
    selectedIds: number[],
    teamsState: State<Team[]>,
    onClearSelection: () => void
  ) => {
    const numRecords = selectedIds.length;

    if (numRecords > 0) {
      const title = numRecords === 1 ? "Activate Team" : "Activate Teams";
      const message =
        numRecords === 1
          ? "This action will activate this team. Are you sure?"
          : "This action will activate multiple teams. Are you sure?";
      openConfirmationModal(
        message,
        title,
        () =>
          handleUpdateTeamsStatus(
            true,
            selectedIds,
            teamsState,
            onClearSelection
          ),
        "No",
        "Yes"
      );
    }
  };

  const deactivateSelectedTeams = (
    selectedIds: number[],
    teamsState: State<Team[]>,
    onClearSelection: () => void
  ) => {
    const numRecords = selectedIds.length;
    if (numRecords > 0) {
      const title = numRecords === 1 ? "Deactivate Team" : "Deactivate Teams";
      const message =
        numRecords === 1
          ? "This action will deactivate this team. Are you sure?"
          : "This action will deactivate multiple teams. Are you sure?";

      openConfirmationModal(
        message,
        title,
        () =>
          handleUpdateTeamsStatus(
            false,
            selectedIds,
            teamsState,
            onClearSelection
          ),
        "No",
        "Yes"
      );
    }
  };

  const deleteSelectedTeams = (
      selectedIds: number[],
      teamsState: State<Team[]>,
      onClearSelection: () => void
  ) => {
    const numRecords = selectedIds.length;
    if (numRecords > 0) {
      const title =
          numRecords === 1 ? "Delete Team" : "Delete Teams";
      const message =
          numRecords === 1
              ? "Are you sure you want to delete this team? You can’t undo this action and any data associated with it will be gone forever."
              : "Are you sure you want to delete this teams? You can’t undo this action and any data associated with it will be gone forever.";

      openConfirmationModal(
          message,
          title,
          () =>
              handleDeleteTeams(
                  selectedIds,
                  teamsState,
                  onClearSelection
              ),
          "No",
          "Yes"
      );
    }  const deleteSelectedTeams = (
        selectedIds: number[],
        teamsState: State<Team[]>,
        onClearSelection: () => void
    ) => {
      const numRecords = selectedIds.length;
      if (numRecords > 0) {
        const title =
            numRecords === 1 ? "Delete Team" : "Delete Teams";
        const message =
            numRecords === 1
                ? "Are you sure you want to delete this team? You can’t undo this action and any data associated with it will be gone forever."
                : "Are you sure you want to delete this teams? You can’t undo this action and any data associated with it will be gone forever.";

        openConfirmationModal(
            message,
            title,
            () =>
                handleDeleteTeams(
                    selectedIds,
                    teamsState,
                    onClearSelection
                ),
            "No",
            "Yes"
        );
      }
    };
  };

  return {
    isSelected,
    toggleSelected,
    clearSelection,
    activateSelectedTeams,
    deactivateSelectedTeams,
    deleteSelectedTeams,
  };
};

export const useBulkSelectDepartment = () => {
  const selected = useHookstate(selectedState.departmentIds);
  const { isSelected, toggleSelected, clearSelection } =
    useCommonBulkSelect(selected);

  const changeSingleDepartmentStatus = async (
    newStatus: boolean,
    id: number,
    departmentsState: State<Department[]>,
    onClearSelection: () => void
  ) => {
    await departmentService.updateDepartmentStatus(id, newStatus);

    const index = departmentsState.value.findIndex(
      (department) => department.id === id
    );
    const departments = departmentsState.value;

    departments[index].active = newStatus;
    departmentsState.set(departments);
    onClearSelection();
  };

  const changeMultipleDepartmentStatus = (
    newStatus: boolean,
    selectedIds: number[],
    departmentsState: State<Department[]>,
    onClearSelection: () => void
  ) => {
    selectedIds.forEach((id) => {
      changeSingleDepartmentStatus(
        newStatus,
        id,
        departmentsState,
        onClearSelection
      );
    });
  };

  const deleteMultipleDepartment = async (
      selectedIds: number[],
      departmentsState: State<Department[]>,
      onClearSelection: () => void
  ) => {
    await departmentService.deleteMultipleDepartment(selectedIds);

    const department = departmentsState.value;
    const remainingDepartments = department.filter((department) => !selectedIds.includes(department.id));
    departmentsState.set(remainingDepartments);
    onClearSelection();
  };

  const handleUpdateDepartmentsStatus = (
    status: boolean,
    selectedIds: number[],
    departmentsState: State<Department[]>,
    onClearSelection: () => void
  ) => {
    if (selected.value.length === 1) {
      changeSingleDepartmentStatus(
        status,
        selectedIds[0],
        departmentsState,
        onClearSelection
      );
    } else {
      changeMultipleDepartmentStatus(
        status,
        selectedIds,
        departmentsState,
        onClearSelection
      );
    }
  };

  const handleDeleteDepartments = (
      selectedIds: number[],
      departmentsState: State<Department[]>,
      onClearSelection: () => void
  ) => {
    deleteMultipleDepartment(
        selectedIds,
        departmentsState,
        onClearSelection
    );
  };

  const activateSelectedDepartments = (
    selectedIds: number[],
    departmentsState: State<Department[]>,
    onClearSelection: () => void
  ) => {
    const numRecords = selectedIds.length;
    if (numRecords > 0) {
      const title = numRecords === 1 ? "Activate Team" : "Activate Teams";
      const message =
        numRecords === 1
          ? "This action will activate this team. Are you sure?"
          : "This action will activate multiple teams. Are you sure?";

      openConfirmationModal(
        message,
        title,
        () =>
          handleUpdateDepartmentsStatus(
            true,
            selectedIds,
            departmentsState,
            onClearSelection
          ),
        "No",
        "Yes"
      );
    }
  };

  const deactivateSelectedDepartments = (
    selectedIds: number[],
    departmentsState: State<Department[]>,
    onClearSelection: () => void
  ) => {
    const numRecords = selectedIds.length;
    if (numRecords > 0) {
      const title = numRecords === 1 ? "Deactivate Team" : "Deactivate Teams";
      const message =
        numRecords === 1
          ? "This action will deactivate this team. Are you sure?"
          : "This action will deactivate multiple teams. Are you sure?";

      openConfirmationModal(
        message,
        title,
        () =>
          handleUpdateDepartmentsStatus(
            false,
            selectedIds,
            departmentsState,
            onClearSelection
          ),
        "No",
        "Yes"
      );
    }
  };

  const deleteSelectedDepartments = (
      selectedIds: number[],
      departmentState: State<Department[]>,
      onClearSelection: () => void
  ) => {
    const numRecords = selectedIds.length;
    if (numRecords > 0) {
      const title =
          numRecords === 1 ? "Delete Department" : "Delete Departments";
      const message =
          numRecords === 1
              ? "Are you sure you want to delete this department? You can’t undo this action and any data associated with it will be gone forever."
              : "Are you sure you want to delete this departments? You can’t undo this action and any data associated with it will be gone forever.";

      openConfirmationModal(
          message,
          title,
          () =>
              handleDeleteDepartments(
                  selectedIds,
                  departmentState,
                  onClearSelection
              ),
          "No",
          "Yes"
      );
    }
  };
  return {
    isSelected,
    toggleSelected,
    clearSelection,
    activateSelectedDepartments,
    deleteSelectedDepartments,
    deactivateSelectedDepartments,
  };
};

export const useBulkSelectIntegration = () => {
  const selected = useHookstate(selectedState.integrationIds);
  const { isSelected, toggleSelected, clearSelection } =
    useCommonBulkSelect(selected);

  const changeSingleIntegrationStatus = (
    newStatus: boolean,
    id: number,
    integrationsState: State<Integration[]>
  ) => {
    integrationService.updateIntegrationStatus(id, newStatus).then(() => {
      integrationsState.find((t) => t.id.value === id)?.active.set(newStatus);
      clearSelection();
    });
  };

  const changeMultipleIntegrationStatus = (
    newStatus: boolean,
    selectedIds: number[],
    integrationsState: State<Integration[]>
  ) => {
    integrationService
      .updateMultipleIntegrationsStatus(selectedIds, newStatus)
      .then(() => {
        selectedIds.forEach((id) => {
          integrationsState
            .find((t) => t.id.value === id)
            ?.active.set(newStatus);
        });
        clearSelection();
      });
  };

  const handleUpdateIntegrationsStatus = (
    status: boolean,
    selectedIds: number[],
    integrationsState: State<Integration[]>
  ) => {
    if (selected.value.length === 1) {
      changeSingleIntegrationStatus(status, selectedIds[0], integrationsState);
    } else {
      changeMultipleIntegrationStatus(status, selectedIds, integrationsState);
    }
  };

  const activateSelectedIntegrations = (
    selectedIds: number[],
    integrationsState: State<Integration[]>
  ) => {
    const numRecords = selectedIds.length;
    if (numRecords > 0) {
      const title =
        numRecords === 1 ? "Activate Integration" : "Activate Integrations";
      const message =
        numRecords === 1
          ? "This action will activate this integration. Are you sure?"
          : "This action will activate multiple integrations. Are you sure?";

      openConfirmationModal(
        message,
        title,
        () =>
          handleUpdateIntegrationsStatus(true, selectedIds, integrationsState),
        "No",
        "Yes"
      );
    }
  };

  const deactivateSelectedIntegrations = (
    selectedIds: number[],
    integrationsState: State<Integration[]>
  ) => {
    const numRecords = selectedIds.length;
    if (numRecords > 0) {
      const title =
        numRecords === 1 ? "Deactivate Integration" : "Deactivate Integrations";
      const message =
        numRecords === 1
          ? "This action will deactivate this integration. Are you sure?"
          : "This action will deactivate multiple integrations. Are you sure?";

      openConfirmationModal(
        message,
        title,
        () =>
          handleUpdateIntegrationsStatus(false, selectedIds, integrationsState),
        "No",
        "Yes"
      );
    }
  };

  return {
    isSelected,
    toggleSelected,
    clearSelection,
    activateSelectedIntegrations,
    deactivateSelectedIntegrations,
  };
};

export const useBulkSelectLocations = () => {
  const selected = useHookstate(selectedState.locationIds);
  const { isSelected, toggleSelected, clearSelection } =
    useCommonBulkSelect(selected);

  return {
    isSelected,
    toggleSelected,
    clearSelection,
  };
};

export const useBulkSelectDashboard = () => {
  const selected = useHookstate(selectedState.dashboardIds);
  const { isSelected, toggleSelected, clearSelection } =
    useCommonBulkSelect(selected);

  const deleteSingleDashboard = async (
    id: number,
    dashboardState: State<Dashboard[]>,
    onClearSelection: () => void
  ) => {
    await dashboardsService.deleteDashboard(id);

    const index = dashboardState.value.findIndex((dash) => dash.id === id);
    const dashboards = dashboardState.value;

    dashboards.splice(index, 1);
    dashboardState.set(dashboards);
    onClearSelection();
  };

  const deleteMultipleDashboards = (
    selectedIds: number[],
    dashboardState: State<Dashboard[]>,
    onClearSelection: () => void
  ) => {
    selectedIds.forEach((id) => {
      deleteSingleDashboard(id, dashboardState, onClearSelection);
    });
  };

  const handleDeleteDashboard = (
    selectedIds: number[],
    dashboardState: State<Dashboard[]>,
    onClearSelection: () => void
  ) => {
    if (selectedIds.length === 1) {
      deleteSingleDashboard(selectedIds[0], dashboardState, onClearSelection);
    } else {
      deleteMultipleDashboards(selectedIds, dashboardState, onClearSelection);
    }
  };

  const deleteSelectedDashboards = (
    selectedIds: number[],
    dashboardState: State<Dashboard[]>,
    onClearSelection: () => void
  ) => {
    const numRecords = selectedIds.length;
    if (numRecords > 0) {
      const title = numRecords === 1 ? "Delete Dashboard" : "Delete Dashboards";
      const message =
        numRecords === 1
          ? "This action will delete this dashboard. Are you sure?"
          : "This action will delete multiple dashboards. Are you sure?";

      openConfirmationModal(
        message,
        title,
        () =>
          handleDeleteDashboard(selectedIds, dashboardState, onClearSelection),
        "No",
        "Yes"
      );
    }
  };

  return {
    isSelected,
    toggleSelected,
    clearSelection,
    deleteSelectedDashboards,
  };
};
