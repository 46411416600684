import tw from "tailwind-styled-components";

type WrapperProps = {
  className: string;
  isDisabled: boolean;
};

export const SelectWrapper = tw.div<WrapperProps>`
  ${({ isDisabled, className }) => `
    w-full
    ${isDisabled ? "cursor-not-allowed" : "" }
    ${className}
  `}
`;

export const OptionLabelWrapper = tw.div`
  flex
`;

export const OptionLabelEmail = tw.div`
  ml-[6px]
  text-pl-grayscale-gray
`;
