import { createState, useState } from "@hookstate/core";
import { ResponseStatusAndText } from "services/utils";
import {
  ToastMessage,
  ToastMessageStatus,
  ToastMessageType,
} from "../../../model/toast";
import ToastNotificationMessage from "./ToastNotificationMessage";

const toastMessagesState = createState<{ messages: ToastMessage[] }>({
  messages: [],
});

function messageExists(message: string, type: ToastMessageType) {
  return toastMessagesState.value.messages.filter(
    (m) =>
      m.type == type &&
      m.message == message &&
      m.status != ToastMessageStatus.CLOSED
  ).length;
}

function addMessage(message: string, type: ToastMessageType) {
  if (messageExists(message, type)) {
    return;
  }

  const index = toastMessagesState.messages.length;

  toastMessagesState.messages[index].set({
    type,
    message,
    status: ToastMessageStatus.OPENED,
  });

  setTimeout(
    () =>
      toastMessagesState.messages[index].status.set(ToastMessageStatus.CLOSED),
    3000
  );
}

export const addToastNotification = (response: ResponseStatusAndText) => {
  if (response?.message) {
    if (response.status < 400) {
      addSuccessToastNotification(response.message);
    } else {
      addErrorToastNotification(response.message);
    }
  }
};

export const addSuccessToastNotification = (message: string) => {
  addMessage(message, ToastMessageType.SUCCESS);
};

export const addErrorToastNotification = (message: string) => {
  addMessage(message, ToastMessageType.ERROR);
};

export default function ToastNotificationContainer() {
  const state = useState(toastMessagesState);

  const toastMessages = state.messages.filter(
    (m) => m.status.get() != ToastMessageStatus.CLOSED
  );

  return (
    <>
      <div
        aria-live="assertive"
        className="fixed h-[fit-content] w-full flex px-4 py-6 top-16 pointer-events-none sm:p-6 sm:items-start z-50"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end my-2">
          {toastMessages.map((msgState, index) => (
            <ToastNotificationMessage key={index} messageState={msgState} />
          ))}
        </div>
      </div>
    </>
  );
}
