import tw from "tailwind-styled-components";
import {getBGLightColor, getFontColor} from "utils/stayFactor";

export const IconsWrapper = tw.div`
  absolute
  top-2
  left-[70%]
  flex
  gap-1
`;

export const Wrapper = tw.div`
  gap-5
  px-2
  relative
`;

export const ValueLabel = tw.div`
  text-[22px]
  font-bold
  absolute
  left-[44%]
  w-8
  h-8
  flex
  items-center
  justify-center
  top-[26%]
`;

export const avatarStyle = () => {
  let avatarStyle = 'background: ##00ACC2';
  avatarStyle += '; font-weight: bold';
  avatarStyle += '; font-size: 14px';
  avatarStyle += '; border-radius: 100%';
  avatarStyle += '; padding: 1px';
  avatarStyle += '; padding-top: 4px';
  avatarStyle += '; width: 30px';
  avatarStyle += '; height: 30px';
  avatarStyle += '; vertical-align: middle';
  avatarStyle += '; text-align: center';
  avatarStyle += '; background-color: #00ACC2';
  avatarStyle += '; color: #FFFFFF';
  return avatarStyle;
}

export const nameStyle = () => {
  let nameStyle = 'background: #FFFFFF';
  nameStyle += '; font-size: 13px';
  nameStyle += '; font-weight: bold';
  nameStyle += '; vertical-align: middle';
  nameStyle += '; padding-top: 4px';
  return nameStyle;
}

export const sfStyle = (sf: number) => {
  let sfStyle = 'font-size: 13px';
  sfStyle += '; font-weight: bold';
  sfStyle += '; border-radius: 10px';
  sfStyle += '; padding: 1px';
  sfStyle += '; padding-top: 4px';
  sfStyle += '; width: 32px';
  sfStyle += '; height: 28px';
  sfStyle += '; vertical-align: middle';
  sfStyle += '; text-align: center';
  sfStyle += '; background-color: ' + getBGLightColor(sf);
  sfStyle += '; color: ' + getFontColor(sf);
  return sfStyle;
}

export const deltaStyle = () => {
  let deltaStyle = 'font-size: 13px';
  deltaStyle += '; font-weight: bold';
  deltaStyle += '; vertical-align: middle';
  deltaStyle += '; padding-top: 4px';
  deltaStyle += '; vertical-align: middle';
  deltaStyle += '; text-align: center';
  deltaStyle += '; display: flex';
  return deltaStyle;
}

export const arrowStyle = 'font-size: 8px; padding-top: 5px; padding-right: 2px';

export const tooltipStyle = (sf: number) => {
  let divStyle = 'background: #FFFFFF';
  divStyle += '; border-color:' + getBGLightColor(sf);
  divStyle += '; border-width: 2px';
  divStyle += '; padding: 8px';
  divStyle += '; border-radius: 10px';
  divStyle += '; gap: 12px';
  divStyle += '; display: flex';
  divStyle += '; vertical-align: middle';
  return divStyle;
}

export const iconsClassname = `hover:text-pl-grayscale-black`;
