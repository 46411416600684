import { createState, useState } from "@hookstate/core";
import config from "config";
import { Employee, Team } from "model/employee";
import { initials } from "utils/text";
import { Link } from "react-router-dom";
import React from "react";

// we map instead of computing so that tailwind jit compiler can catch this
const SIZES = {
  0: "h-6 w-6",
  1: "h-8 w-8",
  2: "h-10 w-10",
  3: "h-14 w-14",
  4: "h-16 w-16",
  5: "h-24 w-24",
  6: "h-36 w-36",
  7: "h-48 w-48",
};

const TEXT_SIZES = {
  0: "text-sm",
  1: "text-base",
  2: "text-2xl",
  3: "text-3xl",
  4: "text-3xl",
  5: "text-4xl",
  6: "text-6xl",
  7: "text-8xl",
};

type Size = keyof typeof SIZES;

const errorAvatars = createState({} as { [url: string]: boolean });

export function TeamAvatarGroup({
  teams,
  hoverable = false,
}: {
  teams: Team[];
  hoverable?: boolean;
}) {
  const size = teams.length;
  const sublist = teams.slice(0, size > 5 ? 4 : size);

  return (
    <div>
      <div className="flex -space-x-2 overflow-hidden">
        {sublist.map((team, index) => {
          return (
            <div key={index} className="bg-pl-grayscale-white p-1 rounded-full">
              <Avatar
                size={1}
                name={team.name}
                linkTo={`/teams/${team.id}`}
                avatarColor={true}
                active={team.active}
                hoverable={hoverable}
              />
            </div>
          );
        })}
        {size > 5 && (
          <div className="bg-pl-grayscale-white p-1 rounded-full">
          <Avatar
            size={1}
            name={`+${String(size-4)}`}
            avatarColor={true}
            isMoreMembersAvatar={true}
          />
        </div>
      )}
      </div>
    </div>
  );
}

export function EmployeeAvatarGroup({
  employees,
  hoverable = false,
  showAvatarImage = true,
}: {
  employees: Employee[];
  hoverable?: boolean;
  showAvatarImage?: boolean;
}) {
  const size = employees.length;
  const sublist = employees.slice(0, size > 4 ? 4 : size);

  return (
    <div>
      <div className="flex -space-x-2 overflow-hidden">
        {sublist.map((employee, index) => {
          return (
            <div key={index} className="bg-pl-grayscale-white p-1 rounded-full">
              <EmployeeAvatar
                key={employee.id}
                employee={employee}
                size={1}
                avatarColor={true}
                active={employee.active}
                hoverable={hoverable}
                showAvatarImage={showAvatarImage}
              />
            </div>
          );
        })}
        {size > 5 && (
          <div className="bg-pl-grayscale-white p-1 rounded-full">
          <Avatar
            size={1}
            name={`+${String(size-4)}`}
            avatarColor={true}
            isMoreMembersAvatar={true}
          />
        </div>
      )}
      </div>
    </div>
  );
}


export function EmployeeAvatar({
  employee,
  size,
  linkTo = true,
  avatarColor,
  active = true,
  hoverable,
  showAvatarImage = true,
}: {
  employee: Employee;
  size: Size;
  linkTo?: boolean;
  avatarColor?: boolean;
  active?: boolean;
  hoverable?: boolean;
  showAvatarImage?: boolean;
}) {
  return (
    <>
      {linkTo ? (
        <Avatar
          size={size}
          name={employee.name}
          url={employee.avatar}
          linkTo={`/employees/${employee.id}`}
          avatarColor={avatarColor}
          active={active}
          hoverable={hoverable}
          showAvatarImage={showAvatarImage}
        />
      ) : (
        <Avatar
          size={size}
          name={employee.name}
          url={employee.avatar}
          avatarColor={avatarColor}
          active={active}
          hoverable={hoverable}
          showAvatarImage={showAvatarImage}
        />
      )}
    </>
  );
}

export default function Avatar({
  name,
  title,
  url,
  size,
  linkTo,
  avatarColor,
  active = true,
  hoverable,
  grayscale = false,
  classname = "",
  showAvatarImage = true,
  isMoreMembersAvatar = false,
}: {
  name?: string;
  title?: string;
  url?: string | null;
  size: Size;
  linkTo?: string;
  avatarColor?: boolean;
  active?: boolean;
  hoverable?: boolean;
  grayscale?: boolean;
  classname?: string;
  showAvatarImage?: boolean;
  isMoreMembersAvatar?: boolean;
}) {
  const avatar = (
    <InnerAvatar
      title={title}
      size={size}
      url={url}
      name={name}
      avatarColor={avatarColor}
      active={active}
      hoverable={hoverable}
      grayscale={grayscale}
      classname={classname}
      showAvatarImage={showAvatarImage}
      isMoreMembersAvatar={isMoreMembersAvatar}
    />
  );

  if (linkTo) {
    return <Link to={linkTo}>{avatar}</Link>;
  } else {
    return avatar;
  }
}

function InnerAvatar({
  name,
  title,
  url,
  size,
  avatarColor = false,
  active = true,
  hoverable,
  grayscale = false,
  classname = "",
  showAvatarImage = true,
  isMoreMembersAvatar = false,
}: {
  name?: string;
  title?: string;
  url?: string | null;
  size: Size;
  avatarColor?: boolean;
  active?: boolean;
  hoverable?: boolean;
  grayscale?: boolean;
  classname?: string;
  showAvatarImage?: boolean;
  isMoreMembersAvatar?: boolean;
}) {
  const sizeClasses = SIZES[size];
  const isError = useState(errorAvatars[url || ""]);

  if (showAvatarImage && url && !isError.get()) {
    return (
      <img
        src={url.startsWith("http") ? url : config.appUrl + url}
        onError={(e) =>
          errorAvatars.set({ ...errorAvatars.get(), [url]: true })
        }
        className={`inline-block rounded-full ${sizeClasses}`}
        alt={name || "avatar"}
        title={title}
      />
    );
  } else {
    const fontSize = TEXT_SIZES[size];
    return (
      <div
        className={`${sizeClasses} rounded-full text-center ${
          active ? "bg-pl-primary-green-default" : "!bg-pl-grayscale-midgray"
        } align-middle flex flex-col justify-center items-center ${
          active && hoverable && "hover:bg-pl-primary-green-light"
        } group ${grayscale && "bg-pl-grayscale-black hover:bg-pl-grayscale-black"}
        ${classname}`}
        title={title}
      >
        { name ? (
          <span
            className={`font-bold text-pl-grayscale-white ${
              active && hoverable && "group-hover:text-pl-primary-green-default"}
              ${isMoreMembersAvatar ? "text-small" : fontSize}
              ${grayscale && "group-hover:text-pl-grayscale-white"}`}
          >
            {
              isMoreMembersAvatar ?
                name
                :
                initials(name)
            }
          </span>
        ) : null}
      </div>
    );
  }
}
