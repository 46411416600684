import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  relative
  mb-6
  flex
  flex-col
  gap-2
`;

export const Title = tw.h4`
  text-pl-grayscale-black
  text-2xl
  font-bold
`;

export const Content = tw.div`
  flex
  flex-col
  items-center
  relative
  h-auto
  mt-[100px]
`;

export const CardWrapper = tw.div`
  h-auto
  absolute
  transition-all
  duration-300
  w-[600px]
`;

export const Card = tw.div`
  relative
  cursor-pointer
`;

export const NoRecMessageWrapper = tw.div`
  flex
  flex-col
  items-center
  pt-8
  px-2
  text-sm
  font-normal
`;

export const firstCardStyles = `
  -translate-y-16
  scale-90
  z-[1]
`;

export const lastCardStyles = `
  -translate-y-32
  scale-75
  opacity-75
`;

export const activeStyles = `
  relative
  z-[2]
  active
`;
