import React from "react";
import {LoadingWidget} from "components/utils/LoadingWidget";
import teamService from "services/teamService";
import TeamMembers from "./TeamMembers";
import {Team} from "model/employee";
import {useLocation, useParams} from "react-router-dom";
import Breadcrumbs from "components/generics/Breadcrumbs";
import DetailsNavbar from "components/generics/DetailsNavbar";
import TeamFormPage from "routes/teamForm";
import {TeamDashboard} from "./TeamDashboard";
import authService from "services/auth";

import {ReactComponent as DashboardIcon} from "icons/dashboard.svg";
import {ReactComponent as TeamIcon} from "icons/team.svg";
import {ReactComponent as CogIcon} from "icons/cog.svg";
import {useState} from "@hookstate/core";
import {useDependantState} from "../../utils/state";

const TeamPage = () => {
  const teamId = +useParams<{ id: string }>().id!;
  const currentTeam =
    useDependantState(() => teamService.getTeam(teamId, { employees: true, skills: true }, true), [teamId]);

  return (
    <>
      <LoadingWidget<Team>
        value={currentTeam}
        builder={(team) => <TeamPageWithNav team={team} />}
      />
    </>
  );
};

const TeamPageWithNav = React.memo(({ team }: { team: Team }) => {
  const { tab } = useParams<{ tab: string }>();

  const getNavItems = () => {
    const navItems = [
      {
        label: "Dashboard",
        icon: <DashboardIcon />,
        url: `/teams/${team.id}`,
      },
      {
        label: "Team Members",
        icon: <TeamIcon />,
        url: `/teams/${team.id}/members`,
      }
    ];

    if (authService.currentUserIsAdmin()) {
      navItems.push({
        label: "Manage Team",
        icon: <CogIcon />,
        url: `/teams/${team.id}/edit`,
      });
    }
    return navItems;
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: "Teams", url: `/teams` },
          { label: `${team.name} Team`, url: `/teams/${team.id}` },
          {
            label:
              tab === "members"
                ? "Team Members"
                : tab === "edit"
                ? "Manage Team"
                : "",

            url: !!tab ? `/teams/${team.id}/${tab}` : `/teams/${team.id}`,
          },
        ]}
      />
      <DetailsNavbar items={getNavItems()} />
      {tab === "members" ? (
        <TeamMembers team={team} />
      ) : tab === "edit" ? (
        <TeamFormPage team={team} />
      ) : (
        <TeamDashboard team={team} />
      )}
    </>
  );
});

export default TeamPage;
