import React from "react";
import Avatar from "../../Avatar";

import * as S from "./styles";

import { ReactComponent as DividerIcon } from "icons/divider.svg";
import { ReactComponent as ActiveIcon } from "icons/active.svg";

export type TabsTitle = {
  tabNumber: number;
  tabTitle: string;
};

export default function ModalTabsTitle({tabs, currentTab} : {tabs: TabsTitle[], currentTab: number}) {

  return (
    <>
      <S.Wrapper>
        {
          tabs.map((tab, index) => (
            <>
              <S.TabIconWrapper key={`tab-${index}`}>
                <S.TabIcon>
                  {
                    tab.tabNumber > currentTab ?
                      <Avatar title={tab.tabNumber.toString()} name={tab.tabNumber.toString()} size={0} classname={"!bg-opacity-50"}/>
                      :
                      tab.tabNumber < currentTab ?
                        <ActiveIcon />
                        :
                        <Avatar title={tab.tabNumber.toString()} name={tab.tabNumber.toString()} size={0}/>
                  }
                </S.TabIcon>
              </S.TabIconWrapper>
              {
                tab.tabNumber === currentTab &&
                  <S.TabLabel>
                    {tab.tabTitle}
                  </S.TabLabel>
              }
              {
                tab.tabNumber < tabs.length &&
                <S.TabSeparator active={tab.tabNumber < currentTab}>
                  <DividerIcon />
                </S.TabSeparator>
              }
            </>
          ))
        }
      </S.Wrapper>
    </>
  );
}
