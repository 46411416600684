import tw from "tailwind-styled-components";

export const FirstMemberWrapper = tw.div`
  w-max
  flex
  flex-col
  gap-5
  m-auto
  mt-20
  items-center
`;

export const FirstMemberTitle = tw.h2`
  text-pl-grayscale-black
  text-xl
`;

export const FirstMemberText = tw.h2`
  text-pl-grayscale-black
  text-base
  font-normal
`;

export const RightChildWrapper = tw.div`
  max-w-[280px]
`;
