import React from "react";
import {LoadingWidget} from "components/utils/LoadingWidget";
import dashboardService from "services/dashboards";
import TabTitle from "components/structure/TabTitle";
import graphService from "services/graphService";
import {Employee, Team} from "model/employee";
import DashboardList from "components/ui/DashboardList";
import BaseDashboard from 'components/structure/BaseDashboard';
import RecommendationTable from "components/ui/RecommendationTable";
import employeeService from "services/employeeService";
import teamService from "services/teamService";
import departmentService from "services/departmentService";
import {useState} from "@hookstate/core";
import {Dashboard} from "../../../model/dashboards";

export default function HomePage({me}: { me: Employee }) {
  TabTitle("Home");

  const networkGraphData = useState(() => graphService.getNetworkGraphForTeams());

  const dashboards = useState(() => dashboardService.getDashboards());

  const employees = useState(() => employeeService.getActiveEmployees());

  const teams = useState(() => teamService.getTeams({employees: true, skills: false}));

  const departments =
    useState(() => departmentService.getAllDepartments({
      employees: true,
      skills: false,
      teams: false,
      kpis: false
    }));

  const recommendationsTarget = () => {
    const baseText = "Top Recommendations for ";
    if (me.role === "admin") {
      return baseText + "Organization";
    } else if (me.role === "manager") {
      const countTeams = me.managedTeams ? me.managedTeams.length : 0;
      return countTeams > 1
        ? baseText + "your Teams"
        : countTeams === 1 && me.managedTeams
          ? baseText + me.managedTeams[0].name + " Team"
          : baseText + "Team"; // should never happen
    }
  };

  return (
    <>
      <BaseDashboard
        title="Teams"
        networkGraphContext="teams"
        networkGraphData={networkGraphData}
        employees={employees}
        teams={teams}
        departments={departments}
        pieChartWithViewOptions={true}
        rightChildren={
          <LoadingWidget<Dashboard[]>
            value={dashboards}
            builder={(dashboardList) => (
              <DashboardList dashboardList={dashboardList}/>
            )}
          />
        }
        leftChildren={
          <RecommendationTable
            title={recommendationsTarget()}
            homepage={true}
          />
        }
      />
    </>
  );
}
