import React from "react";
import { CommonModal } from "./CommonModal";
import { Dialog } from "@headlessui/react";
import { createState, useState } from "@hookstate/core";
import Button from "components/generics/Button";
import Avatar from "components/utils/Avatar";
import {ReactComponent as Close} from "icons/close.svg";
import * as S from "./styles";

type StatusConfirmationModalStateProps = {
  open: boolean;
  message: string;
  title: string;
  subject: string;
  subjectType: string;
  onSave: () => void;
  closeLabel?: string;
  continueLabel?: string;
};

const StatusConfirmationModalState = createState<StatusConfirmationModalStateProps>({
  open: false,
  message: "",
  title: "",
  subject: "",
  subjectType: "",
  onSave: () => {},
  closeLabel: "Close",
  continueLabel: "Continue",
});

export const openStatusConfirmationModal = (
  message: string,
  title: string,
  subject: string,
  subjectType: string,
  onSave: () => void,
  closeLabel?: string,
  continueLabel?: string,
) => {
  StatusConfirmationModalState.set({
    open: true,
    message: message,
    title: title,
    subject: subject,
    subjectType: subjectType,
    onSave: onSave,
    closeLabel: closeLabel ? closeLabel : "Cancel",
    continueLabel: continueLabel ? continueLabel : "Continue"
  });
};

export function StatusConfirmationModal() {
  const modalState = useState(StatusConfirmationModalState);

  const closeModal = () => modalState.open.set(false);

  const handleSave = () => {
    closeModal();
    return modalState.value.onSave();
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <>
      <CommonModal openState={StatusConfirmationModalState.open}>
        <S.StatusConfirmationWrapper>
          <S.CloseButtonWrapper>
            <Button minimal onClick={handleClose}>
              <Close className="w-5 text-pl-grayscale-gray"/>
            </Button>
          </S.CloseButtonWrapper>
          <S.AvatarWrapper>
            <Avatar
              size={5}
              name={modalState.subject.value}
            />
          </S.AvatarWrapper>
          <S.StatusConfimationTitle>
            {modalState.title.value}
          </S.StatusConfimationTitle>

          <S.MessageWrapper>
            {modalState.message.value}
          </S.MessageWrapper>

          <S.BottomButtonsWrapper>
            <Button className={"w-[107px] h-12 border-black"} $shadow={true} onClick={handleClose}>{modalState.closeLabel.value}</Button>
            <Button className={"w-[107px] h-12"} $shadow={true} btntype="primary" onClick={handleSave}>
              {modalState.continueLabel.value}
            </Button>
          </S.BottomButtonsWrapper>
        </S.StatusConfirmationWrapper>
      </CommonModal>
    </>
  );
}