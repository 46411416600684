import { ArrowNarrowRightIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import Button from "components/generics/Button";
import employeeService from "services/employeeService";
import organizationService from "services/organizationService";
import teamService from "services/teamService";
import integrationService from "services/integrationService";
import EmployeeModal, {openAddEmployeeModal} from "../../employeeForm/manageEmployee/ManageEmployee";
import React from "react";

import * as S from "./styles";

export default function ManagerLandingPage() {

  const onAdd = () => {
    Promise.all([
      employeeService.getManagers(),
      organizationService.getLocations(),
      teamService.getActiveTeams(),
      integrationService.getActiveIntegrations(),
    ]).then(([managers, locations, teams, integrations]) =>
      openAddEmployeeModal(
        managers,
        locations,
        teams,
        integrations
      )
    )
  }

  return (
    <>
      <EmployeeModal />
      <S.Wrapper>
        <S.BgWrapper>
          <img
            src="/img/networkgraph-placeholder.svg"
            alt={""}
            className={"object-cover opacity-25"}
          />
        </S.BgWrapper>
        <S.ButtonsWrapper>
          <Link to={"/integrations/add"}>
            <Button
              btntype="primary"
              $shadow={true}
              icon={<ArrowNarrowRightIcon />}
              size="large"
              fullwidth
            >
              Add integrations
            </Button>
          </Link>
          <Button $shadow={true}
                  btntype={"primary"}
                  icon={<ArrowNarrowRightIcon />}
                  size="large"
                  fullwidth
                  onClick={() => onAdd()}
                  className={"text-sm"}
          >
            Add employee
          </Button>
        </S.ButtonsWrapper>
      </S.Wrapper>
    </>
  );
}
