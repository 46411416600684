import React from "react";
import { ChartData, ChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";
import { ChartLabelValuePair } from "model/chart";
import { SAMPLE_FOCUSED_HOURS } from "model/mockedValues";
import BlurredContainer from "components/generics/BlurredContainer";

export default function BarChart({
  labelValuePairs,
}: {
  labelValuePairs: ChartLabelValuePair[];
}) {
  let hasData = true;

  const events = labelValuePairs.reduce((a, b) => a + b.value, 0);
  if (events === 0) {
    hasData = false;
  }

  const data = () => {
    if (!hasData) {
      return getChartData(SAMPLE_FOCUSED_HOURS);
    }
    return getChartData(labelValuePairs);
  };

  const getChartData = (values: ChartLabelValuePair[]) => {
    return {
      labels: values.map((pair) => pair.label),
      datasets: [
        {
          data: values.map((pair) => pair.value),
          backgroundColor: ["#00ACC2"],
          borderColor: "#ffffff",
        },
      ],
    } as ChartData<"bar">;
  };

  const options = {
    scales: {
      x: {
        display: true,
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          color: "#8595A7",
        },
      },
      y: {
        display: true,
        title: {
          display: false,
        },
        grid: {
          display: true,
        },
        ticks: {
          display: true,
          color: "#8595A7",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  } as ChartOptions<"bar">;

  return (
    <>
      <div className={"relative z-0 h-64"}>
        <BlurredContainer blur={!hasData} size="sm">
          <Bar
            data={data()}
            options={options}
          />
        </BlurredContainer>
      </div>
    </>
  );
}
