import React from "react";
import { LoadingWidget } from "components/utils/LoadingWidget";
import integrationService from "services/integrationService";
import IntegrationsList from "components/ui/IntegrationsList/IntegrationsList";
import Breadcrumbs from "components/generics/Breadcrumbs";
import { IntegrationConnectionResult } from "model/integration";
import { StatusConfirmationModal } from "components/utils/Modal/StatusConfirmationModal";
import {ConfirmationModal} from "components/utils/Modal/ConfirmationModal";
import {useLocation} from "react-router-dom";
import {
  addErrorToastNotification,
  addSuccessToastNotification
} from "components/utils/Toast/ToastNotificationContainer";

import * as S from "./styles";

export const Integrations = () => {

  const { search } = useLocation();

  let searchParams = new URLSearchParams(search);
  const base64 = searchParams.get("result");

  React.useEffect(() => {
    if (base64 !== null) {
      const result = JSON.parse(
        decodeURIComponent(escape(window.atob(base64 as string)))
      ) as IntegrationConnectionResult;

      if (result) {
        if (result.success) {
          addSuccessToastNotification(result.message);
        } else {
          addErrorToastNotification(result.message);
        }
      }
    }
  }, [base64]);

  return (
    <>
      <ConfirmationModal />
      <StatusConfirmationModal/>
      <Breadcrumbs
        items={[{ label: "Integrations", url: `/integrations` }]}
      />
      <S.Wrapper>
        <LoadingWidget
          value={integrationService.getInstalledIntegrations({unmapped: true})}
          builder={(list) => <IntegrationsList integrationsList={list} />}
        />
      </S.Wrapper>
    </>
  );
};
