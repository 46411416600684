import { DateTime } from 'luxon';
import { Employee, EmployeeFormData, FilterStatus, NotificationSettings } from "model/employee";
import { SAMPLE_EMPLOYEE } from 'model/mockedValues';
import { objKeysAsList } from 'utils/url';
import { ChartLabelValuePair } from '../model/chart';
import {apiDelete, apiFetch, apiPost, apiPut, reportsUtilsFetch} from './utils';

type EmployeeProjections = {
    teamInfo?: boolean,
    location?: boolean
    skills?: boolean,
    integrations?: boolean
}

class EmployeeService {

    getEmployeeInfo(id: number, projections?: EmployeeProjections, excludeCache?: boolean) {
      if (id < 0) {
        // we're dealing with mocked values
        return Promise.resolve(SAMPLE_EMPLOYEE.filter(e => e.id === -id)[0]);
      }

      const query = projections
          ? { projections: objKeysAsList(projections) }
          : undefined;

      if (excludeCache) {
        return apiFetch<Employee>(`/api/xhr/employees/${id}`, query, {cache: 'no-cache'});
      } else {
        return apiFetch<Employee>(`/api/xhr/employees/${id}`, query);
      }
    }

    async getEmployeeFocusedHours(id: number) {
        const data = await reportsUtilsFetch<{hour: number, eventCount: number}[]>(`/focusedHours/byEmployee/${id}`, {sinceDays: 30});

        const offset = Math.floor(new Date().getTimezoneOffset() / 60);

        const localHours = Array.from(Array(24).keys())

        return localHours.map(hour => {
          const label = DateTime.now().set({hour: hour}).toLocaleString(DateTime.TIME_SIMPLE)
            .replace(/:\d+ ?/, ''); //locale date, removing minutes

          const expectedUtc = hour + offset;
          const datum = data.filter(d => d.hour == expectedUtc);
          if (!datum.length) {
            return {label, value: 0} as ChartLabelValuePair;
          }

          return {label, value: datum[0].eventCount};
        })
    }

    getManagers() {
        return apiFetch<Employee[]>(`/api/xhr/employees/managers/active`);
    }

    getActiveEmployees() {
        return apiFetch<Employee[]>(`/api/xhr/employees/all`, { employeeStatus: FilterStatus.ACTIVE });
    }

    getAllEmployees() {
        return apiFetch<Employee[]>(`/api/xhr/employees/all`, {}, {cache: 'no-cache'});
    }

    saveEmployee(employeeInfo: EmployeeFormData) {
        if (employeeInfo.id === 0) {
            return apiPost<Employee>(`/api/xhr/employees`, employeeInfo);
        } else {
            return apiPut<Employee>(`/api/xhr/employees/${employeeInfo.id}`, employeeInfo);
        }
    }

    updateEmployeeStatus(id: number, enable: boolean) {
        return apiPut<Employee>(`/api/xhr/employees/${id}/status`, enable);
    }

    updateMultipleEmployeesStatus(ids: number[], enable: boolean) {
      if (enable) {
        return apiPut<Employee>(`/api/xhr/employees/status/activate`, ids);
      } else {
        return apiPut<Employee>(`/api/xhr/employees/status/deactivate`, ids);
      }
    }

  deleteEmployee(id: number) {
    return apiDelete<Employee>(`/api/xhr/employees/${id}/`);
  }

  deleteMultipleEmployee(ids: number[]) {
      const queryParams = ids.map(id => `ids=${id}`).join('&');
      const url = `/api/xhr/employees?${queryParams}`;
      return apiDelete<Employee>(url);
    }

    getUserNotifications(id: number) {
        return apiFetch<NotificationSettings>(`/api/xhr/employees/${id}/preferences`, {}, {cache: 'no-cache'});
    }

    saveUserNotifications(settings: NotificationSettings) {
        const id = settings.employeeId;
        return apiPut<NotificationSettings>(`/api/xhr/employees/${id}/preferences`, settings);
    }
}

export default new EmployeeService();
