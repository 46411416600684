import {apiFetch, apiPut, ResponseStatusAndText} from "./utils";
import { Skill } from "model/skill";

class SkillService {
  getSkillsForEmployee(id: any) {
    return apiFetch<string[]>(`/api/xhr/employees/${id}/skills`);
  }

  getAll() {
    return apiFetch<Skill[]>(`/api/xhr/employees/skills`);
  }

  saveSkills(skillsList: string[], id: number) {
    return apiPut<ResponseStatusAndText>(`/api/xhr/employees/${id}/skills`, skillsList);
  }
}

export default new SkillService();
