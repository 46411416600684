import lexi from "./lexi";
import {reportsStayFactorFetch} from "./utils";
import {DailyStayFactor} from "../model/stayFactor";
import {Team} from "../model/employee";
import {createDSFAvgArray} from "../utils/stayFactor";

class StayFactorService {

  getStayFactorByEmployee(id: number, numDays: number = 15): Promise<DailyStayFactor[]> {
    return reportsStayFactorFetch<DailyStayFactor[]>(`/employee/history/${id}`, {sinceDays: numDays});
  }

  getStayFactorByTeam(id: number, numDays: number = 15): Promise<DailyStayFactor[]> {
      return reportsStayFactorFetch<DailyStayFactor[]>(`/team/history/${id}`, {sinceDays: numDays});
  }

  getStayFactorByDepartment(id: number, numDays: number = 15): Promise<DailyStayFactor[]> {
      return reportsStayFactorFetch<DailyStayFactor[]>(`/department/history/${id}`, {sinceDays: numDays});
  }

  getStayFactorTimelineForTeams() {
    const numDaysBefore = 90;
    return this.getStayFactorTimelineForAllTeams(numDaysBefore);
  }

  getStayFactorTimelineForAllTeams(numDaysBefore) {
    return lexi
      .getQuery('daily')
      .avg("METRIC_STAY_FACTOR")
      .start.daysAgo(numDaysBefore)
      .fetchAsSingleSeries();
  }

  getStayFactorTimelineForDepartment(id: number, numDays?: number) {
    const daysAgo = numDays ? numDays : 14;
    const dailyStayFactorPromise = this.getStayFactorByDepartment(id, daysAgo) as Promise<DailyStayFactor[]>;
    return dailyStayFactorPromise.then(dsfArray => dsfArray.map(dsf => dsf.value));
  }

  getStayFactorTimelineForTeam(id: number, numDays?: number) {
    const daysAgo = numDays ? numDays : 14;
    const dailyStayFactorPromise = this.getStayFactorByTeam(id, daysAgo) as Promise<DailyStayFactor[]>;
    return dailyStayFactorPromise.then(dsfArray => dsfArray.map(dsf => dsf.value));
  }

  getStayFactorComponentsForTeam(id: number, numDays?: number) {
    const daysAgo = numDays ? numDays : 14;
    return this.getStayFactorByTeam(id, daysAgo) as Promise<DailyStayFactor[]>;
  }

  getStayFactorTimelineForEmployee(id: number, numDays?: number) {
    const daysAgo = numDays ? numDays : 14;
    const dailyStayFactorPromise = this.getStayFactorByEmployee(id, daysAgo) as Promise<DailyStayFactor[]>;
    return dailyStayFactorPromise.then(dsfArray => dsfArray.map(dsf => dsf.value));
  }

  getStayFactorComponentsForEmployee(id: number, numDays?: number) {
    const daysAgo = numDays ? numDays : 14;
    return this.getStayFactorByEmployee(id, daysAgo) as Promise<DailyStayFactor[]>;
  }
}

export default new StayFactorService();
