import {DailyStayFactor, StayFactorMetric} from "model/stayFactor";
import React from "react";
import {calculateVariationPercentage, getBGLightColor, getFontColor} from "utils/stayFactor";
import {GenericChartData} from "model/chart";
import GenericChart, {ChartTypeEnum} from "../GenericChart";
import {nameStyle, tooltipStyle, valueStyle} from "../Doughnut/styles";
import {arrowStyle, deltaStyle} from "../SFComponentsPieChart/styles";
import {CHART_LIGHT_BLACK} from "../constants";

export default function SFComponentsRadarChart({
                                               currentDailyStayFactor,
                                               previousDailyStayFactor
                                             }: {
  currentDailyStayFactor: DailyStayFactor,
  previousDailyStayFactor: DailyStayFactor,
}) {
  const ACTIVITY = "Activity";
  const INTERACTIONS = "Interactions";
  const WORKLIFE_BALANCE = "WorkLife Balance";
  const RECOMMENDATIONS = "Recommendations";
  const MEETINGS = "Meetings";

  const getNominator = (arr: StayFactorMetric[]) => {
    return arr.map(sf => sf.value * sf.factor).reduce((a, b) => a + b, 0);
  }

  const getDenominator = (arr: StayFactorMetric[]) => {
    return arr.map(sf => sf.factor).reduce((a, b) => a + b, 0);
  }

  const current = [currentDailyStayFactor.interactionsOrg,
    currentDailyStayFactor.interactionsTeam,
    currentDailyStayFactor.interactionsMgr] as StayFactorMetric[];

  const previous = [previousDailyStayFactor.interactionsOrg,
    previousDailyStayFactor.interactionsTeam,
    previousDailyStayFactor.interactionsMgr] as StayFactorMetric[];


  const currentInteractions =
    (getDenominator(current)) === 0 ?
      0 :
      Math.round(getNominator(current) / getDenominator(current));

  const previousInteractions =
    (getDenominator(previous)) === 0 ?
      0 :
      Math.round(getNominator(previous) / getDenominator(previous));


  const genericChartData = {
    labels: [
      ACTIVITY,
      INTERACTIONS,
      WORKLIFE_BALANCE,
      RECOMMENDATIONS,
      MEETINGS,
    ],
    datasets: [
      {
        label: "",
        labels: [
          ACTIVITY,
          INTERACTIONS,
          WORKLIFE_BALANCE,
          RECOMMENDATIONS,
          MEETINGS,
        ],
        data: [
          currentDailyStayFactor.activity.value,
          currentInteractions,
          currentDailyStayFactor.wlf.value,
          currentDailyStayFactor.recommendations.value,
          currentDailyStayFactor.meetingTime.value],
      }
    ],
  } as GenericChartData;


  const tooltipText = [
    ACTIVITY + "|" + currentDailyStayFactor.activity.value + "|" + calculateVariationPercentage(currentDailyStayFactor.activity.value, previousDailyStayFactor.activity.value),
    INTERACTIONS + "|" + currentInteractions + "|" + calculateVariationPercentage(currentInteractions, previousInteractions),
    WORKLIFE_BALANCE + "|" + currentDailyStayFactor.wlf.value + "|" + calculateVariationPercentage(currentDailyStayFactor.wlf.value, previousDailyStayFactor.wlf.value),
    RECOMMENDATIONS + "|" + currentDailyStayFactor.recommendations.value + "|" + calculateVariationPercentage(currentDailyStayFactor.recommendations.value, previousDailyStayFactor.recommendations.value),
    MEETINGS + "|" + currentDailyStayFactor.meetingTime.value + "|" + calculateVariationPercentage(currentDailyStayFactor.meetingTime.value, previousDailyStayFactor.meetingTime.value),
  ];


  const externalTooltipHandler = (context) => {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.innerHTML = '<div></div>';
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    let tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipModel.opacity = 0;
      tooltipEl.style.opacity = "0";
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    // Set Text
    if (tooltipModel.title) {
      const name = tooltipModel.title;
      const tooltipValues = tooltipText.filter(t => t.startsWith(name))[0].split('|');
      const value = tooltipValues[1];
      const delta = Number(tooltipValues[2]);

      let nameDiv = '<div style="' + nameStyle() + '">' + name + '</div>';
      let valueDiv = '<div style="' + valueStyle() + '">' + value + '</div>';
      let deltaDiv = '';
      if (delta > 0) {
        deltaDiv = '<div style="margin-top: -4px; color: ' + getFontColor(75) + '; ' + deltaStyle() + '">' +
          '<div style="' + arrowStyle + '">▲</div>' + Math.abs(delta) + '%' + '</div>';
      } else if (delta < 0) {
        deltaDiv = '<div style="margin-top: -4px; color: ' + getFontColor(10) + '; ' + deltaStyle() + '">' +
          '<div style="' + arrowStyle + '">▼</div>' + Math.abs(delta) + '%' + '</div>';
      } else {
        deltaDiv = '<div style="margin-top: -4px; ' + deltaStyle() + '">0%</div>';
      }

      const deltaColor = delta > 0 ? getBGLightColor(75) : delta < 0 ? getBGLightColor(10) : CHART_LIGHT_BLACK;
      const innerHtml = '<div style="' + tooltipStyle(0) + '; border-color: '+deltaColor+'">' + nameDiv + valueDiv + deltaDiv + '</div>';

      let divRoot = tooltipEl.querySelector('div');
      if (divRoot !== null) {
        divRoot.innerHTML = innerHtml;
      }
    }

    const position = context.chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.opacity = "1";
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = (position.left + window.pageXOffset + tooltipModel.caretX - 65) + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    tooltipEl.style.pointerEvents = 'none';
  }


  return (
    <GenericChart report={genericChartData}
                  type={ChartTypeEnum.RADAR}
                  options={{plugins: {
                      tooltip: {
                        enabled: false,
                        position: 'nearest',
                        external: externalTooltipHandler
                      },
                      legend: {
                        display: false
                      }
                    }}}
                  height={300}
    />
  );
}