import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  flex
  items-center
  justify-between
  bg-pl-grayscale-white
  h-10
  max-w-3xl
  mx-auto
  px-4
  sm:px-6
  lg:max-w-7xl
  lg:px-8
`;

export const LinkWrapper = tw.div`
  flex
  items-center
  w-full
`;

export const Link = tw.a`
  text-xs
  text-pl-grayscale-gray
  font-semibold
  p-0
  m-0
  cursor-pointer
  transition-smooth

  after:content-[""]
  after:border
  after:border-solid
  after:border-pl-grayscale-midgray
  after:m-4
  last:after:border-none

  hover:text-pl-grayscale-midgray
`;

export const Copyright = tw.a`
  text-right
  text-xs
  text-pl-grayscale-gray
  font-semibold
  p-0
  m-0
  cursor-pointer
  transition-smooth

  hover:text-pl-grayscale-midgray
`;