import React, { useEffect } from "react";
import { chatSession, sendAsUserMessageWithBotReply } from "model/chat-session";
import { useState } from "@hookstate/core";
import Avatar from "components/utils/Avatar";
import ChatMessage from "./ChatMessage";
import ChatInput from "./ChatInput";
import TabTitle from "components/structure/TabTitle";
import DashboardListModal from "components/ui/Dashboards/DashboardListModal";
import DashboardModal from "routes/insightsDashboard/manageDashboard/DashboardModal";
import { useNavigate, useLocation } from "react-router-dom";
import Breadcrumbs from "../../generics/Breadcrumbs";
import auth from "services/auth";
import {LoadingWidget} from "components/utils/LoadingWidget";
import { ReactComponent as DropArrowDown } from "icons/drop-arrow-down.svg"
import {ReactComponent as Circle} from "icons/circle.svg";
import * as S from "./styles";

const Chat = () => {
  const messages = useState(chatSession.messages);
  TabTitle("Lexi");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      let query = searchParams.get("query");
      if (query) {
        sendAsUserMessageWithBotReply(query);
      }
    }
  }, [location.search]);

  return (
    <>
      <LoadingWidget
        value={auth.me}
        builder={(me) => (
          me ? <DashboardListModal me={me} /> : <></>
        )}
      />
      <DashboardModal />
      <Breadcrumbs
        items={[
          { label: "View Insights", url: `/insights` },
        ]}
      />
      <S.Wrapper>
        <S.LexiChatHeader>
          <S.HeaderWrapper>
            <Avatar name={"Lexi"} size={1} avatarColor={false} />
            <S.HeaderTextWrapper>
                <S.HeaderTextSpan>Lexi</S.HeaderTextSpan>
                <Circle className={"text-pl-primary-green-default mb-2"} />
            </S.HeaderTextWrapper>
          </S.HeaderWrapper>
        </S.LexiChatHeader>

        <S.ChatMessagesWrapper
          id="messages"
        >
          {messages &&
            messages.get({noproxy: true}).map((msg) => (
              <S.IndvidualChatWrapper key={msg.id}>
                <ChatMessage message={msg} />
              </S.IndvidualChatWrapper>
            ))}
        </S.ChatMessagesWrapper>

        <ChatInput />

        <S.QueryExamplesWrapper>
          <S.QueryExamplesHeader>
            <S.ExamplesHeaderLeadingSection>
              Our most popular queries
              <DropArrowDown className="w-4 text-pl-primary-green-default ml-1"/>
            </S.ExamplesHeaderLeadingSection>
              <S.LibraryLink
                target={"_blank"}
                rel="noopener noreferrer"
                href={
                  "https://peoplelogic.zendesk.com/hc/en-us/articles/360052340391"
                }
              >
                {`Query Library`}
              </S.LibraryLink>
          </S.QueryExamplesHeader>
          <S.ExamplesGridWrapper>
            <S.ExamplesGrid>
              {[
                "show me daily activity by team over last three months",
                "show daily activity by team starting 3 months ago until 1 month ago",
                "show me daily accumulated issues created compared to issues resolved over the past month",
                "show me a bar chart of issues created vs issues resolved by employee since 30 days ago",
                "show me a bar chart of opportunities won vs opportunities lost by employee",
                "show monthly asana activity by employee until 1 month ago",
              ].map((query, index) => (
                <S.QueryExample
                  key={`suggested-query-${index}`}
                  onClick={() => {
                    navigate("/insights");
                    sendAsUserMessageWithBotReply(query);
                  }}
                >
                  {query}
                </S.QueryExample>
              ))}
            </S.ExamplesGrid>
          </S.ExamplesGridWrapper>
        </S.QueryExamplesWrapper>
      </S.Wrapper>
    </>
  );
};

export default Chat;
