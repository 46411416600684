import tw from "tailwind-styled-components";
import { BadgeProps } from ".";

type WrapperProps = Pick<BadgeProps, "type" | "size"> & {
  rounded?: number;
};

const WrapperModifiers = {
  info: `
    bg-pl-primary-green-light
    text-pl-primary-green-default
  `,

  critical: `
    bg-pl-primary-red-light
    text-pl-primary-red-default
  `,

  warning: `
    bg-pl-primary-orange-light
    text-pl-primary-orange-default
  `,

  default: `
    bg-pl-grayscale-lightgray
    text-pl-grayscale-gray
  `,

  small: `
    text-[10px]
    px-1.5
    h-[18px]
  `,

  medium: `
    px-3
    h-[25px]
  `,

  large: `
    px-4
    h-[30px]
  `,

  rounded: `
    rounded-[10px]
  `,
};

export const Wrapper = tw.div<WrapperProps>`
  ${({ type, size, rounded }) => `
    rounded
    font-semibold
    text-pl-grayscale-white
    w-max
    flex
    items-center
    text-sm

    ${!!type && WrapperModifiers[type]}
    ${!!size && WrapperModifiers[size]}
    ${!!rounded && WrapperModifiers.rounded}
  `}
`;

export const Label = tw.span``;
