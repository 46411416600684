import React from "react";
import { WidgetTitle } from "components/utils/WidgetTitle";
import { WidgetContainer } from "components/utils/WidgetContainer";
import { ChartLabelValuePair } from "model/chart";
import BarChart from "components/ui/Charts/BarChart";

export default function FocusedHours({
  focusedHoursInfo,
}: {
  focusedHoursInfo: ChartLabelValuePair[];
}) {
  return (
    <>
      <WidgetContainer
        rounded={true}
        shadow={true}
        textAlign={"left"}
        heightAuto
      >
        <WidgetTitle>Focused Hours</WidgetTitle>
        <div className={"h-auto mt-5"}>
          <BarChart labelValuePairs={focusedHoursInfo} />
        </div>
      </WidgetContainer>
    </>
  );
}
