import React from "react";
import {LoadingWidget} from "components/utils/LoadingWidget";
import {Organization} from "model/organization";
import {Location} from "model/employee";
import organizationService from "services/organizationService";
import TabTitle from "components/structure/TabTitle";
import OrganizationForm from "./OrganizationForm";
import {useState} from "@hookstate/core";

export default function OrganizationFormPage() {
  TabTitle("Organization");

  const orgAndLocs = useState(() => Promise.all([
    organizationService.getCurrentOrganization(),
    organizationService.getLocations(true)
  ]));

  return (
      <LoadingWidget
          value={orgAndLocs}
          builder={([org, locs]) => (
              <OrganizationForm organization={org as Organization} locations={locs as Location[]} />
          )}
      />
  );
}