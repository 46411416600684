import {useState} from "@hookstate/core";
import {dashboardGlobalState} from "routes/dashboards/store";
import {Dashboard} from "model/dashboards";

export default function useDashboard() {
  const globalDashboards = useState(dashboardGlobalState);

  const updateGlobalDashboardState = (dashboard: Dashboard) => {
    const allDashboards = globalDashboards.value.map((item) => {
      if (item.id === dashboard.id) {
        item = dashboard;
      }

      return item;
    });

    globalDashboards.set(allDashboards);
  };

  return { globalDashboards, updateGlobalDashboardState };
}
