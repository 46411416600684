import * as Yup from "yup";

export const EmployeeValidator = Yup.object().shape({
  name: Yup.string()
    .matches(
      /^(([^\>]{1,})\s*){1,}$/gi,
      "Name must contain at minimum 1 characters"
    )
    .matches(
      /^\s*[\S]+(\s[\S]+)+\s*$/gms,
      "Please enter at least a first and last name"
    )
    .max(255, "Full name must contain at max 255 characters")
    .required("Name is a required field"),
  email: Yup.string().email().required("Email is a required field"),
});
