import React from "react";
import * as S from "./styles";

export type HeadingProps = {
  text?: string;
  size?: "small" | "medium" | "large";
  weight?: "font-semibold" | "font-bold";
  icon?: React.ReactNode;
};

const Heading = ({ text, size = "medium", weight = "font-semibold",  icon }: HeadingProps) => (
  <S.Wrapper>
    {!!icon && <S.IconWrapper size={size}>{icon}</S.IconWrapper>}
    <S.Title size={size} weight={weight}>{text}</S.Title>
  </S.Wrapper>
);

export default Heading;
