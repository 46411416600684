import tw from "tailwind-styled-components";

export const StatusConfirmationWrapper = tw.div`
  inline-block
  border
  border-solid
  w-full
  max-w-[290px]
  p-4
  my-8
  overflow-hidden
  text-center
  align-middle
  transition-all
  transform
  bg-pl-grayscale-white
  shadow-xl
  rounded-2xl
`;

export const CloseButtonWrapper = tw.div`
  flex
  flex-row
  justify-end
`;

export const AvatarWrapper = tw.div`
  flex
  justify-center
  mt-4
`;

export const MessageWrapper = tw.div`
  mt-4
  text-pl-grayscale-gray
  text-sm
  font-normal
`;

export const BottomButtonsWrapper = tw.div`
  flex
  flex-row
  gap-4 w-full
  justify-center
  mt-4
  mb-2
  p-2
`;

export const StatusConfimationTitle = tw.h3`
  mt-6
  text-xl
  font-bold
  leading-6
  text-pl-grayscale-black
`;

export const Wrapper = tw.div`
  inline-block
  border
  w-full
  max-w-[500px]
  p-6
  overflow-hidden
  text-left
  align-middle
  transition-all
  transform
  bg-pl-grayscale-white
  shadow-xl
  rounded-2xl
`;

export const MainContainer = tw.div`
  grid
  divide-y
  w-full
`;

export const ContentWrapper = tw.div`
  min-h-[100px]
`;

export const ButtonsWrapper = tw.div`
  pt-6
  flex
  items-center
  justify-between
`;

export const FormWrapper = tw.div`
  py-3
  grid
`;