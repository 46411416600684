import { SeverityType } from "model/recommendations";

export function calculateGradientPosition(
  startColor: String,
  endColor: String,
  percentage: number
) {
  const ratio = percentage / 100;
  const hex = function (x: number) {
    const h = Math.round(x).toString(16);
    return h.length === 1 ? "0" + h : h;
  };

  const startR = parseInt(startColor.substring(1, 3), 16);
  const startG = parseInt(startColor.substring(3, 5), 16);
  const startB = parseInt(startColor.substring(5, 7), 16);

  const endR = parseInt(endColor.substring(1, 3), 16);
  const endG = parseInt(endColor.substring(3, 5), 16);
  const endB = parseInt(endColor.substring(5, 7), 16);

  const r = startR * (1 - ratio) + endR * ratio;
  const g = startG * (1 - ratio) + endG * ratio;
  const b = startB * (1 - ratio) + endB * ratio;

  return "#" + hex(r) + hex(g) + hex(b);
}

export const CHART_COLORS = [
  'rgba(0, 194, 136, 0.4)',
  'rgba(0, 172, 194, 0.4)',
  'rgba(234, 234, 22, 0.4)',
  'rgba(255, 135, 24, 0.4)',
  'rgba(251, 108, 113, 0.4)',
  'rgba(0, 194, 136, 0.8)',
  'rgba(0, 172, 194, 0.8)',
  'rgba(183, 177, 22, 0.8)',
  'rgba(255, 135, 24, 0.8)',
  'rgba(251, 108, 113, 0.8)',
  'rgba(193, 201, 210, 1)',
  'rgba(2, 113, 130, 0.7)'
];

export const CHART_LIGHT_COLORS = [
  "rgba(0, 172, 194, 0.3)",
  "rgba(202, 235, 240, 0.3)",
  "rgba(253, 202, 155, 0.3)",
  "rgba(255, 201, 192, 0.3)",
  "rgba(251, 108, 113, 0.3)",
  "rgba(193, 201, 210, 0.3)",
];

export const pickColor = (seed: number) =>
  CHART_COLORS[seed % CHART_COLORS.length];

export const SEVERITY_COLOR = (severity: SeverityType) => {
  switch (severity) {
    case SeverityType.CRITICAL:
      return "bg-pl-primary-red-default";
    case SeverityType.WARNING:
      return "bg-pl-primary-orange-default";
    case SeverityType.INFO:
      return "bg-pl-primary-green-default";
    default:
      return "";
  }
};
