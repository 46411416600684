import { Column, UseSortByColumnOptions } from "react-table";
import {Dashboard} from "./dashboards";

export const defaults = {
  pageNumber: 0,
  pageLength: 10,
  pageLengthMenu: [5, 10, 25, 30, 50, 100],
};

export type DatatableColumn<D extends object> = Column<D> &
  UseSortByColumnOptions<D> & {
    //value passed to this must be a member of the TableData object
    customRenderer?: string;
  };

export type DatatableProps<D extends object> = {
  columns: Array<DatatableColumn<D>>;
  data: Array<D>;
  pageNumber?: number;
  pageLength?: number;
  pageLengthMenu?: number[];
  hasPagination?: boolean;
  pageChangeCallback?: (newPage: number) => void;
  hiddenColumns?: string[];
  sortColumn?: string;
  sortDesc?: boolean;
  isUrlSorted?: boolean;
  $shadow?: boolean;
  hasClearSelection?: boolean;
  onRowSelection?: (rows: any) => void;
};

export type EmployeesTableData = {
  id: number;
  name: string;
  nameRender: JSX.Element;
  email: string;
  emailRender: JSX.Element;
  active: number;
  activeRender: JSX.Element;
  tenure: string;
  tenureRender: JSX.Element;
  stayFactor: JSX.Element;
  actions: JSX.Element;
};

export type TeamsTableData = {
  id: number;
  teamName: string;
  teamNameRenderer: JSX.Element;
  manager: string;
  managerRenderer: JSX.Element;
  active: number;
  activeRender: JSX.Element;
  stayFactor: JSX.Element;
  teamSize: number;
  teamSizeRender: JSX.Element;
};

export type DashboardsTableData = {
  id: number;
  dashboardName: string;
  dashboardNameRender: () => JSX.Element;
  owner: string;
  ownerRender: () => JSX.Element;
  description: string;
  descriptionRender: () => JSX.Element;
  numReports: number;
  actions: number,
  actionsRender: () => JSX.Element
};

export type DepartmentsTableData = {
  id: number;
  departmentName: string;
  departmentNameRenderer: JSX.Element;
  manager: string;
  managerRenderer: JSX.Element;
  active: number;
  activeRender: JSX.Element;
  stayFactor: JSX.Element;
  departmentSize: number;
  departmentSizeRender: JSX.Element;
  //actions: JSX.Element
}

export type IntegrationsTableData = {
  id: number;
  integrationName: string,
  integrationNameRenderer: JSX.Element,
  active: number,
  activeRender: JSX.Element,
  activities: JSX.Element,
  actions: JSX.Element
}
