import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "@hookstate/core";
import DataTableFilters, {Filters,} from "components/utils/Datatable/DataTableFilters";
import {useBulkSelectIntegration} from "hooks/useBulkSelect";
import auth from "services/auth";
import authService from "services/auth";
import React from "react";
import {Integration} from "model/integration";
import {DatatableColumn, IntegrationsTableData} from "model/datatable";
import {getDataTableColumns, getSparklineWidget, updateSelectionIfChanged} from "components/utils/Datatable/DataTableHelper";
import URLSortedDataTable from "components/utils/Datatable/URLSortedDataTable";
import TableItemActiveIcon from "components/utils/Datatable/TableItemActiveIcon";
import {contains} from "utils/text";
import Button from "components/generics/Button";
import {openStatusConfirmationModal} from "components/utils/Modal/StatusConfirmationModal";
import integrationService from "services/integrationService";
import WaitForOnScreen from "components/utils/WaitForOnScreen";
import {LoadingWidget} from "components/utils/LoadingWidget";
import {IntegrationNameRenderer} from "./IntegrationNameRenderer";
import IntegrationModal, {openEditIntegrationModal} from "routes/integrationForm/manageIntegration/ManageIntegration";
import AdditionalDataModal from "routes/integrationForm/additionalData/AdditionalData";

import * as S from "./styles";

import {ReactComponent as EditIcon} from "icons/edit.svg";

const IntegrationsList = ({ integrationsList }: { integrationsList: Integration[] }) => {
  const navigate = useNavigate();
  const integrationsState = useState<Integration[]>(integrationsList);
  const selectedItems = useState<Integration[]>([]);

  const state = useState({
    info: {
      status: "true",
      searchWord: "",
    } as Filters,
  });

  const location = useLocation();

  const columns: DatatableColumn<IntegrationsTableData>[] = [
    {
      id: "name",
      accessor: "integrationName",
      Header: "Integration",
      customRenderer: "integrationNameRenderer",
    },
    {
      id: "active",
      disableSortBy: state.info.status.value !== "all",
      accessor: "active",
      Header: "Active",
      customRenderer: "activeRender",
      sortDescFirst: true,
    },
    {
      disableSortBy: true,
      id: "activities",
      accessor: "activities",
      Header: "Activities",
    },
    {
      id: "actions",
      accessor: "actions",
      Header: "Actions",
      disableSortBy: true,
    }
  ];

  const fromCreateIntegrationToTop = React.useCallback(
    (integrationsList: Integration[]) => {
      const topIntegrationId = location.state as number;
      if (topIntegrationId && topIntegrationId > 0) {
        const filtered = integrationsList.filter((department) => department.id !== topIntegrationId);
        const integration = integrationsList.filter((department) => department.id === topIntegrationId);
        return integration.concat(filtered);
      }
      return integrationsList;
    },
    [location.state]
  );

  // apply selected filters to the integrations list
  function filterIntegrationData(integrationsList: Integration[]): Integration[] {
    return fromCreateIntegrationToTop(
      integrationsList
        .filter((integration) => {
          if (state.info.searchWord.get().length > 0) {
            return contains(
              integration.name,
              state.info.searchWord.get()
            );
          }
          return true;
        })
        .filter((integration) => {
          if (state.info.status.get() === "true") {
            return integration.active;
          } else if (state.info.status.get() === "false") {
            return !integration.active;
          }
          return true;
        })
    );
  }

  function getActions(integration: Integration) {
    return (
      <>
        <S.ActionButtonWrapper>
          {
            auth.currentUserIsAdmin() &&
              <Button
                btntype={"neutral"}
                minimal
                $shadow={false}
                onClick={() => {
                  Promise.all([
                    integrationService.getIntegrationInfoById(integration.id, {formData: true})
                  ])
                    .then(([installedIntegration]) =>
                      openEditIntegrationModal(installedIntegration)
                    );
                }}
              >
                <EditIcon className={`${integration.active ? "text-pl-grayscale-gray" : "text-pl-grayscale-midgray"}`}/>
              </Button>
          }
        </S.ActionButtonWrapper>
      </>
    );
  }

  function getActiveComponent(integration: Integration) {
    return (
      <S.ActiveComponentWrapper>
        <Button
          className={`hover:border-solid
          hover:rounded-full
          hover:border-4
          ${integration.active? `hover:border-pl-primary-green-light` : `hover:border-pl-primary-red-light`}`}
          minimal
          $shadow={false}
          onClick={() => openStatusConfirmationModal(
            integration.active ? `Are you sure you want to deactivate the ${integration.name} integration?`: `Are you sure you want to activate the ${integration.name} integration?`,
            integration.active ? "Deactivate Integration" : "Activate Integration",
            integration.name,
            "integration",
            () => {
              integrationService.updateIntegrationStatus(integration.id, !integration.active)
                .then(() => {
                  integrationsState.find(e => e.id.value === integration.id)?.active.set(!integration.active)
                });
              },
            "Close",
            integration.active ? "Deactivate" : "Activate"
          )}
        >
          <TableItemActiveIcon active={integration.active? true: false} />
        </Button>
      </S.ActiveComponentWrapper>
    )
  }

  function getData(integrationsList: Integration[]): any[] {
    let rows: IntegrationsTableData[] = [];
    integrationsList.map((integration) => {
      return rows.push({
        id: integration.id,
        integrationName: integration.name.toLowerCase().trim(),
        integrationNameRenderer: (
          <IntegrationNameRenderer integration={integration} />
        ),
        active: integration.active ? 1 : 0,
        activeRender: getActiveComponent(integration),
        activities: <ActivitySparklineWidget integration={integration} />,
        actions: getActions(integration),
      });
    });
    return rows;
  }

  const ActivitySparklineWidget = ({ integration }: { integration: Integration }) => {
    const activities = useState<number[]>(() => integrationService.getActivitiesByIntegration(integration));

    return (
      <WaitForOnScreen
        builder={() => (
          <LoadingWidget<number[]>
            value={activities}
            builder={(result) => getSparklineWidget(result, false, false)}
            size="small"
            onError={() => <div>Connected</div>}
          />
        )}
      />
    );
  };

  const onRowSelection = React.useCallback((value) => {
    updateSelectionIfChanged(value, selectedItems)
  }, []);

  const { clearSelection, activateSelectedIntegrations, deactivateSelectedIntegrations } = useBulkSelectIntegration();

  const getBulkActions = () => {
    const selectedIds = selectedItems.value.map(d => d.id);
    return [
      {
        label: "Activate",
        action: () => activateSelectedIntegrations(selectedIds, integrationsState),
      },
      {
        label: "Deactivate",
        action: () => deactivateSelectedIntegrations(selectedIds, integrationsState),
      },
    ];
  };

  return (
    <>
      <AdditionalDataModal/>
      <IntegrationModal/>
      <S.IntegrationDatatableWrapper>
        <DataTableFilters
          title={"Integrations List"}
          type={'integration'}
          titleSize={"large"}
          addLabel={auth.currentUserIsAdmin() ? "New Integration" : undefined}
          state={state.info}
          selectedItems={selectedItems.value}
          bulkActions={getBulkActions()}
          onAdd={() => navigate("/integrations/add")}
        />

        <URLSortedDataTable
          columns={getDataTableColumns(columns)}
          data={getData(filterIntegrationData(integrationsState.value))}
          pageLength={10}
          pageChangeCallback={() => clearSelection()}
          hiddenColumns={authService.currentUserIsAdmin() ? [] : ["actions"]}
          onRowSelection={onRowSelection}
        />

      </S.IntegrationDatatableWrapper>
    </>
  );
};

export default React.memo(IntegrationsList);