import React from "react";
import {useNavigate} from "react-router-dom";
import {Employee, TeamFormData} from "model/employee";
import { CommonModal } from "components/utils/Modal/CommonModal";
import {createState, useState} from "@hookstate/core";
import Button from "components/generics/Button";
import {Form, Formik, FormikValues} from "formik";
import {TeamValidator} from "validations/TeamValidator";
import teamService from "services/teamService";
import {BasicInfoSection} from "../BasicInfoSection";
import {MembersSkillsSection} from "../MembersSkillsSection";
import {Dialog} from "@headlessui/react";
import ModalTabsTitle, {TabsTitle} from "components/utils/Modal/ModalTabsTitle/ModalTabsTitle";
import {AddToDepartment, Department} from "model/department";

import * as S from "./styles";

export const TeamModalState = createState({
  open: false,
  teamInfo: {
    id: 0,
    name: "",
    managerId: 0,
    teamType: "general",
    employees: [],
    employeesSelected: [],
    skills: [],
    active: true,
  } as TeamFormData,
  managers: [] as Employee[],
  types: [] as string[],
  employees: [] as Employee[],
  departments: [] as Department[],
  addToDepartment: {} as AddToDepartment | undefined,
});

export const openTeamModal = (
  managers: Employee[],
  teamTypes: string[],
  employees: Employee[],
  departments: Department[],
  addToDepartment?: AddToDepartment
) => {
  TeamModalState.managers.set(managers);
  TeamModalState.types.set(teamTypes);
  TeamModalState.employees.set(employees);
  TeamModalState.departments.set(departments);
  TeamModalState.addToDepartment.set(addToDepartment);
  if (addToDepartment) {
    TeamModalState.teamInfo.departmentId.set(addToDepartment.departmentId);
  }
  TeamModalState.open.set(true);
}


export default function AddTeamModal() {
  const navigate = useNavigate();

  const modalState = useState(TeamModalState);

  const selectedNavIndex = useState(0);
  const isSaving = useState<boolean>(false);

  const tabsTitle = [
    {
      tabNumber: 1,
      tabTitle: "Basic information",
    },
    {
      tabNumber: 2,
      tabTitle: "Team's Members & Skills",
    } as TabsTitle
  ];

  function handleSave(values: FormikValues) {
    isSaving.set(true);
    const ids = values.employeesSelected.map((val) => val.value);
    const teamToSave = {
      id: 0,
      name: values.name,
      managerId: values.managerId,
      teamType: values.teamType,
      active: true,
      employees: TeamModalState.employees.value.filter((emp) => ids.includes(emp.id)),
      employeesSelected: values.employeesSelected,
      skills: values.skills,
      departmentId: values.departmentId,
    } as TeamFormData;

    save(teamToSave);
  }

  function save(teamToSave: TeamFormData) {
    teamService.saveTeam(teamToSave)
      .then(function (savedTeam) {
        modalState.open.set(false);
        if (modalState.addToDepartment.value) {
          navigate("/departments/" + modalState.addToDepartment.value?.departmentId + "/members", {state: savedTeam.id});
        } else {
          navigate("/teams", { state: savedTeam.id});
        }
      })
      .finally(() => {
        selectedNavIndex.set(0);
        isSaving.set(false);
      });
  }

  return (
    <>
      <CommonModal openState={modalState.open}>
        <S.Wrapper>
          <Formik
            initialValues={{...modalState.teamInfo.value}}
            validationSchema={TeamValidator}
            validateOnMount={true}
            onSubmit={() => {}}
          >
            {({
                values,
                errors,
                isValid,
                setFieldValue,
                setFieldTouched,
                touched,
                handleChange,
                handleBlur,
              }) => (

              <Form>
                <S.MainContainer>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-pl-grayscale-black"
                  >
                    <ModalTabsTitle tabs={tabsTitle} currentTab={selectedNavIndex.value + 1} />
                  </Dialog.Title>


                  <S.ContentWrapper>
                    {
                      selectedNavIndex.value === 0 ?
                        <BasicInfoSection managers={modalState.managers.value}
                                          teamType={modalState.types.value}
                                          departments={modalState.departments.value}
                                          addToDepartment={modalState.addToDepartment.value}
                                          values={values}
                                          errors={errors}
                                          setFieldValue={setFieldValue}
                                          setFieldTouched={setFieldTouched}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          touched={touched}
                                          horizontalDisplay={false}
                                          numColumns={4}
                        />
                        :
                        <MembersSkillsSection employees={modalState.employees.value}
                                              values={values}
                                              setFieldValue={setFieldValue}
                                              horizontalDisplay={false}
                                              numColumns={2}
                        />
                    }
                  </S.ContentWrapper>

                  <S.ButtonsWrapper>
                    {
                      selectedNavIndex.value === 0 ?
                        <Button onClick={() => modalState.open.set(false)}>
                          Close
                        </Button>
                        :
                        <Button onClick={() => selectedNavIndex.set(0)}>
                          Previous
                        </Button>
                    }
                    {
                      selectedNavIndex.value === 0 ?
                        <Button
                          btntype="primary"
                          $shadow={true}
                          disabled={!isValid}
                          onClick={() => {
                            selectedNavIndex.set(1)
                          }}
                        >
                          Next
                        </Button>
                        :
                        <Button
                          type="submit"
                          btntype="primary"
                          $shadow={true}
                          disabled={!isValid || isSaving.value}
                          onClick={() => {
                            isSaving.set(true);
                            handleSave(values);
                          }}
                        >
                          Done
                        </Button>
                    }
                  </S.ButtonsWrapper>
                </S.MainContainer>
              </Form>
            )}
          </Formik>
        </S.Wrapper>
      </CommonModal>
    </>
  );
}
