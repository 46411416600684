import React, {InputHTMLAttributes} from "react";
import Checkbox from "../Checkbox";

import * as S from "./styles";

export type ToggleButtonProps = {
  checked: boolean;
  text: string;
  onChange: () => void;
} & InputHTMLAttributes<HTMLInputElement>;

const ToggleButton = ({
                        checked,
                        text,
                        onChange,
                        ...props
                      }: ToggleButtonProps
) => {
  return (
    <S.Wrapper {...props}>
      <S.ToggleComponent>
        <S.ToggleWrapper onClick={() => onChange()}>
          <S.Toggle checked={checked} />
          <S.ToggleBubble checked={checked}>
            <Checkbox checked={checked}
                      className={"absolute opacity-0 w-0 h-0"}/>
          </S.ToggleBubble>
        </S.ToggleWrapper>
        <S.Label>
          {text}
        </S.Label>
      </S.ToggleComponent>
    </S.Wrapper>
  );
};

export default ToggleButton;
