import {apiDelete, apiFetch, apiPost, apiPut, ResponseStatusAndText} from "./utils";
import { SelectSearchOption } from "components/utils/SelectSearch";
import {Organization, OrganizationFormData, Logo, PrivacyLevel, OrganizationPrivacyLevel} from "model/organization";
import { Location } from "model/employee";

class OrganizationService {

    getIndustryOptions() {
        return apiFetch<SelectSearchOption[]>(`/api/xhr/organizations/industryOptions`);
    }

    getNumEmployeesOptions() {
        return apiFetch<SelectSearchOption[]>(`/api/xhr/organizations/numEmployeesOptions`);
    }

    getTimezones() {
        return apiFetch<SelectSearchOption[]>(`/api/xhr/organizations/timezones`);
    }

    getCurrentOrganization() {
        return apiFetch<Organization>(`/api/xhr/organizations/current`, {}, {cache: 'no-cache'});
    }

    getLocations(useCache?: boolean) {
      return useCache ?
        apiFetch<Location[]>(`/api/xhr/organizations/locations`, {}, {cache: 'no-cache'})
        :
        apiFetch<Location[]>(`/api/xhr/organizations/locations`)
    }

    addLocation(location: Location) {
      return apiPut<Location>(`/api/xhr/organizations/locations`, location);
    }

    updateLocation(location: Location) {
      return apiPost<Location>(`/api/xhr/organizations/locations/${location.id}`, location);
    }

    deleteLocation(id: number) {
      return apiDelete<ResponseStatusAndText>(`/api/xhr/organizations/locations/${id}`);
    }

    saveOrganization(organizationFormData: OrganizationFormData) {
        return apiPost<Organization>(`/api/xhr/organizations`, organizationFormData);
    }

    getBillingUrl() {
        return apiFetch<string>(`/api/xhr/organizations/billing`)
          .then(response => {
            window.open(response, '_blank');
          })
    }

    getCustomerDomain() {
        return apiFetch<Logo>(`/api/xhr/organizations/customer-logo`);
    }

    updatePrivacyLevel(privacyLevel: PrivacyLevel) {
      return apiPost<Organization>(`/api/xhr/organizations/privacy-level`, { privacy: privacyLevel } as OrganizationPrivacyLevel);
    }
}

export default new OrganizationService();
