import React, { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";

import * as S from "./styles";
import {useState} from "@hookstate/core";

export type PaginationProps = {
  items: any[];
  itemsPerPage: number;
  isVisible?: boolean;
  onPaginate: (startIndex: number, endIndex: number, page: number) => void;
};

const Pagination = ({
  items,
  isVisible = true,
  itemsPerPage,
  onPaginate,
}: PaginationProps) => {
  const pageCount = useState(0);
  const itemOffset = useState(0);
  const currentPage = useState(1);

  useEffect(() => {
    const endOffset = itemOffset.value + itemsPerPage;
    onPaginate(itemOffset.value, endOffset, currentPage.value);
    pageCount.set(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    itemOffset.set(newOffset);
    currentPage.set(event.selected + 1);
  };

  return (
    <>
      {isVisible && (
        <ReactPaginate
          previousLabel={<ChevronIcon className="rotate-90" />}
          nextLabel={<ChevronIcon className="rotate-[270deg]" />}
          breakLabel="..."
          breakClassName={S.PaginationItemWrapper}
          breakLinkClassName={S.PaginationItem}
          pageCount={pageCount.value}
          pageRangeDisplayed={4}
          marginPagesDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={S.Wrapper}
          pageClassName={`${S.PaginationItemWrapper} ${
            pageCount.value === 1 ? S.PaginationItemActive : ""
          }`}
          pageLinkClassName={S.PaginationItem}
          previousClassName={`${S.PaginationItemWrapper} ${
            pageCount.value === 1 ? S.PaginationItemDisabled : ""
          }`}
          previousLinkClassName={S.PaginationItem}
          nextClassName={`${S.PaginationItemWrapper} ${
            pageCount.value === 1 ? S.PaginationItemDisabled : ""
          }`}
          nextLinkClassName={S.PaginationItem}
          activeClassName={S.PaginationItemActive}
          disabledClassName={S.PaginationItemDisabled}
        />
      )}
    </>
  );
};

export default Pagination;
