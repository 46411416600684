import lexi from "./lexi";
import {chatSession, ReplyObj, UserMessage} from "model/chat-session";
import {
  ConversationResponse,
  LexiReply,
  LineChartReport,
  LineChartSeries,
  QueryResponse,
  ReportQuery,
} from "model/lexi";
import {DashboardQuery} from "model/dashboards";
import employeeService from "./employeeService";

class ChatService {

  async getChatResultForSingleReport(query: string) {
    const userMessage: UserMessage = {
      message: query,
      mode: "reports",
      startNewIfNotFound: false,
    };
    return this.getChatResultForMessage(userMessage);
  }

  async getChatResultForConversation(query: string, id?: string) {
    const userMessage: UserMessage = {
      conversationId: id,
      message: query,
      mode: "assistant",
      startNewIfNotFound: true,
    };
    return this.getChatResultForMessage(userMessage);
  }

  async getChatResultForMessage(userMessage: UserMessage) {

    const builtin = this.builtinTestResult(userMessage);

    if (builtin) {
      return builtin;
    }

    const response = await lexi.lexiChatFetch(userMessage);
    const conversationResponse = response as ConversationResponse;

    if (conversationResponse) {
      if (conversationResponse.conversationId) {
        // fixme: not the best place to do this
        chatSession.id.set(conversationResponse.conversationId)
      }

      const latestInteraction =
        conversationResponse.interactions[
        conversationResponse.interactions.length - 1
          ];

      if (latestInteraction.reply) {
        return {
          reply: latestInteraction.reply
        };
      } else if (latestInteraction.reports) {
        return {
          reply: latestInteraction.reports
        }
      } else if (latestInteraction.result) {
        const rawQueryResponse = (await lexi.lexiFetch(
          latestInteraction.result as ReportQuery
        )) as QueryResponse;

        if (!rawQueryResponse) {
          return {
            reply: "I'm unable to fetch data for this query."
          }
        }

        const queryResponse = await this.fillInEmployeeData(rawQueryResponse);

        return {
          reply: queryResponse
        };
      }
    }
    return {
      reply: `Sorry, I'm unable to generate a response as of the moment.`,
    };
  }

  async fillInEmployeeData(response: QueryResponse) {
    if (response.type == "lineChart") {
      const newReport = await this.fillInEmployeeDataInLineChart(response.report as LineChartReport);
      return {
        ...response,
        report: newReport
      } as QueryResponse
    }

    return response;
  }

  async fillInEmployeeDataInLineChart(report: LineChartReport) {
    const seriesPromises = report.series.map(async series => {
      if (!series.print.employee) {
        return series;
      }

      try {
        const employee = await employeeService.getEmployeeInfo(series.print.employee.id);

        return {
          ...series,
          seriesLabel: employee.name
        } as LineChartSeries;
      } catch (e) {
        return {
          ...series,
          seriesLabel: `Employee ${series.print.employee.id}`
        }
      }
    });

    const series = await Promise.all(seriesPromises);
    return {
      ...report,
      series
    } as LineChartReport;
  }

  builtinTestResult(userMessage: UserMessage) {
    // temp code to test new charts
    if (userMessage.message === 'test heat map chart') {
      return {reply: {type: "heatMap"} as QueryResponse};

    } else if (userMessage.message === 'test tree map chart') {
      return {reply: {type: "treeMap"} as QueryResponse};

    } else if (userMessage.message === 'test venn diagram chart') {
      return {reply: {type: "vennDiagram"} as QueryResponse};

    } else if (userMessage.message === 'test pie chart') {
      return {reply: {type: "pieChart"} as QueryResponse};

    } else if (userMessage.message === 'test radar chart') {
      return {reply: {type: "radarChart"} as QueryResponse};

    } else if (userMessage.message === 'test polar chart') {
      return {reply: {type: "polarChart"} as QueryResponse};

    } else if (userMessage.message === 'test doughnut chart') {
      return {reply: {type: "doughnutChart"} as QueryResponse};
    } else if (userMessage.message === 'test stacked bar chart') {
      return {reply: {type: "stackedBarChart"} as QueryResponse};
    }
  }

  getChatResultsForDashboard(queries: DashboardQuery[]) {
    return this.getChatResultArray(queries);
  }

  getChatResultArray(queries: DashboardQuery[]): ReplyObj[] {
    let result = [] as ReplyObj[];

    const promises = queries.map(({query}) => {
      const userMessage: UserMessage = {
        message: query,
        mode: "reports",
        startNewIfNotFound: false,
      };

      return lexi.lexiChatFetch(userMessage).then((res) => {
        return res as ConversationResponse;
      });
    });

    Promise.all(promises).then((res) => {
      let reply: LexiReply = {
        reply: "Sorry, I'm unable to generate a response as of the moment.",
      };

      res.forEach((response) => {
        if (response) {
          const latestInteraction =
            response.interactions[response.interactions.length - 1];

          if (latestInteraction.reply) {
            reply.reply = latestInteraction.reply;
          } else if (latestInteraction.reports) {
            reply.reply = latestInteraction.reports[0];
          } else if (latestInteraction.result) {
            lexi.lexiFetch(latestInteraction.result).then((response) => {
              reply.reply =
                response || "I'm unable to fetch data for this query.";
            });
          }
        }

        queries.forEach(({query, name}) => {
          const queryName = name || query;

          result.push({
            name: queryName,
            query,
            reply,
          });
        });
      });
    });

    return result;
  }
}

export default new ChatService();
