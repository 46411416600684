import React from "react";
import { State, useState } from "@hookstate/core";
import { SearchIcon } from "@heroicons/react/solid";
import Button from "components/generics/Button";
import Heading from "components/generics/Heading";
import TextField from "components/generics/TextField";
import { SeverityType } from "model/recommendations";
import { useLocation } from "react-router-dom";

import * as S from "./styles";
import BulkActionsMenu, {
  BulkActionProps,
} from "components/generics/BulkActionsMenu";
import SelectedTableRowsMenu from "./SelectedTableRowsMenu";

export type Filters = {
  status?: string;
  searchWord: string;
};

type Props = {
  title: string;
  type:
    | "team"
    | "employee"
    | "department"
    | "dashboard"
    | "integration"
    | "location";
  titleIcon?: JSX.Element;
  addLabel?: string;
  state: State<Filters>;
  selectedItems: any[];
  bulkActions?: BulkActionProps[];
  onAdd?: () => any;
  resetPagination?: () => void;
  titleSize?: "small" | "medium" | "large";
  titleWeight?: "font-bold" | "font-semibold";
  insideComponent?: boolean;
};

export default function DataTableFilters({
  title,
  type,
  titleIcon,
  addLabel,
  state,
  selectedItems,
  bulkActions,
  titleSize,
  titleWeight,
  onAdd,
  resetPagination = () => {},
}: Props) {
  const innerState = useState(state);

  const updateStatus = (selected: boolean) => {
    resetPagination();

    if (selected) {
      innerState.status.set(
        innerState.status.value === "all"
          ? "false"
          : innerState.status.value === "true"
          ? "none"
          : innerState.status.value === "false"
          ? "all"
          : "true"
      );
    } else {
      innerState.status.set(
        innerState.status.value === "all"
          ? "true"
          : innerState.status.value === "true"
          ? "all"
          : innerState.status.value === "false"
          ? "none"
          : "false"
      );
    }
  };

  return (
    <div className={`bg-pl-grayscale-white flex items-center justify-between rounded-2xl ${type === 'location' ? "px-2 border-none" : "p-4 border"}`}>
      <SelectedTableRowsMenu
        pageTitle={title}
        type={type}
        titleIcon={titleIcon}
        selectedItems={selectedItems}
        bulkActions={bulkActions}
        titleSize={titleSize}
        titleWeight={titleWeight}
      />

      <div className="flex items-center justify-between gap-5">
        {innerState.status.get() && (
          <div className="gap-2 w-48 flex">
            <Button
              size="small"
              minimal
              onClick={() => updateStatus(true)}
              key={"true"}
              $shadow={true}
            >
              <S.FilterLabel
                selected={
                  innerState.status.value === "true" ||
                  innerState.status.value === "all"
                }
                type={SeverityType.INFO}
              >
                Active
              </S.FilterLabel>
            </Button>

            <Button
              size="small"
              minimal
              onClick={() => updateStatus(false)}
              key={"false"}
              $shadow={true}
            >
              <S.FilterLabel
                selected={
                  innerState.status.value === "false" ||
                  innerState.status.value === "all"
                }
                type={SeverityType.CRITICAL}
              >
                Deactivated
              </S.FilterLabel>
            </Button>
          </div>
        )}
        <div className="flex lg:ml-8 items-center rounded-md">
          <div className="relative flex w-[240px] bg-pl-variant-beige rounded-xl">
            <TextField
              placeholder="Search"
              icon={<SearchIcon className="text-pl-grayscale-gray" />}
              iconposition={"right"}
              onChange={({ target: { value } }) => {
                innerState.searchWord.set(value);
                resetPagination();
              }}
              border={false}
            />
          </div>
        </div>
        {onAdd && addLabel && (
          <div className="space-x-8">
            <Button btntype="primary" $shadow={true} onClick={() => onAdd()}>
              {addLabel}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
