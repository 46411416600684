import React from "react";
import employeeService from "services/employeeService";
import { LoadingWidget } from "components/utils/LoadingWidget";
import integrationService from "services/integrationService";
import UnmappedUserMessage from "components/ui/UnmappedUser";
import TabTitle from "components/structure/TabTitle";
import auth from "services/auth";
import { ConfirmationModal } from "components/utils/Modal/ConfirmationModal";
import EmployeesList from "./EmployeesList";
import Breadcrumbs from "components/generics/Breadcrumbs";
import { Employee } from "model/employee";
import { StatusConfirmationModal } from "components/utils/Modal/StatusConfirmationModal";
import {useLocation} from "react-router-dom";
import {useState} from "@hookstate/core";
import {UnmappedIntegrationUser} from "../../model/integration";

const Employees = () => {
  const location = useLocation();
  const id: number | undefined = location.state as number;

  TabTitle("Employees");

  const allEmployees =
    useState<Employee[]>(() => employeeService.getAllEmployees());

  const unmappedUsers = useState<UnmappedIntegrationUser[]>(() => integrationService.getUnmappedUsers());

  return (
    <>
      <ConfirmationModal />
      <StatusConfirmationModal/>
      <Breadcrumbs
        items={[
          { label: "Employees", url: `/employees` },
          { label: "List View", url: `/employees` },
        ]}
      />
      <div className="rounded-md w-full">
        <div className="rounded-md w-full">
          {auth.currentUserIsAdmin() && (
            <LoadingWidget<UnmappedIntegrationUser[]>
              value={unmappedUsers}
              builder={(unmapped) => (
                <UnmappedUserMessage unmapped={unmapped} />
              )}
              size="small"
            />
          )}
          <LoadingWidget<Employee[]>
            value={allEmployees}
            builder={(empList) => (
              <EmployeesList empList={empList} title={"Employees List"} />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(Employees);
