import React from "react";
import {ChartData, ChartOptions, LegendItem} from "chart.js";
import {Chart} from "react-chartjs-2";
import {ChartLabelValuePair} from "model/chart";
import {capitalize} from "utils/text";
import {CHART_COLORS} from "utils/color";
import {CHART_WHITE} from "../constants";
import {SAMPLE_FOCUS_AREA} from "model/mockedValues";
import BlurredContainer from "components/generics/BlurredContainer";
import {ChartTypeEnum} from "../GenericChart";
import {legendStyle, nameStyle, tooltipStyle, valueStyle} from "./styles";

function DoughnutChart({
                         labelValuePairs,
                         showLegend = true,
                       }: {
  labelValuePairs: ChartLabelValuePair[];
  showLegend?: boolean;
}) {
  let hasData = true;

  const events = labelValuePairs.reduce((a, b) => a + b.value, 0);
  if (events === 0) {
    hasData = false;
  }

  let values = [] as ChartLabelValuePair[];

  const data = () => {
    if (!hasData) {
      values = SAMPLE_FOCUS_AREA;
      return getChartData(SAMPLE_FOCUS_AREA);
    }
    values = labelValuePairs;
    return getChartData(labelValuePairs);
  };

  const getChartData = (values: ChartLabelValuePair[]) => {
    return {
      labels: values.map((pair) => capitalize(pair.label) + '|' + pair.value),
      datasets: [
        {
          data: values.map((pair) => pair.value),
          backgroundColor: CHART_COLORS,
          borderColor: CHART_WHITE,
          borderWidth: 3
        },
      ],
    } as ChartData<"doughnut">;
  };

  const getLabels = () => {
    return values.map((value, index) => {
      return {
        text: capitalize(value.label).padEnd(35, " "),
        fillStyle: CHART_COLORS[index],
        strokeStyle: CHART_COLORS[index]
      } as LegendItem;
    })
  }


  const externalTooltipHandler = (context) => {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.innerHTML = '<div></div>';
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    let tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = "0";
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    function getBody(bodyItem) {
      return bodyItem.lines[0].split(':')[0];
    }

    // Set Text
    if (tooltipModel.body) {
      const bodyLines = tooltipModel.body.map(getBody);

      const bodyLine = bodyLines[0].split('|');
      const name = bodyLine[0];
      const value = bodyLine[1];
      const percentage = ((parseInt(value) / events) * 100).toFixed(0);
      const index = values.indexOf(values.find(v => capitalize(v.label) === name) as ChartLabelValuePair);

      let legendDiv = '<div style="' + legendStyle(index) + '"></div>';
      let nameDiv = '<div style="' + nameStyle() + '">' + name + '</div>';
      let valueDiv = '<div style="' + valueStyle() + '">' + value + '</div>';
      let percentageDiv = '<div style="' + valueStyle() + '">' + percentage + '%</div>';

      const innerHtml = '<div style="' + tooltipStyle(index) + '">' + legendDiv + nameDiv + valueDiv + percentageDiv + '</div>';

      let divRoot = tooltipEl.querySelector('div');
      if (divRoot !== null) {
        divRoot.innerHTML = innerHtml;
      }
    }

    const position = context.chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.opacity = "1";
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = (position.left + window.pageXOffset + tooltipModel.caretX - 65) + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    tooltipEl.style.pointerEvents = 'none';
  }


  const options = {
    plugins: {
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler
      },
      legend: {
        fullSize: true,
        display: showLegend,
        position: "bottom",
        align: "start",
        labels: {
          pointStyle: "circle",
          usePointStyle: true,
          textAlign: "left",
          font: {
            family: "Inter",
            size: 12
          },
          generateLabels: getLabels,
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    fill: true,
    skipNull: true,
    cutout: 60,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  } as ChartOptions<"doughnut">;

  return (
    <BlurredContainer blur={!hasData}>
      <Chart type={ChartTypeEnum.DOUGHNUT}
             data={data()}
             options={options}
      />
    </BlurredContainer>
  );
}

export default React.memo(DoughnutChart);
