import React from "react";
import { useState } from "@hookstate/core";
import { useNavigate } from "react-router-dom";
import { sendAsUserMessageWithBotReply } from "model/chat-session";
import { ReactComponent as SendIcon } from "icons/header-send.svg"
import TextField, { TextFieldProps } from "components/generics/TextField";
import { SearchIcon } from "@heroicons/react/solid";
import * as S from "./styles";

type ChatInputHeaderProps = {
  placeholderText?: string;
  redirectToInsights?: boolean;
  smallHeight?: boolean;
} & Pick<TextFieldProps, "inputsize">;

const ChatInputHeader = ({
  placeholderText = "Start typing to learn more about your team!",
  redirectToInsights = false,
  smallHeight = false,
  inputsize = "medium",
}: ChatInputHeaderProps) => {
  const navigate = useNavigate();
  const chatInput = useState("");

  const onSendButtonClicked = () => {
    if (chatInput.value !== "") {
      if (redirectToInsights) {
        navigate("/insights");
      }
      navigate("/insights", {replace: true});
      sendAsUserMessageWithBotReply(chatInput.value);
      chatInput.set("");
    }
  };

  const heightWidthClass = smallHeight ? "h-7 w-7" : "h-10 w-10";

  return (
    <>
      <S.HeaderChatInputWrapper>
        <S.ChatInputContainer>
          <TextField
            placeholder={placeholderText}
            icon={<SearchIcon className="text-[#999]" />}
            value={chatInput.value}
            rounded
            border={true}
            background={true}
            inputsize={inputsize}
            onChange={(event) => {
              chatInput.set(event.target.value);
            }}
            onKeyUp={(event) => {
              if (event.key === "Enter" && chatInput.value.length > 0) {
                onSendButtonClicked();
              }
            }}
            className={"pr-8"}
          />

        <S.HeaderSendButtonWrapper>
            <S.HeaderSendButton
              type="button"
              className={`${heightWidthClass} `}
              onClick={onSendButtonClicked}
            >
                <SendIcon/>
            </S.HeaderSendButton>
          </S.HeaderSendButtonWrapper>
        </S.ChatInputContainer>
      </S.HeaderChatInputWrapper>
    </>
  );
};

export default ChatInputHeader;
