import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  flex-wrap
  hidden
  md:flex
  gap-2
`;

export const MenuItem = tw.div`
  flex
  h-full
  gap-1.5
  items-center
  relative
  font-semibold
  text-pl-grayscale-black
  rounded
  p-2
  hover:text-pl-primary-green-default
`;

export const LinkWrapper = tw.div`
  flex
  flex-col
  font-normal
  last:border-t
  hover:bg-pl-primary-green-light
`;

export const Label = tw.span`
  flex
  items-center
`;

export const Title = tw(Label)`
  gap-1
  text-sm
`;

export const Icon = tw.div`
  w-5
  h-5
`;
