import React from "react";
import BlurredContainer from "components/generics/BlurredContainer";
import SingleNumber from 'components/ui/Charts/SingleNumber';
import {SAMPLE_STAY_FACTOR} from "model/mockedValues";
import SFCategories from "../SFCategories";
import {WidgetTitle} from "components/utils/WidgetTitle";
import {DailyStayFactor} from "model/stayFactor";
import {useState} from "@hookstate/core";
import Button from "components/generics/Button";
import {createTrendArray, getStayFactorValueAndDelta} from "utils/stayFactor";
import {Employee, Team} from "model/employee";
import {Department} from "model/department";
import stayFactorService from "services/stayfactor";
import {LoadingWidget} from "components/utils/LoadingWidget";
import SFComponentsPieChart from "../SFComponentsPieChart";
import {PeriodSelectorState} from "components/utils/PeriodSelector";
import SFComponentsRadarChart from "components/ui/Charts/SFComponentsRadarChart";

import * as S from "./styles";

import {ReactComponent as ArrowLeft} from "icons/arrow-left.svg";
import {ReactComponent as ArrowRight} from "icons/arrow-right.svg";

export default function RangeAndTrendChart({
                                             trendValues = [],
                                             title,
                                             numPages = 1,
                                             currentSFComponents,
                                             employees,
                                             teams,
                                             departments,
                                             pieChartAsFirstPage = true,
                                             pieChartWithViewOptions = false,
                                           }: {
  trendValues?: number[];
  title?: string;
  numPages?: number;
  currentSFComponents?: DailyStayFactor[];
  employees: Employee[];
  teams?: Team[];
  departments?: Department[];
  pieChartAsFirstPage?: boolean;
  pieChartWithViewOptions?: boolean;
}) {
  let hasData = true;

  if (employees) {
    employees = employees.filter(e => e.active);
  }
  if (teams) {
    teams = teams.filter(t => t.active && t.employees);
  }
  if (departments) {
    departments = departments.filter(d => d.active && d.employees);
  }

  const hasPieChartComponent = employees && employees.length > 0;

  const employeesStayFactor = useState<DailyStayFactor[][]>(() => Promise.all(
      hasPieChartComponent ?
          employees.map(emp =>
              stayFactorService.getStayFactorByEmployee(emp.id, PeriodSelectorState.selectedPeriod.value) as Promise<DailyStayFactor[]>)
          :
          []
  ))

  numPages = hasPieChartComponent ? numPages + 1 : numPages;

  const pageNumber = useState<number>(hasPieChartComponent && pieChartAsFirstPage ? numPages - 1 : 0);

  return (
    <S.Wrapper>
      <LoadingWidget<DailyStayFactor[][]>
        value={employeesStayFactor}
        builder={(dsf: DailyStayFactor[][]) => {
          if (hasPieChartComponent) {
            trendValues = createTrendArray(dsf);
          }

          if (trendValues.length === 0) {
            hasData = false;
            trendValues = SAMPLE_STAY_FACTOR;
          }

          const sf = getStayFactorValueAndDelta(trendValues);

          return (
            <BlurredContainer blur={!hasData}>
              {
                title &&
                <S.TitleWrapper>
                  <WidgetTitle>{title}</WidgetTitle>
                </S.TitleWrapper>
              }

              {
                pageNumber.value === 0 &&
                <>
                  <SFCategories title={title} value={sf.currentValue}/>
                  <SingleNumber stayFactor={sf}/>
                </>
              }

              {
                pageNumber.value === 1 && currentSFComponents && currentSFComponents.length > 0 &&
                <SFComponentsRadarChart currentDailyStayFactor={currentSFComponents[0]}
                                      previousDailyStayFactor={currentSFComponents[currentSFComponents.length - 1]}/>
              }

              {
                ((numPages === 2 && pageNumber.value === 1) ||
                  (numPages === 3 && pageNumber.value === 2))
                && hasPieChartComponent
                &&
                <SFComponentsPieChart stayFactorData={dsf}
                                      employees={employees}
                                      teams={teams}
                                      departments={departments}
                                      sfValue={sf.currentValue}
                                      pieChartWithViewOptions={pieChartWithViewOptions}
                />
              }

              {
                numPages > 1 &&
                <S.ArrowsSection>
                  <S.ArrowContainer>
                    <Button
                      className={
                        "!h-10 !w-10 !rounded-full !bg-pl-grayscale-white p-1 flex justify-center"
                      }
                      btntype="default"
                      minimal
                      $shadow={true}
                      disabled={pageNumber.value === 0}
                      onClick={() => pageNumber.set(pageNumber.value - 1)}
                    >
                      <ArrowLeft
                        className={`w-4 h-4 ${
                          pageNumber.value === 0
                            ? "text-pl-grayscale-lightgray cursor-default"
                            : ""
                        } `}
                      />
                    </Button>
                  </S.ArrowContainer>
                  <S.ArrowContainer>
                    <Button
                      className={
                        "!h-10 !w-10 !rounded-full !bg-pl-grayscale-white p-1 flex justify-center"
                      }
                      btntype="default"
                      minimal
                      $shadow={true}
                      disabled={pageNumber.value === numPages - 1}
                      onClick={() => pageNumber.set(pageNumber.value + 1)}
                    >
                      <ArrowRight
                        className={`w-4 h-4 ${
                          pageNumber.value === numPages - 1
                            ? "text-pl-grayscale-lightgray cursor-default"
                            : ""
                        } `}
                      />
                    </Button>
                  </S.ArrowContainer>
                </S.ArrowsSection>
              }
            </BlurredContainer>
          )
        }}
      />
    </S.Wrapper>
  );
}
