import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  relative
  flex
  items-center
  justify-between
  right-0
  w-full
  pr-1
`;

export const SimpleTitle = tw.div`
  text-base
  font-normal
`;

export const WrapperArea = tw.div`
  relative
  z-0
`;

export const WrapperEditArea = tw.div`
  w-full
  my-5
  text-left
`;

export const ButtonsWrapper = tw.div`
  absolute
  right-1
  flex
  gap-4
  mt-1
`;

export const List = tw.ul`
  gap-2
  text-left
  my-3
`;

export const ListItem = tw.li`
  col-span-1
  py-2
`;

export const KeyMetricValues = tw.div`
  flex
  flex-row
  items-end
`;

export const KeyMetricValue = tw.div`
  text-2xl
  font-black
`;

export const KeyMetricPercentage = tw.div`
  flex
  items-center
  ml-2
  mb-1
  text-sm
`;

export const KeyMetricLabel = tw.div`
  text-sm
`;

export const ArrowsSection = tw.div`
  flex
  items-end
  justify-end
  absolute
  right-0
  bottom-[-50px]
  w-full
`;

export const ArrowContainer = tw.div`
  bg-pl-variant-beige
  p-2
  rounded-full
`;

export const LabelArea = tw.div`
  h-24
  w-full
`;

export const TextLabel = tw.span`
  h-full
  w-full
  text-sm
  italic
  flex
  items-center
  justify-center
`;