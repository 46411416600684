import {Employee, Team} from "model/employee";
import {PeriodSelectorState, YEAR_NUM_DAYS,} from "components/utils/PeriodSelector";
import React from "react";
import {NetworkGraphProps} from "model/graph";
import {KeyMetricsType} from "model/keymetrics";
import TabTitle from "components/structure/TabTitle";
import graphService from "services/graphService";
import keyMetricsService from "services/keymetrics";
import {LoadingWidget} from "components/utils/LoadingWidget";
import recommendationsService from "services/recommendations";
import RecommendationTable from "components/ui/RecommendationTable";
import KeyMetrics from "components/ui/Keymetrics";
import BaseDashboard from "components/structure/BaseDashboard";
import {DailyStayFactor} from "model/stayFactor";
import stayfactor from "services/stayfactor";
import {useState} from "@hookstate/core";
import FocusAreas from "components/ui/Focusareas";
import {ChartLabelValuePair} from "model/chart";
import focusAreaService from "services/focusArea";

import * as S from "./styles";

export const TeamDashboard = ({ team }: { team: Team }) => {
  const selectedPeriod = PeriodSelectorState.selectedPeriod.value;

  const networkGraphPropsByTeam =
    useState<NetworkGraphProps>(() => graphService.getNetworkGraphPropsByTeam(team.id));

  const teamStayFactorComponents =
    useState<DailyStayFactor[]>(() => stayfactor.getStayFactorComponentsForTeam(team.id, selectedPeriod)
      .then(dsfArray => dsfArray.slice(0, selectedPeriod)));

  const keyMetricsData =
    useState<KeyMetricsType>(() => keyMetricsService.getKeyMetricsForTeam(team.id, selectedPeriod));

  const teamEmployees = useState<Employee[]>(() => team.employees ? team.employees : []);

  const teamFocusArea = useState<ChartLabelValuePair[]>(() => focusAreaService.getFocusAreaForTeam(team.id, selectedPeriod));

  TabTitle(team.name.concat(" Team"));

  return (
    <>
      <BaseDashboard
        title="Team"
        data={team}
        networkGraphContext="employees"
        networkGraphData={networkGraphPropsByTeam}
        stayFactorComponentsData={teamStayFactorComponents}
        employees={teamEmployees}
        pieChartAsFirstPage={false}
        rightChildren={
        <S.RightChildWrapper>
          <LoadingWidget<KeyMetricsType>
            value={keyMetricsData}
            builder={(metrics) => (
              <KeyMetrics team={team} keyMetricsType={metrics}/>
            )}
          />
          <LoadingWidget<ChartLabelValuePair[]>
            value={teamFocusArea}
            builder={(data) => (
              <FocusAreas eventInfo={data} classname={`mt-8`}/>
            )}
          />
        </S.RightChildWrapper>
        }
        leftChildren={
          <RecommendationTable
            title="Impacting Factors"
            filterByEmployee={true}
            employeeFilterOptions={team.employees}
            loadRecommendations={(
              size,
              pageIndex,
              severityFilters,
              selectedEmployeeId
            ) => {
              return selectedEmployeeId
                ? recommendationsService.getRecommendationsByFilter({
                    employeeId: selectedEmployeeId,
                    pageSize: size,
                    pageNumber: pageIndex,
                    severityList: severityFilters,
                    numDaysBefore: YEAR_NUM_DAYS,
                  })
                : recommendationsService.getRecommendationsByFilter({
                    teamList: [team.id],
                    pageSize: size,
                    pageNumber: pageIndex,
                    severityList: severityFilters,
                    numDaysBefore: YEAR_NUM_DAYS,
                  });
            }}
          />
        }
      />
    </>
  );
};
