import React from "react";
import {DailyStayFactor, EntityStayFactor} from "model/stayFactor";
import {createDSFAvgArray, getBGLightColor, getFontColor, getStayFactorValueAndDelta,} from "utils/stayFactor";
import {Employee, Team} from "model/employee";
import {Department} from "model/department";
import {ChartTypeEnum} from "../GenericChart";
import {GenericChartData} from "model/chart";
import {ChartOptions} from "chart.js";
import {Chart} from "react-chartjs-2";
import {capitalize, initials} from "utils/text";
import {useState} from "@hookstate/core";
import Button from "components/generics/Button";
import {useNavigate} from "react-router-dom";
import {getBorderWidth, getLabels, getSliceLabel, SFByEntity} from "./utils";
import {CHART_WHITE} from "../constants";

import * as S from "./styles";
import {arrowStyle, avatarStyle, deltaStyle, iconsClassname, nameStyle, sfStyle, tooltipStyle} from "./styles";

import {ReactComponent as DepartmentIcon} from "icons/department.svg";
import {ReactComponent as TeamIcon} from "icons/team.svg";
import {ReactComponent as EmployeeIcon} from "icons/employee.svg";

export default function SFComponentsPieChart({
                                               stayFactorData,
                                               employees,
                                               teams,
                                               departments,
                                               sfValue,
                                               pieChartWithViewOptions,
                                             }: {
  stayFactorData: DailyStayFactor[][],
  employees: Employee[],
  teams?: Team[],
  departments?: Department[],
  sfValue: number,
  pieChartWithViewOptions: boolean,
}) {
  const navigate = useNavigate();

  let tooltipModel;
  let tooltipEl;

  const viewValue = useState<string>('employees');
  const isEmployeeView = viewValue && viewValue.value === 'employees';

  const stayFactorDataByEntity = [] as EntityStayFactor[];

  const getEmployeesDailyStayFactor = (emps: Employee[]) => {
    const empsDailyStayFactor = [] as DailyStayFactor[][];
    emps
      .filter(emp => emp.active)
      .forEach(emp => {
        const index = employees.findIndex(e => e.id === emp.id);
        const empDailyStayFactor = stayFactorData[index];

        if (empDailyStayFactor) {
          empsDailyStayFactor.push(empDailyStayFactor);
        }
      });

    if (empsDailyStayFactor.length > 0) {
      return createDSFAvgArray(empsDailyStayFactor) as DailyStayFactor[];
    } else {
      return [];
    }
  }


  if (isEmployeeView) {
    employees.forEach((emp, index) => {
      if (stayFactorData[index].length > 0) {
        stayFactorDataByEntity.push({
          entity: emp,
          stayFactorMetric: stayFactorData[index]
        });
      }
    });

  } else if (teams && viewValue.value === 'teams') {
    const teamsDailyStayFactor = [] as DailyStayFactor[][];
    teams.map((team) => {
      if (team.employees) {
        teamsDailyStayFactor.push(getEmployeesDailyStayFactor(team.employees));
      }
    });

    teams.forEach((t, index) => {
      if (teamsDailyStayFactor[index].length > 0) {
        stayFactorDataByEntity.push({
          entity: t,
          stayFactorMetric: teamsDailyStayFactor[index]
        });
      }
    });

  } else if (departments && viewValue.value === 'departments') {
    const depsDailyStayFactor = [] as DailyStayFactor[][];
    departments.map((dep) => {
      if (dep.employees) {
        depsDailyStayFactor.push(getEmployeesDailyStayFactor(dep.employees));
      }
    });

    departments.forEach((d, index) => {
      if (depsDailyStayFactor[index].length > 0) {
        stayFactorDataByEntity.push({
          entity: d,
          stayFactorMetric: depsDailyStayFactor[index]
        });
      }
    });
  }


  const sfByEntities = stayFactorDataByEntity.map(stayFactorData => {
    return {
      entity: stayFactorData.entity,
      sf: getStayFactorValueAndDelta(stayFactorData.stayFactorMetric.map(sfm => sfm.value))
    } as SFByEntity
  }).sort((a, b) => (a.sf.currentValue > b.sf.currentValue) ? 1 : -1);


  const genericChartData = {
    labels: sfByEntities.map(esf => esf.entity.id),
    datasets: [{
      label: "stay factor",
      data: sfByEntities.map(esf => esf.sf.currentValue)
    }]
  } as GenericChartData;


  const hideTooltip = () => {
    tooltipModel.opacity = 0;
    tooltipEl.style.opacity = "0";
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.innerHTML = '<div></div>';
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      hideTooltip();
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    function getBody(bodyItem) {
      return bodyItem.lines[0].split(':')[0];
    }

    // Set Text
    if (tooltipModel.body) {
      const bodyLines = tooltipModel.body.map(getBody);

      const values = bodyLines[0].split('|');
      const name = values[0];
      const sf = values[1];
      const delta = values[2];

      let innerHtml = '';
      bodyLines.forEach(function (body, i) {
        let avatarDiv = '<div style="' + avatarStyle() + '">' + initials(name) + '</div>';
        let nameDiv = '<div style="' + nameStyle() + '">' + name + '</div>';
        let sfDiv = '<div style="' + sfStyle(sf) + '">' + sf + '</div>';

        let deltaDiv = '';
        if (delta > 0) {
          deltaDiv = '<div style="color: ' + getFontColor(65) + '; ' + deltaStyle() + '">' +
            '<div style="' + arrowStyle + '">▲</div>' + Math.abs(delta) + '%' + '</div>';
        } else if (delta < 0) {
          deltaDiv = '<div style="color: ' + getFontColor(0) + '; ' + deltaStyle() + '">' +
            '<div style="' + arrowStyle + '">▼</div>' + Math.abs(delta) + '%' + '</div>';
        } else {
          deltaDiv = '<div style="' + deltaStyle() + '">0</div>';
        }

        const div = '<div style="' + tooltipStyle(sf) + '">' + avatarDiv + nameDiv + sfDiv + deltaDiv + '</div>';
        innerHtml += div;
      });

      let divRoot = tooltipEl.querySelector('div');
      if (divRoot !== null)
        divRoot.innerHTML = innerHtml;
    }

    const position = context.chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.opacity = "1";
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = (position.left + window.pageXOffset + tooltipModel.caretX - 200) + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    tooltipEl.style.pointerEvents = 'none';
  }


  const pieCustomOptions = {
    plugins: {
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler
      },
      legend: {
        fullSize: true,
        display: true,
        position: "bottom",
        align: "start",
        labels: {
          pointStyle: "circle",
          usePointStyle: true,
          textAlign: "left",
          font: {
            family: "Inter",
            size: 12
          },
          generateLabels: getLabels,
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    fill: true,
    skipNull: true,
    cutout: 25,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
    onClick: function (evt, element) {
      if (element.length > 0) {
        hideTooltip();
        navigate("/" + viewValue.value + "/" + genericChartData.labels[element[0].index], {state: "fetch"})
      }
    }
  } as ChartOptions;


  const getChartData = (values: GenericChartData) => {
    const ids = values.labels;
    const datasets = values.datasets.map((item, index) => {
      const fixedArray = [] as number[];
      item.data.forEach(it => fixedArray.push(1));

      const itemWithFixedValues = {
        data: fixedArray,
        label: item.label
      }; //this is for slices with same size

      return {
        ...itemWithFixedValues,
        ids: values.labels,
        label: capitalize(item.label),
        backgroundColor: item.data.map(value => getBGLightColor(value)),
        borderColor: CHART_WHITE,
        borderWidth: getBorderWidth(stayFactorDataByEntity.length),
      };
    });

    return {
      labels: getSliceLabel(ids, sfByEntities),
      datasets: [datasets[0]],
    };
  };

  return (
    <>
      {
        pieChartWithViewOptions && viewValue &&
        <S.IconsWrapper>
          <Button btntype="neutral"
                  minimal
                  title={"Departments"}
                  icon={<DepartmentIcon
                    className={`${iconsClassname} ${viewValue.value === "departments" && "text-pl-primary-green-default"}`}/>}
                  onClick={() => viewValue.set("departments")}
          />
          <Button btntype="neutral"
                  minimal
                  title={"Teams"}
                  icon={<TeamIcon
                    className={`${iconsClassname} ${viewValue.value === "teams" && "text-pl-primary-green-default"}`}/>}
                  onClick={() => viewValue.set("teams")}
          />
          <Button btntype="neutral"
                  minimal
                  title={"Employees"}
                  icon={<EmployeeIcon
                    className={`${iconsClassname} ${viewValue.value === "employees" && "text-pl-primary-green-default"}`}/>}
                  onClick={() => viewValue.set("employees")}
          />
        </S.IconsWrapper>
      }
      <S.Wrapper>
        <Chart type={ChartTypeEnum.PIE}
               data={getChartData(genericChartData)}
               options={pieCustomOptions}
               height={320}
               width={250}
        />
        <S.ValueLabel>
          {Math.round(sfValue)}
        </S.ValueLabel>
      </S.Wrapper>
    </>
  );
}
