import * as Yup from 'yup';

export const IntegrationValidator = Yup.object().shape({
    name: Yup.string().min(3, 'Name must be at least 3 characters').required('Name is a required field'),
    apiKey: Yup.string().when('mode', {
                        is: (mode) => mode && mode !== 'oauth',
                        then: Yup.string().required('API Key or Username is a required field'),
                        otherwise: Yup.string(),
                        }),
    apiSecret: Yup.string().when('mode', {
                         is: (mode) => mode && mode !== 'oauth',
                         then: Yup.string().required('API Secret is a required field'),
                         otherwise: Yup.string(),
                         }),
    apiFilter: Yup.string().when('integrationRequiredFields', {
                         is: (integrationRequiredFields) => integrationRequiredFields.find(f => f === 'API_FILTER'),
                         then: Yup.string().required('API Filter is a required field'),
                         otherwise: Yup.string(),
                         }),
    apiUrl: Yup.string().when('integrationRequiredFields', {
                         is: (integrationRequiredFields) => integrationRequiredFields.find(f => f === 'API_URL'),
                         then: Yup.string().required('API URL is a required field'),
                         otherwise: Yup.string(),
                         })
});