import React from "react";
import {
  BarChartReport,
  GraphReport,
  LexiReply,
  LineChartReport,
  QueryResponse,
  TableReport,
} from "model/lexi";
import LineChartReportRender from "./reports/LineChartReportRender";
import BarChartRenderer from "./reports/BarChartRenderer";
import TableReportRender from "./reports/TableReportRender";
import NetworkGraph from "../NetworkGraph";
import GenericChart, { ChartTypeEnum } from "../Charts/GenericChart";
import chartMockedValues from "components/ui/Charts/GenericChart/mock";
import matrixMockedValues from "components/ui/Charts/HeatMap/mock";
import HeatMap from "components/ui/Charts/HeatMap";
import VennDiagram from "components/ui/Charts/VennDiagram";
import vennDiagramMockedValues from "components/ui/Charts/VennDiagram/mock";
import * as S from "./styles";

const BotReply = ({ lexiReply, withChartTitle = false }: { lexiReply: LexiReply, withChartTitle: boolean }) => {
  const renderQueryResponse = (queryResponse: QueryResponse | QueryResponse[]) => {
    if (Array.isArray(queryResponse)) {
      return queryResponse.map(qr => renderIndividualResponse(qr, withChartTitle));
    } else {
      return renderIndividualResponse(queryResponse, withChartTitle);
    }
  };

  const renderIndividualResponse = (queryResponse: QueryResponse, withTitle: boolean) => {
    const message = (withTitle && queryResponse.title) ?
      <S.BotReplyContainer>
        {queryResponse.title}
      </S.BotReplyContainer>
      : <></>;

      return <>
        {message}
        {renderIndividualReport(queryResponse)}
      </>
  }
  const renderIndividualReport = (queryResponse: QueryResponse) => {
    const { type, report } = queryResponse;

    switch (type) {
      case "table":
        return (
          <>
            <S.BotReplyContainerMinHeight>
              <TableReportRender
                report={report as TableReport}
                pageSize={5}
              />
            </S.BotReplyContainerMinHeight>
          </>
        );
      case "barChart":
        return (
          <>
            <S.BotReplyContainerMinHeight>
              <BarChartRenderer report={report as BarChartReport} />
            </S.BotReplyContainerMinHeight>
          </>
        );
      case "lineChart":
        return (
          <>
            <S.BotReplyContainerMinHeight>
              <LineChartReportRender report={report as LineChartReport} />
            </S.BotReplyContainerMinHeight>
          </>
        );
      case "graph":
        return (
          <>
            <S.BotReplyContainerMinHeight>
              <NetworkGraph report={report as GraphReport} context="teams" />
            </S.BotReplyContainerMinHeight>
          </>
        );

      case "pieChart":
      case "doughnutChart":
      case "polarChart":
      case "radarChart":
        return (
          <>
            <S.BotReplyContainer>
              <GenericChart
                report={chartMockedValues}
                type={ChartTypeEnum[type.replace("Chart", "").toUpperCase()]}
              />
            </S.BotReplyContainer>
          </>
        );

      case "areaChart":
        const areaChartOptions = {
          elements: {
            line: {
              tension: 0.4,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        };

        return (
          <>
            <S.BotReplyContainer>
              <GenericChart
                report={chartMockedValues}
                type={ChartTypeEnum.AREA}
                options={areaChartOptions}
              />
            </S.BotReplyContainer>
          </>
        );

      case "stackedBarChart":
        const stackedBarChartOptions = {
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        };

        return (
          <>
            <S.BotReplyContainer>
              <GenericChart
                report={chartMockedValues}
                type={ChartTypeEnum.STACKED_BAR}
                options={stackedBarChartOptions}
              />
            </S.BotReplyContainer>
          </>
        );

      case "heatMap":
        return (
          <>
            <S.BotReplyContainer>
              <HeatMap report={matrixMockedValues} />
            </S.BotReplyContainer>
          </>
        );

      case "vennDiagram":
        return (
          <>
            <S.BotReplyContainer>
              <VennDiagram report={vennDiagramMockedValues} />
            </S.BotReplyContainer>
          </>
        );

      default:
        return <></>
    }
  }

  const renderLexiReply = () => {
    const { reply } = lexiReply;
    if (typeof reply === "string") {
      return (
        <>
          <S.BotReplyContainer>
            {reply}
          </S.BotReplyContainer>
        </>
      );
    }

    return renderQueryResponse(reply);
  };
  return <>{lexiReply && renderLexiReply()}</>;
};

export default BotReply;
