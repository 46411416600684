export default {
  report: {
    nodes: [
      {
        id: 2,
        name: "Matthew Schmidt",
        group: 8314,
        active: false,
        isManager: true,
        stayFactor: {
          current: 99,
          variation: 2.345,
        },
        toShow: true,
      },
      {
        id: 10012,
        name: "John Hathorn",
        group: 27663,
        active: true,
        isManager: true,
        stayFactor: {
          current: 33,
          variation: -0.8321838238,
        },
        toShow: true,
      },
      {
        id: 4,
        name: "Hernani Cerqueira",
        group: 8314,
        active: true,
        isManager: true,
        stayFactor: {
          current: 85,
          variation: -0.5,
        },
        toShow: true,
      },
      {
        id: 13328,
        name: "Jean Test Account",
        group: 8314,
        active: true,
        isManager: true,
        stayFactor: {
          current: 80,
          variation: -0.2,
        },
        toShow: true,
      },
      {
        id: 13388,
        name: "Junel Mata",
        group: 13392,
        external: true,
        active: true,
        isManager: true,
        stayFactor: {
          current: 67,
          variation: -1.0,
        },
        toShow: true,
      },
      {
        id: 12696,
        name: "Pedro Matos",
        group: 13392,
        active: true,
        isManager: true,
        stayFactor: {
          current: 86,
          variation: -1.10,
        },
        toShow: true,
      },
      {
        id: 13390,
        name: "Sarah Katherine",
        group: 13392,
        active: true,
        isManager: true,
        stayFactor: {
          current: 54,
          variation: 0.7,
        },
        toShow: true,
      },
      {
        id: 8190,
        name: "Nico Sy",
        group: 8195,
        active: true,
        isManager: true,
        stayFactor: {
          current: 20,
          variation: -80,
        },
        toShow: true,
      },
      {
        id: 3,
        name: "Jim Smith",
        group: 8195,
        active: true,
        isManager: true,
        stayFactor: {
          current: 66,
          variation: 0.6,
        },
        toShow: true,
      },
    ],
    links: [
      {
        source: 2,
        target: 10012,
        value: 6923,
      },
      {
        source: 4,
        target: 10012,
        value: 838,
      },
      {
        source: 4,
        target: 2,
        value: 1753,
      },
      {
        source: 2,
        target: 13328,
        value: 73,
      },
      {
        source: 4,
        target: 13388,
        value: 159,
      },
      {
        source: 4,
        target: 12696,
        value: 159,
      },
      {
        source: 4,
        target: 13328,
        value: 90,
      },
      {
        source: 2,
        target: 13390,
        value: 38,
      },
      {
        source: 2,
        target: 8190,
        value: 12,
      },
      {
        source: 2,
        target: 13388,
        value: 189,
      },
      {
        source: 2,
        target: 3,
        value: 43,
      },
      {
        source: 2,
        target: 12696,
        value: 177,
      },
      {
        source: 10012,
        target: 3,
        value: 11,
      },
      {
        source: 4,
        target: 3,
        value: 3,
      },
      {
        source: 10012,
        target: 13390,
        value: 2,
      },
    ],
  },
};
