import React from "react";
import teamService from "services/teamService";
import { LoadingWidget } from "components/utils/LoadingWidget";
import TabTitle from "components/structure/TabTitle";
import { StatusConfirmationModal } from "components/utils/Modal/StatusConfirmationModal";
import TeamsList from "./TeamsList";
import Breadcrumbs from "components/generics/Breadcrumbs";
import { Team } from "model/employee";
import {useLocation} from "react-router-dom";
import {useState} from "@hookstate/core";

const Teams = () => {
  const location = useLocation();
  const id: number | undefined = location.state as number;

  const allTeams = useState(() => teamService.getTeams({ employees: true, skills: false }));
  TabTitle("Teams");

  return (
    <>
      <StatusConfirmationModal/>
      <Breadcrumbs
        items={[
          { label: "Teams", url: `/teams` },
          { label: "List View", url: `/teams` },
        ]}
      />
      <div className="rounded-md w-full">
        <LoadingWidget<Team[]>
          value={allTeams}
          builder={(teamList) => <TeamsList teamList={teamList} title={"Teams List"} />}
        />
      </div>
    </>
  );
};

export default React.memo(Teams);
