import React from "react";
import * as S from "./styles";

export default function ModalTextTitle({title}: {title: string}) {

  return (
    <S.Wrapper>
      <S.TabLabel>
        {title}
      </S.TabLabel>
    </S.Wrapper>
  );
}
