import React, { InputHTMLAttributes, forwardRef } from "react";

import * as S from "./styles";
import {useState} from "@hookstate/core";

export type CheckboxProps = {
  label?: string;
  labelFor?: string;
  isChecked?: boolean;
  value?: string | ReadonlyArray<string> | number;
  disabled?: boolean;
  lined?: boolean;
  clickable?: boolean;
  rounded?: boolean
  onCheck?: (status: boolean) => void;
} & InputHTMLAttributes<HTMLInputElement>;

const Checkbox: React.ForwardRefRenderFunction<
  HTMLInputElement,
  CheckboxProps
> = (
  {
    label,
    labelFor = "",
    isChecked = false,
    onCheck,
    value,
    disabled = false,
    lined = false,
    clickable = true,
    rounded = false,
    ...props
  }: CheckboxProps,
  ref
) => {
  const checked = useState(isChecked);

  const onChange = () => {
    const status = !checked;
    checked.set(status);

    !!onCheck && onCheck(status);
  };

  return (
    <S.Wrapper>
      <S.Input
        id={labelFor}
        type="checkbox"
        onChange={onChange}
        checked={checked.value}
        value={value}
        disabled={disabled}
        clickable={clickable ? 1 : 0}
        rounded={rounded? 1 : 0}
        ref={ref}
        {...props}
      />
      {!!label && (
        <S.Label
          htmlFor={labelFor}
          lined={lined}
          clickable={clickable ? 1 : 0}
          disabled={disabled}
        >
          {label}
        </S.Label>
      )}
    </S.Wrapper>
  );
};

export default forwardRef(Checkbox);
