import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  h-auto
  mt-5
`;

export const SimpleTitle = tw.div`
  text-base
  font-normal
`;

export const ChartWrapper = tw.div`
  relative
  z-0
  h-[260px]
  px-8
  pb-6
  pt-0
  mt-[-3]
`;

export const ValueLabel = tw.div`
  text-[22px]
  font-bold
  absolute
  left-[44%]
  w-8
  h-8
  flex
  items-center
  justify-center
  top-[39%]
`;