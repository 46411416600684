import React from "react";

type WidgetContainerPropType = {
    children: React.ReactNode,
    appendClassname?: string
}

export function FieldContainer({children, appendClassname}: WidgetContainerPropType) {
    return <div className={"w-full sm:w-2/4 p-2 " + appendClassname}>{children}</div>
}
