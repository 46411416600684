import React from "react";
import Button from "components/generics/Button";
import {
  Row,
  usePagination,
  UsePaginationInstanceProps,
  useMountedLayoutEffect,
  useTable,
  TableInstance,
  useSortBy,
  UseSortByColumnProps,
  ColumnInterface,
  UseSortByOptions,
  UseTableOptions,
  UseSortByInstanceProps,
  UseTableColumnProps,
  useRowSelect, Cell,
} from "react-table";
import {DatatableColumn, defaults, DatatableProps} from "model/datatable";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/solid";
import {useLocation, useNavigate} from "react-router-dom";
import * as S from "./styles";
import Checkbox from "components/generics/Checkbox";
import * as _ from "lodash";
import hash from "object-hash";

interface UseTableAndPaginationInstanceProps<D extends object>
  extends TableInstance<D>,
    UsePaginationInstanceProps<D>,
    UseSortByInstanceProps<D> {
}

const updateRows = _.memoize(
  (rows: Row<any>[]) => rows.map(d => d.original),
  (rows: Row<any>[]) => hash(rows.map(r => r.id).sort())
);

const DataTable = <D extends object>({
                                       columns,
                                       data,
                                       pageNumber = defaults.pageNumber,
                                       pageLength = defaults.pageLength,
                                       hasPagination = true,
                                       pageChangeCallback,
                                       hiddenColumns = [],
                                       isUrlSorted = false,
                                       sortColumn,
                                       sortDesc,
                                       onRowSelection,
                                       hasClearSelection,
                                       $shadow = true,
                                     }: DatatableProps<D>) => {
  // Use the state and functions returned from useTable to build your UI
  const tableInstance = useTable<D>(
    {
      columns,
      data,
      initialState: {
        pageIndex: pageNumber,
        pageSize: pageLength,
        hiddenColumns: hiddenColumns,
      },
      disableSortRemove: true,
      autoResetPage: false,
      autoResetHiddenColumns: false,
      autoResetSortBy: false,
      autoResetSelectedRows: false,
      autoResetSelectedCell: false,
      autoResetSelectedColumn: false,
      enableMultiRowSelection: true,
    } as UseTableOptions<D> & UseSortByOptions<D>,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (!!onRowSelection) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({getToggleAllPageRowsSelectedProps}) => (
              <Checkbox className={"rounded"} {...getToggleAllPageRowsSelectedProps()} />
            ),
            Cell: ({row}) => {
              return <Checkbox className={"rounded"} {...row.getToggleRowSelectedProps()} />;
            },
          },
          ...columns,
        ]);
      }
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    selectedFlatRows,
    state: {pageIndex, pageSize, selectedRowIds},
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    toggleSortBy,
    toggleRowSelected,
  } = tableInstance as UseTableAndPaginationInstanceProps<D>;

  React.useEffect(() => {
    if (sortDesc !== undefined && sortColumn !== undefined && isUrlSorted) {
      toggleSortBy(sortColumn, sortDesc);
    }
  }, [sortDesc, sortColumn]);

  React.useEffect(() => {
    selectedFlatRows.forEach((row) => {
      toggleRowSelected(row.id, false);
    });
  }, [hasClearSelection]);

  const navigate = useNavigate();
  const location = useLocation();

  useMountedLayoutEffect(() =>
      onRowSelection &&
      onRowSelection(updateRows(selectedFlatRows))
    , [selectedRowIds, data]);

  const TableColumnHeader = () => {
    return (
      <>
        <thead>
        {headerGroups.map((headerGroup) => (
          <S.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((header, index) => {
              const column =
                header as ColumnInterface<D> as UseTableColumnProps<D> &
                  UseSortByColumnProps<D> &
                  DatatableColumn<D>;
              return (
                <S.TableHeader
                  {...header.getHeaderProps(column.getSortByToggleProps())}
                  title={undefined}
                  onClick={() => {
                    if (column.canSort) {
                      const isDesc = column.isSorted
                        ? !column.isSortedDesc
                        : column.sortDescFirst;
                      if (isUrlSorted) {
                        navigate(
                          location.pathname +
                          `?column=${column.id}&sort=${
                            isDesc ? "desc" : "asc"
                          }`
                        );
                      } else {
                        column.toggleSortBy(isDesc);
                      }
                    }
                  }}
                  className={`
                      ${
                    index === 0
                      ? "rounded-tl-lg"
                      : index === headerGroup.headers.length - 1
                        ? "rounded-tr-lg"
                        : ""
                  }`}
                >
                  <div className={"flex items-center"}>
                    {header.render("Header")}
                    {column.isSorted && (
                      <>
                        {column.isSortedDesc ? (
                          <ChevronDownIcon className={"w-4 h-4"}/>
                        ) : (
                          <ChevronUpIcon className={"w-4 h-4"}/>
                        )}
                      </>
                    )}
                  </div>
                </S.TableHeader>
              );
            })}
          </S.TableHeaderRow>
        ))}
        </thead>
      </>
    );
  };

  function renderCell(row: Row<D>, cell: Cell<D>) {
    const column = cell.column as DatatableColumn<D>;

    if (column.customRenderer) {
      const renderer = row.original[column.customRenderer];
      if (typeof renderer == "function") {
        return renderer();
      } else {
        return renderer;
      }
    } else {
      return cell.render("Cell");
    }
  }

  const TableBody = () => {
    return (
      <>
        <tbody {...getTableBodyProps()}>
        {page.map((row: Row<D>, i: number) => {
          prepareRow(row);
          return (
            <S.TableDataRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                const column = cell.column as DatatableColumn<D>;
                return (
                  <S.TableData {...cell.getCellProps()} $column_id={column.id}>
                    {/*@ts-ignore*/}

                    <S.TableDataCell>
                      {renderCell(row, cell)}
                    </S.TableDataCell>
                  </S.TableData>
                );
              })}
            </S.TableDataRow>
          );
        })}
        </tbody>
      </>
    );
  };

  const onPrevious = () => {
    previousPage();
    if (pageChangeCallback) {
      pageChangeCallback(pageIndex === 0 ? pageIndex : pageIndex - 1);
    }
  };

  const onNext = () => {
    nextPage();
    if (pageChangeCallback) {
      pageChangeCallback(
        pageIndex === Math.ceil(data.length / pageSize) - 1
          ? pageIndex
          : pageIndex + 1
      );
    }
  };

  const firstRowIndex = () =>
    rows.length === 0 ? 0 : pageIndex * pageSize + 1;
  const lastRowIndex = () =>
    pageIndex * pageSize + pageSize > rows.length
      ? rows.length
      : pageIndex * pageSize + pageSize;

  return (
    <>
      <S.DataTableWrapper>
        <S.TableWrapper $hasShadow={$shadow!} {...getTableProps()}>
          <TableColumnHeader/>
          <TableBody/>
        </S.TableWrapper>
        {hasPagination && (
          <S.PaginationWrapper>
            <S.PaginationInfo>
              Showing {firstRowIndex()} to {lastRowIndex()} of {rows.length}{" "}
              entries
            </S.PaginationInfo>
            <S.PaginationButtons>
              {canPreviousPage && (
                <Button
                  btntype={(canNextPage && "default") || "primary"}
                  onClick={onPrevious}
                  $shadow={true}
                >
                  Previous
                </Button>
              )}
              {canNextPage && (
                <Button btntype="primary" onClick={onNext} $shadow={true}>
                  Next
                </Button>
              )}
            </S.PaginationButtons>
          </S.PaginationWrapper>
        )}
      </S.DataTableWrapper>
    </>
  );
};

export default DataTable;
