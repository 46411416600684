import {createState, useState} from "@hookstate/core";
import {Location} from "model/employee";
import {useNavigate} from "react-router-dom";
import React, {useRef} from "react";
import {Form, Formik, FormikValues} from "formik";
import {SelectSearchOption} from "components/utils/SelectSearch";
import {Dialog} from "@headlessui/react";
import {CommonModal} from "components/utils/Modal/CommonModal";
import {LocationValidator} from "validations/OrganizationValidator";
import ModalTextTitle from "components/utils/Modal/ModalTextTitle";
import TextField from "components/generics/TextField";
import CustomSelect from "components/utils/CustomSelect/CustomSelect";
import TimePicker from "components/utils/TimePicker/TimePicker";
import Button from "components/generics/Button";
import organizationService from "services/organizationService";

import * as S from "./styles";

export const LocationModalState = createState({
  open: false,
  locationInfo: {} as Location,
  timezones: [] as SelectSearchOption[],
  locations: [] as Location[],
});

export const openAddLocationModal = (
  timezones: SelectSearchOption[],
  locations: Location[]
) => {
  LocationModalState.locationInfo.set({
    name: "",
    timeZone: "",
    startTime: "",
    endTime: "",
  } as Location);
  LocationModalState.timezones.set(timezones);
  LocationModalState.locations.set(locations);
  LocationModalState.open.set(true);
}

export const openEditLocationModal = (
  location: Location,
  timezones: SelectSearchOption[],
  locations: Location[]
) => {
  LocationModalState.locationInfo.set({
    id: location.id,
    name: location.name,
    timeZone: location.timeZone,
    startTime: location.startTime,
    endTime: location.endTime
  } as Location);
  LocationModalState.timezones.set(timezones);
  LocationModalState.locations.set(locations);
  LocationModalState.open.set(true);
}


export default function AddLocationModal() {
  const navigate = useNavigate();

  const modalState = useState(LocationModalState);

  const isSaving = useState<boolean>(false);

  const nameRef = useRef<HTMLInputElement>(null);

  function handleSave(values: FormikValues) {
    isSaving.set(true);

    const locationToSave = {
      id: values.id,
      name: values.name,
      timeZone: values.timeZone,
      startTime: values.startTime,
      endTime: values.endTime,
    } as Location;

    save(locationToSave);
  }

  function save(locationToSave: Location) {
    if (locationToSave.id) {
      organizationService.updateLocation(locationToSave)
        .then(function (savedLocation) {
          navigate("/settings/organization", {state: "fetch"})
        })
    } else {
      organizationService.addLocation(locationToSave)
        .then(function (savedLocation) {
          navigate("/settings/organization", {state: savedLocation.id})
        })
    }
    modalState.open.set(false);
    isSaving.set(false);
  }

  const isDuplicated = (name: string) => {
    return LocationModalState.locations.value.filter(loc =>
      loc.name.trim().toLowerCase() === name.trim().toLowerCase() &&
      loc.id !== modalState.locationInfo.id.value).length > 0
  }

  return (
    <CommonModal openState={modalState.open}>
      <S.Wrapper>
        <Formik
          initialValues={{...modalState.locationInfo.value}}
          validationSchema={LocationValidator}
          validateOnMount={true}
          onSubmit={() => {}}
        >
          {({
              values,
              errors,
              isValid,
              setFieldValue,
              setFieldTouched,
              touched,
              handleChange,
              handleBlur,
            }) => (
              <Form>
                <S.MainContainer>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-pl-grayscale-black"
                  >
                    <ModalTextTitle title={"Enter new location details"} />
                  </Dialog.Title>

                  <S.ContentWrapper>
                    <S.FormWrapper>
                      <S.FieldWrapper>
                        <S.LabelWrapper>
                          Location
                        </S.LabelWrapper>
                        <S.ElementWrapper>
                          <TextField
                            ref={nameRef}
                            name="name"
                            value={values.name}
                            clearable
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onClear={() => setFieldValue("name", "")}
                            border={false}
                            placeholder={"Enter a location name"}
                          />
                          <S.Error>
                            {
                              !!errors.name && touched.name
                                ? errors.name
                                : (isDuplicated(values.name)
                                  ? values.name.concat(" is a duplicated name")
                                  : "")
                            }
                          </S.Error>
                        </S.ElementWrapper>
                      </S.FieldWrapper>

                      <S.FieldWrapper>
                        <S.LabelWrapper>
                          Timezone
                        </S.LabelWrapper>
                        <S.ElementWrapper>
                          <CustomSelect
                            placeholder={"Select a timezone"}
                            options={modalState.timezones.value}
                            value={values.timeZone}
                            onChange={(opt) => setFieldValue("timeZone", opt.value)}
                            onBlur={() => setFieldTouched("timeZone", true)}
                            isFixed={false}
                            border={false}
                          />
                          <S.Error>
                            {
                              !!errors.timeZone && touched.timeZone ? errors.timeZone : ""
                            }
                          </S.Error>
                        </S.ElementWrapper>
                      </S.FieldWrapper>

                      <S.DoubleFieldWrapper>
                        <S.FieldWrapper>
                          <S.LabelWrapper>
                            Start Time
                          </S.LabelWrapper>
                          <S.ElementWrapper>
                            <TimePicker
                              value={values.startTime}
                              onChange={(time) => setFieldValue("startTime", time)}
                            />
                            <S.Error>
                              {
                                !!errors.startTime && touched.startTime ? errors.startTime : ""
                              }
                            </S.Error>
                          </S.ElementWrapper>
                        </S.FieldWrapper>

                        <S.FieldWrapper>
                          <S.LabelWrapper>
                            End Time
                          </S.LabelWrapper>
                          <S.ElementWrapper>
                            <TimePicker
                              value={values.endTime}
                              onChange={(time) => setFieldValue("endTime", time)}
                            />
                            <S.Error>
                              {
                                !!errors.endTime && touched.endTime ? errors.endTime : ""
                              }
                            </S.Error>
                          </S.ElementWrapper>
                        </S.FieldWrapper>
                      </S.DoubleFieldWrapper>
                    </S.FormWrapper>
                  </S.ContentWrapper>

                  <S.ButtonsWrapper>
                    <Button onClick={() => modalState.open.set(false)}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      btntype="primary"
                      $shadow={true}
                      disabled={!isValid || isSaving.value}
                      onClick={() => {
                        isSaving.set(true);
                        handleSave(values);
                      }}
                    >
                      Done
                    </Button>
                  </S.ButtonsWrapper>
                </S.MainContainer>
              </Form>
          )}
        </Formik>
      </S.Wrapper>
    </CommonModal>
  );
}