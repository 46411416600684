import tw from "tailwind-styled-components";

export const MainWrapper = tw.div`
  grid
  gap-6
`;

export const Wrapper = tw.div`
  bg-pl-grayscale-white
  px-2
  pb-2
  rounded-2xl
  shadow
  h-full
  w-full
`;


export const MainContainer = tw.div`
  grid
  w-full
`;

export const SubMainContainer = tw.div`
  grid
  pt-6
  divide-y
  gap-6
`;

export const WrapperTitle = tw.div`
  w-full
  flex
  items-center
  justify-between
`;

export const TitleActions = tw.div`
  px-2
`;

export const WrapperContent = tw.div`
  py-6
  gap-x-4
  gap-y-2
`;

export const PrivacyFieldSetWrapper = tw.fieldset`
  w-full
  flex
  flex-col
  gap-5
`;

export const Label = tw.label`
  ml-3
  text-sm
  font-normal
  flex
  flex-row-reverse
  justify-end
  items-center
  gap-3
  cursor-pointer
`;