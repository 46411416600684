import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  flex
  flex-col
  gap-3
`;

export const TitleWrapper = tw.div`
  bg-pl-grayscale-white
  p-5
  rounded-2xl
`;

export const ContentWrapper = tw.div`
  p-6
  h-[400px]
  w-full
  bg-pl-grayscale-white
  rounded-2xl
  flex

`;

export const TabMenuWrapper = tw.div`
  h-full
  w-[180px]
  border-r
  border-pl-grayscale-lightgray
`;