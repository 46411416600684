
import pluralize from 'pluralize';

export const calculatePlural = (eventName: string) => {
    let splitString = eventName.split(" ");

    if(eventName.toLowerCase() === "stay factor" || eventName.toLowerCase().includes("time to")) {
        //do not pluralize
    }else if(splitString[splitString.length-1].toLowerCase() === "activity") {
        let resultStr = '';
        for(let i=0; i < splitString.length; ++i) {
            if(i === splitString.length-1) {
                resultStr += pluralize.plural(splitString[i]);
            } else {
                resultStr += splitString[i]+' ';
            }
        }
        return resultStr;
    } else if(splitString.length === 2) {
        if (splitString[1].endsWith("ing")) {
            return splitString[0] + " " + pluralize.plural(splitString[1]);
        }
        return pluralize.plural(splitString[0]) + " " + splitString[1];
    } else if (splitString.length === 3) {
        if(splitString[1].toLowerCase() === "to") {
            return pluralize.plural(splitString[0]) + " " + splitString[1] + " " + splitString[2];
        }
        return splitString[0] + " " + pluralize.plural(splitString[1]) + " " + splitString[2];
    } else if (splitString.length === 4) {
        return splitString[0] + " " + splitString[1] + " " + pluralize.plural(splitString[2]) + " " + splitString[3];
    }

    return eventName;
}