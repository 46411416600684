import {Employee, Team, AddToTeam} from "model/employee";
import {FormikValues} from "formik";
import {SelectSearchOption} from "components/utils/SelectSearch";
import React from "react";
import MultistringInput from "components/utils/MultistringInput";
import CustomSelect from "components/utils/CustomSelect/CustomSelect";
import {isRepeated} from "utils/text";

import * as S from "./styles";
import {useState} from "@hookstate/core";

type Props = {
  teams: Team[];
  managers: Employee[];
  values: FormikValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  horizontalDisplay?: boolean;
  addToTeam?: AddToTeam
};

export function EmployeeTeamsManagerSection({ teams,
                                              managers,
                                              values,
                                              setFieldValue,
                                              horizontalDisplay = false,
                                              addToTeam,
}: Props) {

  const disableManager = useState(false);
  const teamsState = useState([...teams]);

  const teamsList = () => {
    return teamsState.map((team) => {
      return {
        label: team.name.value,
        value: team.id.value,
      } as SelectSearchOption;
    });
  };

  const managersList = () => {
    return managers.map((manager) => {
      return {
        label: manager.name,
        value: manager.id,
        additionalLabel: isRepeated(manager, managers)
          ? manager.email
          : undefined,
      } as SelectSearchOption;
    });
  };

  return (
    <>
      <S.FormWrapper horizontalDisplay={horizontalDisplay}>
        <S.FormLineWrapper horizontalDisplay={horizontalDisplay!!} numColumns={2}>
          <S.FieldWrapper>
            <S.LabelWrapper>
              Team
            </S.LabelWrapper>
            <S.ElementWrapper>
              <CustomSelect
                placeholder={"Select a team"}
                options={teamsList()}
                value={
                  values.primaryTeamId === undefined
                    ? ""
                    : values.primaryTeamId
                }
                isDisabled={!!addToTeam}
                isClearable
                onChange={(opt) => {
                  setFieldValue(
                    "primaryTeamId",
                    opt.value === "" ? undefined : opt.value
                  );
                  if (opt.value === "") {
                    setFieldValue("managerId", undefined);
                    disableManager.set(false);
                  } else {
                    setFieldValue(
                      "managerId",
                      teams.find((t) => t.id === opt.value)?.manager?.id
                    );
                    disableManager.set(true);
                  }
                }}
                isFixed={false}
                border={false}
              />
              <S.Error>
                {
                  values.primaryTeamId !== undefined &&
                  teams.filter((team) => team.id === values.primaryTeamId)[0] &&
                  !teams.filter((team) => team.id === values.primaryTeamId)[0]?.active ?
                    "This team is not active" : ""
                }
              </S.Error>
            </S.ElementWrapper>
          </S.FieldWrapper>

          <S.FieldWrapper>
            <S.LabelWrapper>
              Manager
            </S.LabelWrapper>
            <S.ElementWrapper>
              <CustomSelect
                options={managersList()}
                value={values.managerId === undefined ? "" : values.managerId}
                isDisabled={addToTeam ? true : disableManager.value}
                onChange={(opt) => {
                  setFieldValue(
                    "managerId",
                    opt.value === "" ? undefined : opt.value
                  );
                  setFieldValue("primaryTeamId", undefined);
                  if (opt.value === "") {
                    teamsState.set([...teams]);
                  } else {
                    teamsState.set([...teams.filter((t) => t.manager?.id === opt.value)]);
                  }
                }}
                isFixed={false}
                border={false}
              />
              <S.Error />
            </S.ElementWrapper>
          </S.FieldWrapper>
        </S.FormLineWrapper>

        <S.FormLineWrapper horizontalDisplay={false} numColumns={1}>
          <S.FieldWrapper>
            <S.LabelWrapper>
              Skills
            </S.LabelWrapper>
            <S.ElementWrapper>
              <MultistringInput
                list={values.skills}
                editMode={true}
                onUpdate={(updatedList) =>
                  setFieldValue("skills", updatedList)
                }
                className={"!mt-0 bg-pl-variant-beige"}
              />
              <S.Error />
            </S.ElementWrapper>
          </S.FieldWrapper>
        </S.FormLineWrapper>
      </S.FormWrapper>
    </>
  );
}