import React from "react";
import Sparkline from "components/ui/Charts/Sparkline";
import { ReactComponent as ArrowUp } from "icons/arrow-up.svg";
import { ReactComponent as ArrowDown } from "icons/arrow-down.svg";
import {StayFactor} from "utils/stayFactor";
import {PeriodSelectorState} from "components/utils/PeriodSelector";

import * as S from "./styles";

export type SingleNumberProps = {
  stayFactor: StayFactor;
};

const SingleNumber = ({ stayFactor }: SingleNumberProps) => {
  const isSingleValue = stayFactor.values.length === 1;

  const nDays = PeriodSelectorState.selectedPeriod.value;
  let timeLabel = `${nDays} days`;

  if (nDays === 7) {
    timeLabel = "week";
  } else if (nDays % 7 === 0) {
    timeLabel = `${nDays / 7} weeks`;
  }

  const minValue = Math.min.apply(Math, stayFactor.values);
  const minYAxis = minValue == 0 ? 0 : minValue - 1;
  const maxValue = Math.max.apply(Math, stayFactor.values);
  const maxYAxis = maxValue == 100 ? 100 : maxValue + 1;

  return (
    <S.Wrapper>
      {!isSingleValue && (
        <>
          <S.DataComparison>
            <S.Value change={stayFactor.deltaPercentage}>
              {stayFactor.deltaPercentage > 0 ? <ArrowUp /> : stayFactor.deltaPercentage < 0 ? <ArrowDown /> : ""}
              {Math.abs(stayFactor.deltaPercentage)}%
            </S.Value>
            {`vs ${timeLabel} ago`}
          </S.DataComparison>

          <S.GraphicComparisonWrapper>
            <Sparkline values={stayFactor.values} min={minYAxis} max={maxYAxis} reverse={true} />
          </S.GraphicComparisonWrapper>
        </>
      )}
    </S.Wrapper>
  );
};

export default SingleNumber;
