import {PrivacyLevel} from "model/organization";
import authService from "./auth";

class PrivacyLevelService {

  hasAccess(currentPrivacyLevel: PrivacyLevel) {
    const currentUserIsAdmin = authService.currentUserIsAdmin();

    return currentPrivacyLevel === PrivacyLevel.ALL_DETAILS_VISIBLE ||
      (currentUserIsAdmin && currentPrivacyLevel === PrivacyLevel.PERSONAL_DETAILS_MASKED_MANAGERS);
  }
}

export default new PrivacyLevelService();