import React from "react";
import {createState, useState} from "@hookstate/core";
import {AddToTeam, Employee, EmployeeFormData, Location, Role, Team} from "model/employee";
import {EmployeeIntegration, Integration} from "model/integration";
import employeeService from "services/employeeService";
import {useNavigate} from "react-router-dom";
import ModalTabsTitle, {TabsTitle} from "components/utils/Modal/ModalTabsTitle/ModalTabsTitle";
import {Form, Formik, FormikValues} from "formik";
import {CommonModal} from "components/utils/Modal/CommonModal";
import {Dialog} from "@headlessui/react";
import Button from "components/generics/Button";
import ModalTextTitle from "components/utils/Modal/ModalTextTitle";
import {EmployeeValidator} from "validations/EmployeeValidator";
import {EmployeeDetailsSection} from "../EmployeeDetailsSection";
import {EmployeeTeamsManagerSection} from "../EmployeeTeamsManagerSection";
import {EmployeeRolesSection} from "../EmployeeRolesSection";
import {EmployeeIntegrationsSection} from "../EmployeeIntegrationsSection";

import * as S from "./styles";

import {ReactComponent as ChevronIcon} from "icons/chevron.svg";

export const EMPTY_EMPLOYEE = {
  id: 0,
  avatar: "",
  role: Role.EMPLOYEE,
  name: "",
  joinDateStr: "",
  email: "",
  jobTitle: "",
  employeeIntegrations: [],
  indexedIntegrations: [],
  skills: [],
} as EmployeeFormData;

export const EmployeeModalState = createState({
  open: false,
  employeeInfo: EMPTY_EMPLOYEE as EmployeeFormData,
  addToTeam: {} as AddToTeam | undefined,
  teams: [] as Team[],
  managers: [] as Employee[],
  locations: [] as Location[],
  integrations: [] as Integration[],
  isEdit: false,
  employee: {} as Employee,
});

export const loadFields = (
  managers: Employee[],
  locations: Location[],
  teams: Team[],
  integrations: Integration[],
  addToTeam?: AddToTeam
) => {
  EmployeeModalState.managers.set(managers);
  EmployeeModalState.locations.set(locations);
  EmployeeModalState.teams.set(teams);
  EmployeeModalState.integrations.set(integrations);
  EmployeeModalState.addToTeam.set(addToTeam);
};


export const openAddEmployeeModal = (
  managers: Employee[],
  locations: Location[],
  teams: Team[],
  integrations: Integration[],
  addToTeam? : AddToTeam
) => {
  loadFields(managers, locations, teams, integrations, addToTeam);

  EmployeeModalState.employeeInfo.set(EMPTY_EMPLOYEE);
  if (addToTeam) {
    EmployeeModalState.employeeInfo.primaryTeamId.set(addToTeam.teamId);
    EmployeeModalState.employeeInfo.managerId.set(addToTeam.managerId);
  }
  EmployeeModalState.isEdit.set(false);
  EmployeeModalState.open.set(true);
}


export const openEditEmployeeModal = (
  employee: Employee,
  managers: Employee[],
  locations: Location[],
  teams: Team[],
  integrations: Integration[]
) => {
  loadFields(managers, locations, teams, integrations);

  EmployeeModalState.employeeInfo.set({
    id: employee.id,
    avatar: employee.avatar,
    role: employee.role,
    name: employee.name,
    joinDateStr: employee.joinDate
      ? employee.joinDate.toLocaleString()
      : new Date().toISOString().slice(0, 10),
    linkedin: employee.linkedin,
    email: employee.email,
    jobTitle: employee.jobTitle,
    location: employee.location?.id,
    employeeIntegrations: employee.integrations
      ? employee.integrations
      : [],
    indexedIntegrations: employee.integrations ?
      employee.integrations.map((integ, index) => {
        return {
          integrationId: integ.integrationId,
          integrationUsername: integ.integrationUsername,
          index: index,
          markedDeleted: false
        }
      })
      : [],
    primaryTeamId: employee.primaryTeam?.id,
    managerId: employee.manager?.id,
    skills: employee.skills
      ? employee.skills.map((skill) => skill.name)
      : [],
  } as EmployeeFormData);

  EmployeeModalState.employee.set(employee);
  EmployeeModalState.isEdit.set(true);
  EmployeeModalState.open.set(true);
};


export default function EmployeeModal() {
  const navigate = useNavigate();

  const modalState = useState(EmployeeModalState);

  const selectedNavIndex = useState(0);
  const isSaving = useState<boolean>(false);
  const isChevronRotate = useState(false);
  const isCollapsed = useState(false);

  const handleCollapseContent = () => {
    isCollapsed.set(!isCollapsed);
    isChevronRotate.set(!isChevronRotate);
  };

  const tabsTitle = [
    {
      tabNumber: 1,
      tabTitle: "Employee's details",
    },
    {
      tabNumber: 2,
      tabTitle: "Employee's Team & Manager",
    },
    {
      tabNumber: 3,
      tabTitle: "Connect to an integration",
    } as TabsTitle
  ];

  function handleSave(values: FormikValues) {
    const validIntegrations: EmployeeIntegration[] = values.indexedIntegrations
      .filter(ei => !ei.markedDeleted)
      .filter(ei => (ei.integrationId !== 0 && ei.integrationUsername !== ""))
      .map(ei => {
        return {
          integrationId: ei.integrationId,
          integrationUsername: ei.integrationUsername
        } as EmployeeIntegration
      });

    const employeeToSave = {
      id: modalState.isEdit.value ? values.id : 0,
      role: values.role,
      name: values.name,
      joinDateStr: values.joinDateStr,
      email: values.email,
      jobTitle: values.jobTitle,
      location: values.location === 0 ? undefined : values.location,
      employeeIntegrations: validIntegrations,
      primaryTeamId: values.primaryTeamId,
      managerId: values.managerId,
      skills: values.skills,
      linkedin: values.linkedin
    } as EmployeeFormData;

    save(employeeToSave);
  }


  function save(employeeToSave: EmployeeFormData) {
    employeeService.saveEmployee(employeeToSave).then(function (savedEmployee) {
      modalState.open.set(false);
      isSaving.set(true);
      if (modalState.isEdit.value) {
        navigate("/employees/" + savedEmployee.id, { state: "fetch"})
      } else {
        if (modalState.addToTeam.value) {
          navigate("/teams/" + modalState.addToTeam.value?.teamId + "/members", { state: savedEmployee.id})
        } else {
          navigate("/employees", { state: savedEmployee.id});
        }
      }
    });
  }


  const cancelButton = () => {
    return <Button onClick={() => {
      isSaving.set(false);
      modalState.open.set(false);
    }}>
      Cancel
    </Button>
  };

  const saveButton = (isValid: boolean, values: FormikValues, label: string) => {
    return <Button
      type="submit"
      btntype="primary"
      $shadow={true}
      disabled={!isValid || isSaving.value}
      onClick={() => {
        isSaving.set(true);
        handleSave(values);
      }}
    >
      {label}
    </Button>
  };

  const nextButton = (isValid: boolean, onClick: () => void) => {
    return <Button
      btntype="primary"
      $shadow={true}
      disabled={!isValid}
      onClick={onClick}
    >
      Next
    </Button>
  };

  const previousButton = (onClick: () => void) => {
    return <Button
      onClick={onClick}
    >
      Previous
    </Button>
  };

  return (
    <>
      <CommonModal openState={modalState.open}>
        <S.Wrapper>
          <Formik
            initialValues={modalState.employeeInfo.get({noproxy: true})}
            validationSchema={EmployeeValidator}
            validateOnMount={true}
            onSubmit={() => {}}
          >
            {({
                values,
                errors,
                isValid,
                setFieldValue,
                touched,
                handleChange,
                handleBlur,
              }) => (

              <Form>
                <S.MainContainer>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-pl-grayscale-black"
                  >
                    {
                      modalState.isEdit.value ?
                        <ModalTextTitle title={"Edit details"} />
                        :
                        <ModalTabsTitle tabs={tabsTitle} currentTab={selectedNavIndex.value + 1} />
                    }
                  </Dialog.Title>

                  <S.ContentWrapper>
                    {
                      modalState.isEdit.value ?
                        <>
                          <EmployeeDetailsSection employee={modalState.employee.value}
                                                  locations={modalState.locations.value}
                                                  values={values}
                                                  errors={errors}
                                                  setFieldValue={setFieldValue}
                                                  handleChange={handleChange}
                                                  handleBlur={handleBlur}
                                                  touched={touched}
                                                  isEdit={true}
                          />
                          <EmployeeTeamsManagerSection teams={modalState.teams.value}
                                                       managers={modalState.managers.value}
                                                       values={values}
                                                       setFieldValue={setFieldValue}
                                                       horizontalDisplay={true}
                                                       addToTeam={modalState.addToTeam.value}
                          />
                          <S.MoreWrapper onClick={handleCollapseContent}>
                            <S.MoreLabel>
                              {
                                isChevronRotate ? "Less" : "More"
                              }
                            </S.MoreLabel>
                            <Button
                              minimal
                              btntype={"primary"}
                              $shadow={false}
                              className={`${isChevronRotate && "scale-[-1]"}`}
                            >
                              <ChevronIcon />
                            </Button>
                          </S.MoreWrapper>
                          {
                            isChevronRotate &&
                            <S.ChevronContentWrapper>
                              <EmployeeRolesSection values={values}
                                                    setFieldValue={setFieldValue}
                                                    activeEmployee={modalState.employee.active.value}
                              />

                              {
                                !modalState.employee.active.value &&
                                <S.CustomDisabledRadioText>
                                  If you want to change the role, please reactivate the user.
                                </S.CustomDisabledRadioText>
                              }

                              <EmployeeIntegrationsSection integrations={modalState.integrations.value}
                                                           values={values}
                                                           setFieldValue={setFieldValue}
                              />
                            </S.ChevronContentWrapper>
                          }
                        </>
                        :
                        <>
                          {
                            selectedNavIndex.value === 0 &&
                            <>
                              <EmployeeDetailsSection employee={modalState.employee.value}
                                                      locations={modalState.locations.value}
                                                      values={values}
                                                      errors={errors}
                                                      setFieldValue={setFieldValue}
                                                      handleChange={handleChange}
                                                      handleBlur={handleBlur}
                                                      touched={touched}
                                                      isEdit={false}
                              />
                              <EmployeeRolesSection values={values}
                                                    setFieldValue={setFieldValue}
                                                    activeEmployee={modalState.employee.active.value}
                              />
                            </>
                          }
                          {
                            selectedNavIndex.value === 1 &&
                            <EmployeeTeamsManagerSection teams={modalState.teams.value}
                                                         managers={modalState.managers.value}
                                                         values={values}
                                                         setFieldValue={setFieldValue}
                                                         horizontalDisplay={false}
                                                         addToTeam={modalState.addToTeam.value}
                            />
                          }
                          {
                            selectedNavIndex.value === 2 &&
                            <EmployeeIntegrationsSection integrations={modalState.integrations.value}
                                                         values={values}
                                                         setFieldValue={setFieldValue}
                            />
                          }
                        </>
                    }
                  </S.ContentWrapper>

                  <S.ButtonsWrapper>
                    {
                      modalState.isEdit.value ?
                        <>
                          {
                            cancelButton()
                          }
                          {
                            saveButton(isValid, values, "Save")
                          }
                        </>
                        :
                        <>
                          {
                            selectedNavIndex.value === 0 &&
                            <>
                              {
                                cancelButton()
                              }
                              {
                                nextButton(isValid, () => selectedNavIndex.set(1))
                              }
                            </>
                          }
                          {
                            selectedNavIndex.value === 1 &&
                            <>
                              {
                                previousButton(() => selectedNavIndex.set(0))
                              }
                              {
                                nextButton(isValid, () => selectedNavIndex.set(2))
                              }
                            </>
                          }
                          {
                            selectedNavIndex.value === 2 &&
                            <>
                              {
                                previousButton(() => selectedNavIndex.set(1))
                              }
                              {
                                saveButton(isValid, values, "Done")
                              }
                            </>
                          }
                        </>
                    }
                  </S.ButtonsWrapper>
                </S.MainContainer>
              </Form>
            )}
          </Formik>
        </S.Wrapper>
      </CommonModal>
    </>
  );
}
