import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  relative
  flex
  items-center
  justify-between
  right-0
  w-full
  pr-2
`;

export const WrapperArea = tw.div`
  relative
  z-0
`;

export const List = tw.ul`
  gap-2
  text-left
  my-2
  px-2
`;

export const ListItem = tw.li`
  col-span-1
  py-1
  border-t
  border-t-pl-grayscale-lightgray
  flex
  items-center
  justify-between
`;

export const ListItemLink = tw.div`
  truncate max-w-[90%]
`;

export const ListItemTitle = tw.div`
  truncate
`;

export const ArrowsSection = tw.div`
  flex
  items-end
  justify-end
  absolute
  right-0
  bottom-[-50px]
  w-full
`;

export const ArrowContainer = tw.div`
  bg-pl-variant-beige
  p-2
  rounded-full
`;

export const ActiveComponentWrapper = tw.div`
  flex
  items-center
  justify-center
`;

export const IntegrationDatatableWrapper = tw.div`
  w-full
`;

export const ActionButtonWrapper = tw.div`
  flex 
  items-center 
  justify-center 
  gap-2
`;

export const IntegrationNameWrapper = tw.div`
  flex 
  flex-row 
  items-center
`;

export const IntegrationLogoWrapper = tw.div`
  inline-block 
  w-8 
  h-8 
  rounded-full 
  p-1 
  border-pl-grayscale-lightgray 
  border-[1px]
`;

export const IntegrationNameTextWrapper = tw.div`
  ml-3
`;

export const IntegrationNameText = tw.p`
  whitespace-no-wrap 
  font-bold 
  text-base
`;