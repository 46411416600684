import React from "react";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import { GenericChartData } from "model/chart";
import { capitalize } from "utils/text";
import { CHART_COLORS, CHART_LIGHT_COLORS } from "utils/color";
import BlurredContainer from "components/generics/BlurredContainer";

import mockedValues from "./mock";
import * as S from "./styles";

export enum ChartTypeEnum {
  PIE = "pie",
  DOUGHNUT = "doughnut",
  POLAR = "polarArea",
  RADAR = "radar",
  AREA = "line",
  STACKED_BAR = "bar",
}

export type GenericChartProps = {
  report: GenericChartData;
  type: ChartTypeEnum;
  options?: ChartOptions;
  height?: number;
  width?: number;
};

const GenericChart = ({ report, type, options, height, width }: GenericChartProps) => {
  let hasData = true;

  React.useEffect(() => {
    report.datasets.forEach((item) => {
      if (typeof item.data[0] === "number") {
        const events = item.data.reduce((a, b) => a + b, 0);
        if (events === 0) {
          hasData = false;
        }
      }
    });
  }, []);

  const getData = () => {
    if (!hasData) {
      return getChartData(mockedValues);
    }
    return getChartData(report);
  };

  const getBackgroundColor = (index: number) => {
    switch (type) {
      case ChartTypeEnum.AREA:
      case ChartTypeEnum.RADAR:
      case ChartTypeEnum.POLAR:
        return CHART_LIGHT_COLORS[index];

      case ChartTypeEnum.PIE:
      case ChartTypeEnum.DOUGHNUT:
        return CHART_COLORS;

      default:
        return CHART_COLORS[index];
    }
  };

  const getChartData = (values: GenericChartData) => {
    const datasets = values.datasets.map((item, index) => {
      return {
        ...item,
        label: capitalize(item.label),
        backgroundColor: getBackgroundColor(index),
        borderColor: CHART_COLORS[index],
      };
    });

    return {
      labels: values.labels.map((label) => capitalize(label)),
      datasets: ["pie", "doughnut", "polarArea"].includes(type)
        ? [datasets[0]]
        : datasets,
    };
  };

  const customOptions = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        onClick: (_, legendItem, legend) => {
          const datasets = legend.legendItems!.map((dataset) => {
            return dataset.text;
          });

          const index = datasets.indexOf(legendItem.text);

          legend.chart.isDatasetVisible(index)
            ? legend.chart.hide(index)
            : legend.chart.show(index);
        },
        labels: {
          usePointStyle: true,
          generateLabels: (chart) => {
            return chart.data.datasets.map((dataset, index) => ({
              text: dataset.label,
              fillStyle: CHART_LIGHT_COLORS[index],
              strokeStyle: CHART_COLORS[index],
              hidden: !chart.isDatasetVisible(index),
            }));
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    fill: true,
    skipNull: true,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
    ...options,
  } as ChartOptions;

  return (
    <S.Wrapper>
      <BlurredContainer blur={!hasData}>
        <Chart type={type} data={getData()} options={customOptions} height={height} width={width}/>
      </BlurredContainer>
    </S.Wrapper>
  );
};

export default GenericChart;
