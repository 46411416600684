import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  flex
  items-center
  gap-4
  w-full
  cursor-pointer
  px-6
  py-3
`;

export const IconWrapper = tw.div`
  w-7
  h-7
  text-pl-grayscale-black
  flex
  items-center
`;

export const Content = tw.div`
  text-pl-grayscale-black
  flex
  flex-col
  w-full
`;

export const Label = tw.span<{ highlighted: number }>`
  ${({ highlighted }) => `
    text-sm
    ${!!highlighted ? "font-medium" : "font-normal"}
  `}
`;

export const Description = tw.span`
  text-pl-grayscale-gray
  text-xs
  font-normal
`;
