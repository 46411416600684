import { SeverityType } from "model/recommendations";
import tw from "tailwind-styled-components";

export const HeaderWrapper = tw.div`
  flex
  flex-col
  lg:flex-row
  justify-between
`;

export const Title = tw.div`
  text-2xl
  font-bold
  flex
  items-center
`;

export const FiltersWrapper = tw.div`
  flex
  items-center
  gap-2
`;

export const EmployeeFilter = tw.div`
  w-[155px]
`;

export const SeverityFiltersWrapper = tw.div`
  flex
  items-center
  gap-2
`;

const SeverityLabelModifiers = {
  info: (selected: boolean) => `
    before:bg-pl-primary-green-default
    hover:bg-pl-primary-green-light
    hover:text-pl-primary-green-default
    hover:border
    hover:rounded-lg
    hover:border-pl-primary-green-default

    ${
      selected &&
      `
        bg-pl-primary-green-light
        text-pl-primary-green-default
        text-pl-primary-green-default
        border
        rounded-lg
        border-pl-primary-green-default
      `
    }
  `,

  warning: (selected: boolean) => `
    before:bg-pl-primary-orange-default
    hover:bg-pl-primary-orange-light
    hover:text-pl-primary-orange-default
    hover:border
    hover:rounded-lg
    hover:border-pl-primary-orange-default

    ${
      selected &&
      `
        bg-pl-primary-orange-light
        text-pl-primary-orange-default
        border
        rounded-lg
        border-pl-primary-orange-default
      `
    }
  `,

  critical: (selected: boolean) => `
    before:bg-pl-primary-red-default
    hover:bg-pl-primary-red-light
    hover:text-pl-primary-red-default
    hover:border
    hover:rounded-lg
    hover:border-pl-primary-red-default

    ${
      selected &&
      `
        bg-pl-primary-red-light
        text-pl-primary-red-default
        border
        rounded-lg
        border-pl-primary-red-default
      `
    }
  `,
};

export const FilterLabel = tw.div<{ selected: boolean; type: SeverityType }>`
  ${({ selected, type }) => `
    flex
    items-center
    justify-center
    gap-2
    w-full
    min-w-[100px]
    h-full
    py-1.5
    px-3
    transition-smooth
    bg-pl-grayscale-white
    text-pl-grayscale-gray
    rounded-lg

    before:content-['']
    before:h-2
    before:w-2
    before:rounded-full
    before:block

    ${SeverityLabelModifiers[type](selected)}
  `}
`;

export const RecommendationsWrapper = tw.div`
  flex
  flex-col
  mt-4
`;

const SeverityWrapperModifiers = {
  info: `
    border-pl-primary-green-default
  `,

  warning: `
    border-pl-primary-orange-default
  `,

  critical: `
    border-pl-primary-red-default
  `,
};

export const RecommendationsGroupContainer = tw.div`
  flex
  flex-col
  mb-8
`;

export const RecommendationsGroupWrapper = tw.div<{
  severity: SeverityType;
}>`
  ${({ severity }) => `
    flex
    flex-col
    gap-12
    border-l-2
    border-solid
    pl-3
    pt-8
    mb-8

    ${SeverityWrapperModifiers[severity]}
  `}
`;

export const DateLabel = tw.div`
  text-md
  font-semibold
  text-pl-grayscale-gray
  ml-3
  mb-[-10px]
`;

export const NoRecommendationsLabel = tw.div`
  text-md
  font-semibold
  text-pl-grayscale-black
  flex
  items-center
  justify-center
  mt-14
`;

export const LoadMoreWrapper = tw.div`
  w-full
  flex
  justify-center
`;
