import React from "react";
import {Disclosure, Listbox, Transition} from "@headlessui/react";
import {ChevronRightIcon} from "@heroicons/react/solid";
import {SelectSearchOption} from "../SelectSearch";
import TextField from "components/generics/TextField";
import Checkbox from "components/generics/Checkbox";
import {none, useState} from "@hookstate/core";
import {CUSTOM_EVENT_CATEGORY} from "model/keymetrics";

import * as S from "./styles";

import {ReactComponent as SearchIcon} from "icons/search.svg";
import {ReactComponent as CloseIcon} from "icons/close.svg";

type Props = {
  options: SelectSearchOption[];
  initialSelected?: SelectSearchOption[];
  onSelectedValuesChanged: (values: any[]) => void;
  placeholder?: string;
  canAddNewValues?: boolean;
};

export default function MultiSelect({
                                      options,
                                      initialSelected = [],
                                      onSelectedValuesChanged,
                                      placeholder = "Search",
                                      canAddNewValues = false,
                                    }: Props) {
  const showOptions = useState<boolean>(false);
  const selectedValues = useState<SelectSearchOption[]>(initialSelected || []);
  const optionLabelFilterValue = useState<string>("");

  const groupedOptions = useState(options.reduce((result, currentValue) => {
    // @ts-ignore
    (result[currentValue['category']] = result[currentValue['category']] || []).push(
      currentValue
    );
    return result;
  }, {}));

  function isSelected(value: any) {
    return selectedValues.find(
      (option) => getOptionValue(option.value) === value
    )
      ? true
      : false;
  }

  function handleClick(value: any) {
    if (!isSelected(value)) {
      handleSelect(value);

    } else {
      handleDeselect(value);
    }
    optionLabelFilterValue.set("");
  }

  function handleSelect(value: any) {
    const additionalSelectedValue = [
      options.find(
        (option) => getOptionValue(option) === value
      ) as SelectSearchOption,
    ];
    selectedValues.merge(additionalSelectedValue);
    onSelectedValuesChanged(selectedValues.value);
  }

  function handleDeselect(value: any) {
    const toRemove = selectedValues.findIndex(option => getOptionValue(option.value) !== value);

    if (toRemove) {
      selectedValues[toRemove].set(none);
      onSelectedValuesChanged(selectedValues.value);

    }
  }

  function getOptionValue(option: SelectSearchOption) {
    return option.value ? option.value : option.label;
  }


  const hasMatchedValues = () => {
    return Object.keys(groupedOptions.value)
      .filter((key) =>
        groupedOptions.value[key].find((option) =>
          option.label.toLowerCase().includes(optionLabelFilterValue.value.toLowerCase())
        )
      ).length > 0;
  }


  const addNewKpi = () => {
    const newLabel = optionLabelFilterValue;
    const newValue = 'C:' + newLabel.value;

    const newSelectSearchOption = {
      category: CUSTOM_EVENT_CATEGORY,
      label: newLabel.value,
      value: newValue
    } as SelectSearchOption;

    groupedOptions.merge(
      (result, currentValue) => {
        (result[CUSTOM_EVENT_CATEGORY] = result[CUSTOM_EVENT_CATEGORY] || []).push(newSelectSearchOption);
        return result;
      });

    options.push(newSelectSearchOption);
    handleClick(newValue);
  }

  return (
    <S.MainWrapper>
      <Listbox
        as="div"
        className="space-y-1"
        value={selectedValues.value}
        onChange={(value: any) => handleClick(value)}
      >
        <S.WrapperComponent>
          <S.ValuesWrapper>
            <Listbox.Button
              className="shadow-sm rounded-[10px] cursor-default relative w-full min-h-[55px] border border-pl-grayscale-lightgray bg-pl-variant-beige pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-pl-primary-green-default300 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              {
                selectedValues.map((selectedValue, index) => (
                  selectedValue && selectedValue.label &&
                  <S.ValueWrapper key={`skill-${index}`}>
                    <S.ValueTag>
                      {
                        selectedValue.label
                      }
                      <CloseIcon
                        className={"w-3 h-3 ml-2 mt-1 cursor-pointer"}
                        onClick={() => handleDeselect(getOptionValue(selectedValue.value))}
                      />
                    </S.ValueTag>
                  </S.ValueWrapper>
                ))
              }
            </Listbox.Button>
          </S.ValuesWrapper>

          <S.TexfieldWrapper>
            <TextField
              placeholder={placeholder}
              value={optionLabelFilterValue.value}
              inputsize="small"
              icon={<SearchIcon className="w-3 h-3 mt-1 text-pl-grayscale-midgray"/>}
              iconposition="right"
              onFocus={() => showOptions.set(true)}
              onChange={(event) => {
                optionLabelFilterValue.set(event.target.value);
              }}
            />
          </S.TexfieldWrapper>

          <Transition
            show={showOptions.value}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="absolute w-full rounded-md bg-pl-grayscale-white z-10"
          >
            <Listbox.Options
              static
              className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
            >
              {
                hasMatchedValues() ?
                  Object.keys(groupedOptions.value)
                    .filter((key) =>
                      groupedOptions.value[key].find((option) =>
                        option.label.toLowerCase().includes(optionLabelFilterValue.value.toLowerCase())
                      )
                    )
                    .map((key) => {
                      return (
                        <S.CategoryWrapper>
                          <Disclosure key={key + "-disclosure"}>
                            {({open}) => (
                              <>
                                <Disclosure.Button
                                  className={`py-3 flex items-center justify-between capitalize text-xs font-bold ${open ? "text-pl-primary-green-default" : ""} `}>
                                  {key}
                                  <ChevronRightIcon
                                    className={`${
                                      open ? "transform rotate-90" : ""
                                    } h-5 w-5`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className={"divide-y"}>
                                  {
                                    groupedOptions.value[key]
                                      .filter((option) =>
                                        optionLabelFilterValue.value !== ""
                                          ? option.label
                                            .toLowerCase()
                                            .includes(
                                              optionLabelFilterValue.value.toLowerCase()
                                            )
                                          : true
                                      )
                                      .map((object) => (
                                        <S.OptionWrapper key={object.label + "-panel"}>
                                          <S.OptionLabel
                                            // @ts-ignore
                                            isSelected={isSelected(object.value)}>
                                            {
                                              object.label
                                            }
                                          </S.OptionLabel>
                                          <Checkbox className={"rounded"}
                                                    checked={isSelected(object.value)}
                                                    onClick={() => handleClick(object.value)}/>
                                        </S.OptionWrapper>
                                      ))
                                  }
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        </S.CategoryWrapper>
                      );
                    })
                  :
                  (
                    canAddNewValues &&
                    <S.NewDivWrapper key={"new-div"}>
                      <S.NewDivSpan onClick={() => addNewKpi()}>
                        Add {optionLabelFilterValue}
                      </S.NewDivSpan>
                    </S.NewDivWrapper>
                  )
              }
            </Listbox.Options>
          </Transition>
        </S.WrapperComponent>
      </Listbox>
    </S.MainWrapper>
  );
}
