export const Wrapper = `
  flex
  items-center
  gap-2
`;

export const PaginationItemWrapper = `
  p-2
  text-center
  rounded-2xl
  min-w-[26px]
  text-pl-primary-green-default
`;

export const PaginationItem = `
  font-semibold
  text-inherit
`;

export const PaginationItemActive = `
  bg-pl-primary-green-light
`;

export const PaginationItemDisabled = `
  text-pl-grayscale-midgray
`;