import tw from "tailwind-styled-components";
import { TextFieldProps } from ".";

type InputProps = Pick<
  TextFieldProps,
  "iconposition" | "disabled" | "inputsize"
> & {
  error?: number;
  hasvalue?: number;
  rounded?: number;
  border?: number;
  background?: number;
  caret_color?: number;
};

const inputModifiers = {
  small: `
    h-7
  `,

  medium: `
    h-10
  `,

  large: `
    h-12
  `,

  disabled: `
    text-pl-grayscale-midgray
    bg-pl-grayscale-lightgray
    cursor-not-allowed

    focus-within:border-pl-grayscale-midgray
    hover:border-pl-grayscale-midgray
  `,

  error: `
    border-pl-primary-red-default
    focus-within:border-pl-primary-red-default
    hover:border-pl-primary-red-default
  `,
};

const iconModifiers = {
  error: `
    text-pl-primary-red-default
  `,
};

export const InputWrapper = tw.div<InputProps>`
  ${({
    inputsize,
    error,
    disabled,
    hasvalue,
    rounded,
    border,
    background,
    caret_color,
  }) => `
    flex
    items-center
    text-sm
    px-3
    gap-2
    transition-smooth

    focus-within:border-pl-primary-green-default
    hover:border-pl-primary-green-default

    ${!!caret_color ? "caret-black" : "caret-pl-primary-green-default"}
    ${!!background ? "bg-pl-variant-beige" : ""}
    ${!!border ? "border border-solid" : "border-none"}
    ${!!rounded ? "rounded-full" : "rounded-[10px]"}
    ${!!disabled && inputModifiers.disabled}
    ${
      !!hasvalue && !disabled
        ? "border-pl-primary-green-default"
        : "border-pl-grayscale-midgray"
    }
    ${!!error && inputModifiers.error}
    ${inputModifiers[inputsize || "medium"]}
  `}
`;

export const Input = tw.input<InputProps>`
  ${({ error, disabled }) => `
    text-pl-grayscale-black
    placeholder-pl-grayscale-gray
    bg-transparent
    border-none
    text-sm
    w-full
    p-0
    focus:ring-0

    ${!!disabled && inputModifiers.disabled};
    ${!!error && iconModifiers.error};
  `}
`;

export const Icon = tw.div<InputProps>`
  ${({ iconposition, disabled, error }) => `
    flex
    w-5
    h-5

    ${!!disabled && inputModifiers.disabled}
    ${!!error ? "text-pl-primary-red-default" : "text-pl-grayscale-black"}
    ${iconposition === "right" ? "order-1" : "order-none"}
  `}
`;

export const Error = tw.div`
  text-pl-primary-red-default
  text-xs
  font-semibold
  h-0
  pt-1
  border-none
`;

export const Wrapper = tw.div`
  w-full
`;
