import tw from "tailwind-styled-components";

export const Wrapper = tw.div``;

export const ButtonWrapper = tw.div`
  w-8
  h-8
  flex
  items-center
  justify-center
  rounded-lg
  group
  transition-smooth
  bg-pl-grayscale-lightgray

  hover:bg-pl-primary-green-light
`;

export const BulkActionsMenuWrapper = tw.div`
  rounded-2xl
`;

export const BulkActionsMenuItem = tw.div`
  font-semibold
  text-pl-grayscale-black
  bg-pl-grayscale-white
  cursor-pointer
  transition-smooth
  p-4
  flex
  items-center
  text-sm
  rounded-2xl
  hover:bg-pl-primary-green-light
  hover:rounded-none
`;
