import { Memoize, MemoizeExpiring } from 'typescript-memoize';
import config from 'config';
import { LexiReply, LexiResponse, QueryResponse } from '../model/lexi';
import auth from './auth';
import {
  addErrorToastNotification,
  addSuccessToastNotification
} from "../components/utils/Toast/ToastNotificationContainer";
import localCache from "../utils/localCache";

const DEF_ERROR_MESSAGE = "An error has occurred. Please try later or contact support.";

window.addEventListener("unhandledrejection", function(e) {
  e.stopPropagation();
  e.stopImmediatePropagation();
  if (e.reason?.message && e.reason?.toast) {
    addErrorToastNotification(e.reason.message);
  }
});

export type ResponseStatusAndText = {
  status: number,
  message: string,
  result: any
}

function transformPromise<T>(promise: () => Promise<Response>, toastErrors: boolean = true): Promise<T> {
  return new Promise<T>(async (resolve, reject) => {
    try {
      const resp = await promise();
      if (resp.status >= 400) {
        let reason = DEF_ERROR_MESSAGE;

        if (resp.status < 500) {
          reason = await resp.clone().json()
            .then(json => json.message ?? DEF_ERROR_MESSAGE)
            .catch(() => resp.text().catch(() => DEF_ERROR_MESSAGE));
        }
        if (!reason) {
          reason = DEF_ERROR_MESSAGE;
        }

        if (toastErrors) {
          addErrorToastNotification(reason);
        }
        reject({message: reason, toast: toastErrors});
        return;
      }

      const body = await resp.clone().json().catch(async () => {
        return {status: resp.status, message: await resp.text()}
      });

      if (!body.result && !body.message) {
        resolve(body as T);
      }

      if(body.message) {
        addSuccessToastNotification(body.message);
      }

      resolve(body.result as T);
    } catch (e) {
      //todo: I feel we should have soemthing like this but I am not certain where else we're handling rejections
      //addErrorToastNotification("An unknown error has occured");
      reject(e);
    }
  });
}

class ApiFetch {

    public apiFetchGet<T>(url: URL, init?: RequestInit) {
      return localCache('fetch', {url, init}, () =>
        transformPromise<T>(() => fetch(url.href, init), false));
    }

    private async getRequestInit(query: string) {
        const token = await auth.token;
        return {
            body: query,
            method: 'POST',
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        } as RequestInit
    }

    public async lexiFetch<T> (url: URL, query: string) {
        const init: RequestInit = await this.getRequestInit(query);
        if (url.toString().endsWith('/query')) {
          return localCache('reports', query, () => transformPromise<T>(() => fetch(url.href, init)));
        } else {
          return transformPromise<T>(() => fetch(url.href, init));
        }
    }
}

const memoizedFetch = new ApiFetch();

function internalFetch<T>(url: URL, query?: { [key: string]: any; }, init?: RequestInit): Promise<T> {
    if (query) {
        Object.keys(query)
            .forEach(k => url.searchParams.append(k, '' + query[k]));
    }


    if (isCacheable(init)) {
        return memoizedFetch.apiFetchGet(url, init);
    } else {
        return transformPromise<T>(() => fetch(url.href, init), !isGet(init));
    }
}

function isCacheable(init?: RequestInit) {
  if (!init) {
    return true;
  }

  if (init.cache === "no-cache") {
    return false;
  }

  return isGet(init);
}

function isGet(init?: RequestInit) {
  return init?.method?.toUpperCase() === 'GET';
}

export function apiFetch<T>(path: string, query?: { [key: string]: any; }, init?: RequestInit): Promise<T> {
    const url = new URL(`${config.appUrl}${path}`);

    init = {
        ...(init || {}),
        credentials: 'include',
        method: 'GET'
    };

    return internalFetch(url, query, init);
}
export async function apiPut<T>(path: string, body: any, init?: RequestInit): Promise<T> {
    return apiWrite(path, body, 'PUT', init);
}

export async function apiPost<T>(path: string, body: any, init?: RequestInit): Promise<T> {
    return apiWrite(path, body, 'POST', init);
}

export async function apiDelete<T>(path: string, body?: any, init?: RequestInit) {
    const url = new URL(`${config.appUrl}${path}`);
    const csrf = await auth.csrfToken;
    const token = await auth.token;

    init = {
        ...(init || {}),
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            [csrf.header]: csrf.token,
            'Authorization': `Bearer ${token}`
        },
    };
    return internalFetch(url, {}, init);
}

export async function apiWrite<T>(path: string, body: any, method: string, init?: RequestInit): Promise<T> {
    const url = new URL(`${config.appUrl}${path}`);
    const csrf = await auth.csrfToken;
    const token = await auth.token;

    init = {
        ...(init || {}),
        method: method,
        body: JSON.stringify(body),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            [csrf.header]: csrf.token,
            'Authorization': `Bearer ${token}`
        },
    };
    return internalFetch(url, {}, init);
}

export async function recommenderFetch<T>(path: string, query?: { [key: string]: any; }, init?: RequestInit): Promise<T> {
    const url = new URL(`${config.recommenderUrl}${path}`);
    return internalServiceFetch(url, query, init);
}

export async function aggregatorFetch<T>(path: string, query?: { [key: string]: any; }, init?: RequestInit): Promise<T> {
    const url = new URL(`${config.aggregatorUrl}${path}`);
    return internalServiceFetch(url, query, init);
}

export async function internalServiceFetch<T>(url: URL, query?: { [key: string]: any; }, init?: RequestInit): Promise<T> {
    const token = await auth.token;

    init = {
        ...(init || {}),
        mode: "cors",
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    };

    return internalFetch(url, query, init);
}

export async function recommenderPut<T>(path: string, body: any, init?: RequestInit): Promise<T> {
    return recommenderWrite(path, body, 'PUT', init);
}

export async function recommenderPost<T>(path: string, body: any, init?: RequestInit): Promise<T> {
    return recommenderWrite(path, body, 'POST', init);
}

export async function recommenderWrite<T>(path: string, body: any, method: string, init?: RequestInit): Promise<T> {
    const url = new URL(`${config.recommenderUrl}${path}`);
    return internalServiceWrite(url, body, method, init);
}

export async function aggregatorPost<T>(path: string, body: any): Promise<T> {
  const url = new URL(`${config.aggregatorUrl}${path}`);
  return internalServiceWrite(url, body, "POST");
}

export async function internalServiceWrite<T>(url: URL, body: any, method: string, init?: RequestInit): Promise<T> {
    const token = await auth.token;

    init = {
        ...(init || {}),
        method: method,
        credentials: 'include',
        body: JSON.stringify(body),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    };
    return internalFetch(url, {}, init);
}

export async function lexiFetch(query: any, subPath: string) {
    let url = new URL(`${config.lexiUrl}/api/v1/reports${subPath}`);
    return memoizedFetch.lexiFetch<LexiResponse>(url, JSON.stringify(query));
}

export async function reportsUtilsFetch<T>(path: string, query: { [key: string]: any; } = {}): Promise<T> {
    return reportsFetch(`/api/v1/reports/utils${path}`, query);
}

export async function reportsStayFactorFetch<T>(path: string, query: { [key: string]: any; } = {}): Promise<T> {
  return reportsFetch(`/api/v1/stayfactor${path}`, query);
}

export async function reportsFetch<T>(path: string, query?: { [key: string]: any; }): Promise<T> {
  const url = new URL(`${config.lexiUrl}${path}`);
  const token = await auth.token;
  const init = {
    method: 'GET',
    mode: "cors",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  } as RequestInit;
  return internalFetch(url, query, init);
}
