import React, { useEffect } from "react";
import { State, useState } from "@hookstate/core";
import {
  UnmappedIntegrationUser,
  UnmappedUser,
  UnmappedUserState,
} from "model/integration";
import { Employee } from "model/employee";
import Button from "components/generics/Button";
import { ReactComponent as CloseIcon } from "icons/close.svg";

import * as S from "./styles";

export function filterResultsByMappedState(
  mappedState: UnmappedUserState,
  unmappedIntegrationUser: UnmappedIntegrationUser[]
) {
  return unmappedIntegrationUser
    .map((un) => {
      const users = un.unmappedUsers.filter(
        (user) => user.mappedState === mappedState
      );
      return {
        sourceId: un.sourceId,
        sourceName: un.sourceName,
        unmappedUsers: users,
      } as UnmappedIntegrationUser;
    })
    .filter((un) => un.unmappedUsers.length > 0);
}

export default function MapperReview({
  state,
  employees
}: {
  state: State<UnmappedIntegrationUser[]>;
  employees: Employee[];
}) {
  const unmappedIntegrationUser = useState(state);
  const integrationUsersState = useState<{
    integrationsWithMappings: UnmappedIntegrationUser[];
    ignoredUsers: UnmappedIntegrationUser[];
    newEmployees: UnmappedIntegrationUser[];
  }>({
    integrationsWithMappings: [],
    ignoredUsers: [],
    newEmployees: [],
  });

  useEffect(() => {
    integrationUsersState.integrationsWithMappings.set(
      filterResultsByMappedState(
        "mappedWithEmployee",
        unmappedIntegrationUser.value
      )
    );

    integrationUsersState.ignoredUsers.set(
      filterResultsByMappedState("ignoredUsers", unmappedIntegrationUser.value)
    );

    integrationUsersState.newEmployees.set(
      filterResultsByMappedState("newEmployees", unmappedIntegrationUser.value)
    );
  }, [state]);

  const hasChanges =
    integrationUsersState.ignoredUsers.length ||
    integrationUsersState.newEmployees.length ||
    integrationUsersState.integrationsWithMappings.length;

  const getMappedState = {
    unmapped: "Unmapped",
    mappedWithEmployee: "Mapped with Employee",
    ignoredUsers: "Ignored",
    newEmployees: "New Employee",
  };

  const undo = (user: UnmappedUser, integration: UnmappedIntegrationUser) => {
    const userState = unmappedIntegrationUser
      .filter((unm) => unm.sourceId.get() === integration.sourceId)[0]
      .unmappedUsers.filter((u) => u.mapping.value === user.mapping)[0];

    userState.mappedState.set("unmapped");
    userState.mapped.set(false);
    userState.employeeId.set(undefined);
  };

  return (
    <S.Wrapper>
      <S.Title>
        {!!hasChanges
          ? "Select an employee below for each unknown integration user."
          : "You have no users to map."}
      </S.Title>

      {!!hasChanges && (
        <S.ListWrapper>
          {unmappedIntegrationUser.value.map((item) => (
            <S.ListContentWrapper className="listContentWrapper">
              <S.ListContent className="listContent">
                <S.ListTitle>{item.sourceName}</S.ListTitle>
                <S.ListItemWrapper>
                  <S.ListItem>
                    <S.ListItemHeader>Integration User</S.ListItemHeader>

                    {item.unmappedUsers.map((user) => (
                      <S.ListItemContent>{user.mapping}</S.ListItemContent>
                    ))}
                  </S.ListItem>

                  <S.ListItem>
                    <S.ListItemHeader>Employee</S.ListItemHeader>
                    {item.unmappedUsers.map((user) => (
                      <S.ListItemContent>
                        {
                          employees.find(employee => employee.id == user.employeeId)?.name
                        }
                      </S.ListItemContent>
                    ))}
                  </S.ListItem>

                  <S.ListItem>
                    <S.ListItemHeader>Status</S.ListItemHeader>
                    {item.unmappedUsers.map((user) => (
                      <S.ListItemContent>
                        {getMappedState[user.mappedState]}
                      </S.ListItemContent>
                    ))}
                  </S.ListItem>

                  {
                    <S.ActionWrapper>
                      {item.unmappedUsers.map((user) => (
                        <>
                          <S.Action>
                            <Button
                              minimal
                              btntype="error"
                              icon={<CloseIcon height={40} />}
                              iconposition="left"
                              onClick={() => undo(user, item)}
                              className={
                                user.mappedState === "unmapped"
                                  ? "opacity-0 pointer-events-none"
                                  : "opacity-1"
                              }
                            >
                              Undo
                            </Button>
                          </S.Action>
                        </>
                      ))}
                    </S.ActionWrapper>
                  }
                </S.ListItemWrapper>
              </S.ListContent>
            </S.ListContentWrapper>
          ))}
        </S.ListWrapper>
      )}
    </S.Wrapper>
  );
}
