import React, { useRef } from "react";
import { State, useState } from "@hookstate/core";
import { RecommendationSettings } from "model/recommendations";
import TextField from "components/generics/TextField";
import {ReactComponent as InfoIcon} from "icons/info.svg";
import SpeechBalloon from "components/generics/SpeechBalloon";
import * as S from "./styles";

export default function RecommendationsSettings({
  state,
}: {
  state: State<RecommendationSettings>;
}) {
  const innerState = useState(state);
  const settingKeyRef = useRef<HTMLInputElement>(null);

  return (
      <S.Wrapper>
        <S.ContentContainer>
          <S.SettingsGridContainer>
            {innerState.settings.map((sett) => {
              const handleClear = (ref: any) => {
                ref.current && (ref.current.value = "");
                sett.settingValue.set("");
              };

              return (
                <S.SettingsItem key={sett.settingKey.value}>
                  <S.SettingTopSection>
                    <S.SettingLabel
                      htmlFor={sett.label.value}
                      title={sett.label.value}
                    >
                      {sett.label.value}
                    </S.SettingLabel>
                    {sett.description.value?
                      <S.ToolTipContainer>
                        <S.ToolTip>
                          <SpeechBalloon $arrowSidePosition="center"
                                         $arrowVerticalPosition="bottom"
                                         $bolded={true}
                          >
                            {sett.description.value}
                          </SpeechBalloon>
                        </S.ToolTip>
                        <InfoIcon className="text-pl-grayscale-midgray h-4 w-4"></InfoIcon>
                      </S.ToolTipContainer>
                    :<></>}
                  </S.SettingTopSection>
                  <S.SettingsTextField>
                    <TextField
                      ref={settingKeyRef}
                      name={sett.settingKey.value}
                      id={sett.settingKey.value}
                      value={sett.settingValue.value}
                      border={false}
                      onChange={({ target: { value } }) =>
                        sett.settingValue.set(value)
                      }
                      onClear={() => handleClear(settingKeyRef)}
                    />
                  </S.SettingsTextField>
                </S.SettingsItem>
              );
            })}
          </S.SettingsGridContainer>
        </S.ContentContainer>
      </S.Wrapper>
  );
}
