type QueueItem<A, R> = {
  argument: A
  resolve: (r: R) => any
  reject: (e: any) => any,
  rejectUnresolved: boolean,
  defaultValue?: any
}

type SingleFetcher<A extends string | number | symbol, R> = (arg: A) => R | Promise<R>
type MultiFetcher<A extends string | number | symbol, R> = (args: A[]) => Record<A, R> | Promise<Record<A, R>>;

type Queue<A extends string | number | symbol, R> = {
  items: QueueItem<A, R>[]
  fetcher: (args: A[]) => Record<A, R> | Promise<Record<A, R>>
  singleFetch?: SingleFetcher<A, R>
}

const QUEUES = {} as Record<string, Queue<any, any>>;

async function resolveQueue<A extends string | number | symbol, R>(id: string) {

  const queue = QUEUES[id] as Queue<A, R>

  if (!queue) {
    throw {error: `Queue not found: ${id}`}
  }

  delete QUEUES[id]

  const args = queue.items.map(i => i.argument)
    .filter((a, i, s) => s.indexOf(a) === i);

  try {
    if (args.length === 1 && queue.singleFetch) {
      const singleResult = await queue.singleFetch(args[0]);
      queue.items[0].resolve(singleResult);
    } else {
      const result = await queue.fetcher(args);

      queue.items.forEach(({argument, resolve, reject, rejectUnresolved, defaultValue}) => {
        if (!result[argument]) {
          if (rejectUnresolved) {
            reject("Argument was not resolved");
            return;
          } else {
            resolve(defaultValue);
          }
        }

        resolve(result[argument]);
      })
    }
  } catch (e) {
    queue.items.forEach(item => item.reject(e));
  }
}

async function multiDebounce<A extends string | number | symbol, R>(
  id: string, argument: A, fetcher: MultiFetcher<A, R>, singleFetch?: SingleFetcher<A, R>,
  {timeout = 250, rejectUnresolved = true, defaultValue}:
    { timeout?: number, rejectUnresolved?: boolean, defaultValue?: any } = {}) {

  if (!QUEUES[id]) {
    setTimeout(() => resolveQueue<A, R>(id), timeout);
    QUEUES[id] = {
      items: [],
      fetcher,
      singleFetch
    }
  }

  return new Promise((resolve, reject) => {
    (QUEUES[id] as Queue<A, R>).items.push({
      argument,
      resolve,
      reject,
      rejectUnresolved,
      defaultValue
    });
  })
}

export default multiDebounce;
