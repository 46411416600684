import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  bg-pl-grayscale-white
  p-6
  rounded-2xl
  h-[180px]
  shadow
  flex
  flex-col
  justify-between
`;

export const Title = tw.div`
  font-semibold
  text-lg
  flex
  items-center
  justify-between
  border-b
  pb-3
  border-pl-grayscale-lightgray
  gap-2
`;

export const Logo = tw.div`
  flex
  items-center
  gap-3
`;

export const Content = tw.div`
  text-sm
  text-pl-grayscale-gray
  my-3
  h-[40%]
  overflow-auto
  no-scrollbar
`;

export const Footer = tw.div`
  flex
  flex-col
  gap-2
  pt-3
  border-pl-grayscale-lightgray
`;

export const FooterItem = tw.div`
  text-sm
  flex
  items-center
  justify-between
`;

export const FooterLink = tw.a`
  transition-smooth
  text-sm
  hover:text-pl-primary-green-default
`;
