import React from "react";
import { Link } from "react-router-dom";
import * as S from "./styles";

export type LogoProps = {
  src: string;
  alt?: string;
  path?: string;
  size?: "small" | "medium" | "large";
  target?: string;
};

const Logo = ({
  src,
  alt = "A Simple Logo",
  path,
  size = "medium",
  target = "_blank",
}: LogoProps) => (
  <S.Wrapper>
    {path ? (
      <Link to={path} target={target} rel="noopenner, noreferrer">
        <S.Logo src={src} alt={alt} size={size} />
      </Link>
    ) : (
      <S.Logo src={src} alt={alt} size={size} />
    )}
  </S.Wrapper>
);

export default Logo;
