import React from "react";
import { Form, Formik } from "formik";
import { useState } from "@hookstate/core";
import {NotificationSettings, NotificationsFrequency} from "model/employee";
import employeeService from "services/employeeService";
import Button from "components/generics/Button";
import Checkbox from "components/generics/Checkbox";
import ToggleButton from "components/generics/ToggleButton";

import * as S from "./styles";

export default function UserNotificationsTab({
  settings,
}: {
  settings: NotificationSettings;
}) {
  const initialState = useState({ ...settings });
  const innerState = useState({ ...settings });

  function handleSave() {
    employeeService
      .saveUserNotifications(innerState.value)
      .then((newSettings) => {
        initialState.set({ ...innerState.value });
      });
  }

  function resetForm() {
    innerState.set({ ...initialState.value });
  }

  return (
    <Formik initialValues={{ ...innerState.value }}
            onSubmit={handleSave}>
      {({ isValid }) => (
        <Form>
          <S.Wrapper>
            <S.HeaderWrapper>
              <S.HeaderTitle>
                Setup Email Notification:
              </S.HeaderTitle>

              <S.HeaderButtons>
                <Button $shadow={true}
                        type="button"
                        onClick={() => resetForm()}>
                  Reset
                </Button>
                <Button $shadow={true}
                        btntype="primary"
                        type="submit"
                        disabled={!isValid}>
                  Save
                </Button>
              </S.HeaderButtons>
            </S.HeaderWrapper>

            <S.BodyWrapper>
              <S.BodyColumns>
                <S.ColumnTitle>
                  Receiving notifications:
                </S.ColumnTitle>
                <S.ToggleWrapper>
                  <ToggleButton
                    id="email"
                    key="email"
                    checked={innerState.email.value}
                    text={"Email Notifications"}
                    onChange={() =>
                      innerState.email.set((prevState) => !prevState)
                    }
                    className={"pb-0"}
                  />
                </S.ToggleWrapper>
                <S.ToggleWrapper>
                  <ToggleButton
                    id="slack"
                    key="slack"
                    checked={innerState.slack.value}
                    text={"Slack Notifications"}
                    onChange={() =>
                      innerState.slack.set((prevState) => !prevState)
                    }
                    className={"pb-0"}
                  />
                </S.ToggleWrapper>
              </S.BodyColumns>

              <S.BodyColumns>
                <S.ColumnTitle>
                  Subscribe to:
                </S.ColumnTitle>
                <S.ChecksWrapper>
                  <Checkbox
                    label={"Alerts & Other Instant Notifications"}
                    labelFor="alertAndOther"
                    checked={innerState.alerts.value}
                    onClick={() =>
                      innerState.alerts.set((prevState) => !prevState)
                    }
                    className={"rounded"}
                  />

                  <S.FrquencyFieldSetWrapper>
                    {
                      NotificationsFrequency.map((freq) => (
                        <S.Label key={freq.value}>
                          {freq.label}

                          <input
                            aria-describedby={`${freq}-description`}
                            name="role"
                            type="radio"
                            checked={innerState.frequency.value === freq.value}
                            onClick={() => {
                              if (innerState.frequency.value === freq.value) {
                                innerState.frequency.set(null);
                              } else {
                                innerState.frequency.set(freq.value)

                              }
                            }}
                            className={S.CustomRadio}

                          />
                        </S.Label>
                      ))
                    }
                  </S.FrquencyFieldSetWrapper>
                </S.ChecksWrapper>
              </S.BodyColumns>
            </S.BodyWrapper>
          </S.Wrapper>
        </Form>
      )}
    </Formik>
  );
}
