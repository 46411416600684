import React, {useEffect} from "react";
import {useState} from "@hookstate/core";

type Size = {
  width: number | undefined;
  height: number | undefined;
}
// Hook
export const useWindowSize = () => {
  const windowSize = useState<Size>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      windowSize.set({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize.value;
}