import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  flex 
  h-screen
  bg-pl-grayscale-black
`;

export const Content = tw.div`
  m-auto 
  flex 
  flex-col 
  items-center 
  justify-center
`

export const SpinnerWrapper = tw.div`
  absolute 
  inset-0

  flex 
  justify-center 
  items-center 
  z-10
`