import React, { useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import { ChartArea, ChartData, ChartOptions } from "chart.js";
import {useState} from "@hookstate/core";
import * as _ from "lodash";

export default function Sparkline({
  values,
  min = 0,
  max = 100,
  fill = true,
  reverse = false
}: {
  values: number[];
  min?: number;
  max?: number;
  fill?: boolean;
  reverse?: boolean;
}) {
  let sparlineValues = [...values];
  sparlineValues = reverse ? [...sparlineValues.reverse()] : [...sparlineValues];

  const chartRef = useRef(null);
  const chartData = useState<ChartData<"line">>({
    datasets: [
      {
        label: "Dataset 1",
        data: sparlineValues,
      },
    ],
  });
  const data = {
    labels: sparlineValues.map((_, i) => i),
    datasets: [
      {
        label: "",
        data: sparlineValues,
      },
    ],
  };


  function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
    min = (min/100);
    max = (max/100);

    const green = (max - 0.7)/(max - min);
    const blue = (max - 0.6)/(max - min);
    const yellow = (max - 0.45)/(max - min);
    const orange = (max - 0.3)/(max - min);

    //********** line
    var line = ctx.createLinearGradient(0, 0, 0, area.height);
    if (min <= 0.3 && max <= 0.3) {
      line.addColorStop(1, 'rgba(251, 108, 113, 0.7)');
    }
    if (min > 0.3 && min <= 0.45 && max > 0.3 && max <= 0.45) {
      line.addColorStop(1, 'rgba(255, 135, 24, 0.7)');
    }
    if (min > 0.45 && min <= 0.6 && max > 0.45 && max <= 0.6) {
      line.addColorStop(1, 'rgba(234, 234, 22, 0.7)');
    }
    if (min > 0.6 && min <= 0.7 && max > 0.6 && max <= 0.7) {
      line.addColorStop(1, 'rgba(0, 172, 194, 0.7)');
    }
    if (min > 0.7 && max > 0.7) {
      line.addColorStop(1, 'rgba(0, 194, 136, 0.7)');
    }

    if (min <= 0.7 && max > 0.7) {
      line.addColorStop(green > 1 ? 1 : green, 'rgba(0, 194, 136, 0.7)');
      line.addColorStop(blue > 1 ? 1 : blue, 'rgba(0, 172, 194, 0.7)');
      if (min <= 0.6) {
        line.addColorStop(yellow > 1 ? 1 : yellow, 'rgba(234, 234, 22, 0.7)');
      }
      if (min <= 0.45) {
        line.addColorStop(orange > 1 ? 1 : orange, 'rgba(255, 135, 24, 0.7)');
      }
      if (min <= 0.3) {
        line.addColorStop(1, 'rgba(251, 108, 113, 0.7)');
      }
    } else if (min <= 0.6 && max > 0.6) {
      line.addColorStop(blue > 1 ? 1 : blue, 'rgba(0, 172, 194, 0.7)');
      line.addColorStop(yellow > 1 ? 1 : yellow, 'rgba(234, 234, 22, 0.7)');
      if (min <= 0.45) {
        line.addColorStop(orange > 1 ? 1 : orange, 'rgba(255, 135, 24, 0.7)');
      }
      if (min <= 0.3) {
        line.addColorStop(1, 'rgba(251, 108, 113, 0.7)');
      }
    } else if (min <= 0.45 && max > 0.45) {
      line.addColorStop(yellow > 1 ? 1 : yellow, 'rgba(234, 234, 22, 0.7)');
      line.addColorStop(orange > 1 ? 1 : orange, 'rgba(255, 135, 24, 0.7)');
      if (min <= 0.3) {
        line.addColorStop(1, 'rgba(251, 108, 113, 0.7)');
      }
    } else if (min <= 0.3 && max > 0.3) {
      line.addColorStop(orange > 1 ? 1 : orange, 'rgba(255, 135, 24, 0.7)');
      line.addColorStop(1, 'rgba(251, 108, 113, 0.7)');
    }


    //********** gradient
    var gradient = ctx.createLinearGradient(0, 0, 0, area.height);
    if (min <= 0.3 && max <= 0.3) {
      gradient.addColorStop(1, 'rgba(251, 108, 113, 0.1)');
    }
    if (min > 0.3 && min <= 0.45 && max > 0.3 && max <= 0.45) {
      gradient.addColorStop(1, 'rgba(255, 135, 24, 0.1)');
    }
    if (min > 0.45 && min <= 0.6 && max > 0.45 && max <= 0.6) {
      gradient.addColorStop(1, 'rgba(234, 234, 22, 0.1)');
    }
    if (min > 0.6 && min <= 0.7 && max > 0.6 && max <= 0.7) {
      gradient.addColorStop(1, 'rgba(0, 172, 194, 0.1)');
    }
    if (min > 0.7 && max > 0.7) {
      gradient.addColorStop(1, 'rgba(0, 194, 136, 0.1)');
    }

    if (min <= 0.7 && max > 0.7) {
      gradient.addColorStop(green > 1 ? 1 : green, 'rgba(0, 194, 136, 0.1)');
      gradient.addColorStop(blue > 1 ? 1 : blue, 'rgba(0, 172, 194, 0.1)');
      if (min <= 0.6) {
        gradient.addColorStop(yellow > 1 ? 1 : yellow, 'rgba(234, 234, 22, 0.1)');
      }
      if (min <= 0.45) {
        gradient.addColorStop(orange > 1 ? 1 : orange, 'rgba(255, 135, 24, 0.1)');
      }
      if (min <= 0.3) {
        gradient.addColorStop(1, 'rgba(251, 108, 113, 0.1)');
      }
    } else if (min <= 0.6 && max > 0.6) {
      gradient.addColorStop(blue > 1 ? 1 : blue, 'rgba(0, 172, 194, 0.1)');
      gradient.addColorStop(yellow > 1 ? 1 : yellow, 'rgba(234, 234, 22, 0.1)');
      if (min <= 0.45) {
        gradient.addColorStop(orange > 1 ? 1 : orange, 'rgba(255, 135, 24, 0.1)');
      }
      if (min <= 0.3) {
        gradient.addColorStop(1, 'rgba(251, 108, 113, 0.1)');
      }
    } else if (min <= 0.45 && max > 0.45) {
      gradient.addColorStop(yellow > 1 ? 1 : yellow, 'rgba(234, 234, 22, 0.1)');
      gradient.addColorStop(orange > 1 ? 1 : orange, 'rgba(255, 135, 24, 0.1)');
      if (min <= 0.3) {
        gradient.addColorStop(1, 'rgba(251, 108, 113, 0.1)');
      }
    } else if (min <= 0.3 && max > 0.3) {
      gradient.addColorStop(orange > 1 ? 1 : orange, 'rgba(255, 135, 24, 0.1)');
      gradient.addColorStop(1, 'rgba(251, 108, 113, 0.1)');
    }

    return {line, gradient};
  }

  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) {
      return;
    }
    //console.log('chartRef: ', chart);
    // @ts-ignore
    const vals = createGradient(chart.ctx, chart.chartArea);
    chartData.set({
      ...data,
      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        pointRadius: 0,
        backgroundColor: vals.gradient,
        lineTension: 0.4,
        fill: fill,
        borderColor: vals.line,
        pointBorderColor: vals.gradient,
        pointBackgroundColor: vals.gradient,
        pointHoverBackgroundColor: vals.gradient,
        pointHoverBorderColor: vals.gradient,
        borderWidth: 1.8
      })),
    });
  }, []);

  const options = {
    scales: {
      x: {
        display: false,
        title: {
          display: false,
        },
      },
      y: {
        display: false,
        min: min,
        max: max,
        title: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  } as ChartOptions<"line">;

  return <Line ref={chartRef} data={_.cloneDeep(chartData.value)} options={options} />;
}
