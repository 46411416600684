import React from "react";
import { WidgetTitle } from "components/utils/WidgetTitle";
import { WidgetContainer } from "components/utils/WidgetContainer";
import {CUSTOM_EVENT_CATEGORY, KeyMetric, KeyMetricsType, KpiCategory} from "model/keymetrics";
import { getEventNameForDisplay } from "utils/text";
import { useState } from "@hookstate/core";
import MultiSelect from "components/utils/Multiselect/Multiselect";
import { LoadingWidget } from "components/utils/LoadingWidget";
import Spinner from "components/utils/Spinner";
import keyMetricsService from "services/keymetrics";
import { SAMPLE_KEY_METRICS } from "model/mockedValues";
import { PeriodSelectorState } from "components/utils/PeriodSelector";
import BlurredContainer from "components/generics/BlurredContainer";
import Button from "components/generics/Button";
import { SelectSearchOption } from "components/utils/SelectSearch";
import {Team} from "model/employee";
import {Department} from "model/department";
import {getTimeInterval} from "utils/dateFormatter";

import * as S from "./styles";

import { ReactComponent as EditIcon } from "icons/edit.svg";
import { ReactComponent as ArrowLeft } from "icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "icons/arrow-right.svg";

export default function KeyMetrics({
  keyMetricsType,
  team,
  department,
  horizontal = false,
  classname = '',
}: {
  team?: Team;
  department?: Department;
  keyMetricsType: KeyMetricsType;
  horizontal?: boolean;
  classname?: string;
}) {
  const numMetrics = horizontal ? 3 : 4;

  const lastValuesState = useState(keyMetricsType);
  const keyMetricsListState = useState<KeyMetricsType>(lastValuesState);
  const editMode = useState(false);
  const isSaving = useState(false);
  const pageNumber = useState<number>(0);

  const hasData = keyMetricsListState.keyMetrics && keyMetricsListState.keyMetrics.length > 0;

  const getValues = () => {
    if (keyMetricsListState.keyMetrics && keyMetricsListState.keyMetrics.length > 0) {
      return keyMetricsListState.keyMetrics.value;
    } else {
      return SAMPLE_KEY_METRICS;
    }
  };

  const totalPages = () => {
    if (getValues().length % numMetrics === 0) {
      return getValues().length / numMetrics;
    } else {
      return Math.floor(getValues().length / numMetrics) + 1;
    }
  };

  const allKpis = useState<KpiCategory[]>(keyMetricsService.getAllKpis());

  const getOptions = (availableKpi: KpiCategory[]) => {
    const options: SelectSearchOption[] = [];

    if (keyMetricsType.keyMetrics) {
      if (availableKpi.filter(c => c.category == CUSTOM_EVENT_CATEGORY).length == 0) {
        const customMetrics = keyMetricsType.keyMetrics.filter(keyMetric => keyMetric.eventName.startsWith('C:'));
        availableKpi.push({
          category: CUSTOM_EVENT_CATEGORY,
          kpis: customMetrics,
        });
      }
    }

    availableKpi
      .sort((catA, catB) =>
        catA.category.toLowerCase().localeCompare(catB.category.toLowerCase())
      )
      .forEach((category) =>
        category.kpis
          .sort((kpiA, kpiB) =>
            kpiA.kpiName.toLowerCase().localeCompare(kpiB.kpiName.toLowerCase())
          )
          .forEach((kpi) =>
            options.push({
              category: category.category,
              label: kpi.kpiName,
              value: kpi.eventName,
            } as SelectSearchOption)
          )
      );

    return options;
  };

  const renderEditArea = (availableKpi: KpiCategory[]) => {
    return (
      <>
        <MultiSelect
          options={getOptions(availableKpi)}
          initialSelected={keyMetricsListState.kpis.value.map((info) => ({
            label: info.kpiName,
            value: info.eventName,
          }))}
          onSelectedValuesChanged={(values) => {
            const metrics = values.map(
              (val) =>
                ({
                  kpiName: val.label,
                  eventName: val.value,
                } as KeyMetric)
            );
            keyMetricsListState.keyMetrics.set([...metrics]);
          }}
          canAddNewValues={!!(team || department)}
        />
      </>
    );
  };

  function saveData() {
    editMode.set(false);
    isSaving.set(true);

    let persisted;
    if (team) {
      persisted = keyMetricsService.saveTeamKpis(team.id, keyMetricsListState.value, PeriodSelectorState.selectedPeriod.value);
    } else if (department) {
      persisted = keyMetricsService.saveDepartmentKpis(department.id, keyMetricsListState.value, PeriodSelectorState.selectedPeriod.value);
    }

    persisted
      .then((updatedKeyMetrics) => {
        keyMetricsListState.set(updatedKeyMetrics);
        lastValuesState.set(updatedKeyMetrics);
      })
      .catch(() => keyMetricsListState.set({...lastValuesState.value}))
      .finally(() => isSaving.set(false));
  }

  const getText = () => {
    return (team || department) ? "Choose your first key metrics" : "Add employee to a team for KPIs";
  }

  return (
    <>
      <WidgetContainer rounded={true} shadow={true} heightAuto className={`min-h-[148px] ${classname}`}>
        <S.Wrapper>
          <WidgetTitle className={`!pr-0 ${horizontal && `flex`}`}>Key Metrics
            <S.SimpleTitle className={`${horizontal && `pt-1 pl-2`}`}>{getTimeInterval()}</S.SimpleTitle>
          </WidgetTitle>
          {
            (team || department) && (
            <>
              {editMode.value ? (
                <>
                  <S.ButtonsWrapper>
                    <Button
                      btntype="error"
                      size="small"
                      minimal={true}
                      $shadow={false}
                      onClick={() => {
                        keyMetricsListState.set({...lastValuesState.value});
                        editMode.set(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      btntype="primary"
                      size="small"
                      minimal={true}
                      $shadow={false}
                      onClick={() => saveData()}
                    >
                      Save
                    </Button>
                  </S.ButtonsWrapper>
                </>
              ) : (
                <Button
                  className={"!text-pl-grayscale-midgray !ml-2"}
                  minimal
                  $shadow={false}
                  onClick={() => editMode.set(true)}
                >
                  <EditIcon />
                </Button>
              )}
            </>
          )}
        </S.Wrapper>

        {
          (team || department) && editMode.value ? (
          <>
            <S.WrapperEditArea>
              <LoadingWidget
                value={allKpis}
                builder={(availableKpi) => {
                  return renderEditArea(availableKpi);
                }}
                size="medium"
              />
            </S.WrapperEditArea>
          </>
        ) : (
          <>
            {isSaving.value ?
              <Spinner size="large" />
              :
              <>
                {
                  keyMetricsListState.kpis.value.length == 0 ?
                    <S.LabelArea>
                      <S.TextLabel>
                        {getText()}
                      </S.TextLabel>
                    </S.LabelArea>
                    :
                    <S.WrapperArea>
                      <BlurredContainer blur={!hasData}>
                      <S.List
                        className={`${
                          horizontal ? `grid grid-cols-3` : `grid grid-cols-2`
                        }`}
                      >
                        {
                          getValues()
                            .slice(
                              numMetrics * pageNumber.value,
                              numMetrics * pageNumber.value + numMetrics
                            )
                            .map((metric, index) => (
                              <S.ListItem key={`metric-${index}`}>
                                <div
                                  className={`px-2 h-full ${
                                    horizontal
                                      ? index !== 0 &&
                                      `border-l-2 border-l-pl-grayscale-lightgray`
                                      : index % 2 !== 0 &&
                                      `border-l-2 border-l-pl-grayscale-lightgray`
                                  } `}
                                >
                                  <S.KeyMetricValues>
                                    <S.KeyMetricValue>
                                      {metric.value}
                                    </S.KeyMetricValue>
                                    <S.KeyMetricPercentage
                                      className={` ${
                                        metric.percentage === 0
                                          ? "text-pl-grayscale-gray"
                                          :
                                          metric.smallerIsBetter ?
                                            metric.percentage < 0 ? "text-pl-primary-green-default" : "text-pl-primary-red-default"
                                            :
                                            metric.percentage < 0 ? "text-pl-primary-red-default" : "text-pl-primary-green-default"}`}
                                    >
                                      {metric.percentage}%
                                    </S.KeyMetricPercentage>
                                  </S.KeyMetricValues>
                                  <S.KeyMetricLabel>
                                    {
                                      getEventNameForDisplay(metric)
                                    }
                                  </S.KeyMetricLabel>
                                </div>
                              </S.ListItem>
                            ))}
                      </S.List>

                      <S.ArrowsSection>
                        <S.ArrowContainer>
                          <Button
                            className={
                              "!h-10 !w-10 !rounded-full !bg-pl-grayscale-white p-1 flex justify-center"
                            }
                            btntype="default"
                            minimal
                            $shadow={true}
                            disabled={pageNumber.value === 0}
                            onClick={() => pageNumber.set(pageNumber.value - 1)}
                          >
                            <ArrowLeft
                              className={`w-4 h-4 ${
                                pageNumber.value === 0
                                  ? "text-pl-grayscale-lightgray cursor-default"
                                  : ""
                              } `}
                            />
                          </Button>
                        </S.ArrowContainer>
                        <S.ArrowContainer>
                          <Button
                            className={
                              "!h-10 !w-10 !rounded-full !bg-pl-grayscale-white p-1 flex justify-center"
                            }
                            btntype="default"
                            minimal
                            $shadow={true}
                            disabled={pageNumber.value === totalPages() - 1}
                            onClick={() => pageNumber.set(pageNumber.value + 1)}
                          >
                            <ArrowRight
                              className={`w-4 h-4 ${
                                pageNumber.value === totalPages() - 1
                                  ? "text-pl-grayscale-lightgray cursor-default"
                                  : ""
                              } `}
                            />
                          </Button>
                        </S.ArrowContainer>
                      </S.ArrowsSection>
                    </BlurredContainer>
                    </S.WrapperArea>
                }
              </>
            }
          </>
        )}
      </WidgetContainer>
    </>
  );
}
