import {apiDelete, apiFetch, apiPost, apiPut} from "./utils";
import {FilterStatus, Team} from "../model/employee";
import {objKeysAsList} from "../utils/url";
import {Department, DepartmentFormData} from "../model/department";
import lexi from "./lexi";

type DepartmentProjections = {
  employees?: boolean,
  skills?: boolean,
  teams?: boolean,
  kpis?: boolean

}

class DepartmentService {

  private getProjections(projections?: DepartmentProjections) {
    return projections
      ? {projections: objKeysAsList(projections)}
      : undefined;
  }

  getDepartment(id: number, projections?: DepartmentProjections, excludeCache?: boolean) {
    const query = this.getProjections(projections);
    if (excludeCache) {
      return apiFetch<Department>(`/api/xhr/departments/${id}`, query, {cache: 'no-cache'});
    } else {
      return apiFetch<Department>(`/api/xhr/departments/${id}`, query);
    }
  }

  getActiveDepartments() {
    return apiFetch<Department[]>(`/api/xhr/departments`, {departmentStatus: FilterStatus.ACTIVE});
  }

  getInactiveDepartments() {
    return apiFetch<Department[]>(`/api/xhr/departments`, {departmentStatus: FilterStatus.INACTIVE});
  }

  getAllDepartments(projections?: DepartmentProjections) {
    const query = this.getProjections(projections);
    return apiFetch<Department[]>(`/api/xhr/departments`, query, {cache: 'no-cache'});
  }

  saveDepartment(departmentFormData: DepartmentFormData) {
    if (departmentFormData.id === 0) {
      return apiPost<Department>(`/api/xhr/departments`, departmentFormData);
    } else {
      return apiPut<Department>(`/api/xhr/departments/${departmentFormData.id}`, departmentFormData);
    }
  }

  updateDepartmentStatus(id: number, enable: boolean) {
    return apiPut<Department>(`/api/xhr/departments/${id}/status`, enable);
  }


  updateMultipleDepartmentsStatus(ids: number[], enable: boolean) {
    if (enable) {
      return apiPut<Department>(`/api/xhr/departments/status/activate`, ids);
    } else {
      return apiPut<Department>(`/api/xhr/departments/status/deactivate`, ids);
    }
  }

  getActivitiesByDepartmentTeams(teamsIds: number[]): Promise<number[]> {
    const numDays = 30;
    return lexi
      .getQuery('daily')
      .count("EVENT_COUNT")
      .start.daysAgo(numDays)
      .forTeamsNotGrouped(teamsIds)
      .fetchAsSingleSeries();
  }

  deleteMultipleDepartment(ids: number[]) {
    const queryParams = ids.map(id => `ids=${id}`).join('&');
    const url = `/api/xhr/departments?${queryParams}`;
    return apiDelete<Department>(url);
  }
}

export default new DepartmentService();