import React from "react";
import Dropdown from "components/generics/Dropdown";
import SubmenuItem from "components/generics/SubmenuItem";
import {SubmenuProps} from "model/submenu";
import organizationService from "services/organizationService";

import {ReactComponent as CogIcon} from "icons/cog.svg";
import {ReactComponent as WalletIcon} from "icons/wallet.svg";

import * as S from "./styles";

export type SettingsDropdownProps = {
  items: SubmenuProps[];
};

const SettingsDropdown = ({items}: SettingsDropdownProps) => (
  <S.Wrapper>
    <Dropdown
      title={
        <S.Title>
          <CogIcon aria-hidden="true"/>
        </S.Title>
      }
      direction="left"
    >
      <S.MenuWrapper>
        {items.map((item, index) => (
          <S.MenuItem key={index} className={`${index == 0 ? "hover:rounded-t-2xl" : ""}`}>
            <SubmenuItem
              label={item.label}
              path={item.path}
              description={item.description}
              icon={item.icon}
              action={item.action}
            />
          </S.MenuItem>
        ))}
        <S.MenuItem key={'billing'} className={"hover:rounded-b-2xl"}>
          <SubmenuItem
            label={"Manage Subscription"}
            action={() => organizationService.getBillingUrl()}
            path={"#"}
            icon={<WalletIcon/>}
          />
        </S.MenuItem>

      </S.MenuWrapper>
    </Dropdown>
  </S.Wrapper>
);

export default SettingsDropdown;
