import {Organization, OrganizationFormData} from "model/organization";
import Breadcrumbs from "components/generics/Breadcrumbs";
import React from "react";
import {useState} from "@hookstate/core";
import {SelectSearchOption} from "components/utils/SelectSearch";
import organizationService from "services/organizationService";
import {LoadingWidget} from "components/utils/LoadingWidget";
import {OrganizationDetailsForm} from "./OrganizationDetailsForm";
import {OrganizationLocationsList} from "./OrganizationLocationsList";
import {Location} from "model/employee";
import {HrisSync, Integration} from "model/integration";
import {OrganizationSyncComponent} from "./manageHrisSync/OrganizationSyncComponent";
import integrationService from "services/integrationService";
import {OrganizationPrivacyControls} from "./managePrivacy/OrganizationPrivacyControls";

import * as S from "./styles";


export default function OrganizationForm({
                                           organization,
                                           locations
                                         }: {
  organization: Organization;
  locations: Location[];
}) {
  const organizationState = useState({
    organizationInfo: {
      id: organization ? organization.id : null,
      name: organization && organization.name ? organization.name : '',
      domain: organization && organization.domain ? organization.domain : '',
      industry: organization && organization.category ? organization.category : '',
      numEmployees: organization && organization.employeeSize ? organization.employeeSize : '',
      startTime: organization && organization.startTime ? organization.startTime : '',
      endTime: organization && organization.endTime ? organization.endTime : '',
      timezone: organization && organization.timeZone ? organization.timeZone : '',
    } as OrganizationFormData,
  });

  const organizationData =
    useState<[SelectSearchOption[], SelectSearchOption[], SelectSearchOption[], HrisSync, Integration[]]>(() => Promise.all([
      organizationService.getIndustryOptions(),
      organizationService.getNumEmployeesOptions(),
      organizationService.getTimezones(),
      integrationService.getHrisSync(),
      integrationService.getHrisEnabled()
    ]));

  return (
    <>
      <Breadcrumbs
        items={[
          {label: "Organizational Settings", url: `/settings/organization`},
        ]}
      />
      <LoadingWidget<[SelectSearchOption[], SelectSearchOption[], SelectSearchOption[], HrisSync, Integration[]]>
        value={organizationData}
        builder={([industries, numEmployees, timezones, hrisSync, hrisEnabled]) => {
          return (
            <S.MainWrapper>
              <OrganizationDetailsForm industries={industries}
                                       numEmployees={numEmployees}
                                       timezones={timezones}
                                       organization={organizationState.organizationInfo.value}
              />
              <OrganizationPrivacyControls organization={organization}
              />
              <OrganizationSyncComponent hrisSync={hrisSync}
                                         hrisEnabled={hrisEnabled}
              />
              <OrganizationLocationsList timezones={timezones}
                                         locations={locations}
              />
            </S.MainWrapper>
          )
        }}
      />
    </>
  );
}