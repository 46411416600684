import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import DataTableFilters, {Filters,} from "components/utils/Datatable/DataTableFilters";
import {useState} from "@hookstate/core";
import dashboardsService from "services/dashboards";
import {LoadingWidget} from "components/utils/LoadingWidget";
import {Dashboard} from "model/dashboards";
import {getAvatar, getDataTableColumns,} from "components/utils/Datatable/DataTableHelper";
import DataTable from "components/utils/Datatable/DataTable";
import {contains} from "utils/text";
import DashboardModal, {
  DashboardModalState,
  openAddDashboardModal,
  openEditDashboardModal,
} from "../insightsDashboard/manageDashboard/DashboardModal";
import {ConfirmationModal, openConfirmationModal,} from "components/utils/Modal/ConfirmationModal";
import Button from "components/generics/Button";
import TabTitle from "components/structure/TabTitle";
import Breadcrumbs from "components/generics/Breadcrumbs";
import useDashboard from "hooks/useDashboard";
import {useBulkSelectDashboard} from "hooks/useBulkSelect";
import {DashboardsTableData, DatatableColumn} from "model/datatable";
import {Employee} from "model/employee";

import {ReactComponent as EditIcon} from "icons/edit.svg";
import {ReactComponent as CopyIcon} from "icons/copy.svg";
import {ReactComponent as TrashIcon} from "icons/trash.svg";
import {ReactComponent as DashboardIcon} from "icons/dashboard.svg";
import {ReactComponent as Lock} from "icons/lock.svg";

import * as S from "./styles";

export default function Dashboards({ me }: { me: Employee }) {
  TabTitle("Dashboards");

  const dashboards =
    useState<Dashboard[]>(() => dashboardsService.getDashboards());

  return (
    <>
      <DashboardModal />
      <ConfirmationModal />
      <Breadcrumbs
        items={[
          { label: "Insights", url: `/insights/dashboards` },
          { label: "List View", url: `/insights/dashboards` },
        ]}
      />
      <div className="w-full">
        <LoadingWidget<Dashboard[]>
          value={dashboards}
          builder={(dashboardList) => (
            <DashboardList dashboardList={dashboardList} />
          )}
        />
      </div>
    </>
  );
}

function DashboardList({
  dashboardList,
}: {
  dashboardList: Dashboard[]
}) {
  // const { globalDashboards } = useDashboard();
  const dashState = useState<Dashboard[]>(
    dashboardList
  );
  const filteredDashboards = useState<any[]>([]);
  const selectedItems = useState<Dashboard[]>([]);
  const hasClearSelection = useState(false);

  // useEffect(() => {
  //   globalDashboards.set(
  //     [...dashState.value].sort(
  //       (a, b) =>
  //         new Date(b.updated!).getTime() - new Date(a.updated!).getTime()
  //     )
  //   );
  // }, [dashboardList]);

  const state = useState({
    info: {
      searchWord: "",
    } as Filters,
  });

  useEffect(() => {
    const nfd =  dashboardList.filter((dashboard) => {
      if (state.info.searchWord.get().length > 0) {
        return contains(dashboard.name, state.info.searchWord.get());
      }
      return true;
    });
    filteredDashboards.set(getData(nfd));
  }, [state.info.searchWord]);

  const columns: DatatableColumn<any>[] = [
    {
      id: "dashboardName",
      accessor: "dashboardName",
      Header: "Dashboard Name",
      customRenderer: "dashboardNameRender",
    },
    {
      id: "owner",
      accessor: "owner",
      Header: "Owner",
      customRenderer: "ownerRender",
    },
    {
      id: "description",
      accessor: "description",
      Header: "Description",
      customRenderer: "descriptionRender",
    },
    {
      id: "numReports",
      accessor: "numReports",
      Header: "# of Reports",
    },
    {
      id: "actions",
      accessor: "actions",
      Header: "Actions",
      disableSortBy: true,
      customRenderer: "actionsRender"
    },
  ];

  function handleEdit() {
    const persistedDashboard = DashboardModalState.dashboard.value;

    dashboardsService.saveDashboard(persistedDashboard).then((persisted) => {
      if (persisted) {
        if (dashState.find((dash) => dash.id.value === persisted.id)) {
          dashState
            .find((dash) => dash.id.value === persisted.id)
            ?.set((p) => ({ ...persisted }));
        } else {
          dashState.merge([persisted]);
        }
      }
    });
  }

  function handleDelete(id: number) {
    dashboardsService.deleteDashboard(id).then(() => {
      dashState.set((dashArray) => dashArray.filter((dash) => dash.id !== id));
    });
  }

  function getActions(dashboard: Dashboard) {
    return (
      <>
        <div className={"flex items-center gap-2"}>
          <Button
            minimal
            btntype={"neutral"}
            $shadow={false}
            onClick={() => {
              openAddDashboardModal(dashboard, () => handleEdit());
            }}
          >
            <CopyIcon />
          </Button>
          <Button
            minimal
            btntype={"neutral"}
            $shadow={false}
            onClick={() => {
              openEditDashboardModal(dashboard, () => handleEdit());
            }}
          >
            <EditIcon />
          </Button>
          <Button
            minimal
            btntype={"neutral"}
            $shadow={false}
            onClick={() => {
              openConfirmationModal(
                "Are you sure you want to delete this dashboard?",
                "Delete Dashboard",
                () => handleDelete(dashboard.id)
              );
            }}
          >
            <TrashIcon />
          </Button>
        </div>
      </>
    );
  }

  const onRowSelection = React.useCallback((value) => {
    selectedItems.set(value);
  }, []);

  const { deleteSelectedDashboards } = useBulkSelectDashboard();

  const getBulkActions = () => {
    const selectedIds = selectedItems.value.map((t) => t.id);

    return [
      {
        label: "Delete",
        action: () => {
          deleteSelectedDashboards(selectedIds, dashState, () =>
            hasClearSelection.set(!hasClearSelection.value)
          );
        },
      },
    ];
  };

  function getDashboardUrl(dashboard: Dashboard) {
    return (
      <>
        <S.DashFieldWrapper>
          <Link
            to={`/insights/dashboards/${dashboard.id}`}
            key={`dashboard-button-${dashboard.id}`}
            className={"font-bold text-base text-pl-grayscale-black"}
          >
            {dashboard.name}
          </Link>
          {
            !dashboard.publicDash && <Lock className="w-4 h-4 text-pl-grayscale-midgray" />
          }
        </S.DashFieldWrapper>
      </>
    );
  }

  function getData(dashboardList: Dashboard[]): any[] {
    let rows: DashboardsTableData[] = [];
    dashboardList.map((dashboard) => {
      return rows.push({
        id: dashboard.id,
        dashboardName: dashboard.name.toLowerCase().trim(),
        dashboardNameRender: () => getDashboardUrl(dashboard),
        owner: dashboard.owner ? dashboard.owner.toLowerCase().trim() : "",
        ownerRender:
          () => dashboard.owner && dashboard.ownerId ? (
            getAvatar(
              dashboard.owner,
              `/employees/${dashboard.ownerId}`,
              "",
              true,
              "",
              true,
              true
            )
          ) : (
            <></>
          ),
        description: dashboard.description ? dashboard.description : "",
        descriptionRender: () => descriptionRender(
          dashboard.description ? dashboard.description : ""
        ),
        numReports: dashboard.dashboardQueries.length,
        actions: dashboard.id,
        actionsRender: () => getActions(dashboard)
      });
    });
    return rows;
  }

  const descriptionRender = (children: any) => {
    return (
      <div>
        <p className={`text-pl-grayscale-gray text-sm font-normal`}>
          {children}
        </p>
      </div>
    );
  };

  return (
    <>
      <div className="rounded-md w-full">
        <DataTableFilters
          title={"Dashboards List"}
          type={"dashboard"}
          titleIcon={<DashboardIcon className={"text-pl-grayscale-gray"} />}
          titleSize={"large"}
          addLabel={"New Dashboard"}
          state={state.info}
          selectedItems={selectedItems.value}
          bulkActions={getBulkActions()}
          onAdd={() => openAddDashboardModal(undefined, () => handleEdit())}
        />
        <DataTable
          columns={getDataTableColumns(columns)}
          data={filteredDashboards.get({noproxy: true})}
          pageLength={10}
          onRowSelection={onRowSelection}
          hasClearSelection={hasClearSelection.value}
        />
      </div>
    </>
  );
}
