export default {
  labels: [
    "Email Sent",
    "Contact Updated",
    "Issue Updated",
    "Account Updated",
    "Opportunity Updated",
    "Others",
  ],
  datasets: [
    {
      label: "first dataset",
      data: [21, 6, 33, 9, 21, 10],
    },
    {
      label: "second dataset",
      data: [31, 3, 23, 12, 11, 20],
    },
    {
      label: "third dataset",
      data: [11, 36, 3, 29, 1, 20],
    },
    {
      label: "fourth dataset",
      data: [1, 21, 53, 3, 6, 13],
    },
  ],
};
