import tw from "tailwind-styled-components";
import { ButtonProps } from ".";

type ButtonWrapperProps = {
  fullwidth?: number;
  minimal?: number;
} & Omit<ButtonProps, "fullwidth" | "minimal">;

const wrapperModifiers = {
  small: `
    h-[32px]
    text-xs
    py-1.5
    px-3
    min-w-[50px]
  `,

  medium: `
    h-[40px]
    text-sm
    py-2
    px-4
    min-w-[90px]
  `,

  large: `
    h-[55px]
    min-w-[120px]
    text-md
    py-4
    px-6
  `,

  fullwidth: `
    w-full
  `,

  minimal: (type: string) => `
    !bg-transparent
    border-none
    hover:bg-transparent
    h-[fit-content]
    !min-w-[fit-content]
    !p-0

    ${
      type === "primary"
        ? "!text-pl-primary-green-default"
        : type === "error"
        ? "!text-pl-primary-red-default"
        : type === "warning"
        ? "!text-pl-primary-orange-default"
        : type === "neutral"
        ? "!text-pl-grayscale-gray"
        : "!text-pl-grayscale-black"
    }
  `,

  disabled: (minimal: number) => `
    disabled:cursor-not-allowed
    disabled:saturate-[30%]

    ${!!minimal ? "disabled:!text-pl-grayscale-midgray" : ""}
  `,

  primary: `
    text-pl-grayscale-white
    bg-pl-primary-green-default
    border-pl-primary-green-default
    hover:bg-pl-primary-green-light
    hover:text-pl-primary-green-default
  `,

  error: `
    text-pl-grayscale-white
    bg-pl-primary-red-default
    border-pl-primary-red-default
    hover:bg-pl-primary-red-light
    hover:text-pl-primary-red-default
  `,

  warning: `
    text-pl-grayscale-white
    bg-pl-primary-orange-default
    border-pl-primary-orange-default
    hover:bg-pl-primary-orange-light
    hover:text-pl-primary-orange-default
  `,

  default: `
    bg-pl-grayscale-white
    text-pl-grayscale-black
    border-pl-grayscale-midgray
    hover:bg-pl-grayscale-lightgray
  `,

  icon: {
    right: `
      flex-row-reverse
    `,

    left: `
      flex-row
    `,
  },
};

export const Wrapper = tw.button<Omit<ButtonWrapperProps, "children">>`
  ${({ size, fullwidth, minimal, btntype, iconposition, disabled, $shadow }) => `
    flex
    items-center
    justify-center
    rounded-lg
    gap-2

    relative
    cursor-pointer
    overflow-hidden
    transition-smooth
    no-underline
    border
    border-solid
    font-semibold

    ${!!size && wrapperModifiers[size!]};
    ${!!btntype ? wrapperModifiers[btntype!] : wrapperModifiers.primary};
    ${fullwidth && wrapperModifiers.fullwidth};
    ${minimal && wrapperModifiers.minimal(btntype || "default")};
    ${disabled && wrapperModifiers.disabled(minimal || 0)}
    ${
      !!iconposition
        ? wrapperModifiers.icon[iconposition]
        : wrapperModifiers.icon.right
    }
    ${!!$shadow && "shadow"}
  `}
`;

const iconWrapperModifiers = {
  small: `
    h-3
    w-4
  `,

  medium: `
    h-4
    w-5
  `,

  large: `
    h-5
    w-6
  `,
};

export const IconWrapper = tw.div<Pick<ButtonWrapperProps, "size">>`
  ${({ size }) => `
    flex
    items-center
    justify-center

    ${!!iconWrapperModifiers && iconWrapperModifiers[size!]}
  `}
`;
