import tw from "tailwind-styled-components";

export const Wrapper = tw.div``;

export const TitleWrapper = tw.div`
  flex
  items-center
  gap-4
`;

export const SelectedWrapper = tw.div`
  flex
  items-center
  gap-4
`;

export const SelectedItemsNumber = tw.div`
  text-pl-grayscale-gray
  border-l
  pl-4
`;
