import tw from "tailwind-styled-components";

type WrapperProps = {
  collapsed?: number;
};

export const Wrapper = tw.div`
  bg-pl-grayscale-white
  rounded-2xl
  border
  border-solid
  border-pl-grayscale-lightgray
`;

export const WidgetWrapper = tw.div`
  p-6
  flex
  flex-col
  gap-3
`;

export const WidgetHeader = tw.div`
  flex
  items-center
  justify-between
  gap-4
`;

export const WidgetRightHeader = tw.div<{ isShuffledComponent: boolean }>`
  ${({isShuffledComponent}) => `
    flex
    items-center
    justify-between
    ${isShuffledComponent ? "" : "w-full"}
  `}
`;

export const BadgeWrapper = tw.div`
  flex
  items-center
  gap-2
`;

export const ActionWrapper = tw.div`
  flex
  items-center
  justify-end
  w-full
  gap-5
  min-h-[fit-content]
`;

export const ActionArea = tw.div`
  h-8
  w-full
  cursor-pointer
`;

const WidgetContentModifiers = {
  notCollapsed: `
    absolute
    opacity-0
    !max-h-0
  `,
};

export const WidgetContent = tw.div`
  flex
  flex-col
  gap-2
  cursor-pointer
  relative
`;

export const Title = tw.div`
  text-xl
  font-semibold
`;

export const Description = tw.div<WrapperProps>`
  ${({ collapsed }) => `
    text-sm
    text-pl-grayscale-gray
    flex
    items-end
    relative
    top-0
    transition
    transition-all
    duration-300

    ${!collapsed && WidgetContentModifiers.notCollapsed}
  `}
`;

export const WidgetFooter = tw.div`
  flex
  items-center
  justify-between
  relative
`;

export const AvatarWrapper = tw.div`
  flex
  items-center
  gap-2
  text-sm
  font-semibold
  cursor-pointer
`;

export const FeedbackWrapper = tw.div<{ showFeedbackWrapper: boolean }>`
  ${({showFeedbackWrapper}) => `
    flex
    items-center
    justify-between
    absolute
    right-0
    bottom-[-60px]
    w-full
    ${showFeedbackWrapper ? "" : "hidden"}
  `}
`;

export const FeedbackActions = tw.div`
  flex
  items-center
`;

export const FeedbackButton = tw.div`
  bg-pl-variant-beige
  p-2
  rounded-full
`;

export const FeedbackQuestion = tw.div`
  text-pl-grayscale-gray
  mt-4
  text-sm
`;

export const feedbackBtnStyles = `
  !rounded-full
  !min-w-0
  w-10
  !h-10
  !bg-pl-grayscale-white
  hover:!text-pl-grayscale-white
`;

export const positiveFeedbackBtnStyles = `
  !bg-pl-primary-green-default
  cursor-default
`;

export const negativeFeedbackBtnStyles = `
  !bg-pl-primary-red-default
  cursor-default
`;

export const btnSuccessStyles = `
  hover:!bg-pl-primary-green-default
`;

export const btnErrorStyles = `
  hover:!bg-pl-primary-red-default
`;
