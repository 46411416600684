import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "routes/home";
import EmployeePage from "routes/employee";
import TeamPage from "routes/team";
import Chat from "components/ui/Lexichat/Chat";
import InsightsDashboard from "routes/insightsDashboard";
import Employees from "routes/employees/Employees";
import Teams from "routes/teams/Teams";
import Departments from "routes/departments/Departments";
import RecommenderPersonalization from "routes/recommendation";
import Dashboards from "routes/dashboards/Dashboards";

import UserPreferences from "routes/user";
import EmployeeImporter from "routes/employeeImporter";
import { Integrations } from "routes/integrations/Integrations";
import { InstalledIntegrations } from "routes/integrations/InstalledIntegrations";
import { IntegrationRedirect } from "routes/integrationForm/IntegrationRedirect";
import Mixpanel from "services/mixpanel";
import { Employee } from "model/employee";
import DepartmentPage from "routes/department";
import OrganizationFormPage from "routes/organizationForm";
import IntegrationsMapper from "routes/integrationsMapper";
import {useState} from "@hookstate/core";

export default function Content({me}: { me?: Employee }) {
  const gradientBg = useState("");

  const location = useLocation();
  useEffect(() => {
    Mixpanel.track("Clicked Link", {Path: location.pathname});
  }, [location]);

  useEffect(() => {
    let condition = location.pathname;
    if (location.pathname.split("/").length > 2) {
      const index = location.pathname.indexOf("/", 1);
      condition = location.pathname.substring(0, index);
    }

    switch (condition) {
      case "/":
      case "/integrations":
        gradientBg.set("bg-home");
        break;

      case "/teams":
        gradientBg.set("bg-team");
        break;

      case "/profile":
      case "/employees":
        gradientBg.set("bg-profile");
        break;

      case "/departments":
        gradientBg.set("bg-department");
        break;

      default:
        gradientBg.set("#E5E5E5");
        break;
    }
  }, [location.pathname]);

  return (
    <>
      <main
        className={`pb-8 h-auto min-h-screen pt-16 bg-pl-variant-beige ${gradientBg.value} bg-no-repeat bg-contain bg-scroll`}
      >
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <Routes>
            <Route path="/settings/organization" element={ <OrganizationFormPage/> } />
            <Route path="/settings/recommendations" element={ <RecommenderPersonalization/> } />
            <Route path="/settings/recommendations/settings" element={ <RecommenderPersonalization/> } />
            <Route path="/departments/:id/:tab" element={ <DepartmentPage/> } />
            <Route path="/departments/:id/" element={ <DepartmentPage/> } />
            <Route path="/departments" element={ <Departments/> } />
            <Route path="/teams/:id/:tab" element={ <TeamPage/> } />
            <Route path="/teams/:id/" element={ <TeamPage/> } />
            <Route path="/teams" element={ <Teams/> } />
            <Route path="/employees/:id/preferences" element={ <UserPreferences/> } />
            <Route path="/employees/import" element={ <EmployeeImporter/> } />
            <Route path="/employees/:id" element={ <EmployeePage/> } />
            <Route path="/employees" element={ <Employees/> } />
            <Route path="/integrations/mapper" element={ <IntegrationsMapper/> } />
            <Route path="/integrations/add" element={ <InstalledIntegrations/> } />
            <Route path="/integrations/:type/authorize" element={ <IntegrationRedirect/> } />
            <Route path="/integrations" element={ <Integrations/> } />
            <Route path="/insights/dashboards/:id" element={ <InsightsDashboard/> } />
            <Route path="/insights/dashboards" element={ me ? <Dashboards me={me}/> : <></> } />
            <Route path="/insights" element={ <Chat/> } />
            <Route path="/" element={ <Home/> } />
          </Routes>
        </div>
      </main>
    </>
  );
}
