import React, { ButtonHTMLAttributes } from "react";
import * as S from "./styles";

export type ButtonProps = {
  size?: "small" | "medium" | "large";
  fullwidth?: boolean;
  as?: React.ElementType;
  minimal?: boolean;
  btntype?: "default" | "error" | "primary" | "warning" | "neutral";
  icon?: React.ReactNode;
  iconposition?: "left" | "right";
  $shadow?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({
  size = "medium",
  fullwidth = false,
  minimal = false,
  btntype = "default",
  icon,
  iconposition,
  $shadow = false,
  children,
  ...props
}: ButtonProps) => (
  <S.Wrapper
    size={size}
    fullwidth={fullwidth ? 1 : 0}
    minimal={minimal ? 1 : 0}
    btntype={btntype}
    iconposition={(!!icon && iconposition) || "right"}
    $shadow={$shadow}
    {...props}
  >
    {!!icon && <S.IconWrapper size={size}>{icon}</S.IconWrapper>}
    {children}
  </S.Wrapper>
);

export default Button;
