import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
    relative
    h-full
`;

export const ContentContainer = tw.div`
    relative
    bg-pl-grayscale-white
    py-6
    px-2
    space-y-6
    sm:p-6
    h-full
`;

export const SettingsGridContainer = tw.div`
    relative
    grid
    grid-cols-1
    md:grid-cols-3
    gap-4
    justify-center
    content-start
`;

export const SettingsItem = tw.div`
    cols-span-1
    mt-2
    gap-1
    grid
    grid-rows-2
    align-self-end
`;

export const SettingsTextField = tw.div`
`;

export const RulebookHeader = tw.div`
    bg-pl-grayscale-white
    py-1 px-4
    space-y-2
    sm:p-4
`;

export const RulebooksContainer = tw.div`
    bg-pl-grayscale-white
    py-5
    px-4
    space-y-1
    sm:p-2
`;

export const RulebookGridContainer = tw.div`
    grid
    grid-cols-1
    md:grid-cols-2
`;

export const RulebookItem = tw.div`
    col-span-1
`;

export const RecommendationFormContainer = tw.div`
    relative
    lg:grid
    lg:grid-cols-12
    bg-pl-grayscale-white
    flex
    px-2
    py-2
    rounded-2xl
    shadow
    my-4
`;

export const SideTabsContainer = tw.aside`
    py-4
    px-2
    sm:px-6
    lg:py-4
    lg:px-2
    lg:col-span-2
`;

export const SideTabs = tw.aside`
    space-y-3
    border-r-[1px]
    border-pl-grayscale-lightgray
    h-full
`;

export const TabContainer = tw.div`
`;

export const TabIcon = tw.div`
`;

export const TabTitle = tw.div`
    flex
    flex-col
`;

export const ContentWrapper = tw.div`
    relative
    space-y-6
    sm:px-6
    lg:px-0
    lg:col-span-10
`;

export const SettingTopSection = tw.label`
    flex
    justify-between
`;

export const SettingLabel = tw.label`
    pl-input-label
    break-normal
`;

export const SettingDescription = tw.label`
    block
    text-pl-grayscale-gray
    text-xs
    font-normal
    py-1
`;

export const RulebookTitle = tw.h3`
    text-sm
    leading-6
    font-normal
    text-pl-grayscale-gray
`;

export const HighlightedTab = tw.span`
    text-sm
    leading-6
    text-pl-primary-green-default
`;

export const ToolTipContainer = tw.div`
    flex
    flex-col
    group
    relative
    self-end
    pl-6
    items-center
`;

export const ToolTip = tw.div`
    w-72
    bottom-10
    pointer-events-none
    absolute
    origin-center
    opacity-0
    transition
    group-hover:opacity-100
`;

export const ButtonsWrapper = tw.div`
  content-end
  items-end
  self-end
  justify-end
  flex
`;