import React from "react";
import * as S from "./styles";
import Badge from "../Badge";

export type GenericCardProps = {
  title?: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  height?: number;
  width?: number;
  badgeText?: string
};

const GenericCard = ({
  title,
  icon,
  children,
  height = 0,
  width = 0,
  badgeText,
}: GenericCardProps) => (
  <S.Wrapper
    style={{
      width: width || "100%",
      height: height || "auto",
    }}
  >
    <S.TitleWrapper>
      <S.IconWrapper>{icon}</S.IconWrapper>
      {title}
      {
        badgeText && <Badge label={badgeText} size="large" type={"critical"} rounded className={"h-[30px]"}/>
      }
    </S.TitleWrapper>

    <S.ContentWrapper>{children}</S.ContentWrapper>
  </S.Wrapper>
);

export default GenericCard;
