import {ChartLabelValuePair, GenericChartData} from "./chart";
import { Employee, Role } from "./employee";
import { KeyMetric } from "./keymetrics";
import { Feedback, Recommendation, SeverityType } from "./recommendations";

export const SAMPLE_FOCUSED_HOURS: ChartLabelValuePair[] = [
  { label: "9am", value: 5 },
  { label: "10am", value: 6 },
  { label: "11am", value: 9 },
  { label: "12pm", value: 5 },
  { label: "1pm", value: 2 },
  { label: "2pm", value: 1 },
  { label: "3pm", value: 8 },
  { label: "4pm", value: 7 },
  { label: "5pm", value: 2 },
  { label: "6pm", value: 5 },
  { label: "7pm", value: 2 },
  { label: "8pm", value: 1 },
];

export const SAMPLE_FOCUS_AREA: ChartLabelValuePair[] = [
  { label: "Email Sent", value: 60 },
  { label: "Contact Updated", value: 55 },
  { label: "Issue Updated", value: 50 },
  { label: "Account Updated", value: 48 },
  { label: "Opportunity Updated", value: 38 },
  { label: "Pipeline Executions Failed", value: 35 },
  { label: "Pull Request Opened", value: 35 },
  { label: "Issues Resolved", value: 28 },
  { label: "Opportunity Created", value: 23 },
  { label: "Tasks Added", value: 18 },
  { label: "Others", value: 150 },
];

export const SAMPLE_KEY_METRICS: KeyMetric[] = [
  {
    kpiName: "Pull Request Opened",
    eventName: "NEW_PR_EVENTS",
    smallerIsBetter: false,
    value: 21,
    percentage: -7,
  },
  {
    kpiName: "Pipeline Executions Failed",
    eventName: "PIPELINE_FAILED",
    smallerIsBetter: true,
    value: 16,
    percentage: 7,
  },
  {
    kpiName: "Issues Resolved",
    eventName: "ISSUE_RESOLVED",
    smallerIsBetter: false,
    value: 5,
    percentage: 0
  },
  {
    kpiName: "Issues Created",
    eventName: "ISSUE_CREATED",
    smallerIsBetter: false,
    value: 10,
    percentage: 8
  },
  {
    kpiName: "Tasks Added",
    eventName: "TASK_ADDED",
    smallerIsBetter: false,
    value: 15,
    percentage: -3
  },
  {
    kpiName: "Demo Missed",
    eventName: "DEMO_MISSED",
    smallerIsBetter: true,
    value: 2,
    percentage: -15
  }
];

export const SAMPLE_EMPLOYEE: Employee[] = [{
  id: 1,
  name: "Matthew Schmidt",
  welcomeName: "Matthew Schmidt",
  role: Role.ADMIN,
  email: "matt@peoplelogic.ai",
  active: true,
  organizationName: "Peoplelogic.ai",
  jobTitle: "CEO",
  linkedin: "www.linkedin.com/matt"
}, {
  id: 2,
  name: "Hernâni Cerqueira",
  welcomeName: "Hernâni Cerqueira",
  role: Role.ADMIN,
  email: "hernani@peoplelogic.ai",
  active: true,
  organizationName: "Peoplelogic.ai",
  jobTitle: "CTO",
  linkedin: "www.linkedin.com/hernani"
}, {
  id: 3,
  name: "Jonathan Bruce",
  welcomeName: "Jonathan Bruce",
  role: Role.ADMIN,
  email: "jon@peoplelogic.ai",
  active: true,
  organizationName: "Peoplelogic.ai",
  jobTitle: "CPO",
  linkedin: "www.linkedin.com/jon"

}]

export const SAMPLE_RECOMMENDATIONS: Recommendation[] = [
  {
    id: 1,
    severity: SeverityType.CRITICAL,
    title: "Hold A 1:1 With Your Team Members",
    summaryText:
      "We've noticed that at least one of your teams has a lower average tenure than the broader organization over the last quarter. See the details below.<br/><br/><table><tr><th></th><th>Team Tenure</th><th>Org Tenure</th></tr><tr><td>Executive (Dev): </td><td>5 years</td><td>13 years</td></tr><tr><td>Operations: </td><td>2 years</td><td>13 years</td></tr><tr><td> : </td><td>2 years</td><td>13 years</td></tr><tr><td>Lexi App: </td><td>2 years</td><td>13 years</td></tr><tr><td>Customized Sales: </td><td>2 years</td><td>13 years</td></tr><tr><td>Sales: </td><td>5 years</td><td>13 years</td></tr><tr><td>Team 1: </td><td>0 years</td><td>13 years</td></tr><tr><td>TESTTTT: </td><td>8 years</td><td>13 years</td></tr><tr><td>Sales And Marketing Team: </td><td>1 year</td><td>13 years</td></tr></table>",
    lastVerified: new Date("2022-06-07 18:26:42"),
    employeeId: -2,
    feedback: Feedback.NO_FEEDBACK,
  },
  {
    id: 2,
    severity: SeverityType.WARNING,
    title: "Nulla facilisi. Ut consectetur ligula vel lorem luctus",
    summaryText:
      "Aliquam luctus laoreet nunc, eu blandit tortor posuere at. Duis finibus nisi metus, pulvinar luctus nulla dapibus a. Integer efficitur quam vel dolor consequat, at fringilla tellus faucibus. Fusce eget velit imperdiet, bibendum dolor quis, malesuada ipsum. Aenean rhoncus hendrerit orci, vel egestas arcu tincidunt ut.",
    lastVerified: new Date(),
    employeeId: -3,
    feedback: Feedback.NO_FEEDBACK,
  },
  {
    id: 3,
    severity: SeverityType.INFO,
    title: "Vivamus orci mauris, vestibulum in posuere nec",
    summaryText:
      "Mauris lobortis magna et lorem volutpat, id convallis erat porta. Aliquam id purus vitae neque suscipit vestibulum at id orci. ",
    lastVerified: new Date("2022-01-23 18:26:42"),
    employeeId: -2,
    feedback: Feedback.NO_FEEDBACK,
  },
  {
    id: 4,
    severity: SeverityType.CRITICAL,
    title: "Consectetur adipiscing elit",
    summaryText:
      "Holding a one on one is critical to the success of your team.  This is your opportunity to build trust with your team members and address any potential blockers quickly. ",
    lastVerified: new Date("2022-06-07 18:26:42"),
    employeeId: -2,
    feedback: Feedback.NO_FEEDBACK
  },
  {
    id: 4,
    severity: SeverityType.INFO,
    title: "Vivamus orci mauris, vestibulum in posuere nec",
    summaryText:
      "Holding a one on one is critical to the success of your team.  This is your opportunity to build trust with your team members and address any potential blockers quickly. ",
    lastVerified: new Date("2022-01-23 18:26:42"),
    employeeId: -2,
    feedback: Feedback.NO_FEEDBACK,
  },
  {
    id: 5,
    severity: SeverityType.CRITICAL,
    title: "Aliquam nec lectus eu justo suscipit sollicitudin",
    summaryText:
      "Holding a one on one is critical to the success of your team.  This is your opportunity to build trust with your team members and address any potential blockers quickly. ",
    lastVerified: new Date("2021-11-23 18:26:42"),
    employeeId: -2,
    feedback: Feedback.NO_FEEDBACK,
  },
  {
    id: 6,
    severity: SeverityType.INFO,
    title: "Donec at porta mauris, quis semper libero",
    summaryText:
      "Holding a one on one is critical to the success of your team.  This is your opportunity to build trust with your team members and address any potential blockers quickly. ",
    lastVerified: new Date("2021-10-07 08:26:42"),
    employeeId: -1,
    feedback: Feedback.HELPFUL,
  },
  {
    id: 7,
    severity: SeverityType.INFO,
    title: "Lorem ipsum dolor sit amet",
    summaryText:
      "Holding a one on one is critical to the success of your team.  This is your opportunity to build trust with your team members and address any potential blockers quickly. ",
    lastVerified: new Date("2022-06-07 18:26:42"),
    employeeId: -2,
    feedback: Feedback.NO_FEEDBACK,
  },
  {
    id: 8,
    severity: SeverityType.WARNING,
    title: "Fusce vestibulum urna odio, vel blandit metus dignissim nec",
    summaryText:
      "Holding a one on one is critical to the success of your team.  This is your opportunity to build trust with your team members and address any potential blockers quickly. ",
    lastVerified: new Date("2022-09-01 08:26:42"),
    employeeId: -3,
    feedback: Feedback.NO_FEEDBACK,
  },
  {
    id: 9,
    severity: SeverityType.CRITICAL,
    title: "Praesent eu feugiat neque, eu consectetur est",
    summaryText:
      "Holding a one on one is critical to the success of your team.  This is your opportunity to build trust with your team members and address any potential blockers quickly. ",
    lastVerified: new Date("2021-12-17 08:26:42"),
    employeeId: -3,
    feedback: Feedback.NO_FEEDBACK,
  },
  {
    id: 10,
    severity: SeverityType.WARNING,
    title: "Pellentesque habitant morbi tristique",
    summaryText:
      "Holding a one on one is critical to the success of your team.  This is your opportunity to build trust with your team members and address any potential blockers quickly. ",
    lastVerified: new Date("2022-09-01 08:26:42"),
    employeeId: -3,
    feedback: Feedback.NO_FEEDBACK,
  },
];

export const SAMPLE_STAY_FACTOR: number[] = [
  50, 20, 56, 53, 59, 62, 68, 70, 28, 85, 89, 89, 92, 48, 80, 90, 85, 35, 87,
  92, 78, 80, 93, 40, 56, 87, 92, 78, 30, 92
];

export const SAMPLE_STAY_FACTOR_SUBCOMPONENTS = {
  labels: [
    "Activity",
    "Interactions",
    "WorkLife Balance",
    "Recommendations",
    "Meetings",
  ],
  datasets: [
    {
      label: "",
      labels: [
        "Activity",
        "Interactions",
        "WorkLife Balance",
        "Recommendations",
        "Meetings",
      ],
      data: [91, 76, 53, 5, 15],
    }
  ],
} as GenericChartData;

export const SAMPLE_STAY_FACTOR_SUBCOMPONENTS_TOOLTIP = [
  "Activity|91|-5",
  "Interactions|76|12",
  "WorkLife Balance|53|38",
  "Recommendations|5|0",
  "Meetings|15|-125",
]