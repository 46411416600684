import React from "react";
import {State, useState} from "@hookstate/core";
import {DashboardQuery} from "model/dashboards";
import {DashboardModalState} from "./DashboardModal";
import {
    closestCenter,
    DndContext,
    DragOverlay
} from "@dnd-kit/core";
import {SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {HandleDragStart, HandleDragOver, HandleDragEnd, GetSensors} from "../DashboardQueriesDragFunctions";
import DashboardModalQueryComponent from "./DashboardModalQueryComponent";

import * as S from "./styles";

import { ReactComponent as PlusIcon } from "icons/plus.svg";

export default function DashboardModalQueriesTab({ errors }: { errors: State<string[]> }) {
  const dashboardState = useState(DashboardModalState.dashboard);
  const activeId = useState<string|null>(null);
  const dashboardQueryListState = useState(DashboardModalState.dashboard);

  function addQuery() {
    dashboardState.dashboardQueries.merge([
      {
        sortOrder: dashboardState.dashboardQueries.value.length,
        query: "",
        name: "",
        displayHeight: 400,
        displayWidth: 1,
      },
    ]);
  }

  return (
    <S.DashboardQueriesContainer id={'dashboard-queries-container'}>
      <DndContext
        sensors={GetSensors()}
        collisionDetection={closestCenter}
        onDragStart={event => HandleDragStart(event, activeId)}
        onDragOver={event => HandleDragOver(event, dashboardQueryListState)}
        onDragCancel={() => activeId.set(null)}
        onDragEnd={event => HandleDragEnd(event, activeId, dashboardQueryListState)}>
        <SortableContext
          items={dashboardQueryListState.dashboardQueries.map(query => ''+query.sortOrder.value)}
          strategy={verticalListSortingStrategy}>
          {
            dashboardState.dashboardQueries.map((dq, index) =>
              <DashboardModalQueryComponent key={index} dq={dq} errors={errors}/>
            )
          }
        </SortableContext>
        {
          activeId.value &&
          <DragOverlay className={"flex flex-wrap flex-row"} style={{position: "absolute", left: 0, top: (parseInt(activeId.value) * 78)}} >
            {
              activeId.value ?
                <DashboardModalQueryComponent dq={dashboardQueryListState.dashboardQueries.find(query => query.sortOrder.value+'' ===activeId.value) as State<DashboardQuery>}
                                              errors={errors}/>
                :
                null
            }
          </DragOverlay>
        }
      </DndContext>

      <S.AddQueryWrapper onClick={() => addQuery()}>
        <PlusIcon className={"h-5 w-5 mr-0.5 cursor-pointer text-pl-primary-green-default"}/>
        <S.AddQueryTextWrapper>
          Add another query
        </S.AddQueryTextWrapper>
      </S.AddQueryWrapper>
    </S.DashboardQueriesContainer>
  );
}
