import React, { HTMLAttributes } from "react";
import * as S from "./styles";

export type BadgeProps = {
  label: string;
  type?: "info" | "critical" | "warning" | "default";
  size?: "small" | "medium" | "large";
  rounded?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const Badge = ({
  label,
  type = "default",
  size = "medium",
  rounded,
  ...props
}: BadgeProps) => (
  <S.Wrapper type={type} size={size} rounded={rounded ? 1 : 0} {...props}>
    <S.Label>{label}</S.Label>
  </S.Wrapper>
);

export default Badge;
