import {useHookstateEffect, useState} from "@hookstate/core";

export function useDependantState<T>(builder: () => Promise<T>, dependencies: any[]) {
  const state = useState(builder);
  const firstLoad = useState(true);
  useHookstateEffect(() => {
    if (firstLoad.value) {
      firstLoad.set(false);
      return;
    }

    state.set(builder());
  }, dependencies);
  return state;
}