import React, { useRef, useEffect } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import * as S from "./styles";
import {useState} from "@hookstate/core";

export type DropdownProps = {
  title: React.ReactNode;
  direction?: "left" | "center" | "right";
  hasIcon?: boolean;
  fullscreen?: boolean;
  children: React.ReactNode;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
};

const Dropdown = ({
  title,
  direction = "center",
  hasIcon = false,
  fullscreen = false,
  width = "",
  minWidth = "",
  maxWidth = "",
  children,
}: DropdownProps) => {
  const isOpen = useState<boolean>(false);
  const wrapperRef = useRef(null);

  const useOutsideAlerter = (ref: React.RefObject<HTMLElement>) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          isOpen.set(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(wrapperRef);

  return (
    <S.Wrapper ref={wrapperRef}>
      <S.TitleWrapper onClick={() => isOpen.set(!isOpen.value)}>
        <S.Title>{title}</S.Title>

        {hasIcon && (
          <S.Icon open={isOpen.value}>
            <ChevronDownIcon />
          </S.Icon>
        )}
      </S.TitleWrapper>

      <S.Content className={`${width} ${minWidth} ${maxWidth}`}
        aria-hidden={!isOpen}
        open={isOpen.value}
        direction={direction}
        fullscreen={fullscreen ? 1 : 0}
        onClick={() => isOpen.set(false)}
      >
        {children}
      </S.Content>
    </S.Wrapper>
  );
};

export default Dropdown;
