import React from 'react';

export function WidgetTitle({
                              children,
                              textSize = "text-xl",
                              fontWeight = "font-bold",
                              padding = true,
                              className = '',
}: {
  children: React.ReactNode,
  textSize?: string,
  fontWeight?: string,
  padding?: boolean,
  className?: string,
}) {
  return <div className={`${textSize} ${fontWeight} ${padding ? "px-2" : ""} ${className}`}>{children}</div>
}
