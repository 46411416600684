export function getPercentageIncrease (previous: number, current: number): number {
    if (previous === 0 && current === 0) {
        return 0;
    } else if (previous > 0 && current === 0) {
        return -100;
    } else if (previous === 0 && current > 0) {
        return 100;
    } else {
        return Math.round( (current - previous) / previous * 100);
    }
}

export function getModule (num: number) {
    return (num > 0) ? num : num * -1;
}

export function getEmployeeTenure (joinDate: Date) {
  const period = new Date(new Date().getTime() - new Date(joinDate).getTime());
  let years = period.getFullYear() - 1970; // at 1970 the date calendar starts
  let months = period.getMonth() + 1;

  if (months == 12) {
    months = 0;
    years += 1;
  }

  let tenure = "";
  if (years !== 0) {
    tenure = tenure.concat(years + " yr" + (years > 1 ? "s " : " "));
  }
  if (months !== 0) {
    tenure = tenure.concat(months + " mo" + (months > 1 ? "s" : ""));
  }
  return tenure;
}

export function getNumDaysBetweenDates(date1: Date, date2: Date) {
  const numDays = (date2.getTime() - date1.getTime())/(24*1000*3600);
  return Math.round(numDays);
}