import React from "react";
import * as S from "./styles";

const Footer = () => {
  const footerLinks: { label: string; path: string }[] = [
    {
      label: "Documentation",
      path: "https://peoplelogic.zendesk.com/hc/en-us",
    },
    {
      label: "File a Support Ticket",
      path: "https://peoplelogic.zendesk.com/hc/en-us/requests/new",
    },
    {
      label: "Service Status",
      path: "https://status.peoplelogic.ai/",
    },
  ];
  return (
    <S.Wrapper>
      <S.LinkWrapper>
        {footerLinks.map(({ label, path }, index) => (
          <S.Link
            key={index}
            href={path}
            target="_blank"
            rel="noopenner, noreferrer"
          >
            {label}
          </S.Link>
        ))}
      </S.LinkWrapper>

      <S.Copyright href="https://status.peoplelogic.ai/">
        ©Peoplelogic.ai
      </S.Copyright>
    </S.Wrapper>
  );
};

export default Footer;
