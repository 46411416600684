import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  w-full
  flex
  items-center
`;

export const TabWrapper = tw.div`

`;

export const TabIconWrapper = tw.div`
  pr-3
`;

export const TabIcon = tw.div`
  w-6
  h-6
`;

export const TabLabel = tw.div`
  pr-3
  text-base
  font-bold
`;

export const TabSeparator = tw.div<{active: boolean}>`
  ${({ active }) => `
    pr-3

    ${!!active ? "text-pl-primary-green-default" : "text-pl-grayscale-midgray"}
  `}
`;
