import {DoubleLoadingWidget, LoadingWidget} from "components/utils/LoadingWidget";
import recommendationService from "services/recommendations";
import { Downgraded, State, useState } from "@hookstate/core";
import { RecommendationSettings, Rulebook } from "model/recommendations";
import { capitalize } from "utils/text";
import RecommendationsRulebooks from "./RecommendationsRulebooks";
import RecommendationsSettings from "./RecommendationsSettings";
import recommendationsService from "services/recommendations";
import React from "react";
import VerticalTabbedForm from "components/utils/Tabs/VerticalTabbedForm";
import TabTitle from "components/structure/TabTitle";
import DetailsNavbar from "components/generics/DetailsNavbar";
import Breadcrumbs from "components/generics/Breadcrumbs";
import { useLocation } from "react-router-dom";
import { Tab } from "components/utils/types";

import { ReactComponent as ListIcon } from "icons/list.svg";
import { ReactComponent as ThumbUpIcon } from "icons/thumb-up.svg";
import { ReactComponent as CogIcon } from "icons/cog.svg";
import { ReactComponent as CandorIcon } from "icons/candor.svg";
import { ReactComponent as ScaleIcon } from "icons/scale.svg";
import { ReactComponent as EventIcon } from "icons/event.svg";
import { ReactComponent as GraphIcon } from "icons/graph.svg";
import { ReactComponent as TeamIcon } from "icons/team.svg";

function getIcon(tab: string) {
  switch (tab) {
    case "general":
      return <ListIcon />;
    case "engineering":
      return <CogIcon />;
    case "hr":
      return <CandorIcon />;
    case "performance":
      return <ThumbUpIcon />;
    case "product":
      return <ScaleIcon />;
    case "reward":
      return <EventIcon />;
    case "sales":
      return <GraphIcon />;
    case "team":
      return <TeamIcon />;
  }
}

export function RecommendationsContent({
  rulebooks,
}: {
  rulebooks: Rulebook[];
}) {
  const rbState = useState({
    info: rulebooks.map((rb) => {
      return {
        name: rb.name,
        disabled: rb.disabled,
        rules: rb.rules,
      } as Rulebook;
    }),
  });

  return (
    <VerticalTabbedForm
      tabs={rulebooks.map((rulebook) => {
        return {
          title: capitalize(rulebook.name),
          builder: () => (
            <RecommendationsRulebooks
              state={
                rbState.info.filter((rb) => rb.name.get() === rulebook.name)[0]
              }
            />
          ),
          icon: getIcon(rulebook.name),
        };
      })}
      showCancel={false}
      showSave={false}
      onSave={() => {}}
    />
  );
}

export function SettingsContent({
  settingsState,
}: {
  settingsState: State<RecommendationSettings[]>;
}) {
  const innerState = useState(settingsState);
  const settings = innerState.value;

  return (
    <VerticalTabbedForm
      tabs={settings.map((setting) => {
        return {
          title: capitalize(setting.rulebook),
          builder: () => (
            <RecommendationsSettings
              state={
                innerState.filter(
                  (st) => st.rulebook.get() === setting.rulebook
                )[0]
              }
            />
          ),
          icon: getIcon(setting.rulebook),
        } as Tab;
      })}
      showCancel={false}
      onSave={() => {}}
    />
  );
}

function updateSettingsState(
  settingsState: State<RecommendationSettings[]>,
  settings: RecommendationSettings[]
) {
  settingsState.set(
    settings.map((sett) => {
      return {
        rulebook: sett.rulebook,
        settings: sett.settings.map((s) => {
          return {
            settingKey: s.settingKey,
            settingValue: s.settingValue,
            label: s.label,
            description: s.description,
          };
        }),
      } as RecommendationSettings;
    })
  );
}

export default function RecommenderPersonalization() {
  const settingsState = useState([
    {
      rulebook: "",
      settings: [],
    },
  ] as RecommendationSettings[]);
  TabTitle("Recommender Personalization");

  const baseState = useState<[Rulebook[], RecommendationSettings[]]>(() => Promise.all([
    recommendationsService.getRulebooksAndRules(),
    recommendationsService.getRecommendationSettings()
  ]))

  return (
    <>
      <div>
        <Breadcrumbs
          items={[
            {
              label: "Recommender Personalization",
              url: `/settings/recommendations`,
            },
          ]}
        />
        <LoadingWidget<[Rulebook[], RecommendationSettings[]]>
          value={baseState}
          builder={([rulebooksValue, settingsValue]) => {
            // only show settings of rulebooks
            settingsValue = settingsValue.filter((sett) =>
              rulebooksValue.find((rb) => rb.name === sett.rulebook)
            );

            updateSettingsState(settingsState, settingsValue);

            return (
              <RecommendationsWithNav
                rulebooks={rulebooksValue}
                settingsState={settingsState}
              />
            );
          }}
        />
      </div>
    </>
  );
}

const RecommendationsWithNav = React.memo(
  ({
    rulebooks,
    settingsState,
  }: {
    rulebooks: Rulebook[];
    settingsState: State<RecommendationSettings[]>;
  }) => {
    const location = useLocation();
    const innerState = useState(settingsState);

    const RULEBOOKS_URL = "/settings/recommendations";
    const SETTINGS_URL = "/settings/recommendations/settings";
    const navItems = [
      {
        label: "Recommendations",
        icon: <ThumbUpIcon />,
        url: RULEBOOKS_URL,
      },
      {
        label: "Settings",
        icon: <CogIcon />,
        url: SETTINGS_URL,
      },
    ];

    function saveData() {
      const settingsToSave = innerState.attach(Downgraded).get();
      recommendationsService
        .updateRecommendationsSettings(settingsToSave)
        .then((result) => {
          const savedSettings = result as RecommendationSettings[];
          innerState.set(savedSettings);
        });
    }

    return (
      <>
        <DetailsNavbar
          items={navItems}
          showSave={location.pathname === SETTINGS_URL}
          onSave={saveData}
        />
        {location.pathname === RULEBOOKS_URL ? (
          <RecommendationsContent rulebooks={rulebooks} />
        ) : (
          <SettingsContent settingsState={settingsState} />
        )}
      </>
    );
  }
);
