import { useMergeLink } from '@mergeapi/react-merge-link';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import mergeIntegrationsService from '../../../../services/mergeIntegrationsService';

const MergeIntegrationModal = ({
                                 integration,
                                 token,
                                 onExit
                               }: { integration: string, token: string, onExit: () => any }) => {

  const navigate = useNavigate();
  const onSuccess = (publicToken) => {
    mergeIntegrationsService.finalizeConnection(integration, publicToken)
      .then(() => {
        navigate("/integrations");
      })

    // Send public_token to server (Step 3)

  };


  const {open, isReady} = useMergeLink({
    linkToken: token,
    onSuccess,
    onExit
  });

  useEffect(() => {
    if (isReady) {
      open();
    }
  }, [token, isReady])

  return <></>;
}

export default MergeIntegrationModal;
