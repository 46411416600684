import {useLocation, useNavigate} from "react-router-dom";
import { useState } from "@hookstate/core";
import DataTableFilters, {
  Filters,
} from "components/utils/Datatable/DataTableFilters";
import { useBulkSelectDepartment } from "hooks/useBulkSelect";
import React from "react";
import { ReactComponent as DepartmentIcon } from "icons/department.svg";
import { Department } from "model/department";
import AddDepartmentModal, {
  openDepartmentModal,
} from "../departmentForm/addDepartment/AddDepartment";
import { DatatableColumn, DepartmentsTableData } from "model/datatable";
import authService from "services/auth";
import {
  getAvatar,
  getDataTableColumns,
  getSparklineWidget, updateSelectionIfChanged,
} from "components/utils/Datatable/DataTableHelper";
import URLSortedDataTable from "components/utils/Datatable/URLSortedDataTable";
import TableItemActiveIcon from "components/utils/Datatable/TableItemActiveIcon";
import { contains } from "utils/text";
import Button from "components/generics/Button";
import { openStatusConfirmationModal } from "components/utils/Modal/StatusConfirmationModal";
import departmentService from "services/departmentService";
import { TeamAvatarGroup } from "components/utils/Avatar";
import WaitForOnScreen from "components/utils/WaitForOnScreen";
import { LoadingWidget } from "components/utils/LoadingWidget";
import employeeService from "services/employeeService";
import teamService from "services/teamService";
import stayFactorService from "services/stayfactor";
import {PrivacyLevel} from "model/organization";
import privacyLevelService from "services/privacyLevelService";
import {openConfirmationModal} from "components/utils/Modal/ConfirmationModal";
import {DailyStayFactor} from "model/stayFactor";
import {PeriodSelectorState} from "components/utils/PeriodSelector";
import {createTrendArray} from "utils/stayFactor";
import * as _ from "lodash";

import * as S from "./styles";

const DepartmentsList = ({
  departmentsList,
}: {
  departmentsList: Department[];
}) => {
  const navigate = useNavigate();

  const privacyLevel = authService.privacyLevel.value as PrivacyLevel;

  const departmentsState = useState<Department[]>(
    departmentsList
  );
  const selectedItems = useState<Department[]>([]);
  const hasClearSelection = useState(false);

  const state = useState({
    info: {
      status: "true",
      searchWord: "",
    } as Filters,
  });

  const location = useLocation();

  const columns: DatatableColumn<DepartmentsTableData>[] = [
    {
      id: "name",
      accessor: "departmentName",
      Header: "Department Name",
      customRenderer: "departmentNameRenderer",
    },
    {
      id: "manager",
      accessor: "manager",
      Header: "Manager",
      customRenderer: "managerRenderer",
    },
    {
      id: "active",
      disableSortBy: state.info.status.value !== "all",
      accessor: "active",
      Header: "Active",
      customRenderer: "activeRender",
      sortDescFirst: true,
    },
    {
      id: "stayFactor",
      disableSortBy: true,
      accessor: "stayFactor",
      Header: "StayFactor™",
    },
    {
      id: "size",
      accessor: "departmentSize",
      Header: "Department Size",
      customRenderer: "departmentSizeRender",
    },
  ];

  const fromCreateTeamToTop = React.useCallback(
    (departmentList: Department[]) => {
      const topDepartmentId = location.state as number;
      if (topDepartmentId && topDepartmentId > 0) {
        const filtered = departmentList.filter(
          (department) => department.id !== topDepartmentId
        );
        const department = departmentList.filter(
          (department) => department.id === topDepartmentId
        );
        return department.concat(filtered);
      }
      return departmentList;
    },
    [location.state]
  );

  // apply selected filters to the teams list
  function filterDepartmentData(departmentList: Department[]): Department[] {
    return fromCreateTeamToTop(
      departmentList
        .filter((department) => {
          if (state.info.searchWord.get().length > 0) {
            return contains(
              department.name.concat(" Department"),
              state.info.searchWord.get()
            );
          }
          return true;
        })
        .filter((department) => {
          if (state.info.status.get() === "true") {
            return department.active;
          } else if (state.info.status.get() === "false") {
            return !department.active;
          }
          return true;
        })
    );
  }

  function getActiveComponent(department: Department) {
    return (
      <S.ActiveComponentWrapper>
        <Button
          className={`hover:border-solid
          hover:rounded-full
          hover:border-4
          ${
            department.active
              ? `hover:border-pl-primary-green-light`
              : `hover:border-pl-primary-red-light`
          }`}
          minimal
          $shadow={false}
          onClick={() =>
            openStatusConfirmationModal(
              department.active
                ? `Are you sure you want to deactivate the ${department.name} department?`
                : `Are you sure you want to activate the ${department.name} department?`,
              department.active
                ? "Deactivate Department"
                : "Activate Department",
              department.name,
              "team",
              () => {
                departmentService
                  .updateDepartmentStatus(department.id, !department.active)
                  .then(() => {
                    departmentsState
                      .find((e) => e.id.value === department.id)
                      ?.active.set(!department.active);
                  });
              },
              "Close",
              department.active ? "Deactivate" : "Activate"
            )
          }
        >
          <TableItemActiveIcon active={department.active ? true : false} />
        </Button>
      </S.ActiveComponentWrapper>
    );
  }

  function getData(departmentList: Department[]): any[] {
    let rows: DepartmentsTableData[] = [];
    departmentList.map((department) => {
    const activeTeams = department.teams ? department.teams.filter(team=> team.active): [];
    return rows.push({
        id: department.id,
        departmentName: department.name
          .concat(" Department")
          .toLowerCase()
          .trim(),
        departmentNameRenderer: getAvatar(
          department.name,
          `/departments/${department.id}`,
          "",
          true,
          "",
          department.active,
          true,
          true,
          true
        ),
        manager: department.manager
              ? department.manager.name.toLowerCase().trim()
              : "",
        managerRenderer: department.manager ? (
            getAvatar(
              department.manager.name,
              `/employees/${department.manager.id}`,
              department.manager.role,
              true,
              department.manager.avatar,
              department.active,
              true
            )
          ) : (
            <></>
          ),
        active: department.active ? 1 : 0,
        activeRender: getActiveComponent(department),
        stayFactor: <StayFactorSparklineWidget department={department} />,
        departmentSize: activeTeams.length,
        departmentSizeRender: activeTeams.length > 0 ? (
          <TeamAvatarGroup teams={activeTeams} hoverable={true} />
        ) : (
          <></>
        ),
      });
    });
    return rows;
  }

  const StayFactorSparklineWidget = ({
    department,
  }: {
    department: Department;
  }) => {
    const employeesStayFactor =
      useState<DailyStayFactor[][]>(() => department.employees ?
        Promise.all(department.employees.filter(e => e.active).map(emp =>
          stayFactorService.getStayFactorByEmployee(emp.id, PeriodSelectorState.selectedPeriod.value)))
        :
        [] as DailyStayFactor[][]);

    return (
      <WaitForOnScreen
        builder={() => (
          <LoadingWidget<DailyStayFactor[][]>
            value={employeesStayFactor}
            builder={(esf: DailyStayFactor[][]) => getSparklineWidget(createTrendArray(esf), true, true)}
            size="small"
          />
        )}
      />
    );
  };

  const onRowSelection = React.useCallback((value) => {
    updateSelectionIfChanged(value, selectedItems);
  }, []);

  const {
    clearSelection,
    activateSelectedDepartments,
    deactivateSelectedDepartments,
    deleteSelectedDepartments
  } = useBulkSelectDepartment();

  const getBulkActions = () => {
    const selectedIds = selectedItems.value.map((d) => d.id);
    return [
      {
        label: "Activate",
        action: () =>
          activateSelectedDepartments(selectedIds, departmentsState, () =>
            hasClearSelection.set(!hasClearSelection.value)
          ),
      },
      {
        label: "Deactivate",
        action: () =>
          deactivateSelectedDepartments(selectedIds, departmentsState, () =>
            hasClearSelection.set(!hasClearSelection.value)
          ),
      },
      {
        label: "Delete",
        action: () =>
          deleteSelectedDepartments(selectedIds, departmentsState, () =>
            hasClearSelection.set(!hasClearSelection.value)
          ),
      },
    ];
  };

  return (
    <>
      <AddDepartmentModal />
      <div className="w-full">
        <DataTableFilters
          title={"Departments List"}
          type={"department"}
          titleIcon={<DepartmentIcon className={"text-pl-grayscale-gray"} />}
          titleSize={"large"}
          addLabel={authService.currentUserIsAdmin() ? "New Department" : undefined}
          state={state.info}
          selectedItems={selectedItems.value}
          bulkActions={getBulkActions()}
          onAdd={() =>
            privacyLevelService.hasAccess(privacyLevel) ?
              Promise.all([
                employeeService.getManagers(),
                teamService.getActiveTeamsWithNoDepartment(),
              ]).then(([managers, teams]) => openDepartmentModal(managers, teams))
              :
              openConfirmationModal(
                "Please select a privacy level in your organization settings that allows you to see this information.",
                "Lower Privacy Level",
                () => navigate("/settings/organization"),
                "",
                "Change Privacy Level"
              )
          }
          resetPagination={() => {
            clearSelection();
          }}
        />

        <URLSortedDataTable
          columns={getDataTableColumns(columns)}
          data={getData(filterDepartmentData(departmentsState.value))}
          pageLength={10}
          pageChangeCallback={() => clearSelection()}
          hiddenColumns={authService.currentUserIsAdmin() ? [] : ["actions"]}
          onRowSelection={onRowSelection}
          hasClearSelection={hasClearSelection.value}
        />
      </div>
    </>
  );
};

export default React.memo(DepartmentsList);
