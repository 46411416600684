import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  relative
  z-0
  mb-8
  bg-pl-grayscale-white
  rounded-2xl
  p-3
  pb-5
  h-[404px]
  max-w-[280px]
  shadow
`;

export const TitleWrapper = tw.div`
  pb-4
  flex
`;

export const ArrowsSection = tw.div`
  flex
  items-end
  justify-end
  absolute
  right-0
  bottom-[-50px]
  w-full
`;

export const ArrowContainer = tw.div`
  bg-pl-variant-beige
  p-2
  rounded-full
`;
