import React from "react";
import TabTitle from "components/structure/TabTitle";
import employeeService from "services/employeeService";
import teamService from "services/teamService";
import {LoadingWidget} from "components/utils/LoadingWidget";
import {ManageDepartment} from "./manageDepartment/ManageDepartment";
import {Department} from "model/department";

export default function DepartmentFormPage({department}: { department?: Department }) {
  TabTitle("Department");

  return (
    <>
      <LoadingWidget
        value={Promise.all([
          employeeService.getManagers(),
          teamService.getActiveTeamsWithNoDepartment(department),
        ])}
        builder={([managers, teams]) =>
          !!department ? (
            <>
              <ManageDepartment department={department} managers={managers} teams={teams}/>
            </>
          ) : (
            <>
            </>
          )
        }
      />
    </>
  );
}
