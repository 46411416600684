import tw from "tailwind-styled-components";

export const VerticalTabbedFormContainer = tw.div`
    relative
    lg:grid
    lg:grid-cols-12
    bg-pl-grayscale-white
    flex
    px-2
    py-2
    rounded-2xl
    shadow 
    my-4
`;

export const VerticalSideTabsContainer = tw.aside`
    py-4
    px-2
    sm:px-6
    lg:py-4
    lg:px-2
    lg:col-span-2
`;

export const VerticalSideTabs = tw.aside`
    space-y-3
    border-r-[1px]
    border-pl-grayscale-lightgray
    h-full
`;

export const TabContainer = tw.div`
`;

export const TabIcon = tw.div`
`;

export const TabTitle = tw.div`
    flex
    flex-col
`;

export const ContentWrapper = tw.div`
    relative
    space-y-6
    sm:px-6
    lg:px-0
    lg:col-span-10
`;