import React, { useEffect } from "react";
import * as S from "./styles";
import {useState} from "@hookstate/core";

export type SpinnerProps = {
  size?: "small" | "medium" | "large";
  color?: "light" | "dark";
  message?: string | React.ReactNode;
};

const Spinner = ({ size = "medium", color = 'dark', message }: SpinnerProps) => {
  const isVisible = useState<boolean>(true);

  useEffect(() => {
    if (!!message) {
      const to = setTimeout(() => {
        isVisible.set(false);
      }, 3000);
      return () => clearTimeout(to);
    }
  }, [message, isVisible]);

  return (
    <S.Wrapper>
      {isVisible && <S.Spinner size={size} color={color} />}
      {!isVisible && <S.Message size={size}>{message}</S.Message>}
    </S.Wrapper>
  );
};

export default Spinner;
