import {useNavigate} from "react-router-dom";
import {Employee, Team, TeamFormData} from "model/employee";
import React from "react";
import {WidgetTitle} from "components/utils/WidgetTitle";
import Button from "components/generics/Button";
import {Form, Formik, FormikValues} from "formik";
import {TeamValidator} from "validations/TeamValidator";
import {SelectSearchOption} from "components/utils/SelectSearch";
import teamService from "services/teamService";
import {BasicInfoSection} from "../BasicInfoSection";
import {MembersSkillsSection} from "../MembersSkillsSection";
import {Department} from "model/department";
import auth from "services/auth";
import privacyLevelService from "services/privacyLevelService";
import {PrivacyLevel} from "model/organization";

import * as S from "./styles";
import {useState} from "@hookstate/core";


export const ManageTeam = ({ team,
                             managers,
                             types,
                             employees,
                             departments,
}: {
  team: Team,
  managers: Employee[],
  types: string[],
  employees: Employee[],
  departments: Department[],
}) => {
  const navigate = useNavigate();

  const isSaving = useState<boolean>(false);

  const getTeam = () => {
    return team ?
      {
        id: team.id,
        name: team.name,
        managerId: team.manager ? team.manager.id : 0,
        teamType: team.teamType ? team.teamType : "general",
        active: team.active,
        employees: team.employees ? team.employees : [],
        employeesSelected: team.employees ? team.employees.map((employee) => {
          return {
            label: employee.name,
            description: employee.name,
            value: employee.id,
          } as SelectSearchOption
        }) : [] as SelectSearchOption[],
        skills: team.skills ? team.skills.map((sk) => sk.name) : [],
        departmentId: team.department?.id,
      } as TeamFormData
      : {};
  };


  function handleSave(values: FormikValues) {
    const ids = values.employeesSelected.map((val) => val.value);
    const teamToSave = {
      id: team.id,
      name: values.name,
      managerId: values.managerId,
      teamType: values.teamType,
      active: team.active,
      employees: employees.filter((emp) => ids.includes(emp.id)),
      employeesSelected: values.employeesSelected,
      skills: values.skills,
      departmentId: values.departmentId,
    } as TeamFormData;

    teamService.saveTeam(teamToSave)
      .then(function (savedTeam) {
        goToTeamPage("fetch");
      });
  }

  function goToTeamPage(action?: string) {
    action ?
      navigate("/teams/" + team.id, { state: "fetch"})
      :
      navigate("/teams/" + team.id)
  }

  return (
    <>
      <Formik
        initialValues={{...getTeam()}}
        validationSchema={TeamValidator}
        validateOnMount={true}
        onSubmit={handleSave}
      >
        {({
            values,
            errors,
            isValid,
            setFieldValue,
            setFieldTouched,
            touched,
            handleChange,
            handleBlur,
          }) => (

          <Form>
            <S.Wrapper>
              <S.MainContainer>

                <S.SubMainContainer>
                  <WidgetTitle>Basic information</WidgetTitle>
                  <BasicInfoSection managers={managers}
                                    teamType={types}
                                    departments={departments}
                                    values={values}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    horizontalDisplay={true}
                                    numColumns={4}
                  />
                </S.SubMainContainer>

                <S.SubMainContainer>
                  <WidgetTitle>
                    {
                      privacyLevelService.hasAccess(auth.privacyLevel.value as PrivacyLevel) ?
                        "Members & Skills" : "Skills"
                    }
                  </WidgetTitle>
                  <MembersSkillsSection employees={employees}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        horizontalDisplay={true}
                                        numColumns={2}
                  />
                </S.SubMainContainer>

                <S.SubMainContainer>
                  <S.ButtonsWrapper>
                    <Button btntype="neutral" $shadow={true} onClick={() => goToTeamPage()}>
                      Cancel
                    </Button>
                    <Button type="submit" btntype="primary" $shadow={true} onClick={() => {
                      isSaving.set(true)
                      handleSave(values);
                    }} disabled={!isValid || isSaving.value}>
                      Save
                    </Button>
                  </S.ButtonsWrapper>
                </S.SubMainContainer>

              </S.MainContainer>
            </S.Wrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};
