import React from "react";
import { State, useState } from "@hookstate/core";
import { UnmappedIntegrationUser, UnmappedUser } from "model/integration";
import { Employee, Location, Team } from "model/employee";
import IntegrationsMapperNewEmployee from "../integrationsMapperNewEmployee";
import CustomSelect from "components/utils/CustomSelect/CustomSelect";
import { ReactComponent as CloseIcon } from "icons/close.svg";

import * as S from "./styles";
import Button from "components/generics/Button";

type Props = {
  state: State<UnmappedIntegrationUser>;
  employees: Employee[];
  teams: Team[];
  managers: Employee[];
  locations: Location[];
};

export default function IntegrationsSelector({
  state,
  employees,
  teams,
  managers,
  locations,
}: Props) {
  const unmappedIntegrationUser = useState(state);

  const updatePreviousState = (user: State<UnmappedUser>) => {
    user.previousMappedState.set(user.mappedState.value);
  };

  const getSelectedEmployee = (user: State<UnmappedUser>) => {
    if (!user.mapped.value) {
      return 0;
    }
    return user.employeeId.value ? user.employeeId.value : 0;
  };

  const onSelectEmployee = (user: State<UnmappedUser>, value: string) => {
    user.employeeId.set(parseInt(value));
    updatePreviousState(user);

    if (!value) {
      user.mapped.set(false);
      user.mappedState.set("unmapped");
    } else {
      user.mapped.set(true);
      user.mappedState.set("mappedWithEmployee");
    }
  };

  const ignoreUser = (user: State<UnmappedUser>) => {
    updatePreviousState(user);
    user.mapped.set(true);
    user.mappedState.set("ignoredUsers");
  };

  const undo = (user: State<UnmappedUser>) => {
    user.mappedState.set(user.previousMappedState.value);
    if (user.mappedState.value === "unmapped") {
      user.mapped.set(false);
    } else {
      user.mapped.set(true);
    }
  };

  return (
    <S.Wrapper>
      <S.Title>
        Select an employee below for each unknown integration user.
      </S.Title>

      <S.ContentWrapper>
        {unmappedIntegrationUser.unmappedUsers.map((user) => (
          <S.SelectorWrapper key={user.mapping.value}>
            {user.mapped.value ? (
              <>
                {user.mappedState.value === "ignoredUsers" && (
                  <S.Label>
                    <S.HighlightedLabel>
                      {user.mapping.value}
                    </S.HighlightedLabel>{" "}
                    was ignored.
                  </S.Label>
                )}
                {user.mappedState.value === "newEmployees" && (
                  <S.Label>
                    <S.HighlightedLabel>
                      {user.mapping.value}
                    </S.HighlightedLabel>{" "}
                    was created as{" "}
                    <span className="font-semibold">
                      {user.newEmployee.value?.name}
                    </span>
                    .
                  </S.Label>
                )}
                {user.mappedState.value === "mappedWithEmployee" && (
                  <S.Label>
                    <S.HighlightedLabel>
                      {user.mapping.value}
                    </S.HighlightedLabel>
                    was mapped as{" "}
                    <span className="font-semibold">
                      {
                        employees.find(
                          (employee) => employee.id == getSelectedEmployee(user)
                        )?.name
                      }
                    </span>
                  </S.Label>
                )}
              </>
            ) : (
              <S.Label>
                Who is
                <S.HighlightedLabel>{user.mapping.value}</S.HighlightedLabel>?
              </S.Label>
            )}

            <S.Selector>
              <CustomSelect
                id={user.mapping.value}
                name={user.mapping.value}
                autoComplete={user.mapping.value}
                isDisabled={user.mappedState.value !== "unmapped"}
                value={getSelectedEmployee(user)}
                onChange={(option: any) => onSelectEmployee(user, option.value)}
                options={employees.map((employee) => ({
                  label: employee.name,
                  value: employee.id,
                }))}
                className={`flex-1`}
              />

              <S.ActionWrapper>
                {user.mappedState.value !== "unmapped" ? (
                  <Button
                    minimal
                    btntype="error"
                    icon={<CloseIcon height={40} />}
                    iconposition="left"
                    onClick={() => undo(user)}
                  >
                    Undo
                  </Button>
                ) : (
                  <S.ActionWrapper>
                    <Button
                      minimal
                      btntype="error"
                      onClick={() => ignoreUser(user)}
                    >
                      Ignore User
                    </Button>

                    <IntegrationsMapperNewEmployee
                      user={user}
                      integration={unmappedIntegrationUser.value}
                      teams={teams}
                      managers={managers}
                      locations={locations}
                    />
                  </S.ActionWrapper>
                )}
              </S.ActionWrapper>
            </S.Selector>
          </S.SelectorWrapper>
        ))}
      </S.ContentWrapper>
    </S.Wrapper>
  );
}
