import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  relative
  flex
  items-center
  justify-between
  right-0
  w-full
  pr-2
`;

export const WrapperArea = tw.div`
  relative
  z-0
`;

export const List = tw.ul`
  gap-2
  text-left
  my-2
  px-2
`;

export const ListItem = tw.li`
  col-span-1
  py-1
  border-t
  border-t-pl-grayscale-lightgray
  flex
  items-center
  justify-between
`;

export const ListItemLink = tw.div`
  truncate max-w-[90%]
`;

export const ListItemTitle = tw.div`
  truncate
`;

export const ArrowsSection = tw.div`
  flex
  items-end
  justify-end
  absolute
  right-0
  bottom-[-50px]
  w-full
`;

export const ArrowContainer = tw.div`
  bg-pl-variant-beige
  p-2
  rounded-full
`;