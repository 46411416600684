import React from "react";
import { useParams } from "react-router-dom";
import { LoadingWidget } from "components/utils/LoadingWidget";
import dashboardsService from "services/dashboards";
import InsightsDashboardMainContainer from "./InsightsDashboardMainContainer";
import DashboardModal from "./manageDashboard/DashboardModal";
import { ConfirmationModal } from "components/utils/Modal/ConfirmationModal";
import DashboardQueryModal from "./manageDashboard/DashboardQueryModal";
import TabTitle from "components/structure/TabTitle";
import Breadcrumbs from "components/generics/Breadcrumbs";
import { Dashboard } from "model/dashboards";
import {useState} from "@hookstate/core";
import {useDependantState} from "../../utils/state";

export default function InsightsDashboard() {
  const dashboardId = +useParams<{ id: string }>().id!;
  TabTitle("Dashboard");

  const dashboard = useDependantState<Dashboard>(() => dashboardsService.getDashboardById(dashboardId), [dashboardId]);

  return (
    <>
      <DashboardModal />
      <DashboardQueryModal />
      <ConfirmationModal />
      <LoadingWidget<Dashboard>
        value={dashboard}
        builder={(dashboard) => (
          <>
            <Breadcrumbs
              items={[
                { label: "Insights", url: `/insights/dashboards` },
                {
                  label: `${dashboard.name}`,
                  url: `/insights/dashboards/${dashboard.id}`,
                },
              ]}
            />
            <InsightsDashboardMainContainer
              dashboard={{
                ...dashboard,
                dashboardQueries: [...dashboard.dashboardQueries].sort(
                  (a, b) => a.sortOrder - b.sortOrder
                ),
              }}
            />
          </>
        )}
      />
    </>
  );
}
