import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  flex
  flex-col
  gap-6
  bg-pl-grayscale-white
  rounded-2xl
  p-6
  relative
`;

export const TitleWrapper = tw.div`
  flex
  items-center
  gap-3
  text-2xl
  font-semibold
`;

export const IconWrapper = tw.div`
  w-8
  h-8
  text-pl-grayscale-gray
`;

export const ContentWrapper = tw.div`
  relative
  overflow-hidden
`;
