import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import {
  ToastMessage,
  ToastMessageStatus,
  ToastMessageType,
} from "../../../model/toast";
import {State, useHookstateEffect} from "@hookstate/core";

export default function ToastNotificationMessage({
  messageState,
}: {
  messageState: State<ToastMessage>;
}) {
  useHookstateEffect(() => {
    if (messageState.status.value === ToastMessageStatus.OPENED) {
      messageState.status.set(ToastMessageStatus.COUNTDOWN_START);
    }
  }, [messageState.status])

  return (
    <>
      <Transition
        show={messageState.status.value !== ToastMessageStatus.CLOSED}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="max-w-sm w-full bg-pl-grayscale-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                {messageState.type.value === ToastMessageType.SUCCESS ? (
                  <CheckCircleIcon
                    className="h-6 w-6 text-pl-primary-green-default"
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="h-6 w-6 text-pl-primary-red-default"
                    aria-hidden="true"
                  />
                )}
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-pl-grayscale-black">
                  {messageState.message.value}
                </p>
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  className="bg-pl-grayscale-white rounded-md inline-flex text-pl-grayscale-midgray hover:text-pl-grayscale-gray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pl-primary-green-default"
                  onClick={() => {
                    messageState.status.set(ToastMessageStatus.CLOSED);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
}
