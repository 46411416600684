import React from "react";
import { CommonModal } from "./CommonModal";
import { Dialog } from "@headlessui/react";
import { createState, useState } from "@hookstate/core";
import Button from "components/generics/Button";
import ModalTextTitle from "./ModalTextTitle";

import * as S from "./styles";

type ConfirmationModalStateProps = {
  open: boolean;
  message: string;
  title: string;
  onSave: () => void;
  closeLabel?: string;
  continueLabel?: string;
  renderMessage?: () => JSX.Element;
};

const ConfirmationModalState = createState<ConfirmationModalStateProps>({
  open: false,
  message: "",
  title: "",
  onSave: () => {},
  closeLabel: "Close",
  continueLabel: "Continue",
  renderMessage: () => <></>,
});

export const openConfirmationModal = (
  message: string,
  title: string,
  onSave: () => void,
  closeLabel?: string,
  continueLabel?: string,
  renderMessage?: () => JSX.Element
) => {
  ConfirmationModalState.set({
    open: true,
    message: message,
    title: title,
    onSave: onSave,
    closeLabel: closeLabel ? closeLabel : "Close",
    continueLabel: continueLabel ? continueLabel : "Continue",
    renderMessage: renderMessage ? renderMessage : () => <></>,
  });
};

export function ConfirmationModal() {
  const modalState = useState(ConfirmationModalState);

  const closeModal = () => modalState.open.set(false);

  const handleSave = () => {
    closeModal();
    return modalState.get({noproxy: true}).onSave();
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <CommonModal openState={ConfirmationModalState.open}>
      <S.Wrapper>
        <S.MainContainer>
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-pl-grayscale-black flex items-center pb-3"
          >
            <ModalTextTitle title={modalState.title.value} />
          </Dialog.Title>

          <S.ContentWrapper>
            <S.FormWrapper>
              {modalState.message.value}
              {modalState.get({noproxy: true}).renderMessage?.()}
            </S.FormWrapper>
          </S.ContentWrapper>

          <S.ButtonsWrapper>
            <Button $shadow={true}
                    onClick={handleClose}>
              {modalState.closeLabel.value}
            </Button>
            <Button $shadow={true}
                    btntype="primary"
                    onClick={handleSave}>
              {modalState.continueLabel.value}
            </Button>
          </S.ButtonsWrapper>
        </S.MainContainer>
      </S.Wrapper>
    </CommonModal>
  );
}
