export enum SeverityType {
  INFO = "info",
  WARNING = "warning",
  CRITICAL = "critical",
}

export enum Feedback {
  NO_FEEDBACK = "NO_FEEDBACK",
  HELPFUL = "HELPFUL",
  UNHELPFUL = "UNHELPFUL",
}

export type SeverityFilterType = {
  type: SeverityType;
  active: boolean;
};

export type Recommendation = {
  id: number;
  severity: SeverityType;
  title: string;
  summaryText: string;
  suggestion?: string;
  lastVerified: Date;
  reportText?: string | null;
  employeeId: number;
  feedback: Feedback;
};

export type RecommendationFilter = {
  pageNumber: number;
  pageSize: number;
  severityList?: SeverityType[];
  employeeId?: number;
  excludedId?: number;
  teamList?: number[];
  numDaysBefore?: number;
};
export type Rulebook = {
  name: string;
  disabled: boolean;
  rules: Rule[];
};

export type Rule = {
  name: string;
  ruleName: string;
  disabled: boolean;
  recommendationTypeId: string;
};

export type RulebookStatus = {
  name: string;
  ruleType: string;
  enable: boolean;
};

export type RecommendationSettings = {
  rulebook: string;
  settings: RecommendationSetting[];
};

export type RecommendationSetting = {
  settingKey: string;
  settingValue: string;
  label: string;
  description?: string;
};

export type RecommendationsRulebooksFormData = {
  selectedTab: string;
  rulebook: Rulebook;
  rules: Rule[];
  rulebooks: Rulebook[];
};

export type SettingsFormData = {
  settingKey: string;
  settingValue: string;
};
