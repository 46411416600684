import React, {useEffect} from "react";
import {Line} from "react-chartjs-2";
import {DataType, LineChartReport, LineChartSeries} from "model/lexi";

import {pickColor} from "utils/color";
import {ChartData, ChartDataset, ChartOptions} from "chart.js";
import * as moment from "moment";

import * as S from "./styles";
import * as _ from "lodash";
import {useState} from "@hookstate/core";

const dateFormatter = (date: string): string => {
  // @ts-ignore
  const now = moment();

  if (/^\d{4}$/.test(date)) {
    return date;
  }
  if (/^\d+-\d+$/.test(date)) {
    // @ts-ignore
    const mdate = moment(date, "YYYY-MM");
    const format = now.year() === mdate.year() ? "MMM" : "MMM YYYY";
    return mdate.format(format);
  } else if (/^\d+-W\d+$/.test(date)) {
    const yearAndWeek = date.split("-W").map((n) => parseInt(n));
    // @ts-ignore
    const startDate = moment().year(yearAndWeek[0]).week(yearAndWeek[1]);
    // @ts-ignore
    const finalDate = moment()
      .year(yearAndWeek[0])
      .week(yearAndWeek[1])
      .add(6, "day");

    const display = (sf: string, ef: string) =>
      `${startDate.format(sf)}-${finalDate.format(ef)}`;

    if (startDate.year() !== finalDate.year()) {
      return display("MMM D YYYY", "MMM D YYYY");
    } else if (startDate.month() !== finalDate.month()) {
      return display("MMM D", "MMM D");
    } else {
      return display("MMM D", "D");
    }
  } else if (/^\d+-\d+-\d+$/.test(date)) {
    // @ts-ignore
    const mdate = moment(date, "YYYY-MM-DD");
    const format = mdate.year() === now.year() ? "dd MMM D" : "dd MMM D YYYY";
    return mdate.format(format);
  }

  return date;
};

const isPercentageSeries = (type: DataType) =>
  type === "percentage" || type === "percentageAsDecimal";

const buildSeriesData = (report: LineChartReport, series: LineChartSeries) => {
  const dataMap = series.values;
  const dataList = report.xAxisValues.map((v) => dataMap[v]);

  if (series.type === "percentageAsDecimal") {
    return dataList.map((item) => Math.round(item * 100));
  }

  return dataList;
};

const generateLabel = (report: LineChartReport, series: LineChartSeries) => {
  const distinctValueTypes = report.series
    .map((series) => series.valuesLabel)
    .sort()
    .filter(
      (valueType, index, list) => index === 0 || valueType !== list[index - 1]
    ).length;

  if (distinctValueTypes === 1 && series.seriesLabel) {
    return series.seriesLabel;
  } else if (distinctValueTypes > 1 && series.seriesLabel) {
    return `${series.seriesLabel} / ${series.valuesLabel}`;
  } else {
    return series.valuesLabel;
  }
};

const buildChartData = (report: LineChartReport) => {
  let series = report.series;

  //filter inactive employees if query for multiple
  const countEmployeeIds = series
    .filter(serie => !!serie.print.employee)
    .map(serie => serie.print.employee.id)
    .length;
  if (countEmployeeIds > 1) {
    series = report.series
      .filter(serie => !!serie.print.employee)
      .filter(serie => serie.print.employee.active ||
        (!serie.print.employee.active && buildSeriesData(report, serie).reduce((a, b) => a + b) > 0));
  }
  return {
    data: {
      labels: report.xAxisValues.map(dateFormatter),
      datasets: series.map((series: any, idx: number) => {
        const dataset: ChartDataset = {
          fill: false,
          backgroundColor: pickColor(idx),
          borderColor: pickColor(idx),
          label: generateLabel(report, series),
          data: buildSeriesData(report, series),
          hidden: false,
        };

        if (series.mode === "secondary") {
          dataset.borderDash = [10, 5];
        }

        return dataset;
      }),
    },
  };
};

const chartOptions = {
  scales: {
    x: {
      display: true,
      title: {
        display: false,
      },
    },
    y: {
      display: true,
      title: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  maintainAspectRatio: false,
  responsive: true,
} as ChartOptions<"line">;

const LineChartReportRender = ({ report }: { report: LineChartReport }) => {
  const chartData = useState<ChartData<"line">>(() => {
    const { data } = buildChartData(report);

    if (data && data.datasets.length) {
      return data as ChartData<"line">;
    }

    return {
      datasets: [],
      labels: [],
    }
  });

  const handleVisibility = (index: number) => {
    chartData.datasets[index].hidden.set(!chartData.datasets[index].hidden.value);
  };

  return (
    <>
      <S.LabelsWrapper>
        {chartData.datasets.map(({ label, backgroundColor, hidden }, index) => (
          <S.LabelWrapper
            key={index}
            onClick={() => {
              handleVisibility(index);
            }}
          >
            <S.LabelIndicator
              style={{ backgroundColor: backgroundColor!.toString() }}
            />

            <S.LabelText isHidden={hidden.value}>{label.value}</S.LabelText>
          </S.LabelWrapper>
        ))}
      </S.LabelsWrapper>

      <Line
        data={_.cloneDeep(chartData.value)}
        options={chartOptions}
        style={{ maxHeight: 300 }}
        redraw={true}
      />
    </>
  );
};

export default LineChartReportRender;
