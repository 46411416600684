import React from "react";
import { SelectSearchOption } from "components/utils/SelectSearch";
import { Location } from "model/employee";
import { useState } from "@hookstate/core";
import DataTableFilters, {
  Filters,
} from "components/utils/Datatable/DataTableFilters";
import {
  Column,
  getDataTableColumns,
} from "components/utils/Datatable/DataTableHelper";
import Button from "components/generics/Button";
import authService from "services/auth";
import URLSortedDataTable from "components/utils/Datatable/URLSortedDataTable";
import { contains } from "utils/text";
import { useLocation } from "react-router-dom";
import { useBulkSelectLocations } from "hooks/useBulkSelect";
import auth from "services/auth";
import AddLocationModal, {
  openAddLocationModal,
  openEditLocationModal,
} from "./manageLocation/ManageLocation";
import {
  ConfirmationModal,
  openConfirmationModal,
} from "components/utils/Modal/ConfirmationModal";
import organizationService from "services/organizationService";

import * as S from "./styles";

import { ReactComponent as EditIcon } from "icons/edit.svg";
import { ReactComponent as TrashIcon } from "icons/trash.svg";

type Props = {
  timezones: SelectSearchOption[];
  locations: Location[];
};

export function OrganizationLocationsList({ timezones, locations }: Props) {
  const locationsState = useState<Location[]>(locations);
  const selectedItems = useState<Location[]>([]);
  const location = useLocation();

  const state = useState({
    info: {
      searchWord: "",
    } as Filters,
  });

  const columns: Column[] = [
    {
      accessor: "location",
      Header: "Location",
    },
    {
      accessor: "timezone",
      Header: "Time Zone",
    },
    {
      accessor: "starttime",
      Header: "Start Time",
    },
    {
      accessor: "endtime",
      Header: "End Time",
    },
    {
      accessor: "actions",
      Header: "Actions",
    },
  ];

  function getData(locations: Location[]): any[] {
    let rows: any[] = [];

    locations.map((loc, index) => {
      return rows.push({
        location: loc.name,
        timezone: loc.timeZone ? loc.timeZone : "",
        starttime: loc.startTime ? loc.startTime : "",
        endtime: loc.endTime ? loc.endTime : "",
        actions: getActions(loc, index),
      });
    });
    return rows;
  }

  function getActions(location: Location, index: number) {
    return (
      <>
        <div className={"flex"}>
          <Button
            className={"!text-pl-grayscale-gray"}
            minimal
            $shadow={false}
            onClick={() => {
              openEditLocationModal(location, timezones, locations);
            }}
          >
            <EditIcon />
          </Button>
          <Button
            className={"!text-pl-grayscale-gray !px-3"}
            minimal
            btntype={"neutral"}
            $shadow={false}
            onClick={() => {
              openConfirmationModal(
                "Are you sure you want to delete this location?",
                "Delete Location",
                () => deleteLocation(location.id)
              );
            }}
          >
            <TrashIcon />
          </Button>
        </div>
      </>
    );
  }

  function deleteLocation(id: number) {
    organizationService
      .deleteLocation(id)
      .then(() =>
        locationsState.set((loc) => loc.filter((val) => val.id !== id))
      );
  }

  const fromCreateLocationToTop = React.useCallback(
    (locationList: Location[]) => {
      const topLocationId = location.state as number;
      if (topLocationId && topLocationId > 0) {
        const filtered = locationList.filter((loc) => loc.id !== topLocationId);
        const recent = locationList.filter((loc) => loc.id === topLocationId);
        return recent.concat(filtered);
      }
      return locationList;
    },
    [location.state]
  );

  // apply selected filters to the teams list
  function filterLocationData(locationsList: Location[]): Location[] {
    return fromCreateLocationToTop(
      locationsList.filter((loc) => {
        if (state.info.searchWord.get().length > 0) {
          return contains(loc.name, state.info.searchWord.get());
        }
        return true;
      })
    );
  }

  const { clearSelection } = useBulkSelectLocations();

  return (
    <>
      <ConfirmationModal />
      <AddLocationModal />
      <S.ListWrapper>
        <DataTableFilters
          title={"Locations List"}
          type={"location"}
          addLabel={auth.currentUserIsAdmin() ? "New Location" : undefined}
          state={state.info}
          selectedItems={selectedItems.value}
          onAdd={() => openAddLocationModal(timezones, locations)}
          resetPagination={() => clearSelection()}
          titleSize={"medium"}
          titleWeight={"font-bold"}
          insideComponent={true}
        />
        <URLSortedDataTable
          columns={getDataTableColumns(columns)}
          data={getData(filterLocationData(locationsState.value))}
          pageLength={10}
          pageChangeCallback={() => clearSelection()}
          hiddenColumns={authService.currentUserIsAdmin() ? [] : ["actions"]}
          $shadow={false}
        />
      </S.ListWrapper>
    </>
  );
}
