import React, { InputHTMLAttributes } from "react";
import Select, { createFilter, StylesConfig } from "react-select";
import { SelectSearchOption } from "../SelectSearch";

import * as S from "./styles";

type Props = {
  onChange: (value: any) => void;
  options: SelectSearchOption[];
  value: any;
  className?: string;
  name?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isFixed?: boolean;
  minimal?: boolean;
  border?: boolean;
  isMulti?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export default ({
  onChange,
  options,
  value,
  className,
  name = "select-component",
  isDisabled = false,
  isClearable = true,
  isSearchable = true,
  isFixed = false,
  minimal,
  border = true,
  isMulti = false,
  ...props
}: Props) => {
  const emptyOption = {
    label: "",
    description: "",
    value: "",
  } as SelectSearchOption;

  const defaultValue = () => {
    if (options) {
      return isMulti
        ? value.map(val => options.find(opt => opt.value === val.value))
        : options.find(option => option.value === value) ?? emptyOption.value;
    } else {
      return isMulti ? [] : ("" as any);
    }
  };

  const colourStyles: StylesConfig<SelectSearchOption> = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999
    }),

    menu: (styles) => ({
      ...styles,
      width: "max-content",
      minWidth: "100%",
    }),

    control: (styles) => ({
      ...styles,
      backgroundColor: isDisabled ? "#F0F2F4" : "#F9F7F5",
      borderColor: !!defaultValue() && !isDisabled ? "#00ACC2" : "#d1d5db",
      boxShadow: "none",
      ":hover": { borderColor: "#00ACC2", boxShadow: "none" },
      ":focus": { boxShadow: "none" },
      cursor: "pointer",
      border: minimal || !border ? "none" : "",
      background: minimal ? "none" : "",
      overflow: "auto",
      borderRadius: 10,
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: "pointer",
        backgroundColor: isDisabled
          ? undefined
          : isFocused
          ? "#CAEBF0"
          : undefined,
        color: "#131A21",
        fontWeight: isSelected ? "600" : undefined,
        fontSize: "14px",
        ":focus": {
          borderColor: isSelected || isFocused ? "#00ACC2" : undefined,
        },
        ":active": {
          backgroundColor: "#CAEBF0",
        },
      };
    },

    indicatorsContainer: (base) => ({
      ...base,
      paddingRight: minimal ? "0" : "8px",
    }),

    clearIndicator: (base) => ({
      ...base,
      svg: {
        width: "15px",
        height: "15px",
        viewBox: "0 0 20 20",
      },
      cursor: "pointer",
      padding: minimal ? "0" : "",
      color: "#C1C9D2",
      "&:hover": {
        color: "#131A21",
      }
    }),

    indicatorSeparator: (base) => ({
      ...base,
      display: minimal ? "none" : "",
      marginLeft: minimal ? "0" : "6px",
      marginRight: minimal ? "0" : "6px",
    }),

    dropdownIndicator: (base) => ({
      ...base,
      padding: minimal ? "0" : "",
      color: "#C1C9D2",
      "&:hover": {
        color: "#131A21",
      }
    }),

    valueContainer: (base) => ({
      ...base,
      padding: minimal ? "0" : isMulti ? "8px" : "",
      paddingLeft: minimal ? "0" : "12px",
    }),

    multiValue: (base) => ({
      ...base,
      color: "#00ACC2",
      backgroundColor: "#CAEBF0",
      borderRadius: 8,
      paddingLeft: "2px",
      paddingRight: "2px",
      marginRight: "8px",
      marginBottom: "8px",
    }),

    multiValueLabel: (base) => ({
      ...base,
      fontSize: "14px",
      fontWeight: "600",
      color: "#00ACC2",
      padding: "3px 3px 3px 6px",
    }),

    multiValueRemove: (base) => ({
      ...base,
      padding: minimal ? "0" : isMulti ? "6px 6px 6px 4px" : "",
      "&:hover": {
        color: "#00ACC2",
        backgroundColor: "#CAEBF0",
      }
    }),

    input: (base, { isDisabled}) => ({
      ...base,
      fontSize: "14px",
      color: isDisabled ? "#C1C9D2" :  "#131A21",
      paddingBottom: isMulti ? "2px" : "",
      paddingRight: "2px",
      marginRight:  isMulti ? "8px" : "",
      marginBottom:  isMulti ? "8px" : "",
    }),

    singleValue: (base, { isDisabled}) => ({
      ...base,
      fontSize: "14px",
      color: isDisabled ? "#C1C9D2" : "#131A21",
    }),

    placeholder: (base) => ({
      ...base,
      fontSize: "14px",
      transition: "all 0.3s",
      fontWeight: minimal ? "600" : "",
      color: minimal ? "#131A21" : "#8595A7",
    }),
  };

  const formatOptionLabel = (label: string, additionalLabel?: string) => (
    <S.OptionLabelWrapper>
      {label}
      {
        additionalLabel &&
        <S.OptionLabelEmail>
          - {additionalLabel}
        </S.OptionLabelEmail>
      }
    </S.OptionLabelWrapper>
  );

  return (
    // @ts-ignore
    <S.SelectWrapper className={className} isDisabled={isDisabled}>
      <Select
        isSearchable={!minimal ? isSearchable : false}
        isDisabled={isDisabled}
        isClearable={!isMulti && isClearable}
        placeholder="Select an option"
        name={name}
        value={defaultValue()}
        formatOptionLabel={({ label, additionalLabel }) => formatOptionLabel(label, additionalLabel)}
        options={options.map(x => ({ value: x.value, label: x.label.trim(), additionalLabel: x.additionalLabel }))} // need to remove empty option from DB
        filterOption={createFilter({ ignoreAccents: false })}
        onChange={(value, { action }) => {
          action === "clear" ? onChange(emptyOption) : onChange(value);
        }}
        menuPosition={isFixed ? "fixed" : "absolute"}
        styles={colourStyles as StylesConfig}
        menuPortalTarget={document.body}
        isMulti={isMulti}
        {...props}
      />
    </S.SelectWrapper>
  );
};
