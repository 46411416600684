import {DailyStayFactor} from "model/stayFactor";

export type StayFactor = {
  currentValue: number;
  deltaAbsolute: number;
  deltaPercentage: number;
  values: number[];
}

export function getStayFactorValueAndDelta(values: number[]) {
  const current = values[0];
  const previous = values[values.length - 1];

  return {
    currentValue: current,
    deltaAbsolute: calculateVariationAbsolute(current, previous),
    deltaPercentage: calculateVariationPercentage(current, previous),
    values: values
  } as StayFactor;
}

export function calculateVariationAbsolute (current: number, previous: number) {
  if (current === 0) {
    return Math.round(0 - previous);
  } else if (previous === 0) {
    return Math.round(current);
  } else {
    return Math.round(current-previous);
  }
};

export function calculateVariationPercentage (current: number, previous: number) {
  if (current === 0) {
    return Math.round(0 - previous);
  } else if (previous === 0) {
    return Math.round(current);
  } else {
    return Math.round(((current/previous) - 1) * 100);
  }
};

export function createTrendArray (esf: DailyStayFactor[][]) {
  const byDate = [] as {day: Date; value: number}[];
  esf = esf.filter(elm => elm.length > 0);
  if (esf.length > 0) {
    const dates = esf[0].map(esfArray => esfArray.day)

    dates.forEach(d => {
      const mapByDay = esf.map(dsfByEmployee => dsfByEmployee.find(dsfItem => dsfItem.day === d));

      let sum = 0;
      let length = 0;
      mapByDay.forEach(v => {
        if (v) {
          sum += v.value;
          length++;
        }
      });

      byDate.push({
        day: d,
        value: sum === 0 ? 0 : Math.round(sum / length)
      });
    })
  }
  return byDate.map(v => v.value);
}

export function createDSFAvgArray (esf: DailyStayFactor[][]) {
  const byDate = [] as {day: Date; value: number}[];
  if (esf.length > 0) {
    const dsf = esf.filter(elm => elm.length > 0)[0] as DailyStayFactor[];
    if (dsf) {
      const dates = dsf.map(esfArray => esfArray.day)

      dates.forEach(d => {
        const mapByDay = esf.map(dsfByEmployee => dsfByEmployee.find(dsfItem => dsfItem.day === d));

        let sum = 0;
        let length = 0;
        mapByDay.forEach(v => {
          if (v) {
            sum += v.value;
            length++;
          }
        });

        byDate.push({
          day: d,
          value: sum === 0 ? 0 : Math.round(sum / length)
        });
      })
    }
  }
  return byDate;
}

export const getBGLightColor = (value: number) => {
  return (value >= 0 && value < 30
    ? 'rgba(251, 108, 113, 0.4)'
    : value >= 30 && value < 45
      ? 'rgba(255, 135, 24, 0.4)'
      : value >= 45 && value < 60
        ? 'rgba(234, 234, 22, 0.4)'
        : value >= 60 && value < 70
          ? 'rgba(0, 172, 194, 0.4)'
          : 'rgba(0, 194, 136, 0.4)')
};

export const getFontColor = (value: number) => {
  return (value >= 0 && value < 30
    ? 'rgb(251, 108, 113)'
    : value >= 30 && value < 45
      ? 'rgb(255, 135, 24)'
      : value >= 45 && value < 60
        ? 'rgb(234, 234, 22)'
        : value >= 60 && value < 70
          ? 'rgb(0, 172, 194)'
          : 'rgb(0, 194, 136)')
};
