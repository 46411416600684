import React, {useRef} from "react";
import {createState, useState} from "@hookstate/core";
import {InstalledIntegrationInfoDto, IntegrationFormData, IntegrationRequiredFields} from "model/integration";
import {CommonModal} from "components/utils/Modal/CommonModal";
import {Dialog} from "@headlessui/react";
import ModalTextTitle from "components/utils/Modal/ModalTextTitle";
import {Form, Formik, FormikErrors, FormikTouched, FormikValues} from "formik";
import Button from "components/generics/Button";
import TextField from "components/generics/TextField";
import Checkbox from "components/generics/Checkbox";
import { IntegrationValidator } from "validations/IntegrationValidator";
import integrationService from "services/integrationService";
import {useNavigate} from "react-router-dom";

import * as S from "./styles";
import * as _ from "lodash";

import {ReactComponent as ChevronIcon} from "icons/chevron.svg";

const EMPTY_INTEGRATION = {
  id: 0,
  name: "",
  mode: "",
  aggregatorType: "",
  apiKey: "",
  apiSecret: "",
  apiUrl: "",
  apiFilter: "",
  ignoreExternalUsers: false,
  enabled: true,
  integrationRequiredFields: {}
} as IntegrationFormData;

export const IntegrationModalState = createState({
  open: false,
  integrationInfo: EMPTY_INTEGRATION as IntegrationFormData,
  installedIntegrationInfo: {} as InstalledIntegrationInfoDto,
  isEdit: false,
});

export const openAddIntegrationModal = (
  installedIntegrationInfo: InstalledIntegrationInfoDto,
) => {
  IntegrationModalState.installedIntegrationInfo.set(installedIntegrationInfo);
  IntegrationModalState.integrationInfo.set(EMPTY_INTEGRATION);
  IntegrationModalState.integrationInfo.mode.set(installedIntegrationInfo.mode);
  IntegrationModalState.integrationInfo.aggregatorType.set(installedIntegrationInfo.type);
  IntegrationModalState.integrationInfo.integrationRequiredFields.set(installedIntegrationInfo.integrationRequiredFields as IntegrationRequiredFields[]);
  IntegrationModalState.isEdit.set(false);
  IntegrationModalState.open.set(true);
}

export const openEditIntegrationModal = (
  installedIntegrationInfo: InstalledIntegrationInfoDto,
) => {
  IntegrationModalState.installedIntegrationInfo.set(installedIntegrationInfo);
  IntegrationModalState.integrationInfo.set({
    id: installedIntegrationInfo.integrationFormData?.id,
    name: installedIntegrationInfo.integrationFormData?.name,
    mode: installedIntegrationInfo.mode,
    aggregatorType: installedIntegrationInfo.type,
    apiKey: installedIntegrationInfo.integrationFormData?.apiKey,
    apiSecret: installedIntegrationInfo.integrationFormData?.apiSecret,
    apiUrl: installedIntegrationInfo.integrationFormData?.apiUrl,
    apiFilter: installedIntegrationInfo.integrationFormData?.apiFilter,
    ignoreExternalUsers: installedIntegrationInfo.integrationFormData?.ignoreExternalUsers,
    enabled: installedIntegrationInfo.integrationFormData?.enabled,
    integrationRequiredFields: installedIntegrationInfo.integrationRequiredFields as IntegrationRequiredFields[]
  } as unknown as IntegrationFormData);
  IntegrationModalState.isEdit.set(true);
  IntegrationModalState.open.set(true);
}

export default function IntegrationModal() {
  const navigate = useNavigate();
  const modalState = useState(IntegrationModalState);

  const hideAdvanced = useState(true);

  const isSaving = useState(false);

  const optionalURL = !modalState.installedIntegrationInfo.integrationRequiredFields.value?.find(e => e === IntegrationRequiredFields.API_URL);
  const optionalFilter = !modalState.installedIntegrationInfo.integrationRequiredFields.value?.find(e => e === IntegrationRequiredFields.API_FILTER);

    function handleSave(values: FormikValues) {
    const formData = values as IntegrationFormData;

    if (modalState.integrationInfo.mode.value === "oauth") {
      save(formData);
    } else {
      integrationService.testConnection(formData)
        .then((testResult) => {
          if (testResult) {
            save(formData);
          }
        })
        .finally(() => isSaving.set(false));
    }
  }

  function save(formData: IntegrationFormData) {
    integrationService.saveIntegration(modalState.isEdit.value, formData)
      .then(() => {
        modalState.open.set(false);
        navigate(modalState.isEdit.value ? "/integrations" : "/integrations/add");
      })
      .finally(() => isSaving.set(false));
  }

  const nameRef = useRef<HTMLInputElement>(null);
  const apiKeyRef = useRef<HTMLInputElement>(null);
  const apiSecretRef = useRef<HTMLInputElement>(null);
  const apiUrlRef = useRef<HTMLInputElement>(null);
  const apiFilterRef = useRef<HTMLInputElement>(null);
  const ignoreExternalUsersRef = useRef<HTMLInputElement>(null);
  const enabledRef = useRef<HTMLInputElement>(null);

  const urlField = (values: FormikValues, touched: FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>, handleChange: { (e: React.ChangeEvent<any>): void; <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : ((e: (string | React.ChangeEvent<any>)) => void) }, handleBlur: { (e: React.FocusEvent<any>): void; <T = any>(fieldOrEvent: T): T extends string ? ((e: any) => void) : void }, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    return <>
      <S.FieldWrapper>
        <S.LabelWrapper>
          API URL
          {
            optionalURL &&
            <> (optional)</>
          }
        </S.LabelWrapper>
        <S.ElementWrapper>
          <TextField
            ref={apiUrlRef}
            name="apiUrl"
            value={values.apiUrl}
            clearable
            onChange={handleChange}
            onBlur={handleBlur}
            onClear={() => setFieldValue("apiUrl", "")}
            border={false}
            placeholder={"Enter an API URL"}
          />
          <S.Error>
            {
              !!errors.apiUrl && touched.apiUrl ? errors.apiUrl : ""
            }
          </S.Error>
        </S.ElementWrapper>
      </S.FieldWrapper>
      <S.DescriptionWrapper className={`${!optionalURL || (optionalURL && !optionalFilter) ? "pb-0" : ""}`}>
        Required for APIs such as Zendesk or on-premise tools
      </S.DescriptionWrapper>
    </>
  };


  const filterField = (values: FormikValues, touched: FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>, handleChange: { (e: React.ChangeEvent<any>): void; <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : ((e: (string | React.ChangeEvent<any>)) => void) }, handleBlur: { (e: React.FocusEvent<any>): void; <T = any>(fieldOrEvent: T): T extends string ? ((e: any) => void) : void }, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    return <>
      <S.FieldWrapper>
        <S.LabelWrapper>
          API Filter
          {
            optionalFilter &&
            <> (optional)</>
          }
        </S.LabelWrapper>
        <S.ElementWrapper>
          <TextField
            ref={apiFilterRef}
            name="apiFilter"
            value={values.apiFilter}
            clearable
            onChange={handleChange}
            onBlur={handleBlur}
            onClear={() => setFieldValue("apiFilter", "")}
            border={false}
            placeholder={"Enter an API Filter"}
          />
          <S.Error>
            {
              !!errors.apiFilter && touched.apiFilter ? errors.apiFilter : ""
            }
          </S.Error>
        </S.ElementWrapper>
      </S.FieldWrapper>
      <S.DescriptionWrapper className={"pb-0"}>For example, a specific organization to filter for in Github or a tenant id in Office 365</S.DescriptionWrapper>
    </>
  };


  return (
    <>
      <CommonModal openState={modalState.open}>
        <S.Wrapper>
          <Formik
            initialValues={{...modalState.integrationInfo.value}}
            validationSchema={IntegrationValidator}
            validateOnMount={true}
            onSubmit={() => {}}
          >
            {({
                values,
                errors,
                isValid,
                setFieldValue,
                touched,
                handleChange,
                handleBlur,
              }) => (

              <Form>
                <S.MainContainer>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-pl-grayscale-black"
                  >
                    <ModalTextTitle title={"Enter your connection details"} />
                  </Dialog.Title>

                  <S.ContentWrapper>
                    <S.FieldWrapper>
                      <S.LabelWrapper>
                        Name
                      </S.LabelWrapper>
                      <S.ElementWrapper>
                        <TextField
                          ref={nameRef}
                          name="name"
                          value={values.name}
                          clearable
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onClear={() => setFieldValue("name", "")}
                          border={false}
                          placeholder={"Enter a description name for this connection"}
                        />
                        <S.Error>
                          {
                            !!errors.name && touched.name ? errors.name : ""
                          }
                        </S.Error>
                      </S.ElementWrapper>
                    </S.FieldWrapper>

                    {
                      modalState.integrationInfo.mode.value === 'form' &&
                      <>
                        <S.FieldWrapper>
                          <S.LabelWrapper>
                            API Key or Username
                          </S.LabelWrapper>
                          <S.ElementWrapper>
                            <TextField
                              ref={apiKeyRef}
                              name="apiKey"
                              value={values.apiKey}
                              clearable
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onClear={() => setFieldValue("apiKey", "")}
                              border={false}
                              placeholder={"Enter a API Key or Username"}
                            />
                            <S.Error>
                              {
                                !!errors.apiKey && touched.apiKey ? errors.apiKey : ""
                              }
                            </S.Error>
                          </S.ElementWrapper>
                        </S.FieldWrapper>

                        <S.FieldWrapper>
                          <S.LabelWrapper>
                            API Secret
                          </S.LabelWrapper>
                          <S.ElementWrapper>
                            <TextField
                              ref={apiSecretRef}
                              name="apiSecret"
                              value={values.apiSecret}
                              clearable
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onClear={() => setFieldValue("apiSecret", "")}
                              border={false}
                              placeholder={"Enter a password or access token"}
                              type="password"
                            />
                            <S.Error>
                              {
                                !!errors.apiSecret && touched.apiSecret ? errors.apiSecret : ""
                              }
                            </S.Error>
                          </S.ElementWrapper>
                        </S.FieldWrapper>
                      </>
                    }

                    {
                      modalState.integrationInfo.mode.value === 'form' &&
                      !optionalURL &&
                      urlField(values, touched, errors, handleChange, handleBlur, setFieldValue)
                    }

                    {
                      modalState.integrationInfo.mode.value === 'form' &&
                      !optionalFilter &&
                      filterField(values, touched, errors, handleChange, handleBlur, setFieldValue)
                    }
                  </S.ContentWrapper>

                  {
                    modalState.integrationInfo.mode.value === 'form' && (optionalURL || optionalFilter) &&
                    <S.ContentWrapper>
                      <Button
                        btntype="primary"
                        minimal={true}
                        icon={<ChevronIcon className={`h-2 w-2 ml-3 text-pl-primary-green-default ${hideAdvanced.value ? "-rotate-90" : ""} `} />}
                        iconposition="right"
                        onClick={() => hideAdvanced.set((prevState) => !prevState)}
                        className={`gap-0 !pt-2 ${hideAdvanced.value ? "" : "!pb-5"}`}
                      >
                        Advanced
                      </Button>
                      {
                        !hideAdvanced.value &&
                        optionalURL &&
                        urlField(values, touched, errors, handleChange, handleBlur, setFieldValue)
                      }
                      {
                        !hideAdvanced.value &&
                        optionalFilter &&
                        filterField(values, touched, errors, handleChange, handleBlur, setFieldValue)
                      }
                    </S.ContentWrapper>
                  }

                  <S.ContentWrapper>
                    <div className={"pt-4"}>
                      {
                        modalState.installedIntegrationInfo.provider.value === 'builtin' &&
                        <>
                          <Checkbox
                            ref={ignoreExternalUsersRef}
                            name={"ignoreExternalUsers"}
                            label="Ignore Unmapped External Users"
                            labelFor="ignoreExternalUsers"
                            checked={values.ignoreExternalUsers}
                            onChange={handleChange}
                          />
                          <S.DescriptionWrapper>Don't show users from outside your organization as unmapped for this integration.</S.DescriptionWrapper>
                        </>
                      }
                    <Checkbox
                      ref={enabledRef}
                      name={"enabled"}
                      label="Integration Enabled"
                      labelFor="enabled"
                      checked={values.enabled}
                      onChange={handleChange}
                    />
                    </div>
                  </S.ContentWrapper>

                  <S.ButtonsWrapper>
                    <Button onClick={() => {
                      modalState.open.set(false);
                      hideAdvanced.set(true);
                      isSaving.set(false);
                    }}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      btntype="primary"
                      $shadow={true}
                      disabled={!isValid || isSaving.value}
                      onClick={() => {
                        isSaving.set(true);
                        handleSave(values);
                      }}
                    >
                      Done
                    </Button>
                  </S.ButtonsWrapper>
                </S.MainContainer>
              </Form>
            )}
          </Formik>
        </S.Wrapper>
      </CommonModal>
    </>
  );
}