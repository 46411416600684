import tw from "tailwind-styled-components";
import { SpeechBalloonProps } from ".";

const wrapperModifiers = {
  primary: `
    bg-pl-primary-green-default
    text-pl-grayscale-white
  `,

  default: `
    bg-pl-grayscale-white
    text-pl-primary-green-dark

  `,
};

const arrowSideModifiers = {
  left: `
    after:left-[10%]
    after:border-l-0
  `,

  center: `
    after:left-[50%]
  `,

  right: `
    after:right-[10%]
    after:border-r-0
  `,
};

const arrowVerticalModifiers = {
  top: (type: "primary" | "default") => `
    after:top-0
    after:border-t-0
    after:mt-[-12px]
    ${
      type === "primary"
        ? "after:border-b-pl-primary-green-default"
        : "after:border-b-pl-grayscale-white"
    }
  `,

  bottom: (type: "primary" | "default") => `
    after:bottom-0
    after:border-b-0
    after:mb-[-12px]
    ${
      type === "primary"
        ? "after:border-t-pl-primary-green-default"
        : "after:border-t-pl-grayscale-white"
    }
  `,
};

export const Wrapper = tw.div<Omit<SpeechBalloonProps, "children">>`
  ${({ type, $bolded, $arrowSidePosition, $arrowVerticalPosition }) => `
    max-w-[350px]
    text-sm
    p-2
    rounded-[10px]
    drop-shadow
    relative

    after:content-['']
    after:absolute
    after:w-0
    after:h-0
    after:border-[12px]
    after:border-transparent
    after:ml-[-11px]

    ${!!type && wrapperModifiers[type]}
    ${!!$arrowSidePosition && arrowSideModifiers[$arrowSidePosition]}
    ${
      !!$arrowVerticalPosition &&
      arrowVerticalModifiers[$arrowVerticalPosition](type!)
    }
    ${!!$bolded && "font-semibold"}
  `}
`;
