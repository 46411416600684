import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  inline-block
  border
  w-full
  max-w-[550px]
  max-h-[850px]
  p-6
  overflow-y-auto
  text-left
  align-middle
  transition-all
  transform
  bg-pl-grayscale-white
  shadow-xl
  rounded-2xl
`;

export const MainContainer = tw.div`
  grid
  gap-6
  divide-y
  w-full
`;

export const ContentWrapper = tw.div`
  pt-3
  grid
  gap-4
`;

export const ContentTitleWrapper = tw.div`
  text-sm
`;

export const FieldWrapper = tw.div`
  gap-1
  grid
`;

export const LabelWrapper = tw.div`
  text-xs
  font-semibold
  text-pl-grayscale-gray
  h-4
`;

export const DescriptionWrapper = tw.div`
  text-[10px]
  font-medium
  text-pl-grayscale-gray
  pt-2
`;

export const ButtonsWrapper = tw.div`
  pt-6
  flex
  items-center
  justify-between
`;

export const Error = tw.div`
  text-pl-primary-red-default
  text-xs
  font-semibold
  py-1
  border-none
`;