export const matrixOptions = {
  plugins: {
    legend: false,
    tooltip: {
      callbacks: {
        title() {
          return "";
        },
        label(context) {
          const v = context.dataset.data[context.dataIndex];
          return ["group: " + v.x, "variable: " + v.y, "value: " + v.v];
        },
      },
    },
  },
  scales: {
    x: {
      type: "category",
      offset: true,
      labels: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
      ticks: {
        display: true,
        padding: 5,
      },
      grid: {
        display: false,
        drawBorder: false,
        tickLength: 0,
      },
    },
    y: {
      type: "category",
      labels: ["v1", "v2", "v3", "v4", "v5", "v6", "v7", "v8", "v9", "v10"],
      offset: true,
      ticks: {
        display: true,
        padding: 5,
      },
      grid: {
        display: false,
        drawBorder: false,
        tickLength: 0,
      },
    },
  },
};
