import React, {useLayoutEffect, useRef} from "react";
import {chatSession, Message, updateMessage} from "model/chat-session";
import {LoadingWidget} from "components/utils/LoadingWidget";
import chatService from "services/chat";
import BotReply from "./BotReply";
import {LexiReply} from "model/lexi";
import {ViewGridAddIcon} from "@heroicons/react/outline";
import {openDashboardListSelectionModal} from "components/ui/Dashboards/DashboardListModal";

import * as S from "./styles";
import {useState} from "@hookstate/core";

const ChatMessage = ({ message }: { message: Message }) => {
  const scrollMarker = useRef(null);

  const chatResult = useState<LexiReply>(() => {
    if (message.sender === 'bot' && message.interpretText) {
      return chatService.getChatResultForConversation(message.text, chatSession.id.get());
    } else return Promise.reject();
  });

  useLayoutEffect(() => {
    const { current } = scrollMarker;
    // @ts-ignore
    current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, []);

  const handleAddToDashboard = () => {
    openDashboardListSelectionModal(message.text);
  };

  return (
    <>
      {message.sender === "user" && (
        <>
          <S.ChatMessageWrapper ref={scrollMarker}>
            <S.ChatMessageContainer>
                <S.ChatMessageSpan>
                  {message.text}
                </S.ChatMessageSpan>
            </S.ChatMessageContainer>
          </S.ChatMessageWrapper>
          <S.ChatAddToDashWrapper>
            <S.ChatAddToDashContainer title={"Add to dashboard"} onClick={handleAddToDashboard}>
              <ViewGridAddIcon
                className={
                  "h-6 w-6 text-pl-primary-green-default cursor-pointer"
                }
              />
            </S.ChatAddToDashContainer>
          </S.ChatAddToDashWrapper>
        </>
      )}

      {message.sender === "bot" && (
        <>
          <S.ChatMessageBotWrapper ref={scrollMarker}>
            <S.ChatMessageBotContainer>
              <S.FullWidthWrap>
                {message.interpretText ? (
                  <>
                    {message.botResponse ? (
                      <>
                        <BotReply lexiReply={message.botResponse} withChartTitle={true} />
                      </>
                    ) : (
                      <LoadingWidget<LexiReply>
                        value={chatResult}
                        builder={(response) => {
                          updateMessage({
                            ...message,
                            botResponse: response,
                          });

                          return <BotReply lexiReply={response} withChartTitle={true} />;
                        }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <S.LexiReplyContainerValid>
                      {message.text}
                    </S.LexiReplyContainerValid>
                  </>
                )}
              </S.FullWidthWrap>
            </S.ChatMessageBotContainer>
          </S.ChatMessageBotWrapper>
        </>
      )}
    </>
  );
};

export default ChatMessage;