import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  bg-pl-grayscale-white
  flex
  px-2
  pb-2
  rounded-2xl
  shadow
  h-full
  mt-6
`;

export const MainContainer = tw.div`
  grid
  gap-6
  divide-y
  w-full
`;

export const SubMainContainer = tw.div`
  grid
  pt-6
`;

export const ButtonsWrapper = tw.div`
  flex
  gap-2
  justify-self-end
  px-2
  pb-6
`;


type FormWrapperProps = {
  horizontalDisplay: boolean;
  numColumns: number;
}

export const FormWrapper = tw.div<FormWrapperProps>`
  ${({ horizontalDisplay, numColumns }) => `
    p-2
    gap-4
    grid

    ${!!horizontalDisplay && numColumns === 4 ? "grid-cols-4" : ""}
    ${!!horizontalDisplay && numColumns === 2 ? "grid-cols-2" : ""}
  `}
`;

export const FieldWrapper = tw.div`

`;

export const LabelWrapper = tw.div`
  text-xs
  font-semibold
  text-pl-grayscale-gray
`;

export const Error = tw.div`
  text-pl-primary-red-default
  text-xs
  font-semibold
  h-0
  pt-1
  border-none
`;
