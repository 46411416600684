import React, { HTMLAttributes } from "react";

type WidgetContainerPropType = {
  children: React.ReactNode;
  shadow?: boolean;
  bgColor?: string;
  textAlign?: string;
  padding?: string;
  rounded?: boolean;
  heightAuto?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const DEFAULT = {
  shadow: true,
  bgColor: "bg-pl-grayscale-white",
  textAlign: "text-center",
  padding: "p-3",
  rounded: false,
  heightAuto: false,
};
export function WidgetContainer({
  children,
  shadow = DEFAULT.shadow,
  bgColor = DEFAULT.bgColor,
  textAlign = DEFAULT.textAlign,
  padding = DEFAULT.padding,
  rounded = DEFAULT.rounded,
  heightAuto = DEFAULT.heightAuto,
  ...props
}: WidgetContainerPropType) {
  return (
    <div
      className={`w-full ${
        heightAuto ? "h-auto" : "h-full"
      } ${textAlign} ${bgColor} ${shadow ? "shadow" : ""} ${
        rounded ? "rounded-2xl" : ""
      } ${padding} ${props.className}`}
    >
      {children}
    </div>
  );
}
