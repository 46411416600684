import React from "react";
import {useNavigate} from "react-router-dom";
import {Employee, Team} from "model/employee";
import { CommonModal } from "components/utils/Modal/CommonModal";
import {createState, useState} from "@hookstate/core";
import Button from "components/generics/Button";
import {Form, Formik, FormikValues} from "formik";
import {Dialog} from "@headlessui/react";
import ModalTabsTitle, {TabsTitle} from "components/utils/Modal/ModalTabsTitle/ModalTabsTitle";
import {DepartmentFormData} from "model/department";
import {DepartmentValidator} from "validations/DepartmentValidator";
import {BasicInfoSection} from "../BasicInfoSection";
import {TeamsSkillsSection} from "../TeamsSkillsSection";
import departmentService from "services/departmentService";

import * as S from "./styles";

export const DepartmentModalState = createState({
  open: false,
  departmentInfo: {
    id: 0,
    name: "",
    managerId: 0,
    departmentType: "",
    teams: [],
    teamsSelected: [],
    skills: [],
    active: true,
  } as DepartmentFormData,
  managers: [] as Employee[],
  teams: [] as Team[],
});

export const openDepartmentModal = (
  managers: Employee[],
  teams: Team[],
) => {
  DepartmentModalState.managers.set(managers);
  DepartmentModalState.teams.set(teams);
  DepartmentModalState.open.set(true);
}


export default function AddDepartmentModal() {
  const navigate = useNavigate();

  const modalState = useState(DepartmentModalState);

  const selectedNavIndex = useState(0);

  const tabsTitle = [
    {
      tabNumber: 1,
      tabTitle: "Basic information",
    },
    {
      tabNumber: 2,
      tabTitle: "Departments's Teams & Skills",
    } as TabsTitle
  ];

  function handleSave(values: FormikValues) {
    const ids = values.teamsSelected.map((val) => val.value);

    const departmentToSave = {
      id: 0,
      name: values.name,
      managerId: values.managerId,
      departmentType: values.departmentType ? values.departmentType : "",
      teams: DepartmentModalState.teams.value.filter((team) => ids.includes(team.id)),
      teamsSelected: values.teamsSelected,
      skills: values.skills,
      active: true,
    } as DepartmentFormData;

    departmentService.saveDepartment(departmentToSave)
      .then(function (savedDepartment) {
      modalState.open.set(false);
      navigate("/departments", {state: savedDepartment.id});
    });
  }

  return (
    <>
      <CommonModal openState={modalState.open}>
        <S.Wrapper>
          <Formik
            initialValues={{...modalState.departmentInfo.value}}
            validationSchema={DepartmentValidator}
            validateOnMount={true}
            onSubmit={() => {}}
          >
            {({
                values,
                errors,
                isValid,
                setFieldValue,
                setFieldTouched,
                touched,
                handleChange,
                handleBlur,
              }) => (

              <Form>
                <S.MainContainer>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-pl-grayscale-black"
                  >
                    <ModalTabsTitle tabs={tabsTitle} currentTab={selectedNavIndex.value + 1} />
                  </Dialog.Title>


                  <S.ContentWrapper>
                    {
                      selectedNavIndex.value === 0 ?
                        <BasicInfoSection managers={modalState.managers.value}
                                          values={values}
                                          errors={errors}
                                          setFieldValue={setFieldValue}
                                          setFieldTouched={setFieldTouched}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          touched={touched}
                                          horizontalDisplay={false}
                                          numColumns={4}
                        />
                        :
                        <TeamsSkillsSection teams={modalState.teams.value}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            horizontalDisplay={false}
                                            numColumns={2}
                        />
                    }
                  </S.ContentWrapper>

                  <S.ButtonsWrapper>
                    {
                      selectedNavIndex.value === 0 ?
                        <Button onClick={() => modalState.open.set(false)}>
                          Close
                        </Button>
                        :
                        <Button onClick={() => selectedNavIndex.set(0)}>
                          Previous
                        </Button>
                    }
                    {
                      selectedNavIndex.value === 0 ?
                        <Button
                          btntype="primary"
                          $shadow={true}
                          disabled={!isValid}
                          onClick={() => {
                            selectedNavIndex.set(1)
                          }}
                        >
                          Next
                        </Button>
                        :
                        <Button
                          type="submit"
                          btntype="primary"
                          $shadow={true}
                          disabled={!isValid}
                          onClick={() => handleSave(values)}
                        >
                          Done
                        </Button>
                    }
                  </S.ButtonsWrapper>
                </S.MainContainer>
              </Form>
            )}
          </Formik>
        </S.Wrapper>
      </CommonModal>
    </>
  );
}
