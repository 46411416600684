import {useEffect, useMemo} from 'react';
import {useState} from "@hookstate/core";

export default function useOnScreen(ref) {

  const isIntersecting = useState<boolean>(false)

  const observer = useMemo(() => new IntersectionObserver(
    ([entry]) => isIntersecting.set(entry.isIntersecting),
  ), [ref])

  useEffect(() => {
    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect() }
  }, [])

  return isIntersecting
}
