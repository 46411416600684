import tw from "tailwind-styled-components";

export const MainWrapper = tw.div`
  flex
  items-center
  justify-center
  w-full
`;

export const WrapperComponent = tw.div`
  relative
`;

export const ValuesWrapper = tw.span`
  inline-block
  w-full
`;

export const ValueWrapper = tw.div`
  relative
  inline-block
  rounded-lg
  bg-pl-primary-green-light
  px-2
  py-1
  mr-2
  mb-2
  text-sm
  font-bold
  text-pl-primary-green-default
`;

export const ValueTag = tw.div`
  flex
`;

export const TexfieldWrapper = tw.div`
  sticky
  top-0
  flex
  w-full
  flex-wrap
  items-stretch
  pt-2
  z-20
  bg-pl-grayscale-white
  text-pl-grayscale-midgray
`;

export const CategoryWrapper = tw.div`
  px-2
  divide-y
  overflow-hidden
  shadow-lg
  ring-1
  ring-black
  ring-opacity-5
  bg-pl-grayscale-white
`;

export const OptionWrapper = tw.div`
  flex
  items-center
  justify-between
  py-3
  hover:text-pl-primary-green-default
`;

export const OptionLabel = tw.span<{ isSelected: boolean }>`
  ${({isSelected}) => `
    ml-5
    text-xs
    font-bold

    ${isSelected && "text-pl-primary-green-default"}
  `}
`;

export const NewDivWrapper = tw.div`
  flex
  items-center
  justify-between
  py-3
  hover:text-pl-primary-green-default
  hover:cursor-pointer
`;

export const NewDivSpan = tw.span`
  ml-5
  text-xs
  font-bold
`;