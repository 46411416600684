import { GraphNode, GraphReport } from "model/lexi";
import { colorValuesNoWhites } from "./constants";
import renderInternal from "./d3renderer";
import "./graph.css";

export const render = ({
                         container,
                         context,
                         width,
                         height,
                         report,
                         unfilteredLinkAvg,
                         onSelect,
                         onSetNodeContext,
                         onClick
                       }: {
  container: HTMLDivElement;
  context: string;
  width: number;
  height: number;
  report: GraphReport;
  unfilteredLinkAvg: number,
  onSelect: (item: GraphNode) => void;
  onSetNodeContext: (item: GraphNode) => void;
  onClick: (item: GraphNode, withMetaKey: boolean) => void;
}) => {
  const ret = renderInternal({
    container,
    context,
    width,
    height,
    report,
    unfilteredLinkAvg,
    makeDisplay,
    colors: colorValuesNoWhites,
    onSelect,
    onSetNodeContext,
    onClick
  });

  return ret as ({ stop: () => any });
};

const makeDisplay = (node: GraphReport["nodes"][0]) => {
  return {
    ...node,
    name: node.name
      .replace(/[^a-zA-Z0-9+ ]/g, "")
      .split(" ")
      .map((s) => s.trim())
      .filter((s) => s)
      .map((s) => s[0].toLocaleUpperCase())
      .slice(0, 2)
      .join(""),
  };
};
