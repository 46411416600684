import React from "react";

import * as S from "./styles";

import { ReactComponent as ThumbUpIcon } from "icons/sfThumbUp.svg";
import { ReactComponent as AlertIcon } from "icons/alert.svg";

type SFCategory = {
  label: string,
  min: number,
  max: number,
  color: string,
  bgcolor: string,
  bgIconColor?: string,
  icon?: React.ReactNode,
};

const categories = [{
  label: "Highly Engaged",
  min: 70,
  max: 100,
  color: "text-[#00C288]",
  bgcolor: "bg-[#EFFAF2]",
  bgIconColor: "bg-[#00C288]",
  icon: <ThumbUpIcon />
}, {
  label: "Engaged",
  min: 60,
  max: 70,
  color: "text-[#00ACC2]",
  bgcolor: "bg-[#EFF9FB]",
  bgIconColor: "bg-[#00ACC2]",
  icon: <ThumbUpIcon />
}, {
  label: "Neutral",
  min: 45,
  max: 60,
  color: "text-[#131A21]",
  bgcolor: "bg-[#FFF3B3]",
}, {
  label: "Disengaged",
  min: 30,
  max: 45,
  color: "text-[#FF8718]",
  bgcolor: "bg-[#FFF4EB]",
  bgIconColor: "bg-[#FF8718]",
  icon: <AlertIcon />
}, {
  label: "Highly Disengaged",
  min: 0,
  max: 30,
  color: "text-[#FB6C71]",
  bgcolor: "bg-[#FFF3F1]",
  bgIconColor: "bg-[#FB6C71]",
  icon: <AlertIcon />
}] as SFCategory[];

export default function SFCategories({title,
                                     value
}: {
  title: string | undefined,
  value: number,
}) {
  const cat = Math.round(value) === 100
    ? categories[0]
    : categories.find(cat => cat.min <= Math.round(value) && cat.max > Math.round(value)) as SFCategory;

  return (
    <S.Wrapper>
      <S.CategoryWrapper className={`${cat.bgcolor}`}>
        <S.CategoryTitleWrapper>
          {
            cat.icon &&
            <S.CategoryIconWrapper className={`${cat.bgIconColor}`}>
              {
                cat.icon
              }
            </S.CategoryIconWrapper>
          }
          <S.CategoryTextWrapper className={`${cat.color}`}>
            {
              cat.label
            }
          </S.CategoryTextWrapper>
        </S.CategoryTitleWrapper>
        <S.CategoryValueWrapper>
          {
            Math.round(value)
          }
        </S.CategoryValueWrapper>
      </S.CategoryWrapper>
    </S.Wrapper>
  );
}