import {Employee, Location} from "model/employee";
import TextField from "components/generics/TextField";
import {FormikErrors, FormikTouched, FormikValues} from "formik";
import CustomSelect from "components/utils/CustomSelect/CustomSelect";
import {SelectSearchOption} from "components/utils/SelectSearch";
import React, {useRef} from "react";
import DatePicker from "react-datepicker";
import {EmployeeAvatar} from "components/utils/Avatar";

import * as S from "./styles";

import {ReactComponent as CameraIcon} from "icons/camera.svg";
import {ReactComponent as EditIcon} from "icons/edit.svg";

type Props = {
  employee: Employee;
  locations: Location[];
  values: FormikValues;
  errors: FormikErrors<FormikValues>
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.ChangeEvent<any>) => void;
  touched: FormikTouched<FormikValues>;
  isEdit: boolean;
};

export function EmployeeDetailsSection({ employee,
                                         locations,
                                         values,
                                         errors,
                                         setFieldValue,
                                         handleChange,
                                         handleBlur,
                                         touched,
                                         isEdit,
}: Props) {
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const linkedinRef = useRef<HTMLInputElement>(null);
  const jobTitleRef = useRef<HTMLInputElement>(null);

  const locationsList = () => {
    return locations.map((location) => {
      return {
        label: location.name,
        value: location.id,
      } as SelectSearchOption;
    });
  };

  return (
    <>
      <S.FormWrapper>
        <S.FormAvatarWrapper>
          <S.FormPhotoWrapper>
            {
              values.avatar ?
                <EmployeeAvatar
                  size={5}
                  employee={employee}
                  avatarColor={false}
                  linkTo={false}
                  active={employee.active}
                />
                :
                <S.PhotoIconWrapper>
                  <CameraIcon />
                </S.PhotoIconWrapper>
            }
            <S.EditPhotoIconWrapper onClick={() => {}}>
              <EditIcon />
            </S.EditPhotoIconWrapper>
          </S.FormPhotoWrapper>
        </S.FormAvatarWrapper>

        <S.FormLineWrapper horizontalDisplay={true} numColumns={2}>
          <S.FieldWrapper>
            <S.LabelWrapper>
              Name
            </S.LabelWrapper>
            <S.ElementWrapper>
              <TextField
                ref={nameRef}
                name="name"
                value={values.name}
                clearable
                onChange={handleChange}
                onBlur={handleBlur}
                onClear={() => setFieldValue("name", "")}
                border={false}
                placeholder={"Enter a name"}
              />
              <S.Error>
                {
                  !!errors.name && touched.name ? errors.name : ""
                }
              </S.Error>
            </S.ElementWrapper>
          </S.FieldWrapper>

          <S.FieldWrapper>
            <S.LabelWrapper>
              Email
            </S.LabelWrapper>
            <S.ElementWrapper>
              <TextField
                ref={emailRef}
                name="email"
                value={values.email}
                clearable
                onChange={handleChange}
                onBlur={handleBlur}
                onClear={() => setFieldValue("email", "")}
                border={false}
                placeholder={"Enter an email"}
                disabled = {isEdit}
              />
              <S.Error>
                {
                  !!errors.email && touched.email ? errors.email : ""
                }
              </S.Error>
            </S.ElementWrapper>
          </S.FieldWrapper>
        </S.FormLineWrapper>

        <S.FormLineWrapper horizontalDisplay={true} numColumns={2}>
          <S.FieldWrapper>
            <S.LabelWrapper>
              Linkedin
            </S.LabelWrapper>
            <S.ElementWrapper>
              <TextField
                ref={linkedinRef}
                name="linkedin"
                value={values.linkedin}
                clearable
                onChange={handleChange}
                onBlur={handleBlur}
                onClear={() => setFieldValue("linkedin", "")}
                border={false}
                placeholder={"Paste a link"}
              />
              <S.Error />
            </S.ElementWrapper>
          </S.FieldWrapper>

          <S.FieldWrapper>
            <S.LabelWrapper>
              Title
            </S.LabelWrapper>
            <S.ElementWrapper>
              <TextField
                ref={jobTitleRef}
                name="jobTitle"
                value={values.jobTitle}
                clearable
                onChange={handleChange}
                onBlur={handleBlur}
                onClear={() => setFieldValue("jobTitle", "")}
                border={false}
                placeholder={"Enter a job title"}
              />
              <S.Error />
            </S.ElementWrapper>
          </S.FieldWrapper>
        </S.FormLineWrapper>

        <S.FormLineWrapper horizontalDisplay={true} numColumns={2}>
          <S.FieldWrapper>
            <S.LabelWrapper>
              Join Date
            </S.LabelWrapper>
            <S.ElementWrapper>
              <S.TimePickerWrapper>
                <DatePicker
                  selected={
                    values.joinDateStr === ""
                      ? new Date()
                      : new Date(values.joinDateStr)
                  }
                  onChange={(date: Date) =>
                    setFieldValue("joinDateStr", date.toISOString())
                  }
                  className={"w-full min-w-0 rounded-[10px] border-none text-pl-grayscale-black placeholder-pl-grayscale-gray bg-pl-variant-beige text-sm"}
                />
              </S.TimePickerWrapper>
              <S.Error />
            </S.ElementWrapper>
          </S.FieldWrapper>

          <S.FieldWrapper>
            <S.LabelWrapper>
              Location
            </S.LabelWrapper>
            <S.ElementWrapper>
              <CustomSelect
                placeholder={"Select a location"}
                options={locationsList()}
                value={values.location}
                onChange={(opt) => {
                  setFieldValue("location", opt.value);
                }}
                isFixed={false}
                border={false}
              />
              <S.Error />
            </S.ElementWrapper>
          </S.FieldWrapper>
        </S.FormLineWrapper>
      </S.FormWrapper>
    </>
  );
}