import React from "react";
import {Link} from "react-router-dom";

import * as S from "./styles";
import {EmployeeAdditionalInfo} from "../styles";

const EmployeeDetailsItem = ({
                               icon,
                               label,
                               value = [],
                               linkTo
                             }: {
  icon: React.ReactNode;
  label: string;
  value?: string[];
  linkTo?: string[] | null,
}) => {
  return (
    <S.DetailsItem>
      <S.DetailsItemIconAndLabel>
        <S.IconWrapper>{icon}</S.IconWrapper>
        <EmployeeAdditionalInfo>
          {label}
        </EmployeeAdditionalInfo>
      </S.DetailsItemIconAndLabel>

      <EmployeeAdditionalInfo className={` ${linkTo ? "!text-pl-primary-green-default" : "!text-pl-grayscale-black"}`}>
        {
          linkTo ?
            linkTo.map((link, index) => {
              return <>
                <Link className={"text-sm font-normal"}
                      to={linkTo[index]}
                >
                  {value[index]}
                </Link>
                {
                  index < linkTo.length-1 && ", "
                }
              </>
            })
            :
            value.map(val => val).join(", ")
        }
      </EmployeeAdditionalInfo>
    </S.DetailsItem>
  );
};

export default EmployeeDetailsItem;
