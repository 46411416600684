import React from "react";
import { getGreeting } from "utils/text";

import * as S from "./styles";

export default function Greetings({ name }: { name: string }) {
  return (
    <>
      <S.Wrapper>
        {getGreeting()}, {name}
      </S.Wrapper>
    </>
  );
}
