import React from "react";
import TabTitle from "components/structure/TabTitle";
import {Team} from "model/employee";
import {ManageTeam} from "./manageTeam/ManageTeam";
import employeeService from "services/employeeService";
import teamService from "services/teamService";
import {LoadingWidget} from "components/utils/LoadingWidget";
import departmentService from "services/departmentService";

export default function TeamFormPage({ team }: { team?: Team }) {
  TabTitle("Team");

  return (
    <>
      <LoadingWidget
        value={Promise.all([
          employeeService.getManagers(),
          teamService.getAllTeamTypes(),
          employeeService.getActiveEmployees(),
          departmentService.getActiveDepartments(),
        ])}
        builder={([managers, types, employees, departments]) =>
          !!team ? (
            <>
              <ManageTeam team={team} managers={managers} types={types} employees={employees} departments={departments}/>
            </>
          ) : (
            <>
            </>
          )
        }
      />
    </>
  );
}
