import { ChartLabelValuePair } from "model/chart";
import lexi from "./lexi";
import { DataCollection } from "model/lexi";

class FocusAreaService {

  getFocusAreaForEmployee(id: number, numDays?: number) {
    const numDaysBefore = numDays ? numDays : 14;
    return this.createChart(id, numDaysBefore, "employees");
  }

  getFocusAreaForTeam(id: number, numDays?: number) {
    const numDaysBefore = numDays ? numDays : 14;
    return this.createChart(id, numDaysBefore, "teams");
  }

  getFocusAreaForDepartment(id: number, numDays?: number) {
    const numDaysBefore = numDays ? numDays : 14;
    return this.createChart(id, numDaysBefore, "departments");
  }

  async createChart(
    id: number,
    numDays: number,
    dataCollection: DataCollection
  ) {
    return this.getFetchPromisse(id, numDays, dataCollection).then((chart) => {
      return this.createChartFromReportTable(chart);
    });
  }

  async getFetchPromisse(
    id: number,
    numDays: number,
    dataCollection: DataCollection
  ) {
    let query = lexi
      .getQuery('table')
      .collection(dataCollection)
      .start.daysAgo(numDays)
      .out("EVENT_TYPE")
      .count("EVENT_COUNT")
      .groupBy("EVENT_TYPE")
      .sortBy("EVENT_TYPE", "COUNT", "desc");

    if (dataCollection === "employees") {
      query = query.forEmployee(id);
    } else if (dataCollection === "teams") {
      query = query.forTeam(id);
    } else {
      query = query.forDepartment(id);
    }

    return query.fetchActivityByTypeAsTableReport();
  }

  async createChartFromReportTable(
    chart: ChartLabelValuePair[]
  ): Promise<ChartLabelValuePair[]> {
    let subChart: ChartLabelValuePair[];
    if (chart.length > 10) {
      chart = chart.sort((a, b) => (a.value < b.value ? 1 : -1));
      subChart = chart.slice(0, 10);

      let othersTotal = 0;
      chart.slice(10).forEach((pair) => (othersTotal += pair.value));
      if (othersTotal > 0) {
        subChart.push({ label: "Others", value: othersTotal });
      }
    } else {
      subChart = chart;
    }

    return subChart;
  }
}

export default new FocusAreaService();
