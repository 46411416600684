import React from "react";
import { Link, Routes } from "react-router-dom";
import {
  ChartSquareBarIcon,
  CubeTransparentIcon,
} from "@heroicons/react/outline";
import Badge from "components/generics/Badge";
import Dropdown from "components/generics/Dropdown";
import SubmenuItem from "components/generics/SubmenuItem";
import { LoadingWidget } from "components/utils/LoadingWidget";
import { Dashboard } from "model/dashboards";
import useDashboard from "hooks/useDashboard";
import { NavMenuProps } from "./types";
import * as S from "./styles";

import { ReactComponent as DashboardIcon } from "icons/dashboard.svg";

const NavMenu = ({ links, submenus }: NavMenuProps) => {
  const { globalDashboards } = useDashboard();

  return (
    <S.Wrapper>
      {links?.map((link, index) => (
        <Link to={link.path ?? "/"} key={index}>
          <S.MenuItem>
            {link.icon && <S.Icon>{link.icon}</S.Icon>}
            {link.label}
            {!!link.badge && <Badge label={link.badge.label} size="small" />}
          </S.MenuItem>
        </Link>
      ))}

      {submenus?.map((item, index) => (
        <Dropdown
          key={index}
          title={
            <S.MenuItem>
              <S.Title>
                {!!item.icon && <S.Icon>{item.icon}</S.Icon>}
                <S.Label>
                  {item.label}
                  {!!item.badge && (
                    <Badge label={item.badge.label} size="small" />
                  )}
                </S.Label>
              </S.Title>
            </S.MenuItem>
          }
          hasIcon={false}
        >
          <S.LinkWrapper className={"hover:rounded-t-2xl"}>
            <SubmenuItem
              label="View Insights"
              path="/insights"
              icon={<CubeTransparentIcon />}
            />
          </S.LinkWrapper>

          <LoadingWidget<Dashboard[]>
            value={globalDashboards}
            size="small"
            builder={(dashboards: Dashboard[]) => (
              <>
                {dashboards.length ? (
                  dashboards.slice(0, 5).map((dashboard, index) => {
                    return (
                      <S.LinkWrapper key={index}>
                        <SubmenuItem
                          label={dashboard.name}
                          path={`/insights/dashboards/${dashboard.id}`}
                          icon={<ChartSquareBarIcon />}
                        />
                      </S.LinkWrapper>
                    );
                  })
                ) : (
                  <></>
                )}
              </>
            )}
          />

          <S.LinkWrapper className={"hover:rounded-b-2xl"}>
            <SubmenuItem
              label="See all"
              path="/insights/dashboards"
              icon={<DashboardIcon />}
            />
          </S.LinkWrapper>
        </Dropdown>
      ))}
    </S.Wrapper>
  );
};

export default NavMenu;
