import React from "react";
import {Dashboard} from "model/dashboards";
import {CommonModal} from "components/utils/Modal/CommonModal";
import {createState, useHookstate, useState} from "@hookstate/core";
import {Dialog} from "@headlessui/react";
import {LoadingWidget} from "components/utils/LoadingWidget";
import dashboards from "services/dashboards";
import CustomSelect from "components/utils/CustomSelect/CustomSelect";
import {DashboardModalState, openEditDashboardModal,} from "routes/insightsDashboard/manageDashboard/DashboardModal";
import Button from "components/generics/Button";
import {Employee} from "model/employee";
import ModalTextTitle from "components/utils/Modal/ModalTextTitle";

import * as S from "components/utils/Modal/styles";

const dashboardListModalState = createState({
  open: false,
  quickAddQuery: "",
});

export const openDashboardListSelectionModal = (message) => {
  dashboardListModalState.open.set(true);
  dashboardListModalState.quickAddQuery.set(message);
};

export const closeDashboardListSelectionModal = () => {
  dashboardListModalState.open.set(false);
};

export default function DashboardListModal({me}: {me: Employee}) {
  const dashboardList = useState(() => dashboards.getDashboardsAllowedToEdit(me));

  const handleClose = () => {
    closeDashboardListSelectionModal();
    isLoadingDashboard.set(false);
  };

  const handleSave = async () => {
    // openDashboardModal()
    if (selectedDashboard.value) {
      isLoadingDashboard.set(true);
      const dashboard = await dashboards.getDashboardById(
        selectedDashboard.value
      );
      openEditDashboardModal(
        {
          ...dashboard,
          dashboardQueries: [
            ...dashboard.dashboardQueries,
            {
              query: dashboardListModalState.quickAddQuery.value,
              displayHeight: 400,
              displayWidth: 1,
              sortOrder: dashboard.dashboardQueries.length,
            },
          ],
        },
        () => {
          dashboards.saveDashboard(DashboardModalState.dashboard.value);
        }
      );
    }
    handleClose();
  };

  const selectedDashboard = useHookstate<number | null>(null);
  const isLoadingDashboard = useHookstate(false);

  const renderCustomSelect = (list: Dashboard[]) => {
    return (
      <CustomSelect
        options={list.map((dashboard) => ({
          label: dashboard.name,
          value: dashboard.id,
        }))}
        onChange={(selected) => {
          selectedDashboard.set(selected.value);
        }}
        value={selectedDashboard.value || undefined}
        className={"mt-5"}
      />
    );
  };

  return (
    <CommonModal openState={dashboardListModalState.open}>
      <S.Wrapper>
        <S.MainContainer>
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-pl-grayscale-black"
          >
            <ModalTextTitle title={"Select Dashboard"} />
          </Dialog.Title>

          <S.ContentWrapper>
            <S.FormWrapper>
              <LoadingWidget<Dashboard[]>
                value={dashboardList}
                builder={(list) => renderCustomSelect(list)}
                size="medium"
              />
            </S.FormWrapper>
          </S.ContentWrapper>

          <S.ButtonsWrapper>
            <Button $shadow={true}
                    onClick={() => handleClose()}>
              Close
            </Button>
            <Button $shadow={true}
                    btntype="primary"
                    disabled={!selectedDashboard.value}
                    onClick={() => handleSave()}>
              Continue
            </Button>
          </S.ButtonsWrapper>
        </S.MainContainer>
      </S.Wrapper>
    </CommonModal>
  );
}
