import React from "react";
// import { ChatIcon, SearchCircleIcon } from "@heroicons/react/solid";
import ChatInputHeader from "components/ui/Lexichat/ChatInputHeader";

const LexiSearch = () => {
  return (
    <>
      {/*<div className="relative p-0 m-0">*/}
      {/*    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">*/}
      {/*        <span className="text-pl-grayscale-lightgray">*/}
      {/*            <ChatIcon className="w-6"/>*/}
      {/*        </span>*/}
      {/*    </div>*/}
      {/*    <input*/}
      {/*        type="text"*/}
      {/*        className="rounded-full border-pl-grayscale-lightgray border-2 outline-none*/}
      {/*                   w-80 focus:w-96 transition-all h-full*/}
      {/*                   px-10 bg-none placeholder-pl-grayscale-lightgray max-w-full*/}
      {/*                   text-pl-grayscale-gray"*/}
      {/*        placeholder="Ask Lexi"*/}
      {/*    />*/}
      {/*    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">*/}
      {/*        <span className="text-pl-grayscale-lightgray">*/}
      {/*            <SearchCircleIcon className="w-6"/>*/}
      {/*        </span>*/}
      {/*    </div>*/}
      {/*</div>*/}
      <ChatInputHeader
        placeholderText={"Ask Lexi"}
        redirectToInsights={true}
        smallHeight={true}
        inputsize="small"
      />
    </>
  );
};

export default LexiSearch;
