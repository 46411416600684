import React, {useEffect} from "react";
import {Recommendation} from "model/recommendations";
import RecommendationItem from "components/ui/Recommendation/RecommendationItem";
import {PrivacyLevel} from "model/organization";
import privacyLevelService from "services/privacyLevelService";
import auth from "services/auth";

import * as S from "./styles";
import {useState} from "@hookstate/core";
import {LoadingWidget} from "../../../utils/LoadingWidget";

export type RecommendationShuffledCardsProps = {
  title?: string;
  fetchData: () => Promise<Recommendation[]>;
};

const RecommendationShuffledCards = ({
                                       title,
                                       fetchData,
                                     }: RecommendationShuffledCardsProps) => {
  const recommendations = useState(fetchData);
  const selectedIndex = useState(0);

  const handleShuffle = (e: React.MouseEvent<HTMLDivElement>) => {
    const clickedCard = e.currentTarget;
    const activeCard = document.querySelector(".active")!;

    if (clickedCard.classList.contains("active")) return;
    const classesFrom = e.currentTarget.className;
    const classesTo = activeCard.className;
    clickedCard.className = classesTo;
    activeCard.className = classesFrom;
  };

  return (
    <S.Wrapper>
      <S.Title>{title}</S.Title>
      <LoadingWidget<Recommendation[]>
        value={recommendations}
        builder={() =>
          recommendations.length > 0 ?
            <S.Content>
              {
                recommendations.slice(0, 3).map((recommendation, index) => (
                  <S.CardWrapper key={index}
                                 className={`${
                                   index == 1
                                     ? S.firstCardStyles
                                     : index == 0
                                       ? S.activeStyles
                                       : S.lastCardStyles
                                 }`}
                                 onClick={(e) => {
                                   selectedIndex.set(index);
                                   handleShuffle(e);
                                 }}
                  >
                    <S.Card>
                      <RecommendationItem
                        recommendation={recommendation.value}
                        key={`recommendation${recommendation.value.id}`}
                        keepCollapsed={selectedIndex.value === index}
                        isShuffledComponent={true}
                        showFeedbackWrapper={selectedIndex.value === index}
                        showAvatarImage={privacyLevelService.hasAccess(auth.privacyLevel.value as PrivacyLevel)}
                      />
                    </S.Card>
                  </S.CardWrapper>
                ))

              }
            </S.Content>
            :
            <S.NoRecMessageWrapper>
              This employee is either new or you’re all caught up on their High Impacting Factors! Check again soon for
              new updates around their engagement, connectivity, or performance changes.
            </S.NoRecMessageWrapper>
        }
      />
    </S.Wrapper>
  );
};

export default RecommendationShuffledCards;
