import { useState } from "@hookstate/core";
import { useEffect, useRef } from 'react';
import { useLocation, useParams } from "react-router-dom";
import config from '../../config';
import { Me } from 'model/employee';
import auth from 'services/auth';
import {capitalize} from "utils/text";

function getQueryVariables(hash): [[string, string]] {
  return hash.substring(1).split('&')
    .map(item => item.split('='))
    .map(([key, value]) => [key, decodeURIComponent(value)]);
}

export const IntegrationRedirect = () => {
  const {type} = useParams<{ type: string | undefined }>();
  const location = useLocation();
  const data = getQueryVariables(location.hash);
  const action = `${config.appUrl}/integrations/${type}/authorize`;
  const csrf = useState<Me["csrf"] | null>(null);
  const form = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (!csrf.get()) {
      auth.csrfToken.then(token => csrf.set(token));
    }

    if (form.current) {
      form.current.submit();
    }
  }, [csrf.get(), form])

  return <>
    <div className={"w-full text-center"}>
      <img className={"object-center inline"} src="/animations/loading-integration.gif" alt="Loading" id="load"/>

      <h3 className={"mt-5"}>Connecting {capitalize(type as string)} Integration...</h3>

      {csrf.get() && <form method="POST" action={action} ref={form}>
        <input type={'hidden'} name={csrf.get()?.header} value={csrf.get()?.token}/>
        {
          data.map(([key, value]) => <input key={key} name={key} type={'hidden'} value={value}/>)
        }
      </form>}
    </div>
  </>
}
