import React from "react";
import { CommonModal } from "components/utils/Modal/CommonModal";
import { Dialog } from "@headlessui/react";
import { createState, useState } from "@hookstate/core";
import { Dashboard, DashboardQuery } from "model/dashboards";
import DashboardModalQueriesTab from "./DashboardModalQueriesTab";
import Button from "components/generics/Button";
import useDashboard from "hooks/useDashboard";
import ModalTabsTitle, {TabsTitle} from "components/utils/Modal/ModalTabsTitle/ModalTabsTitle";
import DashboardModalBasicInfoTab from "./DashboardModalBasicInfoTab";

import * as S from "./styles";

import { ReactComponent as CloseIcon } from "icons/close.svg"

export const EMPTY_DQUERY = {
  sortOrder: 0,
  query: "",
  name: "",
  displayHeight: 400,
  displayWidth: 1,
} as DashboardQuery;

export const EMPTY_DASHBOARD = {
  name: "",
  description: "",
  publicDash: false,
  dashboardQueries: [EMPTY_DQUERY] as DashboardQuery[]
} as Dashboard;

export const DashboardModalState = createState({
  open: false,
  dashboard: EMPTY_DASHBOARD,
  isEdit: true,
  onSave: () => {},
});

export const openEditDashboardModal = (
  dashboardData: Dashboard,
  onSave: () => void
) => {
  DashboardModalState.set({
    open: true,
    dashboard: {
      id: dashboardData.id,
      name: dashboardData.name ? dashboardData.name : "",
      description: dashboardData.description ? dashboardData.description : "",
      publicDash: dashboardData.publicDash ? dashboardData.publicDash : false,
      ownerId: dashboardData.ownerId,
      dashboardQueries: fillDashboardQueries(dashboardData) as DashboardQuery[],
    },
    isEdit: true,
    onSave: onSave,
  });
};

export const openAddDashboardModal = (
  dashboardData: Dashboard | undefined,
  onSave: () => void
) => {
  DashboardModalState.set({
    open: true,
    dashboard: dashboardData ? {
      id: 0,
      name: "",
      description: dashboardData.description ? dashboardData.description : "",
      publicDash: dashboardData.publicDash ? dashboardData.publicDash : false,
      ownerId: dashboardData.ownerId,
      dashboardQueries: fillDashboardQueries(dashboardData) as DashboardQuery[],
    } : EMPTY_DASHBOARD,
    isEdit: false,
    onSave: onSave,
  });
};

const fillDashboardQueries = (dashboardData: Dashboard) => {
  let queries;
  if (dashboardData.dashboardQueries.length > 0) {
    queries = dashboardData.dashboardQueries.map((dq) => {
      return {
        id: dq.id,
        sortOrder: dq.sortOrder,
        name: dq.name ? dq.name : "",
        query: dq.query,
        displayWidth: dq.displayWidth,
        displayHeight: dq.displayHeight,
      };
    }) as DashboardQuery[];
  } else {
    queries = [EMPTY_DQUERY];
  }
  return queries;
}

export default function DashboardModal() {
  const { updateGlobalDashboardState } = useDashboard();

  const modalState = useState(DashboardModalState);
  const queriesErrors = useState<string[]>([]);

  const selectedNavIndex = useState(0);

  const tabsTitle = [
    {
      tabNumber: 1,
      tabTitle: "Basic dashboard information",
    },
    {
      tabNumber: 2,
      tabTitle: "Dashboard queries",
    } as TabsTitle
  ];

  const closeModal = () => {
    modalState.open.set(false);
    selectedNavIndex.set(0);
  }

  function handleSave() {
    closeModal();
    updateGlobalDashboardState(modalState.dashboard.value);
    return modalState.get({noproxy: true}).onSave();
  };

  const isValid = () => {
    return modalState.dashboard.name.value.length >= 3;
  }

  return (
    <>
      <CommonModal openState={modalState.open}>
        <S.Wrapper>
          <S.MainContainer>
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-pl-grayscale-black flex items-center pb-2"
            >
              <ModalTabsTitle tabs={tabsTitle} currentTab={selectedNavIndex.value + 1} />
              <CloseIcon
                className={"h-5 w-5 my-2.5 cursor-pointer text-pl-grayscale-gray hover:text-pl-grayscale-lightgray"}
                onClick={() => closeModal()}
              />
            </Dialog.Title>

            <S.ContentWrapper>
              {
                selectedNavIndex.value === 0 ?
                  <DashboardModalBasicInfoTab
                    isEdit={modalState.isEdit.value}
                  />
                  :
                  <DashboardModalQueriesTab
                    errors={queriesErrors}
                  />
              }
            </S.ContentWrapper>

            <S.ButtonsWrapper>
              {
                selectedNavIndex.value === 0 ?
                  <Button onClick={() => closeModal()}>
                    Close
                  </Button>
                  :
                  <Button onClick={() => selectedNavIndex.set(0)}>
                    Previous
                  </Button>
              }
              {
                selectedNavIndex.value === 0 ?
                  <Button
                    btntype="primary"
                    $shadow={true}
                    disabled={!isValid()}
                    onClick={() => {
                      selectedNavIndex.set(1)
                    }}
                  >
                    Next
                  </Button>
                  :
                  <Button
                    type="submit"
                    btntype="primary"
                    $shadow={true}
                    disabled={queriesErrors.value.length > 0}
                    onClick={() => handleSave()}
                  >
                    Done
                  </Button>
              }
            </S.ButtonsWrapper>
          </S.MainContainer>

        </S.Wrapper>
      </CommonModal>
    </>
  );
}
