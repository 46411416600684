import tw from "tailwind-styled-components";

export const ActiveComponentWrapper = tw.div`
  flex
  items-center
  justify-center
`;

export const LeftToolTipContainer = tw.div`
    flex
    flex-col
    group
    relative
    items-left
`;

export const ToolTip = tw.div`
    w-56
    h-10
    bottom-10
    pointer-events-none
    absolute
    origin-center
    opacity-0
    transition
    group-hover:opacity-100
`;