import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  inline-block
  border
  w-full
  max-w-[500px]
  p-6
  overflow-hidden
  text-left
  align-middle
  transition-all
  transform
  bg-pl-grayscale-white
  shadow-xl
  rounded-2xl
`;

export const MainContainer = tw.div`
  grid
  gap-6
  divide-y
  w-full
`;

export const ContentWrapper = tw.div`
  pt-4
`;

export const ButtonsWrapper = tw.div`
  pt-6
  flex
  items-center
  justify-between
`;