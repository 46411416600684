import React from "react";
import {classNames} from "../../../utils/component";
import {Tab} from "../types";
import {State, useState} from "@hookstate/core";
import * as S from "./styles";

type Props = {
  tabs: Tab[];
  onSave?: () => any;
  showCancel?: boolean;
  showSave?: boolean;
  showButtons?: boolean;
  indexState?: State<number>;
  clicableTabs?: boolean;
};

export default function VerticalTabbedForm({
  tabs,
  onSave,
  showCancel = true,
  showSave = true,
  showButtons = true,
  indexState,
  clicableTabs = true,
}: Props) {
  const selectedNavIndex = useState(indexState ? indexState.value : 0);

  const renderSelectedTab = () => tabs[selectedNavIndex.value].builder();

  return (
    <S.VerticalTabbedFormContainer>
      <S.VerticalSideTabsContainer>
        <S.VerticalSideTabs>
          {tabs.map((item, index) => (
            <S.TabContainer
              key={`${item.title}-${index}`}
              className={classNames(
                clicableTabs
                  ? index === selectedNavIndex.value
                    ? "text-pl-primary-green-default"
                    : "text-pl-grayscale-gray hover:text-pl-grayscale-darkgray cursor-pointer"
                  : index === selectedNavIndex.value
                  ? "text-pl-primary-green-default"
                  : "text-pl-grayscale-gray",
                "group rounded-2xl px-3 py-3 flex items-center text-sm font-semibold"
              )}
              onClick={() => {
                if (clicableTabs) {
                  selectedNavIndex.set(index);
                }
              }}
            >
                <S.TabIcon
                  className={classNames(
                    clicableTabs
                      ? index === selectedNavIndex.value
                        ? "text-pl-primary-green-default group-hover:text-pl-primary-green-default"
                        : "text-pl-grayscale-gray group-hover:text-pl-grayscale-darkgray"
                      : index === selectedNavIndex.value
                      ? "text-pl-primary-green-default"
                      : "text-pl-grayscale-gray",
                    "flex-shrink-0 -ml-1 mr-3 h-5 w-5"
                  )}
                  aria-hidden="true"
                >
                    {item.icon}
                </S.TabIcon>
                <S.TabTitle>
                    <span>{item.title}</span>
                </S.TabTitle>
            </S.TabContainer>
          ))}
        </S.VerticalSideTabs>
      </S.VerticalSideTabsContainer>

      <S.ContentWrapper>
        {renderSelectedTab()}
      </S.ContentWrapper>
    </S.VerticalTabbedFormContainer>
  );
}