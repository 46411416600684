import React from "react";
import { useParams } from "react-router-dom";
import { LoadingWidget } from "components/utils/LoadingWidget";
import TabTitle from "components/structure/TabTitle";
import Breadcrumbs from "components/generics/Breadcrumbs";
import employeeService from "services/employeeService";
import UserNotificationsTab from "./UserNotificationsTab";
import { Employee, NotificationSettings } from "model/employee";
import DetailsNavbar from "components/generics/DetailsNavbar";

import { ReactComponent as NotificationsIcon } from "icons/notifications.svg";
import {useState} from "@hookstate/core";

export default function UserPreferences() {
  const userId = +useParams<{ id: string }>().id!;

  TabTitle("My Preferences");

  const userNotifications = useState<NotificationSettings>(() => employeeService.getUserNotifications(userId));

  const employeeInfo = useState<Employee>(() => employeeService.getEmployeeInfo(userId));

  const PREFERENCES_URL = `/employees/${userId}/preferences`;
  const navItems = [
    {
      label: "Notifications",
      icon: <NotificationsIcon />,
      url: PREFERENCES_URL,
    },
  ];

  return (
    <LoadingWidget<Employee>
      value={employeeInfo}
      builder={(employee) => (
        <>
          <Breadcrumbs
            items={[
              {
                label: `Home`,
                url: `/`,
              },
              {
                label: employee.name,
                url: `/employees/${userId}`,
              },
              {
                label: "My Preferences",
                url: PREFERENCES_URL,
              },
            ]}
          />
          <LoadingWidget<NotificationSettings>
            value={userNotifications}
            builder={(notificationSettings: NotificationSettings) => (
              <>
                <DetailsNavbar items={navItems} />
                <UserNotificationsTab settings={notificationSettings} />
              </>
            )}
          />
        </>
      )}
    />
  );
}
