import tw from "tailwind-styled-components";
import { FilterLabel as StyledFilterLabel } from "components/ui/RecommendationTable/styles";
import {SFColors} from "components/ui/Charts/SFCategories/styles";

export const DataTableWrapper = tw.div`
  w-full
  px-1
  py-4
  overflow-x-auto
`;

export const TableWrapper = tw.table<{ $hasShadow: boolean }>`
  min-w-full
  rounded-2xl
  leading-normal
  ${({ $hasShadow }) => $hasShadow ? "shadow" : ""}
`;

export const TableHeader = tw.th<{ className: string }>`
  px-5
  py-3
  border-pl-grayscale-midgray
  bg-pl-grayscale-white
  text-left
  text-xs
  font-bold
  text-pl-grayscale-gray
  tracking-wider
  ${({ className }) => className}
`;

export const TableHeaderRow = tw.tr`
  min-h-[56px]
`;

export const TableData = tw.td<{ $column_id?: string }>`
${({ $column_id }) => `
    px-5
    border-pl-grayscale-midgray
    bg-pl-grayscale-white
    text-sm
    whitespace-nowrap
    w-full
    text-ellipsis
    overflow-hidden

    ${
      !!$column_id && ["selection", "active"].includes($column_id)
        ? "w-[50px]"
        : "max-w-[250px]"
    }

    ${
      !!$column_id && ["teamSize", "activities"].includes($column_id)
        ? "min-w-[250px]"
        : ""
    }
  `}
`;

export const TableDataRow = tw.tr`
  h-[52px]
`;

export const TableDataCell = tw.div`
  text-pl-grayscale-black
  whitespace-nowrap
`;

export const PaginationWrapper = tw.div`
  px-5
  py-5
  border-t
  flex
  flex-col
  items-center
  xs:flex-row
  xs:justify-between
`;

export const PaginationInfo = tw.span`
  text-xs
  xs:text-sm
  text-pl-grayscale-black
`;

export const PaginationButtons = tw.div`
  inline-flex
  mt-4
  gap-4
  xs:mt-0
`;

export const ActiveIcon = tw.div`
  text-pl-primary-green-default
  w-5
  h-5
`;

export const InactiveIcon = tw.div`
  text-pl-primary-red-default
  w-5
  h-5
`;

export const SparklineCell = tw.div`
  flex
  items-center
  gap-3
`;

export const SparklineWrapper = tw.div<{ hasVariation: boolean }>`
  ${({ hasVariation }) => `
    max-h-[50px]
    ${hasVariation ? "max-w-[100px]" : "max-w-[250px]"}
  `}
`;

const ScoreModifiers = {
  success: `
    bg-pl-primary-green-light
    text-pl-primary-green-default
  `,

  warning: `
    bg-pl-primary-orange-light
    text-pl-primary-orange-default
  `,

  critical: `
    bg-pl-primary-red-light
    text-pl-primary-red-default
  `,

  default: `
    bg-pl-grayscale-lightgray
    text-pl-grayscale-gray
  `,
};

export const Value = tw.div<{ score: number; hasVariation: boolean }>`
  ${({ score, hasVariation }) => `
    flex
    items-center
    justify-center
    rounded-[10px]
    bg-pl-grayscale-lightgray
    px-2
    h-8
    w-9
    font-semibold
    text-pl-grayscale-gray
    text-sm

    ${
      score >= 0 && score < 30
        ? SFColors.HighlyDisengaged
        : score >= 30 && score < 45
          ? SFColors.Disengaged
          : score >= 45 && score < 60
            ? SFColors.Neutral
            : score >= 60 && score < 70
              ? SFColors.Engaged
              : SFColors.HighlyEngaged
    }
  `}
`;

export const Change = tw.div<{ change: number }>`
  ${({ change }) => `
    flex
    justify-center
    items-center
    gap-2
    font-semibold
    text-base
    ${
      change === 0
        ? "text-pl-grayscale-black"
        : change > 0
        ? "text-pl-primary-green-default"
        : "text-pl-primary-red-default"
    }
  `}
`;

export const FilterLabel = StyledFilterLabel;
