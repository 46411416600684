import tw from "tailwind-styled-components";

type FormWrapperProps = {
  horizontalDisplay?: boolean;
};

type FormLineWrapperProps = {
  horizontalDisplay: boolean;
  numColumns: number;
};

export const FormWrapper = tw.div<FormWrapperProps>`
  ${({ horizontalDisplay }) => `
    grid
    gap-3

    ${!!horizontalDisplay ? "pt-3" : ""}
  `}
`;

export const FormAvatarWrapper = tw.div`
  w-full
  flex
  justify-center
`;

export const FormPhotoWrapper = tw.div`
  bg-pl-variant-beige
  rounded-full
  w-[90px]
  h-[90px]
  flex
  items-center
  justify-center
  relative
`;

export const PhotoIconWrapper = tw.div`
  w-6
  text-pl-grayscale-midgray
`;

export const EditPhotoIconWrapper = tw.div`
  w-7
  h-7
  rounded-full
  absolute
  top-[60px]
  left-[60px]
  bg-pl-grayscale-white
  text-pl-primary-green-default
  flex
  items-center
  justify-center
  cursor-pointer
`;

export const FormLineWrapper = tw.div<FormLineWrapperProps>`
  ${({ horizontalDisplay, numColumns }) => `
    grid
    gap-3

    ${!!horizontalDisplay && numColumns === 2 ? "grid-cols-2" : ""}
    ${!!horizontalDisplay && numColumns === 3 ? "grid-cols-3" : ""}
  `}
`;

export const FieldWrapper = tw.div`
  gap-1
  grid
`;

export const IntegrationLineWrapper = tw.div`
  flex
  gap-3
`;

export const IntegrationFieldWrapper = tw.div`
  gap-1
  grow-0
  w-[200px]
`;

export const ButtonWrapper = tw.div`
  gap-1
  grid
  w-5
  items-center
`;

export const LabelWrapper = tw.div`
  text-xs
  font-semibold
  text-pl-grayscale-gray
  h-4
`;

export const ElementWrapper = tw.div`
  items-top
`;

export const TimePickerWrapper = tw.div`
`;

export const RolesFieldSetWrapper = tw.fieldset`
  w-full
  flex
  flex-col
  gap-5
`;

export const Label = tw.label`
  ml-3
  text-sm
  font-normal
  flex
  flex-row-reverse
  justify-end
  items-center
  gap-3
  cursor-pointer
`;

export const Error = tw.div`
  text-pl-primary-red-default
  text-xs
  font-semibold
  h-4
  pt-1
  border-none
`;

export const MoreWrapper = tw.div`
  flex
  items-center
  w-full
  gap-1
  min-h-[fit-content]
  text-pl-primary-green-default
  pb-2
  pt-1
  cursor-pointer
`;

export const MoreLabel = tw.div`
  w-9
  text-sm
  font-semibold
`;

export const CustomRadio = `
  focus:ring-pl-primary-green-default
  h-4
  w-4
  text-pl-primary-green-default
  border-pl-grayscale-midgray
`;

export const CustomRadioDeactivate = `
  h-4
  w-4
  text-pl-grayscale-midgray
  border-pl-grayscale-midgray
  opacity: 0.5;
  focus:ring-pl-grayscale-midgray
  focus:ring-opacity-50
`;