import { useState } from '@hookstate/core';
import React from "react";
import { InstalledIntegrationInfoDto } from "model/integration";
import mergeIntegrationsService from 'services/mergeIntegrationsService';
import { LoadingWidget } from '../../../../utils/LoadingWidget';
import IntegrationLogo from "../IntegrationLogo";
import Button from "components/generics/Button";
import { openAddIntegrationModal } from "routes/integrationForm/manageIntegration/ManageIntegration";
import MergeIntegrationModal from '../MergeIntegrationModal';

import * as S from "./styles";

export type IntegrationItemProps = {
  integration: InstalledIntegrationInfoDto;
};

const IntegrationItem = ({integration}: IntegrationItemProps) => {

  const usingMerge = useState(false);

  const loadMergeToken = () => {
    return mergeIntegrationsService.getLinkToken(integration.type, 'hris')
  }

  const onClickConnect = () => {
    if (integration.provider == "merge") {
      usingMerge.set(true);
    } else if (integration.mode === "form") {
      openAddIntegrationModal(integration);
    } else {
      window.open(integration.authorizeLink, "_self");
    }
  }

  const getCreatedByUrl = () => {
    if (integration.provider == "merge") {
      return "https://merge.dev/";
    } else {
      return "https://peoplelogic.ai/";
    }
  }

  return (
    <S.Wrapper>
      <S.Title>
        <S.Logo>
          <IntegrationLogo integration={integration} rounded/>
          {integration.name}
        </S.Logo>
        {
          !usingMerge.get()
            ? <Button
              size="small"
              onClick={onClickConnect}
            >
              Connect
            </Button>
            : <LoadingWidget
              value={loadMergeToken()}
              builder={token => <MergeIntegrationModal
                integration={integration.type}
                token={token}
                onExit={() => usingMerge.set(false)}
              />}
              size={"small"}
            />
        }

      </S.Title>

      <S.Footer>
        <S.FooterItem>
          Created By:
          <S.FooterLink
            href={getCreatedByUrl()}
            rel="noopenner, noreferrer"
          >
            {integration.createdBy}
          </S.FooterLink>
        </S.FooterItem>
        <S.FooterItem>
          {
            integration.provider === 'merge' ?
              <>Click connect for instructions.</>
              :
              <>
                Setup Instructions:
                <S.FooterLink
                  href={integration.supportUrl ? integration.supportUrl : "#"}
                  rel="noopenner, noreferrer"
                >
                  View
                </S.FooterLink>
              </>
          }
        </S.FooterItem>
      </S.Footer>
    </S.Wrapper>
  );
};

export default IntegrationItem;
