import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  inline-block
  border
  w-full
  max-w-[500px]
  p-6
  overflow-hidden
  text-left
  align-middle
  transition-all
  transform
  bg-pl-grayscale-white
  shadow-xl
  rounded-2xl
`;

export const MainContainer = tw.div`
  grid
  divide-y
  w-full
`;

export const ContentWrapper = tw.div`
  min-h-[270px]
`;

export const ButtonsWrapper = tw.div`
  pt-6
  flex
  items-center
  justify-between
`;

export const FormWrapper = tw.div`
  py-3
  grid
`;

export const FieldWrapper = tw.div`
  gap-1
  grid
  my-2
`;

export const LabelWrapper = tw.div`
  text-xs
  font-semibold
  text-pl-grayscale-gray
  h-4
`;

export const ElementWrapper = tw.div`
  items-top
`;

export const MembersWrapper = tw.div`
  block
  min-h-[55px]
  border
  border-pl-grayscale-lightgray
  rounded-[10px]
  bg-pl-variant-beige
`;

export const Error = tw.div`
  text-pl-primary-red-default
  text-xs
  font-semibold
  h-4
  pt-1
  border-none
`;

export const DashboardQueriesContainer = tw.div`
  flex
  flex-wrap
  flex-row
  relative
  py-2
`;

export const AddQueryWrapper = tw.div`
  flex
  items-center
  my-4
`;

export const AddQueryTextWrapper = tw.div`
  font-semibold
  text-sm
  cursor-pointer
`;

export const QueryContainer = tw.div`
  w-full
`;

export const QueryComponent = tw.div`
  w-full
  mt-1
`;

export const QueryComponentWrapper = tw.div`
  flex
  items-end
  w-full
`;

export const DragIconWrapper = tw.div`
  flex
  py-2
  items-center
  mt-6
`;

export const QueryComponentFieldWrapper = tw.div`
  p-2
`;

export const QueryComponentFieldLabel = tw.div`
  my-1
  font-semibold
  text-pl-grayscale-gray
  text-xs
`;

export const ClosseIconWrapper = tw.div`
  flex
  py-2
  items-center
  mt-6
`;