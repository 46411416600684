import React from "react";
import * as S from "./styles";

export type SpeechBalloonProps = {
  type?: "primary" | "default";
  $arrowSidePosition: "left" | "center" | "right";
  $arrowVerticalPosition: "top" | "bottom";
  $bolded?: boolean;
  children: React.ReactNode;
};

const SpeechBalloon = ({
  type = "default",
  $arrowSidePosition,
  $arrowVerticalPosition,
  $bolded,
  children,
}: SpeechBalloonProps) => (
  <S.Wrapper
    type={type}
    $bolded={$bolded}
    $arrowSidePosition={$arrowSidePosition}
    $arrowVerticalPosition={$arrowVerticalPosition}
  >
    {children}
  </S.Wrapper>
);

export default SpeechBalloon;
