import React from "react";
import organizationService from "services/organizationService";
import Logo from "components/generics/Logo";
import {Logo as LogoType} from "model/organization";
import {LoadingWidget} from "components/utils/LoadingWidget";

import * as S from "./styles";
import {useState} from "@hookstate/core";

const CustomerLogo = () => {
  const customerLogo = useState(() => organizationService.getCustomerDomain());

  return (
    <S.Wrapper>
      <LoadingWidget<LogoType>
        value={customerLogo}
        builder={({url, domain}) => (
          <Logo
            src={url || "/img/customer.png"}
            alt={`${domain} Logo` || "The Customer Lda"}
          />
        )}
        size="small"
      />
    </S.Wrapper>
  );
};

export default CustomerLogo;
