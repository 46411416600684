import React, {useRef} from "react";
import {createState, useState} from "@hookstate/core";
import DatePicker from "react-datepicker";
import {ErrorMessage, Form, Formik, FormikValues} from "formik";
import {EmployeeValidator} from "validations/EmployeeValidator";
import {Employee, EmployeeFormData, Location, Role, ROLES_LABEL_VALUE_PAIR, Team} from "model/employee";
import {CommonModal} from "components/utils/Modal/CommonModal";
import {FieldContainer} from "components/utils/CommonContainers/CommonFieldContainer";
import CustomSelect from "components/utils/CustomSelect/CustomSelect";
import {SelectSearchOption} from "components/utils/SelectSearch";
import TextField from "components/generics/TextField";
import {isRepeated} from "utils/text";

import * as S from "./styles";
import ModalTabsTitle, {TabsTitle,} from "components/utils/Modal/ModalTabsTitle/ModalTabsTitle";
import Button from "components/generics/Button";
import {EmployeeRolesSection} from "routes/employeeForm/EmployeeRolesSection";

const NewEmployeeModalState = createState({
  open: false,
  newEmployee: {
    id: null,
    role: Role.EMPLOYEE,
    name: "",
    joinDateStr: "",
    email: "",
    jobTitle: "",
    employeeIntegrations: [],
  } as EmployeeFormData,
  integrationName: "",
  teamList: [] as Team[],
  managerList: [] as Employee[],
  locationList: [] as Location[],
  onSave: () => {},
});

export const getNewEmployeeFromState = (): EmployeeFormData => {
  return NewEmployeeModalState.newEmployee.value;
};

export const openNewEmployeeModal = (
  employeeData: EmployeeFormData,
  teamList: Team[],
  managerList: Employee[],
  integrationName: string,
  locationList: Location[],
  onSave: () => void
) => {
  NewEmployeeModalState.set({
    open: true,
    newEmployee: employeeData,
    teamList: teamList,
    managerList: managerList,
    integrationName: integrationName,
    locationList: locationList,
    onSave: onSave,
  });
};

export const closeNewEmployeeModal = () => {
  NewEmployeeModalState.open.set(false);
};

export default function NewEmployeeModal({
  teams,
  managers,
  locations,
}: {
  teams: Team[];
  managers: Employee[];
  locations: Location[];
}) {
  const selectedNavIndex = useState(0);
  const modalState = useState(NewEmployeeModalState);
  const newEmployee = useState(NewEmployeeModalState.newEmployee);

  const teamsState = useState(teams);
  const locationsState = useState(locations);

  const disableManager = useState(false);
  const disablePrimaryTeam = useState(false);

  const nameRef = useRef<HTMLInputElement | null>(null);
  const emailRef = useRef<HTMLInputElement | null>(null);
  const titleRef = useRef<HTMLInputElement | null>(null);

  const tabsTitle: TabsTitle[] = [
    {
      tabNumber: 1,
      tabTitle: "Employee's details",
    },
    {
      tabNumber: 2,
      tabTitle: "Employee's Team details",
    },
  ];

  const teamsList = () => {
    return teamsState.value.map((team) => {
      return {
        label: team.name,
        value: team.id,
      } as SelectSearchOption;
    });
  };

  const managersList = () => {
    return managers.map((manager) => {
      return {
        label: manager.name,
        value: manager.id,
        additionalLabel: isRepeated(manager, managers)
          ? manager.email
          : undefined,
      } as SelectSearchOption;
    });
  };

  const locationList = () => {
    return locationsState.value.map((location) => {
      return {
        label: location.name,
        value: location.id,
      } as SelectSearchOption;
    });
  };

  const handleSetTeamsByManager = (managerId: number) => {
    const filteredTeams = teams.filter(
      (team) => team.manager?.id === managerId
    );
    teamsState.set(filteredTeams);
    disablePrimaryTeam.set(true);

    if (!managerId) {
      disablePrimaryTeam.set(false);
      teamsState.set(teams);
    }
  };

  const handleSave = (values: FormikValues) => {
    closeNewEmployeeModal();
    modalState.newEmployee.set(
      values as EmployeeFormData
    );

    return modalState.value.onSave();
  };

  const handleClose = () => {
    closeNewEmployeeModal();
  };

  return (
    <>
      <CommonModal openState={NewEmployeeModalState.open}>
        <S.Wrapper>
          <S.TitleWrapper>
            <ModalTabsTitle
              tabs={tabsTitle}
              currentTab={selectedNavIndex.value + 1}
            />
          </S.TitleWrapper>

          <Formik
            initialValues={{ ...newEmployee.value }}
            validationSchema={EmployeeValidator}
            validateOnMount={true}
            onSubmit={handleSave}
          >
            {({
              values,
              errors,
              setFieldValue,
              touched,
              handleChange,
              handleBlur,
              isValid,
              submitForm,
            }) => (
              <Form>
                <S.FormWrapper>
                  <>
                    {values.employeeIntegrations.map((integration) => (
                      <S.RowContainer
                        key={`${integration.integrationUsername}-${integration.integrationId}`}
                      >
                        <FieldContainer appendClassname="p-0">
                          <S.Label>
                            Integration Username
                            <div className="div-input-field">
                              <TextField
                                name="integrationUsername"
                                value={integration.integrationUsername}
                                disabled
                              />
                            </div>
                          </S.Label>
                        </FieldContainer>

                        <FieldContainer appendClassname="p-0">
                          <S.Label>
                            Integration Type
                            <div className="div-input-field">
                              <TextField
                                name="integrationUsername"
                                value={
                                  NewEmployeeModalState.integrationName.value
                                }
                                disabled={true}
                              />
                            </div>
                          </S.Label>
                        </FieldContainer>
                      </S.RowContainer>
                    ))}
                  </>

                  {selectedNavIndex.value === 0 && (
                    <>
                      <S.RowContainer>
                        <FieldContainer appendClassname="p-0">
                          <S.Label>
                            Name
                            <div className="div-input-field">
                              <TextField
                                ref={nameRef}
                                placeholder="Enter a name"
                                name="name"
                                value={values.name}
                                clearable
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onClear={() => setFieldValue("name", "")}
                                error={
                                  !!errors.name &&
                                  touched.name && <ErrorMessage name="name" />
                                }
                              />
                            </div>
                          </S.Label>
                        </FieldContainer>

                        <FieldContainer appendClassname="p-0">
                          <S.Label>
                            Email
                            <div className="div-input-field">
                              <TextField
                                ref={emailRef}
                                placeholder="Enter an email"
                                name="email"
                                value={values.email}
                                clearable
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onClear={() => setFieldValue("email", "")}
                                error={
                                  !!errors.email &&
                                  touched.email && <ErrorMessage name="email" />
                                }
                              />
                            </div>
                          </S.Label>
                        </FieldContainer>
                      </S.RowContainer>

                      <S.RowContainer>
                        <FieldContainer appendClassname="p-0">
                          <S.Label>
                            Title
                            <div className="div-input-field">
                              <TextField
                                ref={titleRef}
                                placeholder="Enter a job title"
                                name="jobTitle"
                                value={values.jobTitle}
                                clearable
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onClear={() => setFieldValue("jobTitle", "")}
                                error={
                                  !!errors.jobTitle &&
                                  touched.jobTitle && (
                                    <ErrorMessage name="jobTitle" />
                                  )
                                }
                              />
                            </div>
                          </S.Label>
                        </FieldContainer>

                        <FieldContainer appendClassname="p-0">
                          <S.Label>
                            Join Date (mm/dd/yyyy)
                            <div className="div-input-field">
                              <DatePicker
                                selected={
                                  values.joinDateStr === ""
                                    ? new Date()
                                    : new Date(values.joinDateStr)
                                }
                                className={S.InputFieldClass}
                                onChange={(date: Date) => {
                                  setFieldValue(
                                    "joinDateStr",
                                    date.toISOString()
                                  );
                                }}
                              />
                            </div>
                          </S.Label>
                        </FieldContainer>
                      </S.RowContainer>

                      <S.RowContainer>
                        <S.Label className="gap-1">
                          Location (Optional)
                          <CustomSelect
                            options={locationList()}
                            value={
                              values.location === undefined
                                ? ""
                                : values.location
                            }
                            onChange={(opt) =>
                              setFieldValue(
                                "location",
                                opt.value === "" ? undefined : opt.value
                              )
                            }
                            className="font-normal"
                          />
                        </S.Label>
                      </S.RowContainer>
                    </>
                  )}
                  {selectedNavIndex.value === 1 && (
                    <>
                      <S.RowContainer>
                        <FieldContainer appendClassname="p-0">
                          <S.Label>
                            Primary Team (Optional)
                            <CustomSelect
                              options={teamsList()}
                              value={
                                values.primaryTeamId === undefined
                                  ? ""
                                  : values.primaryTeamId
                              }
                              isClearable
                              onChange={(opt) => {
                                const team = teams.find(
                                  (team) =>
                                    !!team.manager && team.id === opt.value
                                );
                                !!opt.value
                                  ? disableManager.set(true)
                                  : disableManager.set(false);
                                if (!values.primaryTeamId) {
                                  teamsState.set(teams);
                                }
                                setFieldValue(
                                  "primaryTeamId",
                                  opt.value === "" ? undefined : opt.value
                                );
                                setFieldValue(
                                  "managerId",
                                  !!team && team.manager
                                    ? team.manager.id
                                    : undefined
                                );
                              }}
                              className="font-normal"
                            />
                          </S.Label>

                          {values.primaryTeamId !== null &&
                            teams.filter(
                              (team) => team.id === values.primaryTeamId
                            )[0] &&
                            !teams.filter(
                              (team) => team.id === values.primaryTeamId
                            )[0]?.active && (
                              <div className="pl-error-message">
                                This team is not active
                              </div>
                            )}
                        </FieldContainer>

                        <FieldContainer>
                          <S.Label>
                            Manager (Optional)
                            <CustomSelect
                              options={managersList()}
                              value={
                                values.managerId === undefined
                                  ? ""
                                  : values.managerId
                              }
                              isDisabled={disableManager.value}
                              onChange={(opt) => {
                                setFieldValue(
                                  "managerId",
                                  opt.value === "" ? undefined : opt.value
                                );
                                setFieldValue("primaryTeamId", undefined);
                                handleSetTeamsByManager(opt.value);
                              }}
                              className="font-normal"
                            />
                          </S.Label>
                        </FieldContainer>
                      </S.RowContainer>
                      <S.RowContainer>
                        <EmployeeRolesSection
                          values={ROLES_LABEL_VALUE_PAIR}
                          setFieldValue={setFieldValue}
                        />
                      </S.RowContainer>
                    </>
                  )}

                  <S.ActionWrapper>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        selectedNavIndex.value === 0
                          ? handleClose()
                          : selectedNavIndex.set(selectedNavIndex.value - 1);
                      }}
                    >
                      {selectedNavIndex.value === 0 ? "Cancel" : "Previous"}
                    </Button>
                    <Button
                      btntype="primary"
                      disabled={!isValid}
                      onClick={(e) => {
                        e.preventDefault();
                        selectedNavIndex.value === 0
                          ? selectedNavIndex.set(selectedNavIndex.value + 1)
                          : submitForm();
                      }}
                    >
                      {selectedNavIndex.value === 0 ? "Next" : "Save"}
                    </Button>
                  </S.ActionWrapper>
                </S.FormWrapper>
              </Form>
            )}
          </Formik>
        </S.Wrapper>
      </CommonModal>
    </>
  );
}
