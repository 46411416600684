import React from "react";
import Content from "components/structure/Content";
import Navbar from "components/structure/Navbar";
import Footer from "components/structure/Footer";
import "react-datepicker/dist/react-datepicker.css";
import { LoadingWidget } from "components/utils/LoadingWidget";
import PreAuthOverlay from "components/structure/PreAuthOverlay";
import ToastNotificationContainer from "components/utils/Toast/ToastNotificationContainer";
import auth from "services/auth";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  DoughnutController,
  LineController,
  ArcElement,
  BarElement,
} from "chart.js";
import "react-resizable/css/styles.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  DoughnutController,
  LineController,
  ArcElement,
  BarElement
);

function App() {
  return (
    // todo: this needs to be pretified and styles placed in the correct place
    <LoadingWidget
      value={auth.me}
      loadingBuilder={() => <PreAuthOverlay />}
      builder={(me) => (
        <>
          <div className="min-h-screen bg-pl-variant-beige pb-4">
            <Navbar />
            <Content me={me ?? undefined} />
          </div>
          <ToastNotificationContainer />
          <Footer />
        </>
      )}
    />
  );
}

export default App;
