import tw from "tailwind-styled-components";
import {SFColors} from "components/ui/Charts/SFCategories/styles";

export const Wrapper = tw.div`
  flex
  items-center
  justify-between
  cursor-pointer
  min-w-[350px]
`;

export const InfoWrapper = tw.div<{ active: boolean }>`
  ${({ active }) => `
    flex
    items-center
    gap-3
    font-semibold
    text-sm

    ${!active && "text-pl-grayscale-gray"}
  `}
`;

export const ScoreWrapper = tw.div`
    flex
    items-center
    justify-between
    gap-2
    w-[90px]
`;

export const CurrentScore = tw.div<{ score: number; hasVariation: boolean }>`
  ${({ score, hasVariation }) => `
    h-8
    min-w-[34px]
    max-w-[34px]
    flex
    items-center
    justify-center
    rounded-lg
    font-semibold
    text-sm

    ${
      score >= 0 && score < 30
        ? SFColors.HighlyDisengaged
        : score >= 30 && score < 45
          ? SFColors.Disengaged
          : score >= 45 && score < 60
            ? SFColors.Neutral
            : score >= 60 && score < 70
              ? SFColors.Engaged
              : SFColors.HighlyEngaged
    }
  `}
`;

export const VariationScore = tw.div<{ variation: number }>`
  ${({ variation }) => `
    text-sm
    font-semibold
    flex
    items-center
    justify-end
    w-full
    gap-1

    ${
      variation > 0
        ? "text-pl-primary-green-default"
        : variation < 0 ?
          "text-pl-primary-red-default"
          : "text-pl-grayscale-black"
    }
  `}
`;
