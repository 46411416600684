import React from "react";
import {Employee, Team} from "model/employee";
import {useNavigate} from "react-router-dom";
import {Form, Formik, FormikValues} from "formik";
import {DepartmentValidator} from "validations/DepartmentValidator";
import {WidgetTitle} from "components/utils/WidgetTitle";
import {BasicInfoSection} from "routes/departmentForm/BasicInfoSection";
import {TeamsSkillsSection} from "routes/departmentForm/TeamsSkillsSection";
import Button from "components/generics/Button";
import {Department, DepartmentFormData} from "model/department";
import {SelectSearchOption} from "components/utils/SelectSearch";
import departmentService from "services/departmentService";

import * as S from "./styles";
import {useState} from "@hookstate/core";


export const ManageDepartment = ({ department, managers, teams}: {
  department: Department,
  managers: Employee[],
  teams: Team[],
}) => {
  const navigate = useNavigate();
  const isSaving = useState<boolean>(false);

  const getDepartment = () => {
    return department ?
      {
        id: department.id,
        name: department.name,
        managerId: department.manager ? department.manager.id : 0,
        departmentType: department.departmentType ? department.departmentType : "",
        teams: department.teams ? department.teams : [],
        teamsSelected: department.teams ? department.teams.map((team) => {
          return {
            label: team.name,
            description: team.name,
            value: team.id,
          } as SelectSearchOption
        }) : [] as SelectSearchOption[],
        skills: department.skills ? department.skills.map((sk) => sk.name) : [],
        active: department.active
      } as DepartmentFormData
      : {};
  };

  function handleSave(values: FormikValues) {
    const ids = values.teamsSelected.map((val) => val.value);
    const departmentToSave = {
      id: department.id,
      name: values.name,
      managerId: values.managerId,
      departmentType: values.departmentType ? values.departmentType : "",
      teams: teams.filter((t) => ids.includes(t.id)),
      teamsSelected: values.teamsSelected,
      skills: values.skills,
      active: department.active,
    } as DepartmentFormData;

    departmentService.saveDepartment(departmentToSave)
      .then(function (savedDep) {
        goToDepartmentPage("fetch");
      });

  }

  function goToDepartmentPage(action?: string) {
    action ?
      navigate("/departments/" + department.id, {state: "fetch"})
      :
      navigate("/departments/" + department.id)
  }

  return (
    <>
      <Formik
        initialValues={{...getDepartment()}}
        validationSchema={DepartmentValidator}
        validateOnMount={true}
        onSubmit={handleSave}
      >
        {({
            values,
            errors,
            isValid,
            setFieldValue,
            setFieldTouched,
            touched,
            handleChange,
            handleBlur,
          }) => (

          <Form>
            <S.Wrapper>
              <S.MainContainer>

                <S.SubMainContainer>
                  <WidgetTitle>Basic information</WidgetTitle>
                  <BasicInfoSection managers={managers}
                                    values={values}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    horizontalDisplay={true}
                                    numColumns={4}
                  />
                </S.SubMainContainer>

                <S.SubMainContainer>
                  <WidgetTitle>Teams & Skills</WidgetTitle>
                  <TeamsSkillsSection teams={teams}
                                      values={values}
                                      setFieldValue={setFieldValue}
                                      horizontalDisplay={true}
                                      numColumns={2}
                  />
                </S.SubMainContainer>

                <S.SubMainContainer>
                  <S.ButtonsWrapper>
                    <Button btntype="neutral" $shadow={true} onClick={() => goToDepartmentPage()}>
                      Cancel
                    </Button>
                      <Button type="submit" btntype="primary" $shadow={true} onClick={() => {
                        isSaving.set(true)
                        handleSave(values);
                      }} disabled={!isValid || isSaving.value}>
                      Save
                    </Button>
                  </S.ButtonsWrapper>
                </S.SubMainContainer>

              </S.MainContainer>
            </S.Wrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};
