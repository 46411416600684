import * as Yup from 'yup';

export const OrganizationValidator = Yup.object().shape({
    name: Yup.string().min(3, 'Organization Name must be at least 3 characters').required('Organization Name is a required field'),
    domain: Yup.string()
});

export const LocationValidator = Yup.object().shape({
    name: Yup.string().required('Location is a required field'),
    timeZone: Yup.string().required('Timezone is a required field'),
    startTime: Yup.string().required('Start Time is a required field'),
    endTime: Yup.string().required('End Time is a required field')
});