import {ChartOptions} from "chart.js";
import {Bar} from "react-chartjs-2";
import {BarChartReport, TableReport} from "model/lexi";
import {pickColor} from "utils/color";
import {useState} from "@hookstate/core";
import * as _ from "lodash";

const UNKNWON_LABELS = {
  "employee": "Unknown",
  "team": "No Team",
  "object": "No Name"
}

const labelFor = (cols: TableReport["columns"][0][], datum: any) => {
  return cols.map(col => {
    const value = datum[col.alias];

    if (col.type in UNKNWON_LABELS) {
      if (value && value.name) {
        return value.name;
      } else {
        return UNKNWON_LABELS[col.type];
      }
    }

    return value?.toString() || "";
  }).join(", ");
};

const isBaselineEntry = (col: TableReport["columns"][0], datum: any) => {
  return datum?.id === 0;
};

const buildChartData = ({ columns, data }: BarChartReport) => {
  const dataColumns = columns.filter(col => !col.group);
  const labelColumns = columns.filter(col => col.group);

  return {
    data: {
      labels: data.map((datum) =>
        labelFor(labelColumns, datum)
      ),
      datasets: dataColumns.map((dc, idx) => {
        return {
          label: dc.label,
          backgroundColor: pickColor(idx),
          data: data.map((datum) => datum[dc.alias]),
        };
      }),
    },
  };
};

const options = ({ columns, data }: BarChartReport) => {
  const dataColumns = columns.filter(col => !col.group);
  const annotations: any[] = [];
  const labelColumns = columns.filter(col => col.group);
  const baselineEntry = data.find((datum) =>
    labelColumns.find((lc) => isBaselineEntry(lc, datum[`${lc.alias}`]))
  );

  if (baselineEntry) {
    annotations.push.apply(
      annotations,
      dataColumns.map((dc, idx) => ({
        type: "line",
        mode: "horizontal",
        value: baselineEntry[dc.alias],
        borderColor: pickColor(idx),
        borderWidth: 2,
        borderDash: [4, 3],
        /*label: {
                // backgroundColor: pickColor(idx),
                position: "right",
                content: `${labelFor(dc, baselineEntry)} / ${dc.label}`,
                enabled: true
            }*/
      }))
    );
  }
  return {
    annotation: { annotations },
    maintainAspectRatio: false,
  } as ChartOptions<"bar">;
};

const BarChartRenderer = ({ report }: { report: BarChartReport }) => {
  const chartData = useState(() => buildChartData(report));
  const chartOptions = useState(() => options(report));
  return <Bar data={_.cloneDeep(chartData.data.value)} options={_.cloneDeep(chartOptions.value)} />;
};

export default BarChartRenderer;
