import { KeyMetric } from "../model/keymetrics";
import { calculatePlural } from "./eventNamePluralizer";
import { SeverityType } from "../model/recommendations";
import {Employee} from "../model/employee";

export function initials(name: string): String {
  const split = name.trim().split(" ");
  if (split.length === 1) {
    return split[0][0].toUpperCase();
  } else if (split.length > 1) {
    const firstName = split[0].replace(/[^a-zA-Z0-9+]/g, '');
    const lastName = split[split.length - 1].replace(/[^a-zA-Z0-9+]/g, '');
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  } else {
    return "";
  }
}

export function capitalize(sentence: string): string {
  if (sentence.length === 2) {
    return sentence.toUpperCase();
  }

  return sentence
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function contains(sentence: string, searchString: string): boolean {
  return sentence.toLowerCase().includes(searchString.toLowerCase());
}

export const getEventNameForDisplay = (keyMetric: KeyMetric) => {
  if (keyMetric.kpiName.startsWith("C:")) {
    return `Custom: ${keyMetric.kpiName.substr(2)}`;
  } else {
    return keyMetric.value !== 1
      ? calculatePlural(keyMetric.kpiName)
      : keyMetric.kpiName;
  }
};

export function getGreeting(): String {
  const hour = new Date().getHours();
  if (hour < 12) {
    return "Good Morning";
  } else if (hour >= 12 && hour <= 17) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
}

export function validEmployeeName(name: string): boolean {
  let arr = name.split(" ");

  if (arr.length > 1) {
    if (arr[0] === "" || arr[1] === "" || arr[0] === " " || arr[1] === " ") {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function validEmail(email: string): boolean {
  let regex: RegExp;
  regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

export const getSeverityLabel = (type: SeverityType) => {
  switch (type) {
    case SeverityType.CRITICAL:
      return "Take Action";
    case SeverityType.WARNING:
      return "Heads Up";
    case SeverityType.INFO:
      return "Learn More";
    default:
      return "";
  }
};

export const isRepeated = (manager: Employee, managers: Employee[]) => {
  return managers.filter(entry => entry.name === manager.name).length > 1;
}
