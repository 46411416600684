import {Employee} from "model/employee";
import TextField from "components/generics/TextField";
import {FormikErrors, FormikTouched, FormikValues} from "formik";
import CustomSelect from "components/utils/CustomSelect/CustomSelect";
import {SelectSearchOption} from "components/utils/SelectSearch";
import {isRepeated} from "utils/text";
import React, {useRef} from "react";
import auth from "services/auth";
import {PrivacyLevel} from "model/organization";
import privacyLevelService from "services/privacyLevelService";

import * as S from "./styles";

type Props = {
  managers: Employee[];
  values: FormikValues;
  errors: FormikErrors<FormikValues>
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.ChangeEvent<any>) => void;
  touched: FormikTouched<FormikValues>;
  horizontalDisplay?: boolean;
  numColumns?: number;
};

export function BasicInfoSection({ managers,
                              values,
                              errors,
                              setFieldValue,
                              setFieldTouched,
                              handleChange,
                              handleBlur,
                              touched,
                              horizontalDisplay = false,
                              numColumns = 4,
}: Props) {
  const privacyLevel = auth.privacyLevel.value as PrivacyLevel;

  const nameRef = useRef<HTMLInputElement>(null);
  const departmentTypeRef = useRef<HTMLInputElement>(null);

  const managersList = () => {
    return managers.map((manager) => {
      return {
        label: manager.name,
        value: manager.id,
        additionalLabel: isRepeated(manager, managers)
          ? manager.email
          : undefined,
      } as SelectSearchOption;
    });
  };


  return (
    <>
      <S.FormWrapper horizontalDisplay={horizontalDisplay}
                     numColumns={privacyLevelService.hasAccess(privacyLevel) ? numColumns : 2}>
        <S.FieldWrapper>
          <S.LabelWrapper>
            Department Name (required)
          </S.LabelWrapper>
          <S.ElementWrapper>
            <TextField
              ref={nameRef}
              name="name"
              value={values.name}
              clearable
              onChange={handleChange}
              onBlur={handleBlur}
              onClear={() => setFieldValue("name", "")}
              border={false}
              placeholder={"Enter a department name"}
            />
            <S.Error>
              {
                !!errors.name && touched.name ? errors.name : ""
              }
            </S.Error>
          </S.ElementWrapper>
        </S.FieldWrapper>

        {
          privacyLevelService.hasAccess(privacyLevel) &&
          <S.FieldWrapper>
            <S.LabelWrapper>
              Manager
            </S.LabelWrapper>
            <S.ElementWrapper>
              <CustomSelect
                placeholder={"Select a manager"}
                options={managersList()}
                value={values.managerId}
                onChange={(opt) => {
                  setFieldValue("managerId", opt.value);
                }}
                isFixed={false}
                border={false}
              />
              <S.Error>
                {
                  errors.managerId ? errors.managerId : ""
                }
              </S.Error>
            </S.ElementWrapper>
          </S.FieldWrapper>
        }

        <S.FieldWrapper>
          <S.LabelWrapper>
            Department Type
          </S.LabelWrapper>
          <S.ElementWrapper>
            <TextField
              ref={departmentTypeRef}
              name="departmentType"
              value={values.departmentType}
              clearable
              onChange={handleChange}
              onBlur={handleBlur}
              onClear={() => setFieldValue("departmentType", "")}
              border={false}
              placeholder={"Enter a department type"}
            />
            <S.Error />
          </S.ElementWrapper>
        </S.FieldWrapper>

      </S.FormWrapper>
    </>
  );
}