import React from "react";
import {useNavigate} from "react-router-dom";

import { ReactComponent as ArrowLeftIcon } from "icons/arrow-left.svg";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";

import * as St from "./styles";

export type Breadcrumb = {
  label: string,
  url: string
}

type BreadcrumbsProp = {
  items: Breadcrumb[],
  includeBack?: boolean
}

const Breadcrumbs = React.memo(({items, includeBack=true}:BreadcrumbsProp) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  }

  const filtered = items.filter(item => item.label !== "");

  return <>
    <div className={"flex my-4 gap-3 items-center"}>
      {
        includeBack && <St.BreadcrumbBack onClick={handleBack}>
          <ArrowLeftIcon className={"h-4 w-4"} />
          <span className={"px-3 leading-5"}>Back</span>
        </St.BreadcrumbBack>
      }
      {
        filtered.map((item,index) =>
          <St.BreadcrumbItem to={item.url} key={index}>
            <span className={`${index !== filtered.length-1 ? 'text-pl-grayscale-gray' : '!text-pl-grayscale-blueblack'} hover:text-pl-grayscale-blueblack`}>
              { item.label }
            </span>
            { index !== filtered.length-1 &&
              <ChevronIcon className={"h-2 w-2 ml-3 text-pl-grayscale-midgray -rotate-90"} />
            }
          </St.BreadcrumbItem>)
      }
    </div>
  </>
})

export default Breadcrumbs;
