import React from "react";
import { LoadingWidget } from "components/utils/LoadingWidget";
import {useLocation, useParams} from "react-router-dom";
import Breadcrumbs from "components/generics/Breadcrumbs";
import DetailsNavbar from "components/generics/DetailsNavbar";
import { ReactComponent as DashboardIcon } from "icons/dashboard.svg";
import { ReactComponent as TeamIcon } from "icons/team.svg";
import { ReactComponent as CogIcon } from "icons/cog.svg";
import {Department} from "model/department";
import departmentService from "services/departmentService";
import DepartmentFormPage from "../departmentForm";
import {DepartmentDashboard} from "./departmentDashboard/DepartmentDashboard";
import DepartmentTeams from "./DepartmentTeams";
import authService from "services/auth";
import {useState} from "@hookstate/core";
import {useDependantState} from "../../utils/state";


const DepartmentPage = () => {
  const departmentId = +useParams<{ id: string }>().id!;
  const currentDepartment = useDependantState<Department>(() => departmentService.getDepartment(departmentId, {
    employees: true,
    skills: true,
    teams: true,
    kpis: false
  }, true), [departmentId]);

  return (
    <>
      <LoadingWidget<Department>
        value={currentDepartment}
        builder={(dep) => <PageWithNav department={dep} />}
      />
    </>
  );
};

const PageWithNav = React.memo(({ department }: { department: Department }) => {
  const { tab } = useParams<{tab: string}>();

  const getNavItems = () => {
    const navItems = [
      {
        label: "Dashboard",
        icon: <DashboardIcon />,
        url: `/departments/${department.id}`,
      },
      {
        label: "Department Teams",
        icon: <TeamIcon />,
        url: `/departments/${department.id}/members`,
      },
    ];

    if (authService.currentUserIsAdmin()) {
      navItems.push(
        {
          label: "Manage Department",
          icon: <CogIcon />,
          url: `/departments/${department.id}/edit`,
        });
    }
    return navItems;
  }

  return (
    <>
      <Breadcrumbs
        items={[
          { label: "Departments", url: `/departments` },
          { label: `${department.name}`, url: `/departments/${department.id}` },
          {
            label:
              tab === "members"
                ? "Department Teams"
                : tab === "edit"
                ? "Manage Department"
                : "",

            url: !!tab ? `/departments/${department.id}/${tab}` : `/departments/${department.id}`,
          },
        ]}
      />
      <DetailsNavbar items={getNavItems()} />
      {tab === "members" ? (
        <DepartmentTeams department={department}/>
      ) : tab === "edit" ? (
        <DepartmentFormPage department={department}/>
      ) : (
        <DepartmentDashboard department={department} />
      )}
    </>
  );
});

export default DepartmentPage;
