import React from "react";
import {useLocation} from "react-router-dom";
import TabTitle from "components/structure/TabTitle";
import departmentService from "services/departmentService";
import {ConfirmationModal} from "components/utils/Modal/ConfirmationModal";
import Breadcrumbs from "components/generics/Breadcrumbs";
import {LoadingWidget} from "components/utils/LoadingWidget";
import {Department} from "model/department";
import DepartmentsList from "./DepartmentsList";
import { StatusConfirmationModal } from "components/utils/Modal/StatusConfirmationModal";
import {useState} from "@hookstate/core";

const Departments = () => {
  const location = useLocation();
  const id: number | undefined = location.state as number;

  const allDepartments = useState<Department[]>(() => departmentService.getAllDepartments({
    employees: true,
    skills: false,
    teams: true,
    kpis: false
  }));
  TabTitle("Departments");

  return (
    <>
      <ConfirmationModal />
      <StatusConfirmationModal/>
      <Breadcrumbs
        items={[
          { label: "Departments", url: `/departments` },
          { label: "List View", url: `/departments` },
        ]}
      />
      <div className="rounded-md w-full">
        <LoadingWidget<Department[]>
          value={allDepartments}
          builder={(departmentList) => <DepartmentsList departmentsList={departmentList} />}
        />
      </div>
    </>
  );
};

export default React.memo(Departments);