import React, {useEffect, useRef} from "react";
import {useHookstate, useState} from "@hookstate/core";
import moment from "moment";
import {Recommendation, SeverityFilterType, SeverityType,} from "model/recommendations";
import {Employee} from "model/employee";
import {getSeverityLabel} from "utils/text";
import CustomSelect from "components/utils/CustomSelect/CustomSelect";
import {SAMPLE_RECOMMENDATIONS} from "model/mockedValues";
import BlurredContainer from "components/generics/BlurredContainer";
import Button from "components/generics/Button";
import RecommendationItem from "components/ui/Recommendation/RecommendationItem";
import {DetailedDateProps, formatDateToDDMon, getDetailedDate, getMonthFromString,} from "utils/dateFormatter";
import LoadMore from "components/generics/LoadMore";
import recommendationsService from "services/recommendations";
import {PrivacyLevel} from "model/organization";
import privacyLevelService from "services/privacyLevelService";
import auth from "services/auth";

import * as S from "./styles";

export type RecommendationTableProps = {
  title?: string;
  filterByEmployee?: boolean;
  employeeFilterOptions?: Employee[];
  loadRecommendations?: (
    batchSize: number,
    batchIndex: number,
    severityFilters: SeverityType[],
    selectedEmployeeId?: number
  ) => Promise<Recommendation[]>;
  homepage?: boolean,
};

export default function RecommendationTable({
  title = "Recommendations",
  filterByEmployee = false,
  employeeFilterOptions = [],
  loadRecommendations,
  homepage = false,
}: RecommendationTableProps) {

  const initialSeverityFilters = Object.keys(SeverityType).map((type) => ({
    type: SeverityType[type],
    active: false,
  } as SeverityFilterType));

  const batchSize = useHookstate(5);
  const selectedEmployeeIdFilter = useHookstate<undefined | number>(undefined);
  const recommendations = useHookstate<undefined | Recommendation[]>(undefined);
  const showLoadMore = useHookstate<boolean>(true);
  const loadMoreRef = useRef<null | HTMLInputElement>(null);

  const filtersToDisable = useState<SeverityType[]>([]);

  const severityFilters = useState<SeverityFilterType[]>(initialSeverityFilters);

  const firstAccess = useState<boolean>(true);

  const getEnabledFilters = () => {
    return severityFilters.value
      .filter((filter) => filter.active)
      .map((item) => item.type) as SeverityType[];
  };

  const topRecommendationsState = useState<Recommendation[] | null>(() => {
    if (!homepage) {
      return null;
    }

    return recommendationsService.getTopRecommendations();
  })

  useEffect(() => {
    if (!homepage || topRecommendationsState.promised) {
      return;
    }

    const recommendationArray = topRecommendationsState.get({noproxy: true});

    if (recommendationArray === null) {
      recommendations.set([]);
      filtersToDisable.set([SeverityType.INFO, SeverityType.WARNING, SeverityType.CRITICAL]);

    } else {
      filtersToDisable.set([]);
      const criticalRec = recommendationArray
        .filter(rec => rec.severity === SeverityType.CRITICAL)
        .sort((a, b) => new Date(b.lastVerified).getTime() - new Date(a.lastVerified).getTime())

      if (criticalRec.length === 0) {
        filtersToDisable.merge([SeverityType.CRITICAL]);
      }

      const warningRec = recommendationArray
        .filter(rec => rec.severity === SeverityType.WARNING)
        .sort((a, b) => new Date(b.lastVerified).getTime() - new Date(a.lastVerified).getTime())

      if (warningRec.length === 0) {
        filtersToDisable.merge([SeverityType.WARNING]);
      }

      const infoRec = recommendationArray
        .filter(rec => rec.severity === SeverityType.INFO)
        .sort((a, b) => new Date(b.lastVerified).getTime() - new Date(a.lastVerified).getTime())

      if (infoRec.length === 0) {
        filtersToDisable.merge([SeverityType.INFO]);
      }

      const allRec = recommendationArray
        .sort((a, b) => new Date(b.lastVerified).getTime() - new Date(a.lastVerified).getTime())

      const recommendationsWithSeverityOrder = criticalRec.concat(warningRec).concat(infoRec);

      const activeFilters = severityFilters.get({noproxy: true})
        .filter(sev => sev.active);

      if (activeFilters.length === 1) {
        recommendations.set(recommendationsWithSeverityOrder.filter(rec =>
         activeFilters
            .map(s => s.type)
            .includes(rec.severity))
          .slice(0, 5));
      }

      if (activeFilters.length > 1) {
        recommendations.set(allRec.filter(rec =>
          activeFilters.filter(sev => sev.active)
            .map(s => s.type)
            .includes(rec.severity))
          .slice(0, 5));
      }

      if (activeFilters.length === 0) {
        recommendations.set(recommendationsWithSeverityOrder.slice(0, 5));
      }

      const sevs = (recommendations.value ?
        recommendations.value?.reduce((sevs: SeverityType[], rec) => sevs.includes(rec.severity) ? sevs : sevs.concat(rec.severity), [])
        :
        []);

      if (firstAccess) {
        severityFilters.set(initialSeverityFilters.map(initSeverity => {
          if (sevs?.includes(initSeverity.type)) {
            initSeverity.active = true;
          } else {
            initSeverity.active = false;
          }
          return initSeverity;
        }))
        firstAccess.set(false);
      }
    }
  }, [topRecommendationsState]);

  const fetchRecommendations = (
    pageSize: number,
    pageIndex: number,
    severity: SeverityType[],
    selectedEmployee?: number
  ) => {
    if (!homepage) {
      loadRecommendations &&
      loadRecommendations(pageSize, pageIndex, severity, selectedEmployee).then(
        (values) => {
          if (values.length > 0) {
            recommendations.set((prevRecommendations) => {
              const recommendationsMap = new Map<number, Recommendation>();
              [
                ...(prevRecommendations ? prevRecommendations : []),
                ...values,
              ].forEach((recommendation) =>
                recommendationsMap.set(recommendation.id, recommendation)
              );
              return Array.from(recommendationsMap.values());
            });
            if (values.length < batchSize.value) {
              showLoadMore.set(false);
            }
            if (pageIndex !== 0) {
              loadMoreRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
              });
            }
          } else {
            recommendations.set([]);
            filtersToDisable.set([SeverityType.INFO, SeverityType.WARNING, SeverityType.CRITICAL]);
          }
        }
      );
    }
  };

  if (recommendations.value === undefined) {
    if (!homepage && firstAccess) {
      severityFilters.set(initialSeverityFilters);
      firstAccess.set(false);
    }

    fetchRecommendations(
      batchSize.value,
      0,
      getEnabledFilters(),
      selectedEmployeeIdFilter.value
    );
  }

  const reloadRecommendations = () => {
    if (homepage) {
      recommendations.set(undefined);
    }
  }

  const getEmployeeFilterOptions = () => {
    return employeeFilterOptions?.map((employee) => ({
      label: employee.name,
      value: employee.id,
    }));
  };

  const countFilteredRecommendations = () => {
    return recommendations.value ? recommendations.value.length : 0;
  };

  const filteredRecommendations = React.useMemo(() => {
    return recommendations.value && recommendations.value.length > 0
      ? recommendations.value
      : SAMPLE_RECOMMENDATIONS;
  }, [recommendations.value]);

  const toggleFilter = (index: number) => {
    severityFilters[index].active.set(!severityFilters[index].active.value);
    recommendations.set(undefined);
    showLoadMore.set(true);
  };

  const getDateGroup = (date: Date) => {
    const { day, year }: DetailedDateProps = getDetailedDate(date);

    const currentDay = new Date().getDate();
    const currentYear = new Date().getFullYear();

    if (currentDay !== +day && currentYear !== +year) {
      return `${formatDateToDDMon(date)} ${year}`;
    } else {
      return formatDateToDDMon(date);
    }
  };

  const groupedRecommendations = React.useMemo(() => {
    let groupedItems = {};

    filteredRecommendations.forEach((recommendation) => {
      if (!groupedItems[getDateGroup(recommendation.lastVerified)]) {
        groupedItems[getDateGroup(recommendation.lastVerified)] = {};
      }

      severityFilters.forEach((filter, _, self) => {
        if (
          !groupedItems[getDateGroup(recommendation.lastVerified)][filter.type]
        ) {
          groupedItems[getDateGroup(recommendation.lastVerified)][filter.type] =
            [];
        }

        const filteredBySeverityItems = filteredRecommendations.filter(
          (item) => {
            return self.every((j) => !j.active)
              ? item.severity === filter.type.value
              : filter.active && item.severity === filter.type.value;
          }
        );

        filteredBySeverityItems.forEach((filteredItem) => {
          if (filteredItem.lastVerified === recommendation.lastVerified) {
            groupedItems[getDateGroup(recommendation.lastVerified)][
              filter.type
            ].push(filteredItem);

            groupedItems[getDateGroup(recommendation.lastVerified)][
              filter.type
            ] = groupedItems[getDateGroup(recommendation.lastVerified)][
              filter.type
            ].filter(
              (item, index, self) =>
                index === self.findIndex((t) => t.id === item.id)
            );
          }
        });

        if (
          !groupedItems[getDateGroup(recommendation.lastVerified)][filter.type]
            .length
        ) {
          delete groupedItems[getDateGroup(recommendation.lastVerified)][
            filter.type
          ];
        }
      });
    });

    Object.keys(groupedItems).forEach((date) => {
      if (
        Object.values(groupedItems[date]).every(
          (item) => !(item as any[]).length
        )
      ) {
        delete groupedItems[date];
      }
    });

    // Order the Object by Date Keys
    let orderedGroupItems = {};
    orderedGroupItems = Object.keys(groupedItems)
      .map((item) => {
        let key: any = item.split(" ");
        if (key.length == 2) {
          const currentYear = new Date().getFullYear();
          key.push(currentYear.toString());
        }

        key = key.join(" ");

        return {
          key,
          value: groupedItems[item],
        };
      })
      .sort((a, b) => {
        return (
          moment(b.key, "DD/MMM/YYYY").toDate().getTime() -
          moment(a.key, "DD/MMM/YYYY").toDate().getTime()
        );
      })
      .reduce((cv, pv) => {
        const currentDay = new Date().getDate();
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        const day = +pv.key.split(" ")[0];
        const month = getMonthFromString(pv.key.split(" ")[1]);
        const year = +pv.key.split(" ")[2];

        cv[pv.key] = pv.value;

        if (
          day === currentDay &&
          month === currentMonth &&
          year === currentYear
        ) {
          delete Object.assign(cv, { ["Today"]: cv[pv.key] })[pv.key];
        } else if (year === currentYear) {
          const key = pv.key.split(" ").slice(0, 2).join(" ");
          delete Object.assign(cv, { [key]: cv[pv.key] })[pv.key];
        }

        return cv;
      }, {});

    return orderedGroupItems;
  }, [filteredRecommendations]);


  const getNoRecommendationsLabel = () => {
    setTimeout(function () {
      return "Congratulations! You’ve acted on all of your top recommendations.";
    }, 5000);
    return undefined;
  }

  return (
    <>
      {/* HEADER */}
      <S.HeaderWrapper>
        <S.Title>{title}</S.Title>

        {/* FILTER */}
        <S.FiltersWrapper>
          {privacyLevelService.hasAccess(auth.privacyLevel.value as PrivacyLevel) && filterByEmployee && (
            <S.EmployeeFilter>
              <CustomSelect
                placeholder="Filter by employee"
                options={getEmployeeFilterOptions()}
                value={selectedEmployeeIdFilter.value}
                minimal
                onChange={(opt) => {
                  selectedEmployeeIdFilter.set(opt.value);
                  recommendations.set(undefined);
                  showLoadMore.set(true);
                }}
              />
            </S.EmployeeFilter>
          )}

          {/* SEVERITY FILTER BUTTONS */}
          <S.SeverityFiltersWrapper>
            {severityFilters.map((filter, index) => (
              <Button
                size="small"
                minimal
                onClick={() => toggleFilter(index)}
                key={index}
                $shadow={true}
                disabled={homepage && filtersToDisable.includes(filter.type)}
              >
                <S.FilterLabel selected={filter.active.value} type={filter.type.value}>
                  {getSeverityLabel(filter.type.value)}
                </S.FilterLabel>
              </Button>
            ))}
          </S.SeverityFiltersWrapper>
        </S.FiltersWrapper>
      </S.HeaderWrapper>

      {
        homepage && countFilteredRecommendations() === 0 ?
          // @ts-ignore
          <S.NoRecommendationsLabel>
            {
              getNoRecommendationsLabel() // fixme
            }
          </S.NoRecommendationsLabel>
          :
          <BlurredContainer blur={countFilteredRecommendations() === 0}>
            <S.RecommendationsWrapper>
              {Object.keys(groupedRecommendations).map((date, grindex) => (
                <>
                  {
                    <S.RecommendationsGroupContainer key={date}>
                      <S.DateLabel>{date}</S.DateLabel>

                      {severityFilters.map(({ type }, index) => (
                        <>
                          {!!groupedRecommendations[date][type] &&
                            groupedRecommendations[date][type].length && (
                              <S.RecommendationsGroupWrapper
                                // @ts-ignore
                                severity={type.value}
                                key={type.value}
                              >
                                {groupedRecommendations[date][type].map(
                                  (recommendation, index) => (
                                    <RecommendationItem
                                      recommendation={recommendation}
                                      key={recommendation.id}
                                      onChangeFeedback={() => reloadRecommendations()}
                                      showAvatarImage={privacyLevelService.hasAccess(auth.privacyLevel.value as PrivacyLevel)}
                                    />
                                  )
                                )}
                              </S.RecommendationsGroupWrapper>
                            )}
                        </>
                      ))}
                    </S.RecommendationsGroupContainer>
                  }
                </>
              ))}
            </S.RecommendationsWrapper>

            {/* LOAD MORE */}
            {
              !homepage &&
              <S.LoadMoreWrapper>
                <LoadMore
                  ref={loadMoreRef}
                  shadow
                  onLoadMore={() => {
                    let nextBatchIndex = Math.ceil(
                      filteredRecommendations.length / batchSize.value
                    );
                    fetchRecommendations(
                      batchSize.value,
                      nextBatchIndex,
                      getEnabledFilters(),
                      selectedEmployeeIdFilter.value
                    );
                  }}
                />
              </S.LoadMoreWrapper>
            }
          </BlurredContainer>
      }
    </>
  );
}
