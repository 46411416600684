import { capitalize } from "./text";
import {DateTime} from "luxon";
import {PeriodSelectorState} from "components/utils/PeriodSelector";

export type DetailedDateProps = {
  year: string;
  month: string;
  day: string;
};

export const getFormattedDateByTimestamp = (timestamp: number): string => {
  return new Intl.DateTimeFormat("sv-SE").format(timestamp);
};

export const getFormattedDateByNewDate = (date: Date): string => {
  return date.toISOString().substring(0, 10);
};

export function formatDateToDDMon(value: Date): string {
  let date = new Date(value);
  const day = date.toLocaleString("en-us", { day: "2-digit" });
  const month = date.toLocaleString("en-us", { month: "short" });
  return day + " " + capitalize(month).substring(0, 3);
}

export const getDetailedDate = (
  date: string | number | Date
): DetailedDateProps => {
  let formattedDate =
    typeof date === "number"
      ? getFormattedDateByTimestamp(date)
      : typeof date === "object"
      ? getFormattedDateByNewDate(date)
      : date;

  const splittedDate = formattedDate.split("-");

  return {
    year: splittedDate[0],
    month: splittedDate[1],
    day: splittedDate[2],
  };
};

export const getMonthFromString = (month: string) => {
  return Math.ceil(
    "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1
  );
};

export const getTimeInterval = () => {
  const today = DateTime.now().startOf('day');
  const dayOfTheWeek = today.weekday;
  const begin = today.minus({ days: (PeriodSelectorState.selectedPeriod.value * 2)+dayOfTheWeek }).startOf('day');
  const from = begin.monthShort.concat(' ').concat(begin.day.toString());
  const to = today.monthShort.concat(' ').concat(today.day.toString());

  return '(' + from + ' - ' + to + ')';
}
