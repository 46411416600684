import {createState, State, useState} from "@hookstate/core";
import { Dashboard, DashboardQuery } from "model/dashboards";
import { CommonModal } from "components/utils/Modal/CommonModal";
import { Dialog } from "@headlessui/react";
import React, {useRef} from "react";
import Button from "components/generics/Button";
import ModalTextTitle from "components/utils/Modal/ModalTextTitle";
import TextField from "components/generics/TextField";

import * as S from "./styles";

export const DashboardQueryModalState = createState({
  open: false,
  queries: [""],
  dashboardQuery: {
    id: 0,
    sortOrder: 0,
    name: "",
    query: "",
    displayWidth: 0,
    displayHeight: 0,
  } as DashboardQuery,
  title: "",
  onSave: () => {},
});

export const openDashboardQueryModal = (
  dashboard: State<Dashboard>,
  dashboardQuery: DashboardQuery,
  title: string,
  onSave: () => void
) => {
  DashboardQueryModalState.set({
    open: true,
    queries: dashboard.dashboardQueries.value
      .filter((dq) => dq.id !== dashboardQuery.id)
      .map((dq) => dq.query),
    dashboardQuery: { ...dashboardQuery },
    title: title,
    onSave: onSave,
  });
};

export default function DashboardQueryModal() {
  const modalState = useState(DashboardQueryModalState);
  const dashboardQueryState = useState(DashboardQueryModalState.dashboardQuery);

  const nameRef = useRef<HTMLInputElement | null>(null);
  const queryRef = useRef<HTMLInputElement | null>(null);

  const closeModal = () => modalState.open.set(false);

  const handleSave = () => {
    closeModal();
    return modalState.value.onSave();
  };

  return (
    <>
      <CommonModal openState={DashboardQueryModalState.open}>
        <S.Wrapper>
          <S.MainContainer>
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-pl-grayscale-black"
            >
              <ModalTextTitle title={modalState.title.value} />
            </Dialog.Title>

            <S.ContentWrapper>
              <S.FormWrapper>
                <S.FieldWrapper>
                  <S.LabelWrapper>
                    Query name (Optional)
                  </S.LabelWrapper>
                  <S.ElementWrapper>
                    <TextField
                      ref={nameRef}
                      name="name"
                      value={dashboardQueryState.name.value}
                      onChange={({ target: { value } }) =>
                        dashboardQueryState.name.set(value)
                      }
                      clearable
                      border={false}
                      placeholder="Enter a name"
                    />
                  </S.ElementWrapper>
                </S.FieldWrapper>

                <S.FieldWrapper>
                  <S.LabelWrapper>
                    Query text
                  </S.LabelWrapper>
                  <S.ElementWrapper>
                    <TextField
                      ref={queryRef}
                      name="query"
                      value={dashboardQueryState.query.value}
                      onChange={({ target: { value } }) => {
                        dashboardQueryState.query.set(value);
                      }}
                      clearable
                      border={false}
                      placeholder="Enter a query text"
                    />
                    {
                      dashboardQueryState.query.value.length === 0 &&
                      <S.Error>Query text is a required field</S.Error>
                    }
                  </S.ElementWrapper>
                </S.FieldWrapper>

              </S.FormWrapper>
            </S.ContentWrapper>

            <S.ButtonsWrapper>
              <Button onClick={() => closeModal()}>
                Close
              </Button>
              <Button
                type="submit"
                btntype="primary"
                $shadow={true}
                disabled={dashboardQueryState.query.value.length === 0}
                onClick={() => handleSave()}
              >
                Done
              </Button>
            </S.ButtonsWrapper>
          </S.MainContainer>
        </S.Wrapper>
      </CommonModal>
    </>
  );
}
