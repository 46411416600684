import tw from "tailwind-styled-components";
import {LogoProps} from '.'

type LogoWrapperProps = Pick<LogoProps, 'size'>

const LogoModifiers = {
  small: `
    h-7
    w-7
  `,

  medium: `
    h-10
    w-10
  `,

  large: `
    h-15
    w-15
  `
}

export const Wrapper = tw.div`
  flex-shrink-0
  hidden
  sm:block
  transition-smooth
  hover:scale-110
`;

export const Logo = tw.img<LogoWrapperProps>`
  ${({size}) => `
    block
    w-auto

    ${!!size && LogoModifiers[size]}
  `}
`;