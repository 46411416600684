import React, { useEffect, useRef } from "react";
import { InstalledIntegrationInfoDto } from "model/integration";
import Heading from "components/generics/Heading";
import TextField from "components/generics/TextField";
import Button from "components/generics/Button";
import Pagination from "components/generics/Pagination";
import LoadMore from "components/generics/LoadMore";
import IntegrationItem from "./IntegrationItem";
import IntegrationModal from "routes/integrationForm/manageIntegration/ManageIntegration";
import AdditionalDataModal from "routes/integrationForm/additionalData/AdditionalData";

import * as S from "./styles";

import { ReactComponent as SearchIcon } from "icons/search.svg";
import {useState} from "@hookstate/core";


export type NewIntegrationListProps = {
  integrations: InstalledIntegrationInfoDto[];
  tab: string
};

const OPTIONS = [
  "all",
  "CRM",
  "collaboration",
  "customer support",
  "developer",
  "featured",
  "general",
  "productivity",
  "project management",
  "HRIS",
  "ATS",
  "marketing"
];

const OPTIONS_DESCRIPTION = [
  {name: "CRM", description: "Understand the flow of leads, contacts, accounts, and deals by connecting Peoplelogic.ai to your CRM tool. Optimize your time to close and improve your customer relationship management by receiving deeper insights and recommendations into your processes."},
  {name: "collaboration", description: "Connecting Peoplelogic to your collaboration tools will paint a picture of how your team communicates, interacts, and shares what they know with each other."},
  {name: "customer support", description: "Do you know how your employee experience affects your customers? Connect Peoplelogic to your ticketing platform to get insights and recommendations to optimize the experience your customers have with your team."},
  {name: "developer", description: "Supercharge your engineering teams by connecting Peoplelogic to your developer tools to get insights and recommendations around team performance and best practices."},
  {name: "featured", description: "Our current list of Featured Tools."},
  {name: "general", description: "A list of other general tools that you can connect."},
  {name: "productivity", description: "Connect Peoplelogic to your productivity tools to help your team better understand team utilization, capacity, and efficiency."},
  {name: "project management", description: "Connect Peoplelogic to your project management platforms to receive deeper, meaningful insights on your projects and tasks."},
  {name: "HRIS", description: "Connect Peoplelogic to your HRIS platform to keep Peoplelogic in sync with the current state of your team, including hirings, terminations, team changes, and more."},
  {name: "ATS", description: "Connecting Peoplelogic to your ATS will surface insights and recommendations around how your candidates move through the recruiting and hiring process and transition into onboarding and the employee experience."},
  {name: "marketing", description: "Connect Peoplelogic to your marketing tools to better understand what brings in more leads, customers, and learn how the employe experience is connected."},
];

const NewIntegrationList = ({ integrations, tab }: NewIntegrationListProps) => {
  const search = useState("");
  const hasFilter = useState(false);
  const itemsPerPage = useState(9);
  const selectedFilter = useState(tab);
  const startIndex = useState(0);
  const endIndex = useState(9);

  const loadMoreRef = useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    selectedFilter.value !== "all" || search.value.length >= 3
      ? hasFilter.set(true)
      : hasFilter.set(false);
  }, [selectedFilter, search, itemsPerPage]);

  const getIntegrationsToDisplay = () => integrations
    .filter((integration) => {
      if (search.value?.length >= 3) {
        if (selectedFilter.value !== "all") {
          return (
            integration.name.toLowerCase().includes(search.value.toLowerCase()) &&
            integration.category.toLowerCase() ===
            selectedFilter.value.toLowerCase()
          );
        } else {
          return integration.name
            .toLowerCase()
            .includes(search.value.toLowerCase());
        }
      } else if (selectedFilter.value !== "all") {
        return (
          integration.category.toLowerCase() === selectedFilter.value.toLowerCase()
        );
      } else {
        return integration;
      }
    })
    .slice(startIndex.value, endIndex.value);

  useEffect(() => {
    if (loadMoreRef.current && itemsPerPage.value > 9) {
      loadMoreRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  });

  const handlePaginate = (
    start: number,
    end: number,
    page: number
  ) => {
    startIndex.set(start);
    endIndex.set(end);
    search.set("");
  };


  const categoryTabs = () => OPTIONS
    .sort((a, b) => a.localeCompare(b))
    .filter(o => o == "all" || integrations.filter(i => i.category.toLowerCase() == o.toLowerCase()).length);

  const displayedIntegrations = getIntegrationsToDisplay();
  const countItems = displayedIntegrations.length + startIndex.value;
  const isPaginationVisible = startIndex.value > 0 || displayedIntegrations.length >= itemsPerPage.value;

  return (
    <>
      <AdditionalDataModal/>
      <IntegrationModal/>
      <S.Wrapper>
        <S.TitleWrapper>
          <Heading text="Select an Integration Type" size="medium" />
          <S.SearchWrapper>
            <TextField
              placeholder="Search"
              icon={<SearchIcon className="text-pl-grayscale-gray" />}
              iconposition={"right"}
              onChange={({ target: { value } }) => {
                search.set(value);
              }}
              border={false}
            />
          </S.SearchWrapper>
        </S.TitleWrapper>

        <S.FilterListWrapper>
          {categoryTabs().map((option, index) => (
            <Button
              key="index"
              size="small"
              className={`${S.FilterItem} ${
                selectedFilter.value === option ? S.SelectedFilterItem : ""
              }`}
              onClick={() => selectedFilter.set(option)}
            >
              {option}
            </Button>
          ))}
        </S.FilterListWrapper>

        {
          OPTIONS_DESCRIPTION.find(obj => obj.name === selectedFilter.value) &&
          <S.DescriptionWrapper>
            {
              OPTIONS_DESCRIPTION.find(obj => obj.name === selectedFilter.value)?.description
            }
          </S.DescriptionWrapper>
        }

        <S.ListWrapper>
          <>
            {!!displayedIntegrations.length ? (
              displayedIntegrations.map((integration) => (
                <IntegrationItem
                  key={integration.id}
                  integration={integration}
                />
              ))
            ) : (
              <S.Empty>No items to show</S.Empty>
            )}
          </>
        </S.ListWrapper>

        <S.FooterWrapper>
          <S.TotalItems>
            {`${
              countItems <= integrations.length
                ? countItems
                : integrations.length
            } of ${integrations.length} entries`}
          </S.TotalItems>

          <LoadMore
            ref={loadMoreRef}
            minimal
            isVisible={isPaginationVisible}
            onLoadMore={() => itemsPerPage.set(itemsPerPage.value + 9)}
          />

          <S.Pagination>
            <Pagination
              items={integrations}
              itemsPerPage={itemsPerPage.value}
              isVisible={isPaginationVisible}
              onPaginate={(
                startIndex: number,
                endIndex: number,
                page: number
              ) => handlePaginate(startIndex, endIndex, page)}
            />
          </S.Pagination>
        </S.FooterWrapper>
      </S.Wrapper>
    </>
  );
};

export default NewIntegrationList;
