import {KeyMetric, KeyMetricsType, Kpi, KpiCategory} from "model/keymetrics";
import lexi from "./lexi";
import {DateTime} from "luxon";
import {apiFetch, apiPut} from "./utils";
import {DataCollection} from "model/lexi";
import {getPercentageIncrease, getModule} from "../utils/math";
import {Department} from "model/department";

class KeyMetricsService {

    getTeamKpis(teamId: number) {
        return apiFetch<KeyMetric[]>(`/api/xhr/teams/${teamId}/kpis`);
    }

    saveTeamKpis(teamId: number, keyMetricsType: KeyMetricsType, numDays?: number) {
        const numDaysBefore = numDays ? numDays : 14;
        return apiPut<KeyMetric[]>(`/api/xhr/teams/${teamId}/kpis`, keyMetricsType.keyMetrics)
          .then(metrics => this.createLineChart(teamId, metrics, "teams", numDaysBefore));
    }

    getAllKpis() {
        return apiFetch<KpiCategory[]>(`/api/xhr/teams/kpiCategories`);
    }

    getKeyMetricsForTeam(teamId: any, numDays?: number) {
        const numDaysBefore = numDays ? numDays : 14;
        return this.getKeyMetrics(`/api/xhr/teams/${teamId}/kpis`)
          .then(metrics => this.createLineChart(teamId, metrics, "teams", numDaysBefore));
    }

    getKeyMetricsForEmployee(id: any, numDays?: number) {
      const numDaysBefore = numDays ? numDays : 14;
        return this.getKeyMetrics(`/api/xhr/employees/${id}/kpis`)
          .then(metrics => this.createLineChart(id, metrics, "employees", numDaysBefore));
    }

    getDepartmentKpis(departmentId: number) {
      return apiFetch<KeyMetric[]>(`/api/xhr/departments/${departmentId}/kpis`);
    }

    saveDepartmentKpis(departmentId: number, keyMetricsType: KeyMetricsType, numDays?: number) {
      const numDaysBefore = numDays ? numDays : 14;
      return apiPut<KeyMetric[]>(`/api/xhr/departments/${departmentId}/kpis`, keyMetricsType.keyMetrics)
        .then(metrics => this.createLineChart(departmentId, metrics, "departments", numDaysBefore));
    }

    getKeyMetricsForDepartment(id: number, numDays?: number) {
      const numDaysBefore = numDays ? numDays : 14;
      return this.getKeyMetrics(`/api/xhr/departments/${id}/kpis`)
        .then(metrics => this.createLineChart(id, metrics, "departments", numDaysBefore));
    }

    async getKeyMetrics (url: string) {
      return apiFetch<KeyMetric[]>(url);
    }

    async createLineChart(id: number, metrics: KeyMetric[], dataCollection: DataCollection, numDays: number) {
      let keyMetrics = {} as KeyMetricsType;

      let kpis: Kpi[] = [];
      metrics.map(m => kpis.push({kpiName: m.kpiName, eventName: m.eventName} as Kpi));
      keyMetrics.kpis = kpis;

      const nowAtStartOfDay = DateTime.now().startOf('day');
      const dayOfTheWeek = nowAtStartOfDay.weekday;

      const lastDays = nowAtStartOfDay.minus({ days: (numDays * 2)+dayOfTheWeek }).startOf('day');

      let keyMetric: KeyMetric[] = [];

      if (metrics && metrics.length > 0) {
        for (let k = 0; k < metrics.length; k++) {
          let kpi = metrics[k];

          const lineChart = await this.getFetchPromisse(id, dataCollection, kpi.eventName, lastDays, nowAtStartOfDay);

          if (! lineChart.xAxisValues || !lineChart.series || !lineChart.series.length) {
            continue;
          }
          const xAxis = lineChart.xAxisValues;

          const values = xAxis.map(key => lineChart.series[0].values[key as string] || 0);

          const previousValues = values.slice(0, numDays + 1);
          const currentValues = values.slice(numDays + 1);
          const prev = previousValues.reduce((a, b) => a + b, 0);
          const curr = currentValues.reduce((a, b) => a + b, 0);

          let percentage = getPercentageIncrease(prev, curr);
          keyMetric.push({
            kpiName: kpi.kpiName,
            eventName: kpi.eventName,
            smallerIsBetter: kpi.smallerIsBetter,
            value: curr,
            percentage: percentage});
        }
      } else {
        return keyMetrics;
      }

      keyMetric.sort((a, b) => getModule(a.percentage) < getModule(b.percentage) ? 1 : -1);
      keyMetrics.keyMetrics = keyMetric;

      return keyMetrics;
    }

    async getFetchPromisse(id: number, dataCollection: DataCollection, field: string, start: DateTime, end: DateTime) {
        let query = lexi.getQuery('daily')
            .collection(dataCollection)
            .start.setDate(start)
            .end.setDate(end)
            .count(field);

        if (dataCollection === 'employees') {
            query = query.forEmployee(id);
        } else if (dataCollection === 'departments') {
            query = query.forDepartment(id);
        } else if (dataCollection === 'teams') {
            query = query.forTeam(id);
        }
        return query.fetchAsMultiSeries();
    }

}

export default new KeyMetricsService();
