import React from "react";
import * as St from "components/utils/Datatable/styles";

import {ReactComponent as ActiveIcon} from "icons/active.svg";
import {ReactComponent as DeactiveIcon} from "icons/deactive.svg";

const TableItemActiveIcon = ({active}:{active:boolean}) => {
  return <>
    {
      active ?
        <St.ActiveIcon>
          <ActiveIcon />
        </St.ActiveIcon>
        :
        <St.InactiveIcon>
          <DeactiveIcon />
        </St.InactiveIcon>
    }
  </>
}

export default TableItemActiveIcon;