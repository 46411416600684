import React, {useRef} from "react";

import {ReactComponent as CloseIcon} from "icons/close.svg";
import {State, useState} from "@hookstate/core";

export default function MultistringInput({
  list = [],
  editMode = false,
  onUpdate,
  className = "",
  previousSkills,
}: {
  list?: string[];
  editMode?: boolean;
  onUpdate: (updatedList: string[]) => void;
  className?: string;
  previousSkills?: string[];
}) {
  const initialList = useState<string[]>([...(previousSkills || list)]);
  const inputValue = useState<string>("");
  const inputRef = useRef<null | HTMLInputElement>(null);

  const onUpdateValues = (resultList: string[]) => {
    onUpdate(resultList);
  };

  const includesValue = (value: string) => {
    return initialList.some((element) => {
      return element.value.toLowerCase() === value.toLowerCase();
    });
  };

  return (
    <>
      <div
        className={`block mt-2 min-h-[55px] ${
          editMode ? "border border-pl-grayscale-lightgray rounded-[10px] py-2 px-3" : "px-2 pt-2"
        } ${className ? className : ""}`}
        onClick={() => inputRef.current?.focus()}
      >
        {
          initialList.map((str, index) => (
            <div
              key={`skill-${index}`}
              className={`relative inline-block rounded-lg px-2 py-1 mr-2 mb-2 text-sm capitalize
               ${editMode ? "bg-pl-primary-green-light font-semibold text-pl-primary-green-default" : "bg-pl-grayscale-lightgray text-pl-grayscale-gray"}
              `}
            >
              <div className={"flex"}>
                {str.value}
                {editMode && (
                  <CloseIcon
                    className={"w-3 h-3 ml-2 mt-1 cursor-pointer"}
                    onClick={() => {
                      initialList.set(array => array.filter(item => item.toLowerCase() !== str.value.toLowerCase()));
                      onUpdateValues(initialList.value);
                    }}
                  />
                )}
              </div>
            </div>
          ))
        }

        {editMode && (
          <input
            ref={inputRef}
            type="text"
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                if (inputValue.value !== "" && !includesValue(event.currentTarget.value)) {
                  initialList.merge([event.currentTarget.value]);
                  onUpdateValues(initialList.value);
                }
                inputValue.set("");
              }
            }}
            onChange={(event) => inputValue.set(event.target.value)}
            value={inputValue.value}
            className="inline-block text-sm w-[100px] p-0 focus:ring-0 border-0 appearance-none outline-none text-pl-grayscale-black bg-transparent"
          />
        )}
      </div>
    </>
  );
}
