import React from "react";
import { LogoutIcon } from "@heroicons/react/outline";
import Dropdown from "components/generics/Dropdown";
import SubmenuItem from "components/generics/SubmenuItem";
import { EmployeeAvatar } from "components/utils/Avatar";
import { SubmenuProps } from "model/submenu";
import { Employee } from "model/employee";
import { logout } from '../../../../services/auth';

import * as S from "./styles";

export type AvatarDropdownProps = {
  user: Employee;
  items: SubmenuProps[];
};

const AvatarDropdown = ({ user, items }: AvatarDropdownProps) => {
  return (
    <>
      <Dropdown
        title={
          <S.Title>
            <EmployeeAvatar employee={user} size={1} linkTo={false} hoverable/>
          </S.Title>
        }
        direction="left"
      >
        <S.MenuWrapper>
          {items.map((item, index) => (
            <S.MenuItem key={index} className={`${index == 0 ? "hover:rounded-t-2xl" : ""}`}>
              <SubmenuItem
                label={item.label}
                path={item.path}
                description={item.description}
                icon={item.icon}
              />
            </S.MenuItem>
          ))}

          <S.MenuItem className={"hover:rounded-b-2xl"}>
            <SubmenuItem label="Sign Out" action={() => logout()} icon={<LogoutIcon />} />
          </S.MenuItem>
        </S.MenuWrapper>
      </Dropdown>
    </>
  );
};

export default AvatarDropdown;
