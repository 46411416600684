export const CUSTOM_EVENT_CATEGORY = 'custom';

export type KeyMetricsType = {
  kpis: Kpi[],
  keyMetrics: KeyMetric[],
}

export type Kpi = {
  kpiName: string,
  eventName: string,
}

export type KeyMetric = {
    kpiName: string,
    eventName: string,
    smallerIsBetter: boolean,
    value: number,
    percentage: number
}

export type KpiCategory = {
    category: string,
    kpis: KeyMetric[]
}