export default {
  labels: ["My Matrix"],
  datasets: [
    {
      label: "Basic matrix",
      data: [
        {
          x: "A",
          y: "v1",
          v: 30,
        },
        {
          x: "A",
          y: "v2",
          v: 95,
        },
        {
          x: "A",
          y: "v3",
          v: 22,
        },
        {
          x: "A",
          y: "v4",
          v: 14,
        },
        {
          x: "A",
          y: "v5",
          v: 59,
        },
        {
          x: "A",
          y: "v6",
          v: 52,
        },
        {
          x: "A",
          y: "v7",
          v: 88,
        },
        {
          x: "A",
          y: "v8",
          v: 20,
        },
        {
          x: "A",
          y: "v9",
          v: 99,
        },
        {
          x: "A",
          y: "v10",
          v: 66,
        },
        {
          x: "B",
          y: "v1",
          v: 37,
        },
        {
          x: "B",
          y: "v2",
          v: 50,
        },
        {
          x: "B",
          y: "v3",
          v: 81,
        },
        {
          x: "B",
          y: "v4",
          v: 79,
        },
        {
          x: "B",
          y: "v5",
          v: 84,
        },
        {
          x: "B",
          y: "v6",
          v: 91,
        },
        {
          x: "B",
          y: "v7",
          v: 82,
        },
        {
          x: "B",
          y: "v8",
          v: 89,
        },
        {
          x: "B",
          y: "v9",
          v: 6,
        },
        {
          x: "B",
          y: "v10",
          v: 67,
        },
        {
          x: "C",
          y: "v1",
          v: 96,
        },
        {
          x: "C",
          y: "v2",
          v: 13,
        },
        {
          x: "C",
          y: "v3",
          v: 98,
        },
        {
          x: "C",
          y: "v4",
          v: 10,
        },
        {
          x: "C",
          y: "v5",
          v: 86,
        },
        {
          x: "C",
          y: "v6",
          v: 23,
        },
        {
          x: "C",
          y: "v7",
          v: 74,
        },
        {
          x: "C",
          y: "v8",
          v: 47,
        },
        {
          x: "C",
          y: "v9",
          v: 73,
        },
        {
          x: "C",
          y: "v10",
          v: 40,
        },
        {
          x: "D",
          y: "v1",
          v: 75,
        },
        {
          x: "D",
          y: "v2",
          v: 18,
        },
        {
          x: "D",
          y: "v3",
          v: 92,
        },
        {
          x: "D",
          y: "v4",
          v: 43,
        },
        {
          x: "D",
          y: "v5",
          v: 16,
        },
        {
          x: "D",
          y: "v6",
          v: 27,
        },
        {
          x: "D",
          y: "v7",
          v: 76,
        },
        {
          x: "D",
          y: "v8",
          v: 24,
        },
        {
          x: "D",
          y: "v9",
          v: 1,
        },
        {
          x: "D",
          y: "v10",
          v: 87,
        },
        {
          x: "E",
          y: "v1",
          v: 44,
        },
        {
          x: "E",
          y: "v2",
          v: 29,
        },
        {
          x: "E",
          y: "v3",
          v: 58,
        },
        {
          x: "E",
          y: "v4",
          v: 55,
        },
        {
          x: "E",
          y: "v5",
          v: 65,
        },
        {
          x: "E",
          y: "v6",
          v: 56,
        },
        {
          x: "E",
          y: "v7",
          v: 9,
        },
        {
          x: "E",
          y: "v8",
          v: 78,
        },
        {
          x: "E",
          y: "v9",
          v: 49,
        },
        {
          x: "E",
          y: "v10",
          v: 36,
        },
        {
          x: "F",
          y: "v1",
          v: 35,
        },
        {
          x: "F",
          y: "v2",
          v: 80,
        },
        {
          x: "F",
          y: "v3",
          v: 8,
        },
        {
          x: "F",
          y: "v4",
          v: 46,
        },
        {
          x: "F",
          y: "v5",
          v: 48,
        },
        {
          x: "F",
          y: "v6",
          v: 100,
        },
        {
          x: "F",
          y: "v7",
          v: 17,
        },
        {
          x: "F",
          y: "v8",
          v: 41,
        },
        {
          x: "F",
          y: "v9",
          v: 33,
        },
        {
          x: "F",
          y: "v10",
          v: 11,
        },
        {
          x: "G",
          y: "v1",
          v: 77,
        },
        {
          x: "G",
          y: "v2",
          v: 62,
        },
        {
          x: "G",
          y: "v3",
          v: 94,
        },
        {
          x: "G",
          y: "v4",
          v: 15,
        },
        {
          x: "G",
          y: "v5",
          v: 69,
        },
        {
          x: "G",
          y: "v6",
          v: 63,
        },
        {
          x: "G",
          y: "v7",
          v: 61,
        },
        {
          x: "G",
          y: "v8",
          v: 54,
        },
        {
          x: "G",
          y: "v9",
          v: 38,
        },
        {
          x: "G",
          y: "v10",
          v: 93,
        },
        {
          x: "H",
          y: "v1",
          v: 39,
        },
        {
          x: "H",
          y: "v2",
          v: 26,
        },
        {
          x: "H",
          y: "v3",
          v: 90,
        },
        {
          x: "H",
          y: "v4",
          v: 83,
        },
        {
          x: "H",
          y: "v5",
          v: 31,
        },
        {
          x: "H",
          y: "v6",
          v: 2,
        },
        {
          x: "H",
          y: "v7",
          v: 51,
        },
        {
          x: "H",
          y: "v8",
          v: 28,
        },
        {
          x: "H",
          y: "v9",
          v: 42,
        },
        {
          x: "H",
          y: "v10",
          v: 7,
        },
        {
          x: "I",
          y: "v1",
          v: 5,
        },
        {
          x: "I",
          y: "v2",
          v: 60,
        },
        {
          x: "I",
          y: "v3",
          v: 21,
        },
        {
          x: "I",
          y: "v4",
          v: 25,
        },
        {
          x: "I",
          y: "v5",
          v: 3,
        },
        {
          x: "I",
          y: "v6",
          v: 70,
        },
        {
          x: "I",
          y: "v7",
          v: 34,
        },
        {
          x: "I",
          y: "v8",
          v: 68,
        },
        {
          x: "I",
          y: "v9",
          v: 57,
        },
        {
          x: "I",
          y: "v10",
          v: 32,
        },
        {
          x: "J",
          y: "v1",
          v: 19,
        },
        {
          x: "J",
          y: "v2",
          v: 85,
        },
        {
          x: "J",
          y: "v3",
          v: 53,
        },
        {
          x: "J",
          y: "v4",
          v: 45,
        },
        {
          x: "J",
          y: "v5",
          v: 71,
        },
        {
          x: "J",
          y: "v6",
          v: 64,
        },
        {
          x: "J",
          y: "v7",
          v: 4,
        },
        {
          x: "J",
          y: "v8",
          v: 12,
        },
        {
          x: "J",
          y: "v9",
          v: 97,
        },
        {
          x: "J",
          y: "v10",
          v: 72,
        },
      ],
      backgroundColor(c) {
        const value = c.dataset.data[c.dataIndex].v;
        const alpha = (value - 5) / 40;
        return `rgba(0, 200, 0, ${alpha})`;
      },
      borderColor: "white",
      borderWidth: 1,
      hoverBackgroundColor: "yellow",
      hoverBorderColor: "yellowgreen",
      width: ({ chart }) =>
        (chart.chartArea || {}).width / chart.scales.x.ticks.length - 3,
      height: ({ chart }) =>
        (chart.chartArea || {}).height / chart.scales.y.ticks.length - 3
    },
  ],
};
