import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  flex
  w-full
  gap-6
  bg-pl-grayscale-white
  rounded-2xl
  p-6
  shadow
`;

export const MenuWrapper = tw.nav`
  flex
  flex-col
  gap-6
`;

export const MenuItemWrapper = tw.div`
  flex
  flex-col
  cursor-pointer
  gap-1
`;

export const TitleWrapper = tw.div<{ isSelected: boolean }>`
  ${({ isSelected }) => `
    flex
    items-center
    gap-2
    font-semibold
    transition-smooth
    text-sm
    ${isSelected ? "text-pl-primary-green-default" : "text-pl-grayscale-gray"}
  `}
`;

export const Subtitle = tw.p`
  text-xs
`;

export const ContentWrapper = tw.div`
  w-full
  flex
  flex-col
  justify-between
  pl-6
  gap-[40px]
  border-l
  border-pl-grayscale-lightgray
`;

export const ActionWrapper = tw.div`
  px-4
  py-3
  text-right
  sm:px-6
  flex
  justify-between
`;

export const FixedActionWrapper = tw.div`
  flex
  gap-2
`;