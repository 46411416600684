import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
    flex-1
    flex
    flex-col
    h-auto
`;

export const LexiChatHeader = tw.div`
    flex
    sm:items-center
    py-4
    px-6
    bg-pl-grayscale-white
    rounded-[15px]
    border-[1px]
    border-pl-grayscale-lightgray
`;

export const HeaderWrapper = tw.div`
    flex
    items-center
    space-x-3
`;

export const HeaderTextWrapper = tw.div`
    flex
    text-xl
    mt-1
    items-center
`;
export const HeaderTextSpan = tw.span`
    text-pl-grayscale-black
    mr-2
    font-bold
`;

export const ChatMessagesWrapper = tw.div`
    flex
    flex-col
    space-y-4
    py-3
    overflow-y-auto s
    crollbar-thumb-blue
    scrollbar-thumb-rounded
    scrollbar-track-pl-primary-green-defaultlighter
    scrollbar-w-2 scrolling-touch
    min-h-[300px] max-h-[70vh]
`;

export const IndvidualChatWrapper = tw.div`
    chat-message
`;

export const QueryExamplesWrapper = tw.div`
    pt-4
    mb-2
    sm:mb-0
    relative
`;

export const QueryExamplesHeader = tw.div`
    flex
    justify-between
    font-semibold
    mb-6
`;

export const ExamplesHeaderLeadingSection = tw.div`
    flex
`;

export const LibraryLinkSpan = tw.span`
    font-normal
`;

export const LibraryLink = tw.a`
    text-pl-primary-green-default
    hover:underline
`;

export const ExamplesGridWrapper = tw.div`
    w-full
    text-center
`;

export const ExamplesGrid = tw.ul`
    grid
    grid-cols-3
    gap-6
    sm:grid-cols-2
`;

export const QueryExample = tw.div`
    bg-pl-grayscale-white
    text-pl-primary-green-default
    hover:bg-pl-primary-green-light
    hover:text-pl-primary-green-default
    cursor-pointer rounded-[15px]
    flex
    items-center
    justify-center
    font-semibold
    p-2
`;

export const BotReplyContainerMinHeight = tw.div`
    w-full
    px-4
    py-2
    rounded-[15px]
    inline-block
    border-[1px]
    border-pl-grayscale-lightgray
    text-pl-grayscale-black
    min-h-[350px]
`;

export const BotReplyContainer = tw.div`
    w-full
    px-4
    py-2
    rounded-[15px]
    inline-block
    border-[1px]
    border-pl-grayscale-lightgray
    text-pl-grayscale-black
`;

export const LexiReplyContainer = tw.div`
    px-4
    py-2
    rounded-[15px]
    inline-block
    bg-pl-grayscale-lightgray
    text-pl-grayscale-gray
    border-[1px]
    border-pl-grayscale-lightgray
`;

export const ChatInputWrapper = tw.div`
    py-4
    mb-2
    sm:mb-0
`;

export const HeaderChatInputWrapper = tw.div`
    p-4
    mb-2
    sm:mb-0
`;

export const ChatInputContainer = tw.div`
    relative
    flex
    rounded-[15px]
    bg-pl-grayscale-white
    border-[1px]
    border-pl-grayscale-lightgray
`;

export const SendButtonWrapper = tw.div`
    absolute
    right-0
    p-2
    items-center
    inset-y-0
    sm:flex
`;

export const HeaderSendButtonWrapper = tw.div`
    absolute
    right-0
    items-center
    inset-y-0
    sm:flex
`;

export const SendButton = tw.button`
    inline-flex
    items-center
    justify-center
    rounded-full
    transition-smooth
    focus:outline-none
`;

export const HeaderSendButton = tw.button`
    inline-flex
    items-center
    justify-center
    rounded-full
    transition-smooth
    text-pl-grayscale-white
    bg-pl-primary-green-default
    hover:bg-pl-primary-green-light
    hover:text-pl-primary-green-default
    focus:outline-none
`;

export const ChatMessageWrapper = tw.div`
    flex
    items-end
    justify-end
`;

export const ChatMessageContainer = tw.div`
    flex
    flex-col
    space-y-2
    text-base
    max-w-xs
    order-1
    items-end
`;

export const ChatMessageSpan = tw.span`
    px-4
    py-2
    rounded-[15px]
    inline-block
    bg-pl-primary-green-default
    text-pl-grayscale-white
`;

export const ChatMessageBotWrapper = tw.div`
    flex
    items-end
`;

export const ChatMessageBotContainer = tw.div`
    flex
    flex-col
    space-y-2
    text-base
    w-full
    max-w-[85%]
    order-2
    items-start
`;

export const FullWidthWrap = tw.div`
    w-full
`;

export const LexiReplyContainerValid = tw.div`
    px-4
    py-2
    rounded-[15px]
    inline-block
    bg-pl-grayscale-white
    text-pl-grayscale-black
    border-[1px]
    border-pl-grayscale-lightgray
`;

export const ChatAddToDashWrapper = tw.div`
    flex
    items-end
    justify-end
    my-1
    hidden
`;

export const ChatAddToDashContainer = tw.div`
`;


