import React, {useRef} from "react";
import {SelectSearchOption} from "components/utils/SelectSearch";
import {OrganizationFormData} from "model/organization";
import {Form, Formik, FormikValues} from "formik";
import {WidgetTitle} from "components/utils/WidgetTitle";
import Button from "components/generics/Button";
import {OrganizationValidator} from "validations/OrganizationValidator";
import TextField from "components/generics/TextField";
import TimePicker from "components/utils/TimePicker/TimePicker";
import CustomSelect from "components/utils/CustomSelect/CustomSelect";
import organizationService from "services/organizationService";

import * as S from "./styles";
import {useState} from "@hookstate/core";

type Props = {
  industries: SelectSearchOption[];
  numEmployees: SelectSearchOption[];
  timezones: SelectSearchOption[];
  organization: OrganizationFormData;
};

export function OrganizationDetailsForm({ industries,
                                          numEmployees,
                                          timezones,
                                          organization,
}: Props) {
  const isSaving = useState<boolean>(false);

  const nameRef = useRef<HTMLInputElement>(null);
  const domainRef = useRef<HTMLInputElement>(null);

  function handleSave(values: FormikValues) {
    isSaving.set(true);
    const formData = values as OrganizationFormData;
    organizationService.saveOrganization(formData)
      .then((organization) => isSaving.set(false));
  }

  return (
    <Formik
      initialValues={{ ...organization }}
      validationSchema={OrganizationValidator}
      validateOnMount={true}
      onSubmit={handleSave}
    >
      {({
          values,
          errors,
          isValid,
          setFieldValue,
          touched,
          handleChange,
          handleBlur,
        }) => (
        <Form>
          <S.Wrapper>
            <S.MainContainer>

              <S.SubMainContainer>
                <S.WrapperTitle>
                  <WidgetTitle>Organization Details</WidgetTitle>
                  <S.TitleActions>
                    <Button type="submit"
                            btntype="primary"
                            $shadow={true}
                            disabled={!isValid || isSaving.value}
                    >
                      Save
                    </Button>
                  </S.TitleActions>
                </S.WrapperTitle>

                <S.WrapperContent>
                  <S.FieldWrapper numColumns={4}>
                    <S.LabelWrapper>
                      Organization Name
                    </S.LabelWrapper>
                    <S.ElementWrapper>
                      <TextField
                        ref={nameRef}
                        name="name"
                        value={values.name}
                        clearable
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onClear={() => setFieldValue("name", "")}
                        border={false}
                        placeholder={"Enter an organization name"}
                      />
                      <S.Error>
                        {
                          !!errors.name && touched.name ? errors.name : ""
                        }
                      </S.Error>
                    </S.ElementWrapper>
                  </S.FieldWrapper>

                  <S.FieldWrapper numColumns={2}>
                    <S.LabelWrapper>
                      Domain
                    </S.LabelWrapper>
                    <S.ElementWrapper>
                      <TextField
                        ref={domainRef}
                        name="domain"
                        value={values.domain}
                        clearable
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onClear={() => setFieldValue("domain", "")}
                        border={false}
                        placeholder={"Enter a domain"}
                      />
                      <S.Error>
                        {
                          !!errors.domain && touched.domain ? errors.domain : ""
                        }
                      </S.Error>
                    </S.ElementWrapper>
                  </S.FieldWrapper>

                  <S.FieldWrapper numColumns={1}>
                    <S.LabelWrapper>
                      Business Start Time
                    </S.LabelWrapper>
                    <S.ElementWrapper>
                      <TimePicker
                        value={values.startTime}
                        onChange={(time) => setFieldValue("startTime", time)}
                      />
                    </S.ElementWrapper>
                    <S.Error />
                  </S.FieldWrapper>

                  <S.FieldWrapper numColumns={1}>
                    <S.LabelWrapper>
                      Business End Time
                    </S.LabelWrapper>
                    <S.ElementWrapper>
                      <TimePicker
                        value={values.endTime}
                        onChange={(time) => setFieldValue("endTime", time)}
                      />
                    </S.ElementWrapper>
                    <S.Error />
                  </S.FieldWrapper>

                  <S.FieldWrapper numColumns={2}>
                    <S.LabelWrapper>
                      Num of Employees
                    </S.LabelWrapper>
                    <S.ElementWrapper>
                      <CustomSelect
                        options={numEmployees}
                        value={values.numEmployees}
                        onChange={(opt) => setFieldValue("numEmployees", opt.value)}
                        border={false}
                      />
                      <S.Error/>
                    </S.ElementWrapper>
                  </S.FieldWrapper>

                  <S.FieldWrapper numColumns={2}>
                    <S.LabelWrapper>
                      Category
                    </S.LabelWrapper>
                    <S.ElementWrapper>
                      <CustomSelect
                        options={industries}
                        value={values.industry}
                        onChange={(opt) => setFieldValue("industry", opt.value)}
                        border={false}
                      />
                      <S.Error/>
                    </S.ElementWrapper>
                  </S.FieldWrapper>

                  <S.FieldWrapper numColumns={4}>
                    <S.LabelWrapper>
                      Time Zone
                    </S.LabelWrapper>
                    <S.ElementWrapper>
                      <CustomSelect
                        options={timezones}
                        value={values.timezone}
                        onChange={(opt) => setFieldValue("timezone", opt.value)}
                        border={false}
                      />
                      <S.Error/>
                    </S.ElementWrapper>
                  </S.FieldWrapper>
                </S.WrapperContent>

              </S.SubMainContainer>
            </S.MainContainer>
          </S.Wrapper>
        </Form>
      )}
    </Formik>
  );
}