import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {Feedback, Recommendation, SeverityType} from "model/recommendations";
import BlurredContainer from "components/generics/BlurredContainer";
import Badge from "components/generics/Badge";
import Button from "components/generics/Button";
import Avatar from "components/utils/Avatar";
import {addErrorToastNotification, addToastNotification,} from "components/utils/Toast/ToastNotificationContainer";
import {getSeverityLabel} from "utils/text";
import {DetailedDateProps, formatDateToDDMon, getDetailedDate,} from "utils/dateFormatter";
import {LoadingWidget} from "components/utils/LoadingWidget";
import {Employee} from "model/employee";
import employeeService from "services/employeeService";
import recommendationsService from "services/recommendations";

import {ReactComponent as DotsIcon} from "icons/dots.svg";
import {ReactComponent as ChevronIcon} from "icons/chevron.svg";
import {ReactComponent as ThumbUpIcon} from "icons/thumb-up.svg";
import {ReactComponent as ThumbDownIcon} from "icons/thumb-down.svg";
import {ReactComponent as CheckIcon} from "icons/check.svg";
import {ReactComponent as CloseIcon} from "icons/close.svg";

import * as S from "./styles";
import {useState} from "@hookstate/core";

export type RecommendationItemProps = {
  recommendation: Recommendation;
  maxLength?: number;
  keepCollapsed?: boolean;
  startCollapsed?: boolean;
  onChangeFeedback?: () => any;
  isShuffledComponent?: boolean;
  showFeedbackWrapper?: boolean;
  showAvatarImage?: boolean;
};

const RecommendationItem = ({
  recommendation,
  maxLength = 230,
  keepCollapsed = false,
  startCollapsed = false,
  onChangeFeedback,
  isShuffledComponent = false,
  showFeedbackWrapper = true,
  showAvatarImage = true,
}: RecommendationItemProps) => {
  // AVATAR LOGIC
  const employeeInfo =
    useState<Employee>(() => employeeService.getEmployeeInfo(recommendation.employeeId, {
      teamInfo: false,
      location: false,
      skills: false,
    }));

  useEffect(() => {

    if (currentFeedback.value !== Feedback.NO_FEEDBACK) {
      isFeedbackDisabled.set(true);
    }
  }, [recommendation]);

  // TIMESTAMP LOGIC
  const getFormattedDate = () => {
    let formattedDate;

    const { day, month, year }: DetailedDateProps = getDetailedDate(
      recommendation.lastVerified
    );
    const currentDay = new Date().getDate();
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    if (currentDay !== +day && currentYear !== +year) {
      formattedDate = `${formatDateToDDMon(
          recommendation.lastVerified
      )} ${year}`;
    } else {
      formattedDate = formatDateToDDMon(recommendation.lastVerified);
    }

    return formattedDate;
  };

  // ACCORDION LOGIC
  const isChevronRotate = useState(false);
  const isCollapsed = useState(
    keepCollapsed || startCollapsed
  );
  const maxTextLength = useState(maxLength);

  const handleCollapseContent = () => {
    if (!keepCollapsed) {
      isCollapsed.set(!isCollapsed.value);
      isChevronRotate.set(!isChevronRotate.value);
    }
  };

  const handleSeeAllText = () => {
    maxTextLength.set(prev => prev > 0 ? 0 : prev);
  };

  useEffect(() => {
    if (keepCollapsed) {
      isCollapsed.set(true);
      isChevronRotate.set(true);
    } else {
      isCollapsed.set(false);
      isChevronRotate.set(false);
    }
  }, [keepCollapsed]);

  // FEEDBACK LOGIC
  const isFeedbackDisabled = useState<boolean>(false);
  const currentFeedback = useState<Feedback>(
    recommendation.feedback
  );

  useEffect(() => {
    currentFeedback.set(recommendation.feedback);
    isFeedbackDisabled.set(false);
  }, [recommendation.id]);

  const updateFeedBack = (newFeedback: Feedback) => {
    recommendationsService
      .updateRecommendationFeedback(recommendation.id, newFeedback)
      .then((result) => {
        addToastNotification(result);
      })
      .catch((reason) => addErrorToastNotification(reason as string));

    currentFeedback.set(newFeedback);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (currentFeedback.value !== Feedback.NO_FEEDBACK) {
      timer = setTimeout(() => {
        isFeedbackDisabled.set(true);
        if (onChangeFeedback) {
          onChangeFeedback();
        }
      }, 30000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [currentFeedback]);

  return (
    <S.Wrapper>
      <BlurredContainer blur={!recommendation}>
        <S.WidgetWrapper>
          <S.WidgetHeader>
            {
              isShuffledComponent &&
              <S.Title onClick={handleCollapseContent} className={"text-lg"}>
                {recommendation.title}
              </S.Title>
            }
            <S.WidgetRightHeader isShuffledComponent={!!isShuffledComponent}>
              <S.BadgeWrapper>
                <>
                  <Badge
                    label={getSeverityLabel(recommendation.severity)}
                    size="large"
                    type={SeverityType[recommendation.severity.toUpperCase()]}
                    rounded
                  />

                  <Badge label={getFormattedDate()} size="large" rounded />
                </>

                {!isCollapsed && !isShuffledComponent && (
                  <S.AvatarWrapper>
                    <LoadingWidget<Employee>
                      value={employeeInfo}
                      builder={(employeeData) => (
                        <Avatar
                          size={1}
                          name={employeeData.name}
                          title={employeeData.name}
                          linkTo={`/employees/${employeeData.id}`}
                          avatarColor={true}
                          showAvatarImage={showAvatarImage}
                        />
                      )}
                      size={"small"}
                    />
                  </S.AvatarWrapper>
                )}
              </S.BadgeWrapper>

              <S.ActionWrapper>
                <S.ActionArea onClick={handleCollapseContent} />
                <Button minimal className="hidden">
                  <DotsIcon />
                </Button>

                {!keepCollapsed && !isShuffledComponent && (
                  <Button
                    minimal
                    $shadow={false}
                    className={`${isChevronRotate && "scale-[-1]"}`}
                    onClick={handleCollapseContent}
                  >
                    <ChevronIcon />
                  </Button>
                )}
              </S.ActionWrapper>
            </S.WidgetRightHeader>
          </S.WidgetHeader>

          <S.WidgetContent>
            {
              !isShuffledComponent &&
              <S.Title onClick={handleCollapseContent}>
                {recommendation.title}
              </S.Title>
            }
            <S.Description
              collapsed={isCollapsed ? 1 : 0}
              onClick={() =>
                isCollapsed && recommendation.summaryText.length > maxLength
                  ? handleSeeAllText()
                  : handleCollapseContent()
              }
            >
              <div className={`${!isCollapsed ? "h-[50px]" : ""}`}
                   dangerouslySetInnerHTML={{ __html: (isShuffledComponent && !showFeedbackWrapper ?
                       recommendation.summaryText.slice(0, 50) :
                       recommendation.summaryText) }}>
              </div>
            </S.Description>
          </S.WidgetContent>

          <S.WidgetFooter>
            {isCollapsed && (
              <S.AvatarWrapper>
                <LoadingWidget<Employee>
                  value={employeeInfo}
                  builder={(employeeData) => (
                    <>
                      <Avatar
                        size={1}
                        name={employeeData.name}
                        linkTo={`/employees/${employeeData.id}`}
                        avatarColor={true}
                        showAvatarImage={showAvatarImage}
                      />

                      <Link to={`/employees/${employeeData.id}`}>
                        {employeeData.name}
                      </Link>
                    </>
                  )}
                  size={"small"}
                />
              </S.AvatarWrapper>
            )}

            <S.FeedbackWrapper showFeedbackWrapper={showFeedbackWrapper}>
              <S.FeedbackQuestion>Is this helpful?</S.FeedbackQuestion>
              <S.FeedbackActions>
                {currentFeedback.value === Feedback.HELPFUL && (
                  <S.FeedbackButton>
                    <Button
                      className={`${S.feedbackBtnStyles} ${S.positiveFeedbackBtnStyles}`}
                      btntype="primary"
                      minimal
                      $shadow={true}
                      onClick={() => {
                        !isFeedbackDisabled.value &&
                          updateFeedBack(Feedback.NO_FEEDBACK);
                      }}
                    >
                      <CheckIcon width="18" height="20" color="#fff" />
                    </Button>
                  </S.FeedbackButton>
                )}

                {currentFeedback.value !== Feedback.HELPFUL && !isFeedbackDisabled.value && (
                  <S.FeedbackButton>
                    <Button
                      className={`
                      ${S.feedbackBtnStyles}
                      ${S.btnSuccessStyles}
                      group
                    `}
                      btntype="primary"
                      minimal
                      $shadow={true}
                      onClick={() => {
                        updateFeedBack(Feedback.HELPFUL);
                      }}
                    >
                      <ThumbUpIcon />
                    </Button>
                  </S.FeedbackButton>
                )}

                {currentFeedback.value === Feedback.UNHELPFUL && (
                  <S.FeedbackButton>
                    <Button
                      className={`${S.feedbackBtnStyles} ${S.negativeFeedbackBtnStyles}`}
                      btntype="error"
                      minimal
                      $shadow={true}
                      onClick={() => {
                        !isFeedbackDisabled.value &&
                          updateFeedBack(Feedback.NO_FEEDBACK);
                      }}
                    >
                      <CloseIcon width="28" height="30" color="#fff" />
                    </Button>
                  </S.FeedbackButton>
                )}

                {currentFeedback.value !== Feedback.UNHELPFUL && !isFeedbackDisabled.value && (
                  <S.FeedbackButton>
                    <Button
                      className={`${S.feedbackBtnStyles} ${S.btnErrorStyles} group`}
                      btntype="error"
                      minimal
                      $shadow={true}
                      onClick={() => {
                        updateFeedBack(Feedback.UNHELPFUL);
                      }}
                    >
                      <ThumbDownIcon />
                    </Button>
                  </S.FeedbackButton>
                )}
              </S.FeedbackActions>
            </S.FeedbackWrapper>
          </S.WidgetFooter>
        </S.WidgetWrapper>
      </BlurredContainer>
    </S.Wrapper>
  );
};

export default RecommendationItem;
