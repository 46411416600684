import {Employee, AddToTeam} from "model/employee";
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useHookstateEffect, useState} from "@hookstate/core";
import DataTableFilters, {
  Filters,
} from "components/utils/Datatable/DataTableFilters";
import {DatatableColumn, EmployeesTableData} from "model/datatable";
import {contains} from "utils/text";
import auth from "services/auth";
import {
  getAvatar,
  getSparklineWidget,
  styleCell, updateSelectionIfChanged,
} from "components/utils/Datatable/DataTableHelper";
import WaitForOnScreen from "components/utils/WaitForOnScreen";
import {LoadingWidget} from "components/utils/LoadingWidget";
import stayFactorService from "services/stayfactor";
import {useBulkSelectEmployees} from "hooks/useBulkSelect";
import URLSortedDataTable from "components/utils/Datatable/URLSortedDataTable";
import authService from "services/auth";
import employeeService from "services/employeeService";
import TableItemActiveIcon from "components/utils/Datatable/TableItemActiveIcon";
import Button from "components/generics/Button";
import {openStatusConfirmationModal} from "components/utils/Modal/StatusConfirmationModal";
import EmployeeModal, {
  openAddEmployeeModal,
  openEditEmployeeModal,
} from "../employeeForm/manageEmployee/ManageEmployee";
import organizationService from "services/organizationService";
import teamService from "services/teamService";
import integrationService from "services/integrationService";
import {getEmployeeTenure} from "utils/math";
import privacyLevelService from "services/privacyLevelService";
import {openConfirmationModal} from "components/utils/Modal/ConfirmationModal";
import {PrivacyLevel} from "model/organization";
import {PeriodSelectorState} from "components/utils/PeriodSelector";

import * as S from "./styles";
import * as _ from "lodash";

import {ReactComponent as TeamIcon} from "icons/team.svg";
import {ReactComponent as EditIcon} from "icons/edit.svg";

type Props = {
  empList: Employee[];
  title: string;
  addToTeam?: AddToTeam;
};


const StayFactorSparklineWidget = ({employee}: { employee: Employee }) => {
  const stayFactorValue =
    useState(() => stayFactorService.getStayFactorTimelineForEmployee(employee.id, PeriodSelectorState.selectedPeriod.value));


  return (
    <WaitForOnScreen
      builder={() => (
        <LoadingWidget<number[]>
          value={stayFactorValue}
          builder={(result) => getSparklineWidget(result, true, true)}
          size="small"
        />
      )}
    />
  );
};

const EmployeesList = ({empList, title, addToTeam}: Props) => {
  const navigate = useNavigate();

  const privacyLevel = authService.privacyLevel.value as PrivacyLevel;

  const employeesState = useState<Employee[]>(
    empList
  );
  const selectedItems = useState<Employee[]>([]);
  const hasClearSelection = useState(false);

  const searchState = useState({
    info: {
      status: "true",
      searchWord: "",
    } as Filters,
  });

  const location = useLocation();

  const fromCreateEmployeeToTop = React.useCallback(
    (empList: Employee[]) => {
      const topEmployeeId = location.state as number;
      if (topEmployeeId && topEmployeeId > 0) {
        const filtered = empList.filter((emp) => emp.id !== topEmployeeId);
        const employee = empList.filter((emp) => emp.id === topEmployeeId);
        return employee.concat(filtered);
      }
      return empList;
    },
    [location.state]
  );

  const columns: DatatableColumn<EmployeesTableData>[] = [
    {
      id: "name",
      accessor: "name",
      Header: "Name",
      customRenderer: "nameRender",
    },
    {
      id: "email",
      accessor: "email",
      Header: "Email",
      customRenderer: "emailRender",
    },
    {
      id: "active",
      disableSortBy: searchState.info.status.value !== "all",
      accessor: "active",
      Header: "Active",
      customRenderer: "activeRender",
      sortDescFirst: true,
    },
    {
      id: "tenure",
      accessor: "tenure",
      Header: "Tenure",
      customRenderer: "tenureRender",
      sortDescFirst: true,
    },
    {
      id: "stayFactor",
      disableSortBy: true,
      accessor: "stayFactor",
      Header: "StayFactor™",
    },
    {
      accessor: "actions",
      Header: "Actions",
    },
  ];

  // apply selected filters to the employees list
  function filterEmployeeData(): EmployeesTableData[] {
    return empList
      .filter((emp) => {
        if (searchState.info.searchWord.get().length > 0) {
          return contains(emp.name, searchState.info.searchWord.get());
        }
        return true;
      })
      .filter((emp) => {
        if (searchState.info.status.get() === "true") {
          return emp.active;
        } else if (searchState.info.status.get() === "false") {
          return !emp.active;
        }
        return true;
      })
      .map(employee => {
        return {
          id: employee.id,
          name: employee.name.toLowerCase().trim(),
          nameRender: getAvatar(
            employee.name,
            `/employees/${employee.id}`,
            employee.role,
            true,
            employee.avatar,
            employee.active,
            true,
            true
          ),
          email: employee.email.toLowerCase().trim(),
          emailRender: styleCell(
            employee.active,
            employee.email.toLowerCase().trim()
          ),
          active: employee.active ? 1 : 0,
          activeRender: getActiveComponent(employee),
          tenure: employee.joinDate ? getEmployeeTenure(employee.joinDate) : "",
          tenureRender: getTenureRender(employee),
          stayFactor: <StayFactorSparklineWidget employee={employee}/>,
          actions: getActions(employee),
        } as EmployeesTableData;
      })
  }

  function getActions(employee: Employee) {
    return (
      <>
        <div className={"flex items-center gap-2"}>
          {auth.currentUserIsAdmin() && (
            <Button
              btntype={"neutral"}
              minimal
              $shadow={false}
              onClick={() =>
                privacyLevelService.hasAccess(privacyLevel) ?
                  Promise.all([
                    employeeService.getEmployeeInfo(employee.id, {
                      teamInfo: true,
                      location: true,
                      skills: true,
                      integrations: true,
                    }),
                    employeeService.getManagers(),
                    organizationService.getLocations(),
                    teamService.getActiveTeams(),
                    integrationService.getActiveIntegrations(),
                  ]).then(([emp, managers, locations, teams, integrations]) =>
                    openEditEmployeeModal(
                      emp,
                      managers,
                      locations,
                      teams,
                      integrations
                    )
                  )
                  :
                  openConfirmationModal(
                    "Please select a privacy level in your organization settings that allows you to see this information.",
                    "Lower Privacy Level",
                    () => navigate("/settings/organization"),
                    "",
                    "Change Privacy Level"
                  )
              }
            >
              <EditIcon/>
            </Button>
          )}
        </div>
      </>
    );
  }

  function getActiveComponent(employee: Employee) {
    return (
      <S.ActiveComponentWrapper>
        <Button
          className={`hover:border-solid
          hover:rounded-full
          hover:border-4
          ${
            employee.active
              ? `hover:border-pl-primary-green-light`
              : `hover:border-pl-primary-red-light`
          }`}
          minimal
          $shadow={false}
          onClick={() =>
            openStatusConfirmationModal(
              employee.active
                ? `Are you sure you want to deactivate ${employee.name}'s acount?`
                : `Are you sure you want to activate ${employee.name}'s acount?`,
              employee.active ? "Deactivate Employee" : "Activate Employee",
              employee.name,
              "employee",
              () => {
                employeeService
                  .updateEmployeeStatus(employee.id, !employee.active)
                  .then(() => {
                    employeesState
                      .find((e) => e.id.value === employee.id)
                      ?.active.set(!employee.active);
                  });
              },
              "Close",
              employee.active ? "Deactivate" : "Activate"
            )
          }
        >
          <TableItemActiveIcon active={employee.active}/>
        </Button>
      </S.ActiveComponentWrapper>
    );
  }

  const getTenureRender = (employee: Employee) => {
    return <>
      <Button
        btntype={"neutral"}
        minimal
        $shadow={false}
        className={"cursor-text"}
        onClick={() => {
        }}
        title={(employee.active && employee.joinDate) || (!employee.active && employee.endDate) ?
          employee.active
            ?
            'Join date: ' + employee.joinDate
            :
            'Deactivated on ' + employee.endDate
          :
          ""
        }
      >
        {
          employee.joinDate ? getEmployeeTenure(employee.joinDate) : ""
        }
      </Button>
    </>
  }

  const {
    clearSelection,
    activateSelectedEmployees,
    deactivateSelectedEmployees,
    deleteSelectedEmployees,
  } = useBulkSelectEmployees();

  const getBulkActions = () => {
    const selectedIds = selectedItems.value.map((e) => e.id);
    return [
      {
        label: "Activate",
        action: () =>
          activateSelectedEmployees(selectedIds, employeesState, () =>
            hasClearSelection.set(!hasClearSelection.value)
          ),
      },
      {
        label: "Deactivate",
        action: () =>
          deactivateSelectedEmployees(selectedIds, employeesState, () =>
            hasClearSelection.set(!hasClearSelection.value)
          ),
      },
      {
        label: "Delete",
        action: () =>
          deleteSelectedEmployees(selectedIds, employeesState, () =>
            hasClearSelection.set(!hasClearSelection.value)
          ),
      },
    ];
  };

  return (
    <>
      <EmployeeModal/>
      <DataTableFilters
        title={title}
        type={"employee"}
        titleIcon={<TeamIcon className={"text-pl-grayscale-gray"}/>}
        titleSize={"large"}
        addLabel={auth.currentUserIsAdmin() ? "New Employee" : undefined}
        state={searchState.info}
        selectedItems={selectedItems.value}
        bulkActions={getBulkActions()}
        onAdd={() =>
          privacyLevelService.hasAccess(privacyLevel) ?
            Promise.all([
              employeeService.getManagers(),
              organizationService.getLocations(),
              teamService.getActiveTeams(),
              integrationService.getActiveIntegrations(),
            ]).then(([managers, locations, teams, integrations]) =>
              openAddEmployeeModal(
                managers,
                locations,
                teams,
                integrations,
                addToTeam
              )
            )
            :
            openConfirmationModal(
              "Please select a privacy level in your organization settings that allows you to see this information.",
              "Lower Privacy Level",
              () => navigate("/settings/organization"),
              "",
              "Change Privacy Level"
            )
        }
        resetPagination={() => clearSelection()}
      />
      <URLSortedDataTable
        columns={columns}
        data={filterEmployeeData()}
        pageLength={10}
        // pageChangeCallback={() => clearSelection()}
        hiddenColumns={authService.currentUserIsAdmin() ? [] : ["actions"]}
        onRowSelection={(value) => {
          updateSelectionIfChanged(value, selectedItems);
        }}
        hasClearSelection={hasClearSelection.value}
      />
    </>
  );
};

export default React.memo(EmployeesList);
