import {Location} from "../model/employee";

export enum PrivacyLevel {
  ALL_DETAILS_VISIBLE = 'ALL_DETAILS_VISIBLE',
  PERSONAL_DETAILS_MASKED_EVERYONE = 'PERSONAL_DETAILS_MASKED_EVERYONE',
  PERSONAL_DETAILS_MASKED_MANAGERS = 'PERSONAL_DETAILS_MASKED_MANAGERS',
  TEAM_LEVEL_ONLY = 'TEAM_LEVEL_ONLY',
  DEPARTMENT_LEVEL_ONLY = 'DEPARTMENT_LEVEL_ONLY'
}

export type OrganizationPrivacyLevel = {
  privacy: PrivacyLevel
}

export const PRIVACY_LABEL_VALUE_PAIR = [
  {
    value: PrivacyLevel.ALL_DETAILS_VISIBLE,
    label: "All Details Visible"
  },
  {
    value: PrivacyLevel.PERSONAL_DETAILS_MASKED_EVERYONE,
    label: "Personal Details Masked (from everyone, including admins)"
  },
  {
    value: PrivacyLevel.PERSONAL_DETAILS_MASKED_MANAGERS,
    label: "Personal Details Masked (from managers and department heads)"
  },
];

export type OrganizationFormData = {
    id: number,
    name: string,
    domain: string,
    industry: string,
    numEmployees: string,
    startTime: string,
    endTime: string,
    timezone: string
}

export type Organization = {
    id: number,
    name: string,
    domain: string,
    category: string,
    employeeSize: string,
    startTime: string,
    endTime: string,
    timeZone: string,
    locations: Location[],
    privacyLevel: PrivacyLevel
}

export type Logo = {
  domain: string,
  url: string
}

export type AddToLocation = {
  locationId: number;
};