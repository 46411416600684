import React from "react";
import { Link } from 'react-router-dom';

import { TableReport } from "model/lexi";
import DataTable from "components/utils/Datatable/DataTable";

const ReportLink = ({ href, label }: { href: string; label: string }) => (
  <Link to={href} target="_blank" rel="noopener noreferrer" >
    {label}
  </Link>
);

const rendererForType = (type: string) => {
  switch (type) {
    case "employee":
      return ({ cell: { value } }: any) => {
        if (value) {
          return (
            <ReportLink href={`/employees/${value.id}`} label={value.name} />
          );
        } else {
          return <span></span>;
        }
      };
    case "team":
      return ({ cell: { value } }: any) => {
        if (value) {
          return <ReportLink href={`/team/${value.id}`} label={value.name} />;
        } else {
          return <span>No Team</span>;
        }
      };
    case "object":
      return ({cell: {value}} : any) => value?.name || "No Name"
    default:
      return undefined;
  }
};

const TableReportRender = ({
  report,
  pageSize = 10,
}: {
  report: TableReport;
  pageSize?: number;
}) => {
  const columns = report.columns.map((col) => {
    const definition: any = {
      Header: col.label,
      accessor: col.alias,
    };

    const cellRenderer = rendererForType(col.type);

    if (cellRenderer) {
      definition.Cell = cellRenderer;
    }

    return definition;
  });

  const data = report.data
    .filter((row) => {
      //if no employee column just return true
      return "employee" in row ? row.employee != null : true;
    })
    .map((datum) => ({ ...datum }));

  return <DataTable columns={columns} data={data} pageLength={pageSize} />;
};

export default TableReportRender;
