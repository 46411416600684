import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";

export const BreadcrumbBack = tw.div`
  flex
  items-center
  cursor-pointer
  border-r-2
  text-sm
  font-semibold
  text-pl-grayscale-blueblack
`;

export const BreadcrumbItem = tw(Link)`
  flex
  items-center
  leading-5
  text-sm
  font-semibold
  text-pl-grayscale-gray
`;
