import tw from "tailwind-styled-components";

export const Wrapper = tw.section`
  w-full
  fixed
  z-10
  bg-pl-grayscale-white
  shadow-lg
`;

export const LogoAndNavMenuWrapper = tw.section`
  flex
  items-center
`;

export const LogoWrapper = tw.div`
  flex
  items-center
`;

export const PeoplelogicLogo = tw.div`
  pr-4
  border-pl-grayscale-midgray
  flex-shrink-0

  sm:border-r-[1px]
`;

export const NavMenuWrapper = tw.div`
  max-w-[550px]
  ml-4
`;

export const LexyAndButtonsWrapper = tw.section`
  hidden

  md:block
`;

export const LexyAndButtonsContent = tw.div`
  ml-4
  flex
  items-center

  md:ml-6
`;

export const GreetingsWrapper = tw.div`
  flex
  items-right
`;

export const LexySearchWrapper = tw.div`
  h-5/6
`;

export const DesktopNavbarWrapper = tw.section`
  max-w-7xl
  mx-auto
  h-16
  px-4
  flex
  items-center
  justify-between

  sm:px-6
  lg:px-8
`;

export const ProfileAndSettingsWrapper = tw.div`
  flex
  items-center
`;
