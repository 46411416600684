import React, {useEffect} from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {DotsVerticalIcon} from "@heroicons/react/solid";
import {ResizableBox, ResizeCallbackData} from "react-resizable";
import {Dashboard, DashboardQuery} from "model/dashboards";
import {State, useState} from "@hookstate/core";
import BotReply from "components/ui/Lexichat/BotReply";
import dashboardsService from "services/dashboards";
import {RefreshIcon} from "@heroicons/react/outline";
import {openConfirmationModal} from "components/utils/Modal/ConfirmationModal";
import {openDashboardQueryModal} from "./manageDashboard/DashboardQueryModal";
import {LoadingWidget} from "components/utils/LoadingWidget";
import chat from "services/chat";
import {LexiReply} from "model/lexi";
import {useWindowSize} from "utils/windowSize";

import {ReactComponent as EditIcon} from "icons/edit.svg";
import {ReactComponent as TrashIcon} from "icons/trash.svg";
import * as querystring from "querystring";
import {da} from "date-fns/locale";

type InsightsDashboardItemProps = {
    id: string;
    invisible: boolean;
    dashboardQuery: State<DashboardQuery>;
    isAdminOrOwner: boolean;
    updateQuery?: (
        dashboardQuery: State<DashboardQuery>
    ) => Promise<boolean> | undefined;
    deleteQuery?: (
        dashboardQuery: DashboardQuery
    ) => Promise<boolean> | undefined;
    onResize?: (dashboardQuery: DashboardQuery) => void;
    //initialChatResult: ReplyObj | undefined;
    dashboard: State<Dashboard>;
    onDelete: () => void;
    onEdit: () => void;
    canUpdateDashboard: boolean;
};

export function InsightsDashboardItem({
                                          id,
                                          invisible,
                                          dashboardQuery,
                                          updateQuery,
                                          deleteQuery,
                                          isAdminOrOwner,
                                          onResize,
                                          dashboard,
                                          onDelete,
                                          onEdit,
                                          canUpdateDashboard,
                                      }: InsightsDashboardItemProps) {
    const {attributes, listeners, setNodeRef, transform, transition} =
        useSortable({id: id});

    const dashboardQueryState = dashboardQuery;

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const openQueryMenu = useState(false);

    const {width} = useWindowSize();
    const currentScreenWidth = React.useMemo(
        () => (width ? width : 1200),
        [width]
    );

    const boxResizeWidth = useState<number | undefined>(undefined);
    const initialBoxHeight = 400;
    const boxResizeHeight = useState<number>(initialBoxHeight);
    const minBoxWidth = useState<number | undefined>(undefined);

    const toggleRefresh = useState(false);

    const updateSize = (columnSpan: number, height: number) => {
        dashboardQueryState.merge({
            displayWidth: columnSpan,
            displayHeight: height
        });
        if (canUpdateDashboard) {
            dashboardsService.saveDashboardQuery(dashboardQueryState.value);
        }
    };

    const setBoxSize = (columnSpan: number, height: number) => {
        let rowWidth = document.getElementById(
            "dashboard-queries-container"
        )?.clientWidth;
        if (rowWidth !== undefined) {
            if (currentScreenWidth >= 1200) {
                // Extra large devices (large desktops, 1200px and up)
                boxResizeWidth.set((rowWidth / 3 - 1) * columnSpan);
                minBoxWidth.set(rowWidth / 3 - 1);
            } else if (currentScreenWidth >= 992) {
                // Large devices (desktops, 992px and up)
                if (columnSpan === 2 || columnSpan === 3) {
                    boxResizeWidth.set((rowWidth / 2 - 1) * 2);
                } else {
                    boxResizeWidth.set(rowWidth / 2 - 1);
                }
                minBoxWidth.set(rowWidth / 2 - 1);
            } else {
                // Medium devices (tablets, 991px and below)
                boxResizeWidth.set(rowWidth);
                minBoxWidth.set(rowWidth);
            }
            boxResizeHeight.set(height);
        }
    };

    useEffect(() => {
        setBoxSize(
            dashboardQueryState.displayWidth.value,
            dashboardQueryState.displayHeight.value
        );
    }, [
        currentScreenWidth,
        dashboardQueryState.displayWidth,
        dashboardQueryState.displayHeight,
    ]);

    const handleResizeStop = (
        event: React.SyntheticEvent,
        {size}: ResizeCallbackData
    ) => {
        let columnsOccupied = 1;
        if (minBoxWidth) {
            if (size.width > minBoxWidth.value! && size.width < minBoxWidth.value! * 2) {
                columnsOccupied = 2;
            } else if (size.width > minBoxWidth.value! * 2) {
                columnsOccupied = 3;
            }
        }
        setBoxSize(columnsOccupied, size.height);
        updateSize(columnsOccupied, size.height);
    };

    const handleResize = (
        event: React.SyntheticEvent,
        {size}: ResizeCallbackData
    ) => {
        boxResizeWidth.set(size.width);
        boxResizeHeight.set(size.height);
    };

    function handleDeleteQuery() {
        openQueryMenu.set(!openQueryMenu.value);
        openConfirmationModal(
            "Are you sure you want to delete this query?",
            "Delete Query",
            () => onDelete()
        );
    }

    function handleEditQuery() {
        openQueryMenu.set(!openQueryMenu.value);
        openDashboardQueryModal(dashboard, dashboardQueryState.value, "Edit Query", () =>
            onEdit()
        );
    }

    function handleRefreshQuery() {
        toggleRefresh.set(!toggleRefresh.value);
        openQueryMenu.set(!openQueryMenu.value);
    }

    const widgetMenu = () => {
        const iconClassName = "w-5 h-5 mr-4";
        const menuItemClassName =
            "flex text-pl-grayscale-black block w-full text-left pl-4 pr-6 py-4 text-sm cursor-pointer hover:bg-pl-primary-green-light";
        return (
            <>
                <div
                    id="dropdown"
                    className={`${
                        openQueryMenu.value ? "" : "hidden"
                    } absolute right-0 rounded-2xl shadow-lg bg-pl-grayscale-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10`}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                >
                    <div className="divide-y" role="none">
                        <button
                            type="submit"
                            className={`${menuItemClassName} hover:rounded-t-2xl`}
                            onClick={() => handleRefreshQuery()}
                            role="menuitem"
                            id="refresh"
                        >
                            <RefreshIcon className={iconClassName} aria-hidden="true"/>
                            Refresh
                        </button>
                        <button
                            type="submit"
                            className={menuItemClassName}
                            onClick={() => handleEditQuery()}
                            role="menuitem"
                            id="edit"
                        >
                            <EditIcon className={iconClassName} aria-hidden="true"/>
                            Edit
                        </button>
                        <button
                            type="submit"
                            className={`${menuItemClassName} hover:rounded-b-2xl`}
                            onClick={() => handleDeleteQuery()}
                            role="menuitem"
                            id="delete"
                        >
                            <TrashIcon className={iconClassName} aria-hidden="true"/>
                            Delete
                        </button>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            {boxResizeWidth && minBoxWidth && (
                <ResizableBox
                    className={"p-2"}
                    height={boxResizeHeight.value}
                    width={boxResizeWidth.value || dashboardQuery.displayWidth.value}
                    minConstraints={[minBoxWidth.value || dashboardQuery.displayWidth.value, initialBoxHeight]}
                    onResizeStop={handleResizeStop}
                    onResize={handleResize}
                    handle={
                        <span
                            className={"react-resizable-handle react-resizable-handle-se p-2"}
                        ></span>
                    }
                >
                    <div
                        ref={setNodeRef}
                        style={style}
                        className={`h-full flex flex-col bg-pl-grayscale-white shadow-sm rounded-2xl border border-pl-grayscale-lightgray ${
                            invisible ? "invisible" : ""
                        }`}
                    >
                        <div className="min-h-[50px] flex items-center justify-between rounded-t-md truncate border-b">
                            <div
                                className="flex-1 px-4 py-2 text-sm truncate "
                                {...attributes}
                                {...listeners}
                            >
                                <div className="text-pl-grayscale-black font-medium hover:text-pl-grayscale-gray">
                                    {dashboardQueryState.name.value}
                                </div>
                            </div>
                            {
                                canUpdateDashboard &&
                                <div className="flex-shrink-0 pr-2">
                                    <button
                                        type="button"
                                        id="menu-button"
                                        aria-expanded="true"
                                        aria-haspopup="true"
                                        data-dropdown-toggle="dropdown"
                                        onClick={() => openQueryMenu.set(!openQueryMenu.value)}
                                        className="w-8 h-8 bg-pl-grayscale-white inline-flex items-center justify-center text-pl-grayscale-midgray rounded-full bg-transparent hover:text-pl-grayscale-gray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pl-primary-green-default"
                                    >
                                        <span className="sr-only">Open options</span>
                                        <DotsVerticalIcon className="w-5 h-5" aria-hidden="true"/>
                                    </button>
                                    {widgetMenu()}
                                </div>
                            }
                        </div>
                        <div className="text-pl-grayscale-gray p-5 border-0 flex justify-center overflow-auto">
                            <RenderQuery
                                queryId={dashboardQueryState.id.value!}
                                queryString={dashboardQuery.query.value}
                            />
                        </div>
                    </div>
                </ResizableBox>
            )}
        </>
    );
}

const RenderQuery = ({queryId, queryString}: { queryId: number, queryString: string }) => {
    const lexiReply = useState<LexiReply>(() => dashboardsService.execute(queryId).then(response => ({reply: response})));

    return (
        <>
            <LoadingWidget<LexiReply>
                value={lexiReply}
                builder={(reply) => <>{reply && <BotReply lexiReply={reply} withChartTitle={false}/>}</>}
            />
        </>
    );
};
