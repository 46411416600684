import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useState } from "@hookstate/core";

type Props = {
  value?: string;
  name?: string;
  onChange?: (time: string) => any;
};

export default function TimePicker({
  value,
  name = "timePicker",
  onChange = () => {},
}: Props) {
  const hour = useState("12");
  const minute = useState("0");
  const period = useState("AM");

  const updateValueState = () => {
    const time = `${hour.value}:${minute.value.padStart(2, "0")} ${
      period.value
    }`;
    onChange(time);
  };

  const renderHoursOptions = () => {
    return Array.from(Array(12).keys()).map((i, index) => (
      <option value={index + 1} key={index}>{`${index + 1}`}</option>
    ));
  };

  const renderMinutesOptions = () => {
    return Array.from(Array(4).keys()).map((i, index) => (
      <option value={index * 15} key={index}>
        {`${index * 15}`.padStart(2, "0")}
      </option>
    ));
  };

  return (
    <>
      <Menu as="div" className="relative">
        <Menu.Button className="w-full flex items-center text-sm text-pl-grayscale-gray h-[40px]">
          <input
            type="text"
            name={name}
            id={name}
            readOnly={true}
            value={value}
            className={`h-[40px] pl-input text-sm text-pl-grayscale-black placeholder-pl-grayscale-gray rounded-[10px] bg-pl-variant-beige border-none`}
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute left-0 mt-2 rounded-md shadow-lg py-1 bg-pl-grayscale-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
            <div className={"flex items-center"}>
              <select
                value={hour.get()}
                onChange={({ target: { value } }) => {
                  hour.set(value);
                  updateValueState();
                }}
                className={"border-0 bg-none text-right p-1 text-sm"}
              >
                {renderHoursOptions()}
              </select>
              <span className={"font-bold text-sm"}>:</span>
              <select
                value={minute.get()}
                onChange={({ target: { value } }) => {
                  minute.set(value);
                  updateValueState();
                }}
                className={"pl-time-picker-text"}
              >
                {renderMinutesOptions()}
              </select>
              <select
                value={period.get()}
                onChange={({ target: { value } }) => {
                  period.set(value);
                  updateValueState();
                }}
                className={"pl-time-picker-text"}
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
