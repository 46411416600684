import React from "react";
import { Rulebook } from "model/recommendations";
import recommendationsService from "services/recommendations";
import { capitalize } from "utils/text";
import { useState, State } from "@hookstate/core";
import ToggleButton from "components/generics/ToggleButton";

import * as S from "./styles";

export default function RecommendationsRulebooks({
  state,
}: {
  state: State<Rulebook>;
}) {
  const ruleBookState = useState(state);

  return (
    <form action="#" method="POST">
      <S.Wrapper>
        <S.RulebookHeader>
            <S.RulebookTitle>
              You can enable or disable recommendations for{" "}
              <S.HighlightedTab>
                {capitalize(ruleBookState.name.value)}
              </S.HighlightedTab>{" "}
              by toggling each item below.
            </S.RulebookTitle>
        </S.RulebookHeader>
        <S.RulebooksContainer>
          <ToggleButton
            id={ruleBookState.name.value}
            key={ruleBookState.name.value}
            checked={!ruleBookState.disabled.value}
            text={"All"}
            onChange={() => {
              recommendationsService
                .updateRuleOrRulebookStatus(
                  ruleBookState.name.value,
                  "rulebook",
                  ruleBookState.disabled.value
                )
                .then(() =>
                  ruleBookState.disabled.set((prevState) => !prevState)
                );
            }}
          />
          <S.RulebookGridContainer>
            {ruleBookState.rules.map((rule) => (
              <S.RulebookItem>
                <ToggleButton
                  id={rule.recommendationTypeId.value}
                  key={rule.recommendationTypeId.value}
                  checked={!rule.disabled.value}
                  text={rule.ruleName.value}
                  onChange={() => {
                    recommendationsService
                      .updateRuleOrRulebookStatus(
                        rule.name.value,
                        "rule",
                        rule.disabled.value
                      )
                      .then(() => rule.disabled.set((prevState) => !prevState));
                  }}
                />
              </S.RulebookItem>
            ))}
          </S.RulebookGridContainer>
        </S.RulebooksContainer>
      </S.Wrapper>
    </form>
  );
}
