import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  pr-4
`;

export const MenuWrapper = tw.div`
  w-[18rem]
`;

export const MenuItem = tw.div`
  border-b
  cursor-pointer
  hover:bg-pl-primary-green-light
  last:border-none
`;

export const Title = tw.div`
  h-7
  w-7
  text-pl-grayscale-black
  transition-smooth

  hover:rotate-180
  hover:translate-x-[-3px]
  hover:text-pl-primary-green-default
`;
