import React from "react";
import {FieldHookCallback, ISettings} from "@flatfile/adapter";
import {FlatfileButton} from "@flatfile/react";
import {useState} from "@hookstate/core";
import {DateTime} from "luxon";
import {LoadingWidget} from "components/utils/LoadingWidget";
import {Employee} from "model/employee";
import auth from "services/auth";
import {apiPost} from "services/utils";
import {WidgetTitle} from "components/utils/WidgetTitle";
import {WidgetSubtitle} from "components/utils/WidgetSubtitle";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/solid";
import TabTitle from "components/structure/TabTitle";
import Breadcrumbs from "components/generics/Breadcrumbs";
import Button from "components/generics/Button";

import * as S from "./styles";

const LICENSE_KEY = "c64efc2a-0f01-4daa-8ed0-4e6e677ee533";

const additionDateFormats = ["MM/dd/yyyy", "M/d/yyyy", "M/d/yy"];

const primaryButtonSettings = {
  backgroundColor: "#00ACC2",
  border: "1px solid #00ACC2",
  borderRadius: "1rem",
  ":hover": {
    backgroundColor: "#CAEBF0",
    border: "1px solid #00ACC2",
    color: "#00ACC2",
  },
};

const primaryVariantButtonSettings = {
  backgroundColor: "#fff",
  border: "1px solid #00ACC2",
  color: "#00ACC2",
  borderRadius: "1rem",
  ":hover": {
    backgroundColor: "#CAEBF0",
    color: "#00ACC2",
  },
};

const secondaryButtonSettings = {
  backgroundColor: "#fff",
  border: "1px solid #C1C9D2",
  color: "#131A21",
  borderRadius: "1rem",
  ":hover": {
    backgroundColor: "#F0F2F4",
  },
};

const fieldSettings: ISettings = {
  fields: [
    // Required fields
    {
      label: "Name",
      key: "name",
      validators: [{validate: "required"}],
    },
    {
      label: "Email",
      key: "email",
      validators: [
        {
          validate: "regex_matches",
          regex:
            "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])",
          error: "Must be in email format",
        },
        {
          validate: "required",
        },
      ],
    },
    {
      label: "Job Title",
      key: "jobTitle",
      validators: [{validate: "required"}],
    },
    {
      label: "Join Date",
      key: "joinDate",
      validators: [{validate: "required"}],
    },
    {
      label: "PL Access Level",
      key: "role",
      validators: [{validate: "required"}],
      type: "select",
      options: [
        {value: "Employee", label: "Employee"},
        {value: "Manager", label: "Manager"},
        {
          value: "Admin",
          label: "Admin",
        },
      ],
    },
    // Optional fields
    {
      label: "Primary Team",
      key: "primaryTeam",
    },
    {
      label: "Location",
      key: "location",
    },
    {
      label: "Manager",
      key: "manager",
      validators: [
        {
          validate: "regex_matches",
          regex:
            "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])",
          error: "Must be in email format",
        },
      ],
    },
    {
      label: "Skills",
      key: "skills",
    },
    {
      label: "Confluence Username",
      key: "confluence",
    },
    {
      label: "GSuite Username",
      key: "gsuite",
    },
    {
      label: "Github Username",
      key: "github",
    },
    {
      label: "Gitlab Username",
      key: "gitlab",
    },
    {
      label: "Hubspot Username",
      key: "hubspot",
    },
    {
      label: "Jira Username",
      key: "jira",
    },
    {
      label: "Office 365 Username",
      key: "office365",
    },
    {
      label: "Salesforce Username",
      key: "salesforce",
    },
    {
      label: "Zendesk Username",
      key: "zendesk",
    },
    {
      label: "Freshservice Username",
      key: "freshservice",
    },
  ],
  allowCustom: false,
  encoding: "utf-8",
  disableManualInput: true,
  allowInvalidSubmit: false,
  type: "employee",
  styleOverrides: {
    primaryButtonColor: "#00ACC2",
  },
  managed: false,
  title: "Bulk add employees",
  theme: {
    global: {
      successColor: "#00ACC2",
      backgroundColor: "#fff",
    },
    buttons: {
      primary: primaryButtonSettings,
      success: primaryButtonSettings,
      headerMatchYes: primaryButtonSettings,
      headerMatchNo: primaryVariantButtonSettings,
      secondary: secondaryButtonSettings,
    },
    dialog: {
      root: {
        backgroundColor: "#fff",
      },
      footer: {
        backgroundColor: "#fff",
      },
    },
    headerMatch: {
      select: {
        border: "1px solid #F0F2F4",
        backgroundColor: "#F9F7F5",
        borderRadius: "1rem",
        ":focus": {
          border: "1px solid #F0F2F4",
        },
        ":selected": {
          color: "#00ACC2",
        },
      },
      table: {
        th: {
          backgroundColor: "#fff",
          color: "#8595A7",
          borderColor: "#fff",
          fontWeight: "600",
        },
        td: {
          borderColor: "#fff",
          ":hover": {
            backgroundColor: "#EFF9FA",
          },
        },
      },
    },
    manualInput: {
      root: {
        padding: "0 4rem",
      },
      table: {
        th: {
          backgroundColor: "#fff",
          color: "#8595A7",
          borderColor: "#fff",
          fontWeight: "600",
        },
        td: {
          borderColor: "#fff",
          ":hover": {
            backgroundColor: "#EFF9FA",
          },
        },
      },
    },
  },
};

const validateJoinDate: FieldHookCallback = (values) =>
  values.map(([date, index]) => {
    let parsed = DateTime.fromISO("" + date);

    if (!parsed.isValid) {
      for (let format of additionDateFormats) {
        parsed = DateTime.fromFormat("" + date, format);
        if (parsed.isValid) {
          break;
        }
      }
    }

    if (!parsed.isValid) {
      return [
        {
          info: [
            {
              message:
                "Please check that the join date is formatted MM/DD/YYYY",
              level: "error",
            },
          ],
        },
        index,
      ];
    } else {
      return [
        {
          value: parsed.toFormat("MM/dd/yyyy"),
        },
        index,
      ];
    }
  });

type ImportResult = {
  success: boolean;
  message: string;
};

async function submitImport(data: any) {
  return apiPost<ImportResult>("/api/xhr/organizations/import", data);
}

function InnerEmployeeImporter({me}: { me: Employee }) {
  const importResult = useState(null as ImportResult | null);
  TabTitle("Employee Importer");

  return (
    <>
      <S.ImportResultWrapper>
        <ImportResultValue result={importResult.value}/>
      </S.ImportResultWrapper>

      <S.WidgetWrapper>
        <S.WidgetHeader>
          <S.WidgetTitleWrapper>
            <WidgetTitle>Import Data</WidgetTitle>

            <WidgetSubtitle>
              Including employees with teams and integration mappings
            </WidgetSubtitle>
          </S.WidgetTitleWrapper>
          <S.ActionWrapper>
            <FlatfileButton
              licenseKey={LICENSE_KEY}
              customer={{
                userId: "" + me.id,
              }}
              settings={fieldSettings}
              onData={async (results) => {
                const result = await submitImport(results.data);
                importResult.set(result);

                if (result.success) {
                  return "Import was successfull!";
                } else {
                  return "There was an error importing your data!";
                }
              }}
              fieldHooks={{
                joinDate: validateJoinDate,
              }}
            >
              <Button $shadow={true} btntype="primary">
                Begin Import
              </Button>
            </FlatfileButton>
          </S.ActionWrapper>
        </S.WidgetHeader>

        <S.WidgetContentWrapper>
          To use the importer below, create a CSV file (comma separated file)
          similar to the following:
          <S.WidgetContentPreviewWrapper>
            <S.WidgetContentPreview>
              "Name","Email","Job Title","Join Date","PL Access Level","Primary
              Team","Location","Manager","Skills"
              <br/>
              "Jim
              Smith","jim@company.com","President","09/10/2015","Manager","Marketing","Durham","","Leadership"
              <br/>
              "James Smith","james@company.com","Marketing
              Associate","09/10/2018","Employee","Marketing","","jim@company.com",""
            </S.WidgetContentPreview>
          </S.WidgetContentPreviewWrapper>
          <S.DownloadLinkWrapper>
            Alternatively,{" "}
            <S.DownloadLink href="/importer/test-import.csv">
              click here to download
            </S.DownloadLink>{" "}
            a complete example to edit and then upload.
          </S.DownloadLinkWrapper>
        </S.WidgetContentWrapper>
      </S.WidgetWrapper>
    </>
  );
}

function ImportResultValue({result}: { result: ImportResult | null }) {
  if (!result) {
    return <></>;
  }

  if (result.success) {
    // format as success
    return (
      <div
        className={
          "bg-pl-primary-green-default rounded p-4 flex items-center text-pl-grayscale-white"
        }
      >
        <CheckCircleIcon className={"h-20 w-20 mr-5"}/>
        <div dangerouslySetInnerHTML={{__html: result.message}}/>
      </div>
    );
  } else {
    // format as error
    return (
      <div
        className={
          "bg-pl-primary-red-default rounded p-4 flex items-center text-pl-grayscale-white"
        }
      >
        <XCircleIcon className={"h-20 w-20 mr-5"}/>
        <div dangerouslySetInnerHTML={{__html: result.message}}/>
      </div>
    );
  }
}

export default function EmployeeImporter() {
  return (
    <>
      <Breadcrumbs
        items={[
          {label: "Import Employees & Teams", url: `/employees/import`},
        ]}
      />
      <LoadingWidget
        value={auth.me}
        builder={(me) => {
          if (me) {
            return <InnerEmployeeImporter me={me}/>;
          } else return <></>;
        }}
      />
    </>
  );
}
