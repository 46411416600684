import tw from "tailwind-styled-components";

export const MenuWrapper = tw.div`
  w-[18rem]
`;

export const MenuItem = tw.div`
  hover:bg-pl-primary-green-light
  last:border-t
  cursor-pointer
`;

export const Title = tw.div`
  h-8
  w-8
`;
