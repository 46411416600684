import React, { forwardRef, InputHTMLAttributes } from "react";
import Button from "components/generics/Button";

import * as S from "./styles";

import { ReactComponent as XIcon } from "icons/close.svg";
import { ReactComponent as EyeShow } from "icons/eye-show.svg";
import { ReactComponent as EyeOff } from "icons/eye-off.svg";
import {useState} from "@hookstate/core";

export type TextFieldProps = {
  inputsize?: "small" | "medium" | "large";
  icon?: React.ReactNode | JSX.Element;
  iconposition?: "left" | "right";
  disabled?: boolean;
  error?: React.ReactNode;
  clearable?: boolean;
  rounded?: boolean;
  border?: boolean;
  background?: boolean;
  caret_color?: boolean;
  onClear?: () => void;
  type?: "text" | "password";
} & InputHTMLAttributes<HTMLInputElement>;

const TextField: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TextFieldProps
> = (
  {
    onChange,
    onClear,
    value,
    name,
    inputsize = "medium",
    icon,
    iconposition = "left",
    error,
    disabled = false,
    clearable = false,
    rounded = false,
    border = true,
    background = true,
    caret_color = false,
    type = "text",
    ...props
  }: TextFieldProps,
  ref
) => {
  const show = useState<boolean>(false);

  return (
    <S.Wrapper>
      <S.InputWrapper
        inputsize={inputsize}
        disabled={disabled}
        error={!!error ? 1 : 0}
        hasvalue={!!value ? 1 : 0}
        rounded={!!rounded ? 1 : 0}
        border={!!border ? 1 : 0}
        background={!!background ? 1 : 0}
        caret_color={!!caret_color ? 1 : 0}
      >
        {!!icon && (
          <S.Icon
            iconposition={clearable ? "left" : iconposition}
            disabled={disabled}
            error={!!error ? 1 : 0}
          >
            {icon}
          </S.Icon>
        )}
        <S.Input
          type={type === "password" ?
            show ?
              "text" :
              "password" :
            "text"}
          onChange={onChange}
          value={value}
          name={name}
          disabled={disabled}
          error={!!error ? 1 : 0}
          autoComplete="off"
          ref={ref}
          {...(name ? { id: name } : {})}
          {...props}
        />
        {
          type == "password" && (
            <Button
              minimal
              type="button"
              size="medium"
              icon={show.value ? <EyeOff className="w-4 h-4" /> : <EyeShow className="w-4 h-4" />}
              className="!text-pl-grayscale-midgray hover:!text-pl-grayscale-gray"
              onClick={() => {
                show.set(!show.value);
              }}
            />
          )
        }
        {clearable && !!onClear && (
          <Button
            minimal
            type="button"
            size="medium"
            icon={<XIcon className="w-4 h-4" />}
            className="!text-pl-grayscale-midgray hover:!text-pl-grayscale-gray"
            onClick={() => onClear()}
          />
        )}
      </S.InputWrapper>
      {!!error && !disabled && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  );
};

export default forwardRef(TextField);
