import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  inline-block
  border
  w-full
  max-w-[500px]
  max-h-[800px]
  p-6
  overflow-y-auto
  text-left
  align-middle
  transition-all
  transform
  bg-pl-grayscale-white
  shadow-xl
  rounded-2xl
`;

export const MainContainer = tw.div`
  grid
  gap-6
  divide-y
  w-full
`;

export const ContentWrapper = tw.div`
  pt-3
  min-h-[500px]
`;

export const ChevronContentWrapper = tw.div`
  grid
  gap-5
`;

export const ButtonsWrapper = tw.div`
  pt-6
  flex
  items-center
  justify-between
`;

export const MoreWrapper = tw.div`
  flex
  items-center
  w-full
  gap-1
  min-h-[fit-content]
  text-pl-primary-green-default
  pb-2
  pt-1
  cursor-pointer
`;

export const MoreLabel = tw.div`
  w-9
  text-sm
  font-semibold
`;



export const CustomDisabledRadioText = tw.div`
  ml-2
  text-sm
  px-6 py-2
  text-pl-primary-red-default
`;
