import tw from "tailwind-styled-components";
import { IntegrationLogoProps } from ".";

const wrapperModifiers = {
  small: `
    h-8
    w-8
    p-2
  `,

  medium: `
    h-14
    w-14
    p-3
  `,

  large: `
    h-20
    w-20
    p-3
  `,
};

export const Wrapper = tw.div<Omit<IntegrationLogoProps, "integration">>`
  ${({ size, rounded }) => `
    ${!!size && wrapperModifiers[size]}
    ${rounded ? "rounded-full" : ""}
    bg-pl-grayscale-white
    shadow
    flex
    items-center
    justify-center
  `}
`;

export const Logo = tw.img`
  object-cover
`;
