import tw from "tailwind-styled-components";
import { HeadingProps } from ".";

const titleModifiers = {
  small: `
    text-base
  `,

  medium: `
    text-xl
  `,

  large: `
    text-2xl
  `,
};

const iconModifiers = {
  small: `
    h-4
    w-4
  `,

  medium: `
    h-5
    w-5
  `,

  large: `
    h-6
    w-6
  `,
};

export const Wrapper = tw.div`
  flex
  items-center
  gap-2
  text-pl-grayscale-black
`;

export const Title = tw.h2<{size: string, weight: string}>`
  ${({ size, weight}) => `
    ${weight}
    text-pl-grayscale-black

    ${titleModifiers[size!]}
  `}
`;

export const IconWrapper = tw.div<Pick<HeadingProps, "size">>`
  ${({ size }) => `
    ${iconModifiers[size!]}
  `}
`;
