import React, {useRef} from "react";
import TextField from "components/generics/TextField";
import Checkbox from "components/generics/Checkbox";
import auth from "services/auth";
import {Employee} from "model/employee";
import {useState} from "@hookstate/core";
import {DashboardModalState} from "./DashboardModal";

import * as S from "./styles";

export default function DashboardModalBasicInfoTab({ isEdit }: { isEdit: boolean} ) {
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);

  const dashboardState = useState(DashboardModalState.dashboard);

  const canUpdateVisibility = () => {
    if (!isEdit) {
      return true;

    } else {
      const me = auth.me.value as Employee;
      return auth.currentUserIsAdmin() || me.id === dashboardState.ownerId.value;
    }
  };

  return (
    <S.FormWrapper>
      <S.FieldWrapper>
        <S.LabelWrapper>
          Dashboard Name
        </S.LabelWrapper>
        <S.ElementWrapper>
          <TextField
            ref={nameRef}
            name="name"
            value={
              dashboardState.name.value ? dashboardState.name.value : ""
            }
            clearable
            onChange={({ target: { value } }) => {
              dashboardState.name.set(value);
              DashboardModalState.dashboard.name.set(value);
            }}
            border={false}
            placeholder="Enter a description name for this connection"
          />
          {
            dashboardState.name.value === "" ?
              <S.Error>Name is a required field</S.Error>
              :
              (
                dashboardState.name.value.length < 3 &&
                <S.Error>Name must be at least 3 characters</S.Error>
              )
          }
        </S.ElementWrapper>
      </S.FieldWrapper>

      <S.FieldWrapper>
        <S.LabelWrapper>
          Description
        </S.LabelWrapper>
        <S.ElementWrapper>
          <TextField
            ref={descriptionRef}
            name="description"
            clearable
            value={dashboardState.description.value}
            onChange={({ target: { value } }) =>
              dashboardState.description.set(value)
            }
            border={false}
            placeholder="Enter a description for this connection"
          />
        </S.ElementWrapper>
      </S.FieldWrapper>

      <S.FieldWrapper>
        <S.ElementWrapper>
          <Checkbox
            name="publicDash"
            label="Make Public"
            checked={dashboardState.publicDash.value}
            disabled={!canUpdateVisibility()}
            onChange={({ target: { checked } }) =>
              dashboardState.publicDash.set(checked)
            }
            rounded
          />
        </S.ElementWrapper>
      </S.FieldWrapper>
    </S.FormWrapper>
  );
}