import hash from 'object-hash';
import auth from '../services/auth';

const CACHE_ENABLED = false;

type CacheEntry = {
  user: number
  access: number,
  expires: number,
  value: any
}

async function getOrSet<T>(cache: string, key: any, factory: () => T | Promise<T>, expirationSeconds: number = 120): Promise<T> {

  if (!CACHE_ENABLED) {
    return factory();
  }

  const keyHash = hash.MD5({cache, key});

  const me = auth.me.get();

  if (!me) {
    return factory();
  }

  let value  = _fromCacheIfValid(keyHash, me.id);

  if (!value) {
    value = await factory();
  }

  try {
    _addToCache(keyHash, value, me.id, expirationSeconds);
  } catch (e) {
    _clearStaleEntries();
    _addToCache(keyHash, value, me.id, expirationSeconds);
  }
  return value as T;
}

function _clearStaleEntries() {
  const keys = Object.keys(localStorage);

  const sorted = keys
    .map(k => ({k: k, e: _getEntry(k)}))
    .sort((a, b) => (b.e?.access ?? 0) - (a.e?.access ?? 0));

  for (let i = 0; i < 10 && i < sorted.length; i++) {
    _clearFromCache(sorted[i].k);
  }
}

function _fromCacheIfValid<T>(keyHash: string, currentUser: number): T | null {
  try {
    const entry = _getEntry(keyHash);

    if (!entry) {
      return null;
    }

    if (entry.user !== currentUser) {
      _clearFromCache(keyHash);
      return null;
    }

    if (entry.expires < new Date().getTime()) {
      _clearFromCache(keyHash);
      return null;
    }

    return entry.value as T;
  } catch (e) {
    // ignore parsing errors
    _clearFromCache(keyHash);
    return null;
  }
}

function _getEntry(keyHash: string) {
  const cachedValue = localStorage.getItem(keyHash);

  if (!cachedValue) {
    return null;
  }

  return JSON.parse(cachedValue) as CacheEntry;
}

function _addToCache(keyHash: string, value: any, currentUser: number, expirationSeconds: number) {
  const entry = {
    user: currentUser,
    access: new Date().getTime(),
    expires: new Date().getTime() + expirationSeconds * 1000,
    value
  } as CacheEntry;

  localStorage.setItem(keyHash, JSON.stringify(entry));
}

function _clearFromCache(keyHash: string) {
  localStorage.removeItem(keyHash);
}

export default getOrSet;
