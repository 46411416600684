import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  px-6
  py-4
  mb-3
  bg-pl-grayscale-white
  flex
  gap-4
  justify-between
  rounded-2xl
  border
  w-full
  border-pl-primary-orange-default
`;

export const Content = tw.div`
  flex
  flex-col
  gap-1
`;

export const Title = tw.div`
  text-md
  leading-6
  font-semibold
  text-pl-grayscale-black
`;

export const Message = tw.div`
  text-pl-grayscale-gray
  text-sm
`;

export const Link = tw.div`
  font-semibold
  text-pl-primary-orange-default
  cursor-pointer
  text-sm
  flex
  items-center
  gap-2
  mt-1
`;

export const ActionWrapper = tw.div`
  min-w-[140px]
  flex
  justify-end
`;

export const ActionButton = `
  bg-pl-primary-orange-light
  text-pl-primary-orange-default
  rounded-xl
  hover:bg-pl-primary-orange-default
  hover:text-pl-primary-orange-light
`