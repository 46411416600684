import { EmployeeFormData } from "./employee";

export type Icon = {
  icon: string;
};

export type Integration = {
  id: number;
  name: string;
  provider: string;
  aggregatorType: string;
  active: boolean;
  lastStatus: string;
  lastSuccess: Date;
  mode: string;
  logo: string;
  supportUrl: string;
  ignoreExternalUnmapped: boolean;
  unmapped?: number;
  requiresAdditionalData: boolean;
};

export enum IntegrationRequiredFields {
  API_URL = "API_URL",
  API_FILTER = "API_FILTER"
};

export type InstalledIntegrationInfoDto = {
  id: string;
  name: string;
  type: string;
  provider: string;
  description: string;
  version: string;
  authorizeLink: string;
  logo: string;
  createdBy: string;
  mode: string;
  addButtonText: string;
  supportUrl: string;
  category: string;
  signupEnabled: boolean;
  premium: boolean;
  eventTypes: string[];
  ignoreExternalUnmapped: boolean;
  requiresAdditionalData: boolean;
  integrationFormData?: IntegrationFormData;
  integrationRequiredFields?: IntegrationRequiredFields[];
};

export type IntegrationFormData = {
  id: number;
  name: string;
  mode: string;
  aggregatorType: string;
  apiKey: string;
  apiSecret: string;
  apiUrl: string;
  apiFilter: string;
  ignoreExternalUsers: boolean;
  enabled: boolean;
  tenant: string;
  integrationRequiredFields: IntegrationRequiredFields[];
};

export type IntegrationForm = {
  id: number;
  resourceId: string;
  apiKey: string;
  apiSecret: string;
  apiUrl: string;
  apiFilter: string;
  name: string;
  aggregatorType: string;
  additionalData: string;
  mode: string;
  requiresAdditionalConnectionData: boolean;
  active: boolean;
  lastAggregationSuccess: Date;
  tenant: string;
  ignoreExternalUnmapped: boolean;
  new: boolean;
  additionalConnectionData: { formFields: FormField[] };
};

export type IntegrationConnectionResult = {
  success: boolean,
  message: string,
}

export type FormField = {
  key: string;
  data: any;
  required?: boolean;
  help?: string;
};

export type AuthData = {
  aggregatorType: string;
  name: string;
  apiKey: string;
  apiSecret: string;
  filter?: string;
  resourceId?: string;
  additionalData?: string;
  formFields: FormField[];
};

export type IntegrationTest = {
  apiKey: string;
  apiSecret: string;
  apiUrl: string;
  filter: string;
  aggregatorType: string;
  active: boolean;
};

export type EmployeeIntegration = {
  integrationId: number;
  integrationUsername: string;
};

export type IndexedEmployeeIntegration = EmployeeIntegration & {
  index: number;
  markedDeleted: boolean;
};

export type UnmappedIntegrationUser = {
  sourceId: number;
  sourceName: string;
  ignoreExternalUnmapped: boolean;
  sourceType: string;
  unmappedUsers: UnmappedUser[];
};

export type UnmappedUser = {
  mapping: string;
  employeeId?: number;
  mapped: boolean;
  mappedState: UnmappedUserState;
  previousMappedState: UnmappedUserState;
  newEmployee?: EmployeeFormData;
};

export type UnmappedUserState =
  | "unmapped"
  | "mappedWithEmployee"
  | "ignoredUsers"
  | "newEmployees";

export type HrisSync = {
  integrationId: number;
  active: boolean;
  lastSync: Date;
}