import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  inline-block
  border
  w-full
  max-w-[500px]
  p-6
  overflow-hidden
  text-left
  align-middle
  transition-all
  transform
  bg-pl-grayscale-white
  shadow-xl
  rounded-2xl
`;

export const MainContainer = tw.div`
  grid
  gap-6
  divide-y
  w-full
`;

export const ContentWrapper = tw.div`
  pt-4
`;

export const ButtonsWrapper = tw.div`
  pt-6
  flex
  items-center
  justify-between
`;


export const FormWrapper = tw.div`
  p-2
  gap-4
  grid
`;

export const DoubleFieldWrapper = tw.div`
  gap-4
  grid
  grid-cols-2
`;

export const FieldWrapper = tw.div`
  gap-1
  grid
`;

export const LabelWrapper = tw.div`
  text-xs
  font-semibold
  text-pl-grayscale-gray
  h-4
`;

export const ElementWrapper = tw.div`
  items-top
`;

export const Error = tw.div`
  text-pl-primary-red-default
  text-xs
  font-semibold
  h-4
  pt-1
  border-none
`;
