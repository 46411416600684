import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  shadow
  rounded-2xl
  sm:overflow-hidden
  bg-pl-grayscale-white
  my-4
  divide-y
`;

export const HeaderWrapper = tw.div`
  py-6
  px-4
  sm:p-6
  flex
  w-full
  flex
  items-center
  justify-between
`;

export const HeaderTitle = tw.h3`
  text-lg
  leading-6
  font-medium
  text-pl-grayscale-black
`;

export const HeaderButtons = tw.div`
  flex
  gap-4
`;

export const BodyWrapper = tw.div`
  py-6
  px-4
  sm:p-6
  grid
  grid-cols-2
`;

export const BodyColumns = tw.div`
`;

export const ColumnTitle = tw.span`
  text-sm
  font-normal
  text-pl-grayscale-gray
`;

export const ToggleWrapper = tw.div`
  flex
  flex-col
  sm:flex-row
  w-full
  items-center
  mt-6
`;

export const ChecksWrapper = tw.div`
  flex
  flex-col
  w-full
  mt-4
  gap-4
`;

export const FrquencyFieldSetWrapper = tw.fieldset`
  w-full
  flex
  flex-col
  gap-5
`;

export const Label = tw.label`
  text-sm
  font-normal
  flex
  flex-row-reverse
  justify-end
  items-center
  gap-3
  cursor-pointer
`;

export const CustomRadio = `
  focus:ring-pl-primary-green-default
  h-4
  w-4
  text-pl-primary-green-default
  border-pl-grayscale-midgray
`;