import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  flex
  flex-col
  gap-6
`;

export const Title = tw.p`
  text-sm
`;

export const ListWrapper = tw.div`
  flex
  flex-col
  gap-6
`;

export const ListTitle = tw.div`
  font-semibold
`;

export const ListContentWrapper = tw.div`
  flex
  items-center
`;

export const ListContent = tw.div`
  flex
  flex-col
  w-full
  items-start
  justify-between
  flex-3
`;

export const ListItemWrapper = tw.div`
  flex
  justify-between
  w-full
`;

export const ListItem = tw.div`
  flex
  flex-col
  items-start
  w-full
`;

export const ListItemContent = tw.div`
  flex
  flex-col
  items-start
  justify-center
  w-full
  border-b
  border-pl-grayscale-lightgray
  h-[45px]
  text-sm
`;

export const ListItemHeader = tw(ListItemContent)`
  !text-pl-grayscale-gray
  font-semibold
`;

export const ActionWrapper = tw.div`
  mt-[44px]
  flex
  flex-col
  justify-center
`;

export const Action = tw.div`
  border-t-0
  border-b-0
  first:border-b
  first:border-t
  last:border-t
  last:border-b
  border-pl-grayscale-lightgray py-3
`;
