import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  pb-3
`;

export const Title = tw.div`
  text-center
  mb-5
  -mt-5
  text-pl-grayscale-black
  text-xl
  font-bold
  truncate
`;

export const CurrentValue = tw.div`
  text-center
  text-2xl
  font-bold
  -mt-2
`;

export const DataComparison = tw.div`
  flex
  justify-center
  items-center
  gap-2
  py-2
  text-pl-grayscale-gray
  text-base
`;

export const Value = tw.div<{ change: number }>`
  ${({ change }) => `
    flex
    justify-center
    items-center
    gap-2
    font-semibold
    text-lg
    ${
      change === 0
        ? "text-pl-grayscale-black"
        : change > 0
        ? "text-pl-primary-green-default"
        : "text-pl-primary-red-default"
    }
  `}
`;

export const GraphicComparisonWrapper = tw.div`
  h-[120px]
  w-[255px]
  justify-center
  items-center
  flex
  p-2
  pl-3
`;

export const TimeLabel = tw.div`
  text-sm
  text-center
`;
