import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  inline-block
  w-full
  max-w-[500px]
  p-6
  my-8
  text-left
  align-middle
  transition-all
  transform
  bg-pl-grayscale-white
  shadow-xl
  rounded-2xl
`;

export const TitleWrapper = tw.div`
  text-lg
  font-medium
  leading-6
  text-pl-grayscale-black
  border-b
  pb-6
  mb-5
  border-b-dark:border-pl-grayscale-black
`;

export const FormWrapper = tw.div`
  flex
  flex-col
  gap-6
`;

export const RowContainer = tw.div`
  flex
  items-center
  gap-4
  w-full
`;

export const Label = tw.label`
  text-pl-grayscale-gray
  text-sm
  font-semibold
  w-full
  flex
  flex-col
`;

export const ActionWrapper = tw.div`
  flex
  w-full
  items-center
  justify-between
  px-2
  pt-6
  mt-4
  border-t
  border-t-dark:border-pl-grayscale-black
`;

export const RadioWrapper = tw.div`
  w-full
  flex
  flex-col
  gap-5
`;

export const RadioLabelClass = `
  flex-row-reverse
  justify-end
  items-center
  gap-3
  cursor-pointer
  text-pl-grayscale-black
  font-normal
`

export const InputFieldClass = `
  bg-pl-variant-beige
  border-pl-grayscale-midgray
  rounded-lg
  focus:ring-pl-primary-green-default
  focus:border-pl-primary-green-default
  hover:border-pl-primary-green-default
  transition-smooth
  w-full
  h-10
`
