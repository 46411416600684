import React, { Fragment, useEffect } from "react";
import { State, useState } from "@hookstate/core";
import { Dialog, Transition } from "@headlessui/react";

export function CommonModal({
  children,
  openState,
}: {
  children: React.ReactNode;
  openState: State<boolean>;
}) {
  const isOpen = useState(openState);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") {
      return;
    }
    isOpen.set(false);
  };

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") handleClose(e, "escapeKeyDown");

      return () => document.removeEventListener("keydown", () => {});
    });
  }, []);

  return (
    <>
      <Transition show={isOpen.value} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto backdrop-blur-[6px]"
          onClose={() => handleClose}
        >
          <div className="min-h-screen text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {children}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
