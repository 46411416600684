import tw from "tailwind-styled-components";
import { SpinnerProps } from ".";

const SpinnerModifiers = {
  small: `
    h-3
    w-3
  `,

  medium: `
    h-12
    w-12
  `,

  large: `
    h-24
    w-24
  `,

  light: `
    border-white
  `,

  dark: `
    border-pl-grayscale-black
  `,
};

const MessageModifiers = {
  small: `
    text-sm
  `,

  medium: `
    text-md
  `,

  large: `
    text-lg
  `,
};

export const Wrapper = tw.div`
  flex
  justify-center
  items-center
  p-4
`;

export const Spinner = tw.div<SpinnerProps>`
${({ size, color }) => `
  animate-spin
  rounded-full
  border-b-2


  ${!!size && SpinnerModifiers[size]}
  ${color == "light" ? SpinnerModifiers.light : SpinnerModifiers.dark}
`}
`;

export const Message = tw.span<SpinnerProps>`
  ${({ size }) => `
    text-pl-grayscale-gray
    font-normal
    w-full
    cursor-default
    text-center

    ${!!size && MessageModifiers[size]}
  `}
`;
