import {
  PeriodSelectorState,
  YEAR_NUM_DAYS,
} from "components/utils/PeriodSelector";
import React from "react";
import { NetworkGraphProps } from "model/graph";
import { KeyMetricsType } from "model/keymetrics";
import TabTitle from "components/structure/TabTitle";
import graphService from "services/graphService";
import keyMetricsService from "services/keymetrics";
import focusAreaService from "services/focusArea";
import { LoadingWidget } from "components/utils/LoadingWidget";
import recommendationsService from "services/recommendations";
import RecommendationTable from "components/ui/RecommendationTable";
import KeyMetrics from "components/ui/Keymetrics";
import { Department } from "model/department";
import BaseDashboard from "components/structure/BaseDashboard";
import {useState} from "@hookstate/core";
import {Employee} from "../../../model/employee";
import {ChartLabelValuePair} from "model/chart";

import * as S from "./styles";
import FocusAreas from "../../../components/ui/Focusareas";

export const DepartmentDashboard = ({
  department,
}: {
  department: Department;
}) => {
  const selectedPeriod = PeriodSelectorState.selectedPeriod.value;

  const teamsIds = department.teams
    ? department.teams.map((team) => team.id)
    : [];

  const networkGraphPropsByDepartment =
    useState<NetworkGraphProps>(() => graphService.getNetworkGraphForDepartment(teamsIds));

  const keyMetricsData =
    useState<KeyMetricsType>(() => keyMetricsService.getKeyMetricsForDepartment(
      department.id,
      selectedPeriod
    ));

  const departmentFocusArea =
      useState<ChartLabelValuePair[]>(() => focusAreaService.getFocusAreaForDepartment(department.id, selectedPeriod))

  const departmentEmployees = useState<Employee[]>(() => department.employees ? department.employees : []);

  TabTitle(department.name.concat(" Department"));

  return (
    <>
      <BaseDashboard
        title="Teams"
        data={department}
        networkGraphContext="teams"
        networkGraphData={networkGraphPropsByDepartment}
        employees={departmentEmployees}
        rightChildren={
        <S.RightChildWrapper>
          <LoadingWidget<KeyMetricsType>
            value={keyMetricsData}
            builder={(metrics) => (
              <KeyMetrics department={department} keyMetricsType={metrics}/>
            )}
          />
          <LoadingWidget<ChartLabelValuePair[]>
            value={departmentFocusArea}
            builder={(data) => (
              <FocusAreas eventInfo={data} classname={`mt-8`}/>
            )}
          />
        </S.RightChildWrapper>
        }
        leftChildren={
          <RecommendationTable
            title="Impacting Factors"
            filterByEmployee={true}
            employeeFilterOptions={department.employees}
            loadRecommendations={(
              size,
              pageIndex,
              severityFilters,
              selectedEmployeeId
            ) => {
              return selectedEmployeeId
                ? recommendationsService.getRecommendationsByFilter({
                    employeeId: selectedEmployeeId,
                    pageSize: size,
                    pageNumber: pageIndex,
                    severityList: severityFilters,
                    numDaysBefore: YEAR_NUM_DAYS,
                  })
                : recommendationsService.getRecommendationsByFilter({
                    teamList: department.teams?.map((t) => t.id),
                    pageSize: size,
                    pageNumber: pageIndex,
                    severityList: severityFilters,
                    numDaysBefore: YEAR_NUM_DAYS,
                  });
            }}
          />
        }
      />
    </>
  );
};
