import React from "react";
import {ROLES_LABEL_VALUE_PAIR} from "model/employee";
import {FormikValues} from "formik";

import * as S from "./styles";

type Props = {
  values: FormikValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  activeEmployee?: boolean;
};

export function EmployeeRolesSection({ values, setFieldValue, activeEmployee}: Props) {
  return (
    <>
      <S.FormWrapper>
        <S.RolesFieldSetWrapper>
          {ROLES_LABEL_VALUE_PAIR.map((role) => (
            <S.Label key={role.value}>
              {role.label}

              <input
                aria-describedby={`${role.value}-description`}
                name="role"
                type="radio"
                disabled={!activeEmployee}
                defaultChecked={role.value === values.role}
                onClick={() => setFieldValue("role", role.value)}
                className={activeEmployee ? S.CustomRadio : S.CustomRadioDeactivate}
              />
            </S.Label>
          ))}
        </S.RolesFieldSetWrapper>
      </S.FormWrapper>
    </>
  );
}
