export default {
  labels: [
    "Engineering",
    "Sales",
    "Human Resource",
    "Engineering ∩ Sales",
    "Engineering ∩ Human Resource",
    "Sales ∩ Human Resource",
    "Engineering ∩ Sales ∩ Human Resource",
  ],
  datasets: [
    {
      label: "Teams Integration",
      data: [
        { sets: ["Engineering"], value: 80 },
        { sets: ["Sales"], value: 83 },
        { sets: ["Human Resource"], value: 77 },
        { sets: ["Engineering", "Sales"], value: 32 },
        { sets: ["Engineering", "Human Resource"], value: 28 },
        { sets: ["Sales", "Human Resource"], value: 51 },
        { sets: ["Engineering", "Sales", "Human Resource"], value: 63 },
      ],
    },
  ],
};
