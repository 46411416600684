import React, { forwardRef, useRef } from "react";
import Button from "../Button";

export type LoadMoreProps = {
  minimal?: boolean;
  shadow?: boolean;
  isVisible?: boolean;
  onLoadMore: () => void;
};

const LoadMore: React.ForwardRefRenderFunction<
  HTMLInputElement,
  LoadMoreProps
> = ({ minimal, shadow, isVisible = true, onLoadMore }: LoadMoreProps, ref) => {
  return (
    <div ref={ref}>
      {isVisible && (
        <Button
          btntype="primary"
          minimal={minimal}
          $shadow={shadow}
          onClick={onLoadMore}
        >
          Load more
        </Button>
      )}
    </div>
  );
};

export default forwardRef(LoadMore);
