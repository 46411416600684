import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  h-full
  mt-6
  w-full
`;

export const MainContainer = tw.div`
  flex
  gap-6
`;

export const LeftContainer = tw.div`
  flex
  flex-col
  gap-6
  w-[75%]
`;

export const LeftContainerItem = tw.div`
`;

export const NetworkGraphContainer = tw.div`
  bg-pl-grayscale-white
  rounded-2xl
  shadow
  overflow-hidden
  flex
  flex-col
  gap-6
`;

export const RightContainer = tw.div`
  w-[25%]
`;

export const RightContainerItem = tw.div`
`;
