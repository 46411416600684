import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  flex
  relative
`;

export const GraphWrapper = tw.div`
  w-[100%]
  relative
  overflow-hidden
  transition-all
  duration-500
  ease-in-out
`;

export const GraphAreaWrapper = tw.div`
  w-[100%]
  flex
  flex-col
  relative
`;

const contextMenuWrapperModifiers = {
  open: `
    translate-y-0
  `
}

export const ContextMenuWrapper = tw.div<{isOpen: boolean}>`
  ${({isOpen}) => `
    bg-pl-grayscale-lightgray
    w-[100%]
    flex
    items-center
    space-between
    p-4
    absolute
    bottom-0
    transition
    duration-300
    ease-in-out
    translate-y-[100px]

    ${isOpen && contextMenuWrapperModifiers.open};
  `}
`;

export const ContextMenuTitle = tw.p`
  pr-4
  w-[100%]
`;

export const ContextMenuOptions = tw.div`
  w-[100%]
  flex
  justify-center
  gap-6
  border-pl-grayscale-midgray
  border-l
  border-solid
  pl-10
`;