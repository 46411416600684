export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export function getImageSource (source: string) {
  if (source.startsWith('http')) {
    return source;
  }
  return `data:image/jpeg;base64,${source}`
}
