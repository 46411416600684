import {HrisSync, Integration} from "model/integration";
import {WidgetTitle} from "components/utils/WidgetTitle";
import CustomSelect from "components/utils/CustomSelect/CustomSelect";
import {SelectSearchOption} from "components/utils/SelectSearch";
import Button from "components/generics/Button";
import integrationService from "services/integrationService";
import ToggleButton from "components/generics/ToggleButton";
import {addSuccessToastNotification} from "components/utils/Toast/ToastNotificationContainer";
import {useState} from "@hookstate/core";
import React from "react";
import {useNavigate} from "react-router-dom";

import * as S from "./styles";

type Props = {
  hrisSync: HrisSync;
  hrisEnabled: Integration[];
};

export function OrganizationSyncComponent({
                                            hrisSync,
                                            hrisEnabled
}: Props) {
  const navigate = useNavigate();

  const hris = useState<number>(hrisSync && hrisSync.active ? hrisSync.integrationId : 0);
  const isSaving = useState<boolean>(false);
  const showSelector = useState<boolean>(!!hrisSync.integrationId && hrisSync.active);

  const hrisSyncState = useState<HrisSync>({
    integrationId: hrisSync.integrationId,
    active: hrisSync.integrationId ? hrisSync.active : false
  } as HrisSync);

  const getHrisEnabled = () => {
    const options: SelectSearchOption[] = [];

    hrisEnabled.forEach(integration =>
      options.push({
        label: integration.name,
        value: integration.id,
      } as SelectSearchOption));

    return options;
  }

  const addNew = () => {
    isSaving.set(true);
    hrisSyncState.merge({integrationId: hris.value, active: true});
    save(true);
  }

  const changeStatus = () => {
    if (hrisSyncState.value.active && hris.value > 0) {
      showSelector.set(false);
      save(false);
    } else {
      hrisSyncState.active.set(true);
      hris.set(0);
      showSelector.set(true);
    }
  }

  const save = (active: boolean) => {
    const newHris = {
      integrationId: hrisSyncState.integrationId.value,
      active: active
    } as HrisSync;

    integrationService.saveHrisSync(newHris)
      .then((saved) => {
        isSaving.set(false);
        if (saved) {
          addSuccessToastNotification("Your HRIS integration was successfully " + (newHris.active ? "activated." : "deactivated."));
          hrisSyncState.set(newHris);
          if (!active) {
            hris.set(0);
          }
        }
      });
  }

  return (
    <S.MainWrapper>
      <S.Wrapper>
        <S.MainContainer>
          <S.SubMainContainer>
            <S.WrapperTitle>
              <WidgetTitle>HRIS Roster Synchronization</WidgetTitle>
              <S.TitleActions>
                <Button type="button"
                        btntype="primary"
                        $shadow={true}
                        disabled={!(hris.value>0) || isSaving.value}
                        className={"w-[90px] h-[38px]"}
                        onClick={addNew}
                >
                  Save
                </Button>
              </S.TitleActions>
            </S.WrapperTitle>

            <S.WrapperContent>
              <S.WrapperSelectContent>
                <S.ToggleWrapper>
                  <ToggleButton
                    id="sync"
                    key="sync"
                    checked={hrisSyncState.value.active}
                    text={"Enable"}
                    onChange={changeStatus}
                    className={"pb-0"}
                  />
                </S.ToggleWrapper>
              </S.WrapperSelectContent>

              {
                showSelector ?
                  hrisEnabled.length === 0 ?
                    <S.WrapperContentMessage className={"pb-4"}>
                      <S.BlackParagraph>
                        Currently, there are no active HRIS integrations. Please
                      </S.BlackParagraph>
                      <Button type="button"
                              btntype="primary"
                              minimal
                              $shadow={false}
                              className={"h-[21px]"}
                              onClick={() => navigate("/integrations/add", { state: 'HRIS' })}
                      >
                        add one
                      </Button>
                    </S.WrapperContentMessage>
                    :
                    <>
                      <S.WrapperContentMessage className={"pb-2"}>
                        Synchronize using
                      </S.WrapperContentMessage>
                      <S.WrapperSelect>
                        <CustomSelect
                          options={getHrisEnabled()}
                          value={hris.value}
                          onChange={(opt) => {
                            hris.set(opt.value);
                          }}
                          placeholder={"Select an integration"}
                          border={false}
                          className={"max-w-[50%]"}
                        />
                      </S.WrapperSelect>
                    </>
                  :
                  <></>
              }
            </S.WrapperContent>
          </S.SubMainContainer>
        </S.MainContainer>
      </S.Wrapper>
    </S.MainWrapper>
  );
}