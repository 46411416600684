import {asPromise, DoubleLoadingWidget, LoadingWidget} from "components/utils/LoadingWidget";
import employeeService from "services/employeeService";
import auth from "services/auth";
import ManagerLandingPage from "routes/home/managerLanding/ManagerLandingPage";
import integrationService from "services/integrationService";
import TabTitle from "components/structure/TabTitle";
import React from "react";
import {Employee, Role} from "model/employee";
import {Integration} from "model/integration";
import HomePage from "routes/home/homePage/HomePage";
import {useState} from "@hookstate/core";

export default function Home() {
  TabTitle("Home");

  function ifAdmin<T>(fn: (me: Employee) => Promise<T>, def: T): Promise<T> {
    return asPromise(auth.me).then(me => {
      if (me?.role === Role.ADMIN) {
        return fn(me);
      } else {
        return def;
      }
    }) || Promise.reject()
  }

  const employeeIntegrations =
    useState<Integration[]>(ifAdmin((me) => integrationService.getActiveIntegrations(), []));


  const allEmployees =
    useState<Employee[]>(ifAdmin((me) => employeeService.getAllEmployees(), []));

  const employeeInfo =
    useState<Employee>(ifAdmin((me) => employeeService.getEmployeeInfo(me.id, {
      teamInfo: true,
      location: true,
      skills: true,
    }), {} as Employee));

  return (
    <LoadingWidget<Employee>
      value={auth.me}
      builder={(me) => (
        <>
          {me && (
            <LoadingWidget<Employee>
              value={employeeInfo}
              builder={(employee) => (
                <div className={"h-full mt-6"}>
                  {
                    employee.role === "admin" ?
                      <DoubleLoadingWidget<Integration[], Employee[]>
                        values={[employeeIntegrations, allEmployees]}
                        builder={(integrations, employees) => (
                          <>
                            {
                              (integrations.length === 0 || employees.length <= 1) ? (
                                <ManagerLandingPage/>
                              ) : (
                                <HomePage me={employee}/>
                              )
                            }
                          </>
                        )}
                      />
                      :
                      <HomePage me={employee}/>
                  }
                </div>
              )}
            />
          )}
        </>
      )}
    />
  );
}
