import React from "react";
import Dropdown from "components/generics/Dropdown";
import Button from "components/generics/Button";
import { ReactComponent as DotsIcon } from "icons/dots.svg";

import * as S from "./styles";

export type BulkActionProps = {
  label: string;
  action: () => void;
};

export type BulkActionsMenuProps = {
  actions: BulkActionProps[];
};

const BulkActionsMenu = ({ actions }: BulkActionsMenuProps) => (
  <S.Wrapper>
    <Dropdown
      title={
        <S.ButtonWrapper>
          <Button minimal>
            <DotsIcon
              color="pl-primary-green-light"
              className="transition-smooth group-hover:text-pl-primary-green-default"
            />
          </Button>
        </S.ButtonWrapper>
      }
      minWidth={"min-w-[1px]"}
      width={"w-[150px]"}
      direction="center"
    >
      <S.BulkActionsMenuWrapper>
        {actions.map((item, index) => (
          <S.BulkActionsMenuItem
            onClick={item.action}
            key={index}
            className={`${
              actions.length === 1
                ? "hover:rounded-2xl"
                : actions.length > 1 && index == actions.length - 1
                ? "hover:rounded-b-2xl"
                : actions.length > 1 && index == 0
                ? "hover:rounded-t-2xl"
                : ""
            }`}
          >
            {item.label}
          </S.BulkActionsMenuItem>
        ))}
      </S.BulkActionsMenuWrapper>
    </Dropdown>
  </S.Wrapper>
);

export default BulkActionsMenu;
