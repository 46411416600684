import React from "react";
import { useNavigate } from "react-router-dom";
import { UnmappedIntegrationUser } from "model/integration";
import WarningMessage from "components/generics/WarningMessage";
import authService from "services/auth";
import {PrivacyLevel} from "model/organization";
import privacyLevelService from "services/privacyLevelService";
import {openConfirmationModal} from "components/utils/Modal/ConfirmationModal";

export default function UnmappedUserMessage({
  unmapped,
}: {
  unmapped: UnmappedIntegrationUser[];
}) {
  const privacyLevel = authService.privacyLevel.value as PrivacyLevel;

  const numUsers = unmapped
    .flatMap((map) => map.unmappedUsers.length)
    .reduce((a, b) => a + b, 0);

  const navigate = useNavigate();

  const goToMapper = () => {
    privacyLevelService.hasAccess(privacyLevel) ?
      navigate("/integrations/mapper")
      :
      openConfirmationModal(
        "Please select a privacy level in your organization settings that allows you to see this information.",
        "Lower Privacy Level",
        () => navigate("/settings/organization"),
        "",
        "Change Privacy Level"
      );
  };

  return (
    <>
      {unmapped.length > 0 && (
        <WarningMessage
          title={`${numUsers} Unmapped Integration User`}
          message={
            "Hi there! We have found some unmapped integration users. Please help us map your employees to your integrated apps so we can gather better metrics and you can receive better insights."
          }
          linkText="Visit the employee mapper tool now"
          actionText="Employee Mapper"
          onNavigate={goToMapper}
        />
      )}
    </>
  );
}
