import React from 'react';
import {
    DndContext,
    closestCenter,
    DragOverlay
} from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {InsightsDashboardItem} from './InsightsDashboardItem';
import {none, State, useState} from "@hookstate/core";
import {Dashboard, DashboardQuery} from "model/dashboards";
import {ReplyObj} from "model/chat-session";
import dashboardsService from "services/dashboards";
import {
    GetSensors,
    useDashboardQueriesDrag
} from "./DashboardQueriesDragFunctions";
import {DashboardQueryModalState} from "./manageDashboard/DashboardQueryModal";
import {Employee, Role} from "model/employee";

// /insights/dashboards/{id}
export default function InsightsDashboardDraggableContainer({
                                                                dashboard,
                                                                me,
                                                            }: { dashboard: State<Dashboard>, me: Employee }) {

    // const activeId = useState<string | null>(null);
    // const dashboardQueryListState = useState(dashboard);
    const {
        draggedId,
        dashboardItems,
        HandleDragStart,
        HandleDragOver,
        HandleDragEndWithSave,
        HandleDragCancel
    } = useDashboardQueriesDrag(dashboard)

    const canUpdateDashboard = (me.role === Role.MANAGER && dashboard.ownerId.value === me.id) ||
        (me.role === Role.ADMIN && (dashboard.publicDash.value || dashboard.ownerId.value === me.id));


    function deleteQuery(id?: number) {
        // delete in database
        if (!id) {
            return;
        }
        dashboardsService.deleteDashboardQuery(id)
            .then(() => {
                const index = dashboardItems.findIndex(dashboardQuery => dashboardQuery.id.value === id);
                dashboardItems.merge({[index]: none});
            });
    }


    function editQuery() {
        const editedDashboardQuery = {...DashboardQueryModalState.dashboardQuery.value};
        dashboardsService.saveDashboardQuery(editedDashboardQuery)
            .then(() => {
                const index = dashboardItems.findIndex(dashboardQuery => dashboardQuery.id.value === editedDashboardQuery.id);
                dashboardItems.merge({[index]: editedDashboardQuery});
            });
    }


    const activeQuery = () => {
        return dashboardItems.find(query => query.sortOrder.value + '' === draggedId.value);
    }

    return (
        <DndContext
            sensors={GetSensors()}
            collisionDetection={closestCenter}
            onDragStart={HandleDragStart}
            onDragOver={HandleDragOver}
            onDragCancel={HandleDragCancel}
            onDragEnd={(event) => HandleDragEndWithSave(event, canUpdateDashboard)}>
            <SortableContext
                items={dashboardItems.map(query => '' + query.sortOrder)}
                strategy={verticalListSortingStrategy}>
                <div id={'dashboard-queries-container'} className="flex flex-wrap flex-row">
                    {
                        dashboardItems.map((query) =>
                            <InsightsDashboardItem key={query.id.value}
                                                   id={'' + query.sortOrder}
                                                   isAdminOrOwner={true}
                                                   dashboardQuery={query}
                                                   onResize={(updatedDashboardQuery) => console.log('updated query', updatedDashboardQuery)}
                                                   invisible={query.sortOrder.value + '' === draggedId.value}
                                                   canUpdateDashboard={canUpdateDashboard}
                                                   dashboard={dashboard}
                                                   onDelete={() => deleteQuery(query.id.value)}
                                                   onEdit={() => editQuery()}/>)
                    }
                </div>
            </SortableContext>

            <DragOverlay>{draggedId.value ?
                <InsightsDashboardItem key={activeQuery()!.id.value || "new"}
                                       id={draggedId.value || ""}
                                       isAdminOrOwner={true}
                                       dashboardQuery={activeQuery()!}
                                       invisible={false}
                                       canUpdateDashboard={canUpdateDashboard}
                                       dashboard={dashboard}
                                       onDelete={() => deleteQuery(activeQuery()?.id?.value)}
                                       onEdit={() => editQuery()}/>
                :
                null}
            </DragOverlay>
        </DndContext>
    );
}
