import tw from "tailwind-styled-components";

export const LabelsWrapper = tw.div`
  flex
  flex-wrap
  justify-center
  gap-2
  max-h-[300px]
  overflow-auto
`;

export const LabelWrapper = tw.div`
  flex
  items-center
  justify-center
  flex-wrap
  gap-2
  cursor-pointer
  transition-smooth

  hover:opacity-70
`;

export const LabelIndicator = tw.div`
  w-[50px]
  h-[15px]
`;

export const LabelText = tw.span<{ isHidden?: boolean }>`
  ${({ isHidden }) => `
    text-sm
    transition-smooth
    ${isHidden ? "line-through opacity-70" : ""}
  `}
`;
