import React from "react";
import Heading from "components/generics/Heading";
import * as S from "./styles";
import BulkActionsMenu, {
  BulkActionProps,
} from "components/generics/BulkActionsMenu";

export type SelectedTableRowsMenuProps = {
  pageTitle: string;
  type:
    | "team"
    | "employee"
    | "department"
    | "dashboard"
    | "integration"
    | "location";
  titleIcon?: React.ReactNode;
  titleSize?: "small" | "medium" | "large";
  titleWeight?: "font-bold" | "font-semibold";
  selectedItems: any[];
  bulkActions?: BulkActionProps[];
};

const SelectedTableRowsMenu = ({
  pageTitle,
  type,
  titleIcon,
  titleSize,
  titleWeight,
  selectedItems,
  bulkActions,
}: SelectedTableRowsMenuProps) => {
  const formattedSelectedText = () => {
    return selectedItems.length > 1
      ? `${selectedItems.length} ${type + "s"} selected`
      : `${selectedItems.length} ${type} selected`;
  };

  return (
    <S.Wrapper>
      <S.TitleWrapper>
        <Heading
          text={pageTitle}
          icon={!!titleIcon && titleIcon}
          size={titleSize}
          weight={titleWeight}
        />
        {!!selectedItems.length && (
          <S.SelectedWrapper>
            <S.SelectedItemsNumber>
              {formattedSelectedText()}
            </S.SelectedItemsNumber>
            {!!bulkActions && <BulkActionsMenu actions={bulkActions} />}
          </S.SelectedWrapper>
        )}
      </S.TitleWrapper>
    </S.Wrapper>
  );
};

export default SelectedTableRowsMenu;
