import {DashboardQuery} from "model/dashboards";
import {State, useState} from "@hookstate/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, {useRef} from "react";
import TextField from "components/generics/TextField";
import {DashboardModalState} from "./DashboardModal";

import * as S from "./styles";

import {ReactComponent as DragIcon} from "icons/drag.svg";
import {ReactComponent as CloseIcon} from "icons/close.svg";

export default function DashboardModalQueryComponent({ dq,
                                                       errors,
}: {
  dq: State<DashboardQuery>;
  errors: State<string[]>;
}) {

  const nameRef = useRef<HTMLInputElement>(null);
  const queryRef = useRef<HTMLInputElement>(null);

  const dashboardState = useState(DashboardModalState.dashboard);
  const dashboardQueryState = useState(dq);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: dashboardQueryState.sortOrder.value + "" });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  function deleteQuery(dq: DashboardQuery) {
    dashboardState.dashboardQueries
      .filter(dashq => dashq.query.value === dq.query)
      .forEach(dashq => errors.set(p => p.filter(value => value !== "dq-" + dashq.sortOrder.value)));

    errors.set(p => p.filter(value => value !== "dq-" + dq.sortOrder));

    const newList = dashboardState.dashboardQueries.value
      .filter(d => d.sortOrder !== dq.sortOrder)
      .map(d => {
        return {
          id: d.id ?? undefined,
          sortOrder: d.sortOrder > dq.sortOrder ? d.sortOrder-1 : d.sortOrder,
          name: d.name ?? undefined,
          query: d.query ?? undefined,
          displayWidth: d.displayWidth ?? undefined,
          displayHeight: d.displayHeight ?? undefined,
        } as DashboardQuery
      })

    dashboardState.dashboardQueries.set(newList);
  }

  return (
    <S.QueryContainer>
      <S.QueryComponent key={"dq-" + dashboardQueryState.sortOrder.value}>
        <div
          className={"flex items-center justify-between w-full"}
          ref={setNodeRef}
          style={style}
        >
          <S.DragIconWrapper>
            <DragIcon className={"h-5 w-5 my-2.5"} {...attributes} {...listeners} />
          </S.DragIconWrapper>

          <S.QueryComponentFieldWrapper>
            <S.QueryComponentFieldLabel>
              Query name (Optional)
            </S.QueryComponentFieldLabel>
            <TextField
              ref={nameRef}
              name="name"
              value={
                dashboardQueryState.name.value
                  ? dashboardQueryState.name.value
                  : ""
              }
              onChange={({ target: { value } }) => {
                dashboardQueryState.name.set(value);
              }}
              placeholder="Enter a name"
              border={false}
            />
          </S.QueryComponentFieldWrapper>

          <S.QueryComponentFieldWrapper>
            <S.QueryComponentFieldLabel>
              Query text
            </S.QueryComponentFieldLabel>
            <TextField
              ref={queryRef}
              name="query"
              value={dashboardQueryState.query.value}
              onChange={({ target: { value } }) => {
                dashboardQueryState.query.set(value);
                if (dashboardState.dashboardQueries.filter(dq => dq.query.value === value).length > 1) {
                  errors.merge(["dq-" + dashboardQueryState.sortOrder.value]);
                } else {
                  errors.set(p => p.filter(value => value !== "dq-" + dashboardQueryState.sortOrder.value));
                }
              }}
              placeholder="Enter a query text"
              border={false}
            />
          </S.QueryComponentFieldWrapper>

          <S.ClosseIconWrapper>
            <CloseIcon
              className={"h-5 w-5 my-2.5 cursor-pointer text-pl-primary-red-default"}
              onClick={() => deleteQuery(dashboardQueryState.value)}
            />
          </S.ClosseIconWrapper>
        </div>
      </S.QueryComponent>

      {
        errors.value.filter(obj => obj === "dq-" + dashboardQueryState.sortOrder.value).length > 0 &&
        <S.Error>Query already exists!</S.Error>
      }
    </S.QueryContainer>
  );
}
