export const COLORS: any = {
  LIGHT: "#F0F2F4",
  DARK: "#232e3b",
  CHART_RED: "#FB6C71",
  CHART_GREEN: "#00ACC2",
  CHART_GREEN_ALT: "#81ccd7",
  CHART_LIGHT_GREEN: "#CAEBF0",
  CHART_ORANGE: "#FDCA9B",
  CHART_LIME_GREEN: "#00e900",
};

export const SFCOLORS: any = {
  HIGHLY_ENGAGED: "#00C288",
  ENGAGED: "#00ACC2",
  NEUTRAL: "#FFF3B3",
  DISENGAGED: "#FF8718",
  HIGHLY_DISENGAGED: "#FB6C71"
};

const colorKeysNoWhites = Object.keys(COLORS).filter(
  (color) => color !== "white" && color !== "light"
);
export const colorValuesNoWhites = colorKeysNoWhites.map((key) => COLORS[key]);
