import React, { useEffect } from "react";
import { DatatableProps } from "model/datatable";
import { useLocation } from "react-router-dom";
import DataTable from "./DataTable";
import {useState} from "@hookstate/core";

const URLSortedDatatable = <D extends object>(props: DatatableProps<D>) => {
  const sortDesc =
    useState<boolean | undefined>(undefined);
  const sortColumn =
    useState<string | undefined>(undefined);
  const location = useLocation();
  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      let order = searchParams.get("sort");
      let column = searchParams.get("column");

      if (order === "desc") {
        sortDesc.set(true);
      } else if (order === "asc") {
        sortDesc.set(false);
      }

      if (column) {
        sortColumn.set(column);
      }
    }
  }, [location.search]);

  return (
    <DataTable
      {...props}
      isUrlSorted={true}
      sortColumn={sortColumn.value}
      sortDesc={sortDesc.value}
    />
  );
};

export default URLSortedDatatable;
