import React from "react";
import "chart.js/auto";
import { Chart as ChartJS, LinearScale } from "chart.js";
import { Chart } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import { GenericChartData } from "model/chart";
import { capitalize } from "utils/text";
import { CHART_COLORS, CHART_LIGHT_COLORS } from "utils/color";
import { VennDiagramController, ArcSlice } from "chartjs-chart-venn";

import BlurredContainer from "components/generics/BlurredContainer";

import mockedValues from "./mock";
import * as S from "./styles";

ChartJS.register(VennDiagramController, ArcSlice, LinearScale);

export type VennDiagramProps = {
  report: GenericChartData;
  options?: ChartOptions;
};

const VennDiagram = ({ report, options }: VennDiagramProps) => {
  let hasData = true;

  const getData = () => {
    if (!hasData) {
      return getChartData(mockedValues);
    }
    return getChartData(report);
  };

  const getChartData = (values: GenericChartData) => {
    const datasets = values.datasets.map((item) => {
      return {
        ...item,
        label: capitalize(item.label),
        backgroundColor: CHART_LIGHT_COLORS,
        borderColor: CHART_COLORS,
      };
    });

    return {
      labels: !!values.labels.length
        ? values.labels.map((label) => capitalize(label))
        : [],
      datasets,
    };
  };

  const customOptions = {
    skipNull: true,
    animation: {
      animateScale: true,
      animateRotate: true,
    },

    ...options,
  } as ChartOptions;

  return (
    <S.Wrapper>
      <BlurredContainer blur={!hasData}>
        <Chart type={"venn"} data={getData()} options={customOptions} />
      </BlurredContainer>
    </S.Wrapper>
  );
};

export default VennDiagram;
