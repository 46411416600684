import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  grid
  grid-cols-1
  divide-y
`;

export const EmployeeSection = tw.div`
  py-5
  px-2
  relative
  w-full
  flex
  flex-col
  items-center
  justify-center
  gap-2
`;

export const EmployeeAvatarSection = tw.div<{ $hasBadge: boolean }>`
${({ $hasBadge }) => `
  ${$hasBadge && "w-full flex flex-col items-center justify-top h-20"}
`}
`;

export const BadgeSection = tw.div`
  absolute
  top-[70px]
`;

export const ToolTipContainer = tw.div`
    flex
    flex-col
    group
    relative
    self-end
    items-center
`;

export const LeftToolTipContainer = tw.div`
    flex
    flex-col
    group
    relative
    items-left
`;

export const ToolTip = tw.div`
    w-56
    h-10
    bottom-10
    pointer-events-none
    absolute
    origin-center
    opacity-0
    transition
    group-hover:opacity-100
`;

export const EmployeeName = tw.div`
  font-bold
  text-lg
`;

export const EmployeeAdditionalInfo = tw.div`
  text-sm
  font-normal
  text-pl-grayscale-gray
`;

export const EmployeeEmailInfo = tw.div`
  break-all
`;

export const SkillsSection = tw.div`
  py-5
  relative
  flex
`;

export const DetailsSection = tw.div`
  py-5
  relative
  flex
`;

export const DetailsContent = tw.div`
  w-full
  gap-4
  flex
  flex-col
`;
