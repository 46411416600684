import {FormikValues} from "formik";
import React, {useRef} from "react";
import {EmployeeIntegration, IndexedEmployeeIntegration, Integration} from "model/integration";
import CustomSelect from "components/utils/CustomSelect/CustomSelect";
import TextField from "components/generics/TextField";
import {SelectSearchOption} from "components/utils/SelectSearch";
import {State, useState} from "@hookstate/core";
import Button from "components/generics/Button";

import * as S from "./styles";

import {ReactComponent as PlusIcon} from "icons/plus.svg";
import {ReplyIcon} from "@heroicons/react/solid";
import {ReactComponent as RemoveIcon} from "icons/close.svg";

const BLANK_INTEGRATION_USERNAME_PAIR: EmployeeIntegration = {
  integrationId: 0,
  integrationUsername: "",
};

type Props = {
  integrations: Integration[];
  values: FormikValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

export function EmployeeIntegrationsSection({ integrations,
                                              values,
                                              setFieldValue,
}: Props) {
  const usernameRef = useRef<HTMLInputElement | null>(null);

  const integrationsState = useState<IndexedEmployeeIntegration[]>(() => values.indexedIntegrations);

  const getAllIntegrations = () => {
    return integrations.map((integration) => {
      return {
        label: integration.name,
        value: integration.id,
      } as SelectSearchOption;
    });
  };

  const setField = () => {
    setFieldValue("indexedIntegrations", integrationsState.value);
  }

  function addEmptyLine() {
    setField();
    integrationsState.merge([{
      integrationId: BLANK_INTEGRATION_USERNAME_PAIR.integrationId,
      integrationUsername: BLANK_INTEGRATION_USERNAME_PAIR.integrationUsername,
      markedDeleted: false,
      index: integrationsState.value.length}]
    );
  }

  function validIntegration(): boolean {
    let allValid = true;
    integrationsState.forEach((integration) => {
      if (
        integration.integrationId.value === 0 ||
        integration.integrationUsername.value === ""
      ) {
        allValid = false;
        return false;
      }
    });
    return allValid;
  }

  function noNewEmptyLine(): boolean {
    return (
      integrationsState.filter(
        (integration) => integration.integrationId.value === 0
      ).length === 0
    );
  }

  function deleteIntegration(pair: State<IndexedEmployeeIntegration>) {
    if (pair.markedDeleted.value) {
      pair.markedDeleted.set(false);

    } else {
      if (!(pair.integrationUsername.value ===  BLANK_INTEGRATION_USERNAME_PAIR.integrationUsername &&
        pair.integrationId.value === BLANK_INTEGRATION_USERNAME_PAIR.integrationId)) {
        pair.markedDeleted.set(true);
      }
    }
    setField();
  }


  const renderIntegrationUsernameInputs = () => {
    if (integrationsState.length === 0) {
      addEmptyLine();
    }

    const integrationsComponent = (pair: State<IndexedEmployeeIntegration>) => {
      return (
        <>
          <S.IntegrationLineWrapper key={`integrationUsername-` + pair.index.value}>
            <S.IntegrationFieldWrapper>
              <S.LabelWrapper>
                Integration
              </S.LabelWrapper>
              <S.ElementWrapper>
                <CustomSelect
                  placeholder={"Select"}
                  options={getAllIntegrations()}
                  value={pair.integrationId.get()}
                  onChange={(value) => {
                    if (value.value === "") {
                      pair.integrationId.set(0);
                    } else {
                      pair.integrationId.set(value.value);
                    }
                    setField();
                  }}
                  isDisabled={pair.markedDeleted.value}
                  isClearable
                  isFixed={false}
                  border={false}
                />
                <S.Error/>
              </S.ElementWrapper>
            </S.IntegrationFieldWrapper>

            <S.IntegrationFieldWrapper>
              <S.LabelWrapper>
                Parameter
              </S.LabelWrapper>
              <S.ElementWrapper>
                <TextField
                  ref={usernameRef}
                  name="name"
                  value={pair.integrationUsername.get()}
                  disabled={pair.markedDeleted.value}
                  onChange={({ target: { value } }) => {
                    pair.integrationUsername.set(value);
                    setField();
                  }}
                  border={false}
                />
                <S.Error/>
              </S.ElementWrapper>
            </S.IntegrationFieldWrapper>

            <S.ButtonWrapper>
              <Button
                minimal
                btntype={"neutral"}
                $shadow={false}
                onClick={() => deleteIntegration(pair)}
                icon={pair.markedDeleted.value ? (
                  <ReplyIcon className={"h-5 w-5 text-pl-primary-green-default"} />
                ) : (
                  <RemoveIcon className={"h-5 w-5 text-pl-primary-red-default"} />
                )}
              />
            </S.ButtonWrapper>
          </S.IntegrationLineWrapper>
        </>
      );
    };

    return (
      <>
        {
          integrationsState.map((pair) =>
            integrationsComponent(pair))
        }
      </>
    );
  }

  return (
    <S.FormWrapper>
      <S.FormLineWrapper horizontalDisplay={false} numColumns={1}>
        {
          renderIntegrationUsernameInputs()
        }
      </S.FormLineWrapper>
      <S.MoreWrapper onClick={() => {
        if (validIntegration() && noNewEmptyLine()) {
          addEmptyLine();
        }
      }}>
        <Button
          minimal
          btntype={"primary"}
          $shadow={false}
          iconposition={"left"}
          icon={<PlusIcon />}
        >
          Add another integration
        </Button>
      </S.MoreWrapper>
    </S.FormWrapper>
  );
}