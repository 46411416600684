import React from "react";
import { Employee, Team, AddToTeam } from "model/employee";
import {useLocation, useNavigate} from "react-router-dom";
import TabTitle from "components/structure/TabTitle";
import {ConfirmationModal, openConfirmationModal} from "components/utils/Modal/ConfirmationModal";
import Button from "components/generics/Button";
import EmployeesList from "../employees/EmployeesList";
import {StatusConfirmationModal} from "components/utils/Modal/StatusConfirmationModal";
import EmployeeModal, {openAddEmployeeModal} from "../employeeForm/manageEmployee/ManageEmployee";
import teamService from "services/teamService";
import {LoadingWidget} from "components/utils/LoadingWidget";
import employeeService from "services/employeeService";
import organizationService from "services/organizationService";
import integrationService from "services/integrationService";
import authService from "services/auth";
import privacyLevelService from "services/privacyLevelService";
import {PrivacyLevel} from "model/organization";

import * as S from "./styles";
import {useState} from "@hookstate/core";

const TeamMembers = ({ team }: { team: Team }) => {
  const location = useLocation();
  const id: number | undefined = location.state as number;

  const navigate = useNavigate();

  const privacyLevel = authService.privacyLevel.value as PrivacyLevel;

  const currentTeam =
    useState<Team>(() => teamService.getTeam(team.id, { employees: true, skills: true }, true));

  TabTitle("Team");

  const addToTeam = {
    teamId: team.id,
    managerId: team.manager?.id,
  } as AddToTeam;

  const firstMember = () => {
    return (
      <>
        <EmployeeModal />
        <S.FirstMemberWrapper>
          <S.FirstMemberTitle>
            Add your first team member
          </S.FirstMemberTitle>
          {
            authService.currentUserIsAdmin() ?
              <Button
                btntype="primary"
                $shadow={true}
                onClick={() =>
                  privacyLevelService.hasAccess(privacyLevel) ?
                    Promise.all([
                      employeeService.getManagers(),
                      organizationService.getLocations(),
                      teamService.getTeams(),
                      integrationService.getActiveIntegrations()
                    ]).then(([managers, locations, teams, integrations]) =>
                      openAddEmployeeModal(managers, locations, teams, integrations, addToTeam)
                    )
                    :
                    openConfirmationModal(
                      "Please select a privacy level in your organization settings that allows you to see this information.",
                      "Lower Privacy Level",
                      () => navigate("/settings/organization"),
                      "",
                      "Change Privacy Level"
                    )
                }
              >
                New Team Member
              </Button>
              :
              <S.FirstMemberText>
                Please contact your PL Admin to add team members.
              </S.FirstMemberText>
          }
        </S.FirstMemberWrapper>
      </>
    );
  };

  return (
    <>
      <div className={"mt-4"}>
        <LoadingWidget<Team>
          value={currentTeam}
          builder={team =>
            <>
            {team.employees && team.employees.length > 0 ? (
              <>
                <ConfirmationModal />
                <StatusConfirmationModal/>
                <EmployeesList
                  empList={team.employees as Employee[]}
                  title={team.name + " Members"}
                  addToTeam={addToTeam}
                />
              </>
            ) : (
              firstMember()
            )}
            </>
          }
        />
      </div>
    </>
  );
};

export default React.memo(TeamMembers);
