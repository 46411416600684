import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  w-full
  flex
  flex-col
  justify-center
  items-center
  relative
`;

export const BgWrapper = tw.div`
  h-auto
  w-[800px]
`;

export const ButtonsWrapper = tw.div`
  absolute
  flex
  flex-col
  gap-9
  top-40
  w-[300px]
`;