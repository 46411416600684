import {Dashboard, DashboardQuery} from "model/dashboards";
import {apiDelete, apiFetch, apiPost, apiPut, ResponseStatusAndText} from "./utils";
import {Employee, Role} from "model/employee";
import {da} from "date-fns/locale";
import {QueryResponse, ReportQuery} from "../model/lexi";

class Dashboards {
  getDashboards() {
    return apiFetch<Dashboard[]>(`/api/xhr/insights/dashboards`);
  }

  getDashboardsAllowedToEdit(me: Employee) {
    return apiFetch<Dashboard[]>(`/api/xhr/insights/dashboards`)
      .then(dashboards => {
        if (me.role === Role.MANAGER || me.role === Role.ADMIN) {
          return dashboards.filter(dash => (dash.ownerId === me.id || dash.publicDash));
        } else {
          return [] as Dashboard[];
        }
      });
  }

  getTopDashboards() {
    const num = 5;
    return apiFetch<Dashboard[]>(`/api/xhr/insights/dashboards/topdashboards`, {
      topNumberDash: num,
    });
  }

  getDashboardById(id: number) {
    return apiFetch<Dashboard>(`/api/xhr/insights/dashboards/${id}`);
  }

  saveDashboard(dashboard: Dashboard) {
    if (dashboard.id) {
      return apiPut<Dashboard>(
        `/api/xhr/insights/dashboards/${dashboard.id}`,
        dashboard
      );
    } else {
      return apiPost<Dashboard>(`/api/xhr/insights/dashboards`, dashboard);
    }
  }

  deleteDashboard(id: number) {
    return apiDelete<ResponseStatusAndText>(`/api/xhr/insights/dashboards/${id}`);
  }

  deleteDashboardQuery(id: number) {
    return apiDelete<ResponseStatusAndText>(`/api/xhr/insights/dashboards/query/${id}`);
  }

  saveDashboardQuery(dashboardQuery: DashboardQuery) {
    return apiPut<DashboardQuery>(
      `/api/xhr/insights/dashboards/query/${dashboardQuery.id}`,
      dashboardQuery
    );
  }

  execute(dashboardId: number) {
    return apiFetch<QueryResponse>(`/api/xhr/insights/dashboards/query/${dashboardId}/execute`);
  }
}

export default new Dashboards();
