import React from "react";
import {FormikValues} from "formik";
import {SelectSearchOption} from "components/utils/SelectSearch";
import {Team} from "model/employee";
import MultistringInput from "components/utils/MultistringInput";
import CustomSelect from "components/utils/CustomSelect/CustomSelect";

import * as S from "./styles";

type Props = {
  teams: Team[];
  values: FormikValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  horizontalDisplay?: boolean;
  numColumns?: number;
};

export function TeamsSkillsSection({ teams,
                              values,
                              setFieldValue,
                              horizontalDisplay = false,
                              numColumns = 4,
}: Props) {

  const getAllTeams = () => {
    return teams.map((t) => {
      return {
        label: t.name,
        description: t.name,
        value: t.id,
      } as SelectSearchOption;
    });
  };

  return (
    <>
      <S.FormWrapper horizontalDisplay={horizontalDisplay} numColumns={numColumns} >
        <S.FieldWrapper>
          <S.LabelWrapper>
            Teams
          </S.LabelWrapper>
          <S.MembersWrapper>
            <CustomSelect
              placeholder={"Enter a team name"}
              options={getAllTeams()}
              value={values.teamsSelected}
              onChange={(vals) => {
                setFieldValue("teamsSelected", vals);
              }}
              isFixed={false}
              border={false}
              isMulti={true}
            />
          </S.MembersWrapper>
        </S.FieldWrapper>

        <S.FieldWrapper>
          <S.LabelWrapper>
            Skills (Optional)
          </S.LabelWrapper>
          <MultistringInput
            list={values.skills}
            editMode={true}
            onUpdate={(updatedList) =>
              setFieldValue("skills", updatedList)
            }
            className={"!mt-0 bg-pl-variant-beige"}
          />
        </S.FieldWrapper>

      </S.FormWrapper>
    </>
  );
}