import {Employee} from "model/employee";
import {FormikValues} from "formik";
import {SelectSearchOption} from "components/utils/SelectSearch";
import React from "react";
import MultistringInput from "components/utils/MultistringInput";
import CustomSelect from "components/utils/CustomSelect/CustomSelect";
import privacyLevelService from "services/privacyLevelService";
import auth from "services/auth";
import {PrivacyLevel} from "model/organization";

import * as S from "./styles";

type Props = {
  employees: Employee[];
  values: FormikValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  horizontalDisplay?: boolean;
  numColumns?: number;
};

export function MembersSkillsSection({ employees,
                              values,
                              setFieldValue,
                              horizontalDisplay = false,
                              numColumns = 4,
}: Props) {

  const privacyLevel = auth.privacyLevel.value as PrivacyLevel;

  const getAllEmployees = () => {
    return employees.map((employee) => {
      return {
        label: employee.name,
        description: employee.name,
        value: employee.id,
      } as SelectSearchOption;
    });
  };

  return (
    <>
      <S.FormWrapper horizontalDisplay={horizontalDisplay} numColumns={numColumns} >
        {
          privacyLevelService.hasAccess(privacyLevel) &&
          <S.FieldWrapper>
            <S.LabelWrapper>
              Members
            </S.LabelWrapper>
            <S.MembersWrapper>
              <CustomSelect
                placeholder={"Enter a member name"}
                options={getAllEmployees()}
                value={values.employeesSelected}
                onChange={(emps) => {
                  setFieldValue("employeesSelected", emps);
                }}
                isFixed={false}
                border={false}
                isMulti={true}
              />
            </S.MembersWrapper>
            <S.Error />
          </S.FieldWrapper>
        }

        <S.FieldWrapper>
          <S.LabelWrapper>
            Skills The Team Needs (Optional)
          </S.LabelWrapper>
          <MultistringInput
            list={values.skills}
            editMode={true}
            onUpdate={(updatedList) =>
              setFieldValue("skills", updatedList)
            }
            className={"!mt-0 bg-pl-variant-beige"}
          />
          <S.Error />
        </S.FieldWrapper>

      </S.FormWrapper>
    </>
  );
}