import React from "react";
import {Employee} from "model/employee";
import {WidgetContainer} from "components/utils/WidgetContainer";
import {WidgetTitle} from "components/utils/WidgetTitle";
import {EmployeeAvatar} from "components/utils/Avatar";
import Button from "components/generics/Button";
import EmployeeSkills from "components/ui/EmployeeSkills/EmployeeSkills";
import EmployeeDetailsItem from "components/ui/EmployeeDetails/EmployeeDetailsItem";
import skills from "services/skills";
import {openEditEmployeeModal} from "routes/employeeForm/manageEmployee/ManageEmployee";
import {addErrorToastNotification, addToastNotification} from "components/utils/Toast/ToastNotificationContainer";
import employeeService from "services/employeeService";
import teamService from "services/teamService";
import organizationService from "services/organizationService";
import integrationService from "services/integrationService";
import privacyLevelService from "services/privacyLevelService";
import Badge from "components/generics/Badge";
import SpeechBalloon from "components/generics/SpeechBalloon";
import auth from "services/auth";
import {getEmployeeTenure} from "utils/math";
import {PrivacyLevel} from "model/organization";
import authService from "services/auth";

import * as S from "./styles";

import {ReactComponent as CalendarIcon} from "icons/calendar.svg";
import {ReactComponent as TeamIcon} from "icons/team.svg";
import {ReactComponent as ManageIcon} from "icons/manage.svg";
import {ReactComponent as JobIcon} from "icons/job.svg";
import {ReactComponent as EditIcon} from "icons/edit.svg";
import {useState} from "@hookstate/core";
import {LoadingWidget} from "../../utils/LoadingWidget";

export default function EmployeeDetailsComponent({
                                                   employee,
                                                 }: {
  employee: Employee;
}) {
  const me = auth.me.value as Employee;
  const privacyLevel = auth.privacyLevel.value as PrivacyLevel;

  const allEmployees = useState<Employee[]>(() => employeeService.getAllEmployees());

  return (
    <>
      <WidgetContainer shadow={true} rounded={true} heightAuto={true}>
        <S.Wrapper>
          <S.EmployeeSection>
            {
              authService.currentUserIsAdmin() && privacyLevelService.hasAccess(privacyLevel) &&
              <Button
                className={
                  "!text-pl-grayscale-midgray flex items-center justify-between w-full !absolute top-0 !right-2"
                }
                minimal
                onClick={() =>
                  Promise.all([
                    employeeService.getManagers(),
                    organizationService.getLocations(),
                    teamService.getTeams(),
                    integrationService.getActiveIntegrations()
                  ]).then(([managers, locations, teams, integrations]) =>
                    openEditEmployeeModal(employee, managers, locations, teams, integrations)
                  )
                }
              >
                <EditIcon/>
              </Button>
            }
            <S.EmployeeAvatarSection $hasBadge={!employee.active}>
              <EmployeeAvatar
                size={4}
                employee={employee}
                avatarColor={false}
                linkTo={false}
                active={employee.active}
                showAvatarImage={privacyLevelService.hasAccess(privacyLevel)}
              />
              {
                !employee.active &&
                <S.BadgeSection>
                  <S.ToolTipContainer>
                    {!!employee.endDate &&
                      <S.ToolTip>
                        <SpeechBalloon $arrowSidePosition="center"
                                       $arrowVerticalPosition="bottom"
                                       $bolded={true}
                        >
                          {'Deactivated on ' + employee.endDate}
                        </SpeechBalloon>
                      </S.ToolTip>
                    }
                    <Badge label={"Deactivated"} size="medium" type={"critical"} rounded className={"h-[30px]"}/>
                  </S.ToolTipContainer>
                </S.BadgeSection>
              }
            </S.EmployeeAvatarSection>
            <S.EmployeeName>{employee.name}</S.EmployeeName>
            {employee.jobTitle && (
              <S.EmployeeAdditionalInfo>
                {employee.jobTitle}
              </S.EmployeeAdditionalInfo>
            )}
            <S.EmployeeAdditionalInfo>
              <S.EmployeeEmailInfo>
                {employee.email}
              </S.EmployeeEmailInfo>
            </S.EmployeeAdditionalInfo>
          </S.EmployeeSection>

          <S.SkillsSection>
            {employee.skills ? (
              <EmployeeSkills
                skills={employee.skills.map((sk) => sk.name)}
                onUpdate={(list) =>
                  skills
                    .saveSkills(list, employee.id)
                    .then((result) => addToastNotification(result))
                    .catch((reason) =>
                      addErrorToastNotification(reason as string)
                    )
                }
                canUpdate={authService.currentUserIsAdmin() && privacyLevelService.hasAccess(privacyLevel)}
              />
            ) : (
              <EmployeeSkills
                skills={[]}
                onUpdate={(list) => skills.saveSkills(list, employee.id)}
                canUpdate={authService.currentUserIsAdmin() && privacyLevelService.hasAccess(privacyLevel)}
              />
            )}
          </S.SkillsSection>

          <S.DetailsSection>
            <WidgetContainer
              shadow={false}
              rounded={true}
              textAlign={"text-left"}
              padding={"p-0"}
            >
              <WidgetTitle textSize={"text-lg"} fontWeight={"font-bold"}>
                <S.DetailsContent>
                  Details
                  {
                    privacyLevelService.hasAccess(privacyLevel) ?
                      employee.joinDate ?
                        <S.LeftToolTipContainer>
                          {
                            employee.active ?
                              !!employee.joinDate &&
                              <S.ToolTip className={"w-42 p-2"}>
                                <SpeechBalloon $arrowSidePosition="center"
                                               $arrowVerticalPosition="bottom"
                                               $bolded={true}
                                >
                                  {'Join date: ' + employee.joinDate}
                                </SpeechBalloon>
                              </S.ToolTip>
                              :
                              !!employee.endDate &&
                              <S.ToolTip className={"p-2"}>
                                <SpeechBalloon $arrowSidePosition="center"
                                               $arrowVerticalPosition="bottom"
                                               $bolded={true}
                                >
                                  {'Deactivated on ' + employee.endDate}
                                </SpeechBalloon>
                              </S.ToolTip>
                          }
                          <EmployeeDetailsItem
                            icon={<CalendarIcon width={18} height={18}/>}
                            label={"Tenure:"}
                            value={[getEmployeeTenure(employee.joinDate)]}
                          />
                        </S.LeftToolTipContainer>
                        :
                        <EmployeeDetailsItem
                          icon={<CalendarIcon width={18} height={18}/>}
                          label={"Tenure:"}
                          value={[""]}
                        />
                      :
                      <></>
                  }

                  <EmployeeDetailsItem
                    icon={<TeamIcon width={18} height={18}/>}
                    label={"Current Team:"}
                    value={
                      employee.primaryTeam ? [employee.primaryTeam.name] : [""]
                    }
                    linkTo={
                      employee.primaryTeam &&
                      (
                        auth.currentUserIsAdmin() ||
                        me.id === employee.primaryTeam.manager?.id ||
                        me.id === employee.primaryTeam.department?.manager?.id
                      )
                        ? [`/teams/${employee.primaryTeam.id}`]
                        : null
                    }
                  />

                  {
                    privacyLevelService.hasAccess(privacyLevel) &&
                    <EmployeeDetailsItem
                      icon={<ManageIcon width={18} height={18}/>}
                      label={employee.managedTeams && employee.managedTeams.length > 0
                        ?
                        "Managed Teams:"
                        :
                        "Managed Team:"
                      }
                      value={
                        employee.managedTeams && employee.managedTeams.length > 0
                          ? employee.managedTeams.map(team => team.name)
                          : [""]
                      }
                      linkTo={
                        employee.managedTeams && employee.managedTeams.length > 0
                          ? employee.managedTeams.map(team => `/teams/${team.id}`)
                          : null
                      }
                    />
                  }

                  {
                    privacyLevelService.hasAccess(privacyLevel) &&
                    <LoadingWidget<Employee[]>
                      value={allEmployees}
                      builder={() =>
                        <EmployeeDetailsItem
                          icon={<JobIcon width={18} height={18}/>}
                          label={"Supervisor:"}
                          value={employee.manager ? [employee.manager.name] : [""]}
                          linkTo={
                            employee.manager && allEmployees && allEmployees?.find(e => e.id.value === employee.manager?.id)
                              ? [`/employees/${employee.manager.id}`]
                              : null
                          }
                        />}
                    />
                  }
                </S.DetailsContent>
              </WidgetTitle>
            </WidgetContainer>
          </S.DetailsSection>
        </S.Wrapper>
      </WidgetContainer>
    </>
  );
}
