import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  overflow-hidden
  flex
  flex-col
  gap-3
`;

export const Title = tw.p`
  text-sm
`;

export const ContentWrapper = tw.div`
  flex
  flex-col
  gap-5
`;

export const Label = tw.label`
  text-pl-grayscale-gray
  text-sm
  flex
  gap-1
`;

export const HighlightedLabel = tw.span`
  font-semibold
  text-pl-grayscale-black
`;

export const SelectorWrapper = tw.div`
  flex
  flex-col
  gap-1
`;

export const Selector = tw.div`
  flex
  items-center
  gap-6
`;

export const ActionWrapper = tw.div`
  flex
  items-center
  gap-6
`;
