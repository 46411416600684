import React from "react";
import Button from "components/generics/Button";

import { ReactComponent as ArrowRightIcon } from "icons/arrow-right.svg";
import * as S from "./styles";

export type WarningMessageProps = {
  title: string;
  message: string;
  linkText?: string;
  actionText: string;
  onNavigate: () => void;
};

const WarningMessage = ({
  title,
  message,
  linkText,
  actionText,
  onNavigate,
}: WarningMessageProps) => (
  <S.Wrapper>
    <S.Content className="text-left">
      <S.Title>{title}</S.Title>
      <S.Message>{message}</S.Message>
      <S.Link onClick={onNavigate}>
        {linkText}
        <ArrowRightIcon />
      </S.Link>
    </S.Content>

    <S.ActionWrapper>
      <Button
        size="small"
        btntype="warning"
        className={S.ActionButton}
        onClick={onNavigate}
      >
        {actionText}
      </Button>
    </S.ActionWrapper>
  </S.Wrapper>
);

export default WarningMessage;
