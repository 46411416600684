import React from "react";
import * as S from "./styles";

export type BlurredContainerProps = {
  blur?: boolean;
  size?: 'sm' | 'md' | 'lg'
  children: React.ReactNode;
};

const BlurredContainer = ({
  blur = false,
  size = 'sm',
  children,
}: BlurredContainerProps) => (
  <S.Wrapper>
    <S.BlurredArea blur={blur ? 1 : 0}>
      {children}
    </S.BlurredArea>
    
    {blur && 
      <S.Label size={size}>
        Pending Data...
      </S.Label>}
  </S.Wrapper>
);

export default BlurredContainer;
