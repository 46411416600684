import tw from "tailwind-styled-components";

export const Wrapper = tw.div``;

export const ImportResultWrapper = tw.div`
  w-full
`;

export const WidgetWrapper = tw.div`
  h-full
  mt-6
  rounded-2xl
  bg-pl-grayscale-white
  border
  shadow
`;

export const WidgetHeader = tw.div`
  flex
  justify-between
  py-4
  px-6

  border-b
  border-pl-grayscale-lightgray
`;

export const WidgetTitleWrapper = tw.div`
  flex
  items-center
  w-full
  gap-2
`;

export const WidgetContentWrapper = tw.div`
  w-full
  p-6
  flex
  flex-col
  gap-4
  text-pl-grayscale-gray
`;

export const WidgetContentPreviewWrapper = tw.div`
  overflow-y-hidden
  overflow-x-auto
`;

export const WidgetContentPreview = tw.pre`
  text-pl-grayscale-black
`;

export const DownloadLinkWrapper = tw.div`
  text-pl-grayscale-gray
  py-4
`;

export const DownloadLink = tw.a`
  text-pl-primary-green-default
  hover:underline
`;

export const ActionWrapper = tw.div`
  w-full
  flex
  items-center
  justify-end
`;
