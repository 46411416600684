import tw from "tailwind-styled-components";
import { CheckboxProps } from ".";

type WrapperProps = {
  clickable?: number;
  rounded?: number;
} & Pick<CheckboxProps, "disabled" | "checked" | "lined">;

export const Wrapper = tw.div`
  flex
  items-center
`;

export const Input = tw.input<WrapperProps>`
  ${({ disabled, checked, clickable, rounded }) => `
    relative
    flex
    items-center
    justify-center
    w-4
    h-4
    border
    border-solid
    group
    rounded-[5px]

    ${
      checked && disabled
        ? "checked:!bg-pl-grayscale-midgray checked:!border-pl-grayscale-midgray"
        : checked && !disabled
        ? "checked:!bg-pl-primary-green-default checked:!border-pl-primary-green-default"
        : !checked && disabled
        ? "bg-pl-grayscale-lightgray !border-pl-grayscale-midgray"
        : "border-pl-grayscale-midgray"
    }

    ${rounded? "rounded-[5px]" : "rounded-none"}

    ${disabled || !clickable ? "cursor-default" : "cursor-pointer"}
  `}
`;

export const Label = tw.label<WrapperProps>`
  ${({ lined, disabled, clickable }) => `
    pl-3
    leading-4
    text-sm
    font-base
    text-pl-grayscale-black
    select-none

    ${lined && "line-through"}
    ${disabled || !clickable ? "cursor-default" : "!cursor-pointer"}
  `}
`;
