import tw from "tailwind-styled-components";

type FormWrapperProps = {
  horizontalDisplay: boolean;
  numColumns: number;
}

export const MainWrapper = tw.div`
  grid
  gap-6
`;

export const Wrapper = tw.div`
  bg-pl-grayscale-white
  px-2
  pb-2
  rounded-2xl
  shadow
  h-full
  w-full
`;

export const MainContainer = tw.div`
  grid
  w-full
`;

export const SubMainContainer = tw.div`
  grid
  pt-6
  divide-y
  gap-6
`;

export const WrapperTitle = tw.div`
  w-full
  flex
  items-center
  justify-between
`;

export const Title = tw.div`

`;

export const TitleActions = tw.div`
  px-2
`;

export const WrapperContent = tw.div`
  grid
  grid-cols-8
  py-6
  px-2
  gap-x-4
  gap-y-2
`;

export const FormWrapper = tw.div<FormWrapperProps>`
  ${({ horizontalDisplay, numColumns }) => `
    p-2
    gap-4
    grid

    ${!!horizontalDisplay && numColumns === 4 ? "grid-cols-4" : ""}
    ${!!horizontalDisplay && numColumns === 2 ? "grid-cols-2" : ""}
  `}
`;


export const FieldWrapper = tw.div<{numColumns: number}>`
  ${({ numColumns }) => `
    grid
    gap-1

    ${numColumns === 4 ? "col-span-4" : ""}
    ${numColumns === 3 ? "col-span-3" : ""}
    ${numColumns === 2 ? "col-span-2" : ""}
  `}
`;

export const LabelWrapper = tw.div`
  text-xs
  font-semibold
  text-pl-grayscale-gray
  h-4
`;

export const ElementWrapper = tw.div`
  items-top
`;

export const Error = tw.div`
  text-pl-primary-red-default
  text-xs
  font-semibold
  h-4
  pt-1
  border-none
`;

export const ListWrapper = tw.div`
  rounded-md
  w-full
  grid
  pt-6
  px-2
  pb-2
  divide-y
  gap-6
  bg-pl-grayscale-white
  rounded-2xl
  shadow
`;
