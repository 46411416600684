import {createState, useState} from "@hookstate/core";
import SelectSearch, {SelectSearchOption} from "./SelectSearch";
import React from "react";

export const PeriodSelectorState = createState({
  selectedPeriod: 14
});

export const YEAR_NUM_DAYS = 365;
export const PeriodOptions: SelectSearchOption[] = [
  {
    label:'Last 14 days',
    value: 14
  }, {
    label:'Last 30 days',
    value: 30
  }, {
    label:'Last 60 days',
    value: 60
  }, {
    label:'Last year',
    value: 365
  }
]

export default function PeriodSelector() {
  const selectedPeriodState = useState(PeriodSelectorState.selectedPeriod)
  return <>
    <SelectSearch options={[...PeriodOptions]}
                  initialSelectedOption={PeriodOptions[0]}
                  onSelectChanged={(value) => selectedPeriodState.set(value)}
                  disableSearch={true}
                  display={false}/>
  </>
}
