import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  flex
  flex-col
  pb-6
`;

export const ToggleComponent = tw.span`
  inline-flex
  items-center
  cursor-pointer
`;

export const ToggleWrapper = tw.span`
  relative
`;

export const Toggle = tw.span<{checked: boolean}>`
  ${({ checked}) => `
    block
    w-[34px]
    h-5
    rounded-full

    ${checked ? "bg-pl-primary-green-default" : "bg-pl-primary-green-light"}
  `}
`;

export const ToggleBubble = tw.span<{checked: boolean}>`
  ${({ checked}) => `
    absolute
    block
    w-[14px]
    h-[14px]
    m-[3px]
    rounded-full
    inset-y-0
    left-0
    transition-transform
    duration-300
    ease-in-out
    bg-pl-grayscale-white

    ${checked ? "transform translate-x-[14px]" : ""}
  `}
`;

export const Label = tw.span`
  ml-3
  text-sm
  font-normal
  text-pl-grayscale-black
`;
