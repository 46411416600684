import React from "react";
import {NetworkGraphProps} from "model/graph";
import {DoubleLoadingWidget, LoadingWidget} from "components/utils/LoadingWidget";
import GenericCard from "components/generics/GenericCard";
import NetworkGraph from "components/ui/NetworkGraph";
import Avatar from "components/utils/Avatar";
import RangeAndTrendChart from "components/ui/Charts/RangeAndTrendChart";
import {ReactComponent as TeamIcon} from "icons/team.svg";
import {DailyStayFactor} from "model/stayFactor";
import {Employee, Team} from "model/employee";
import {Department} from "model/department";

import * as S from "./styles";
import {State, StateMethods} from "@hookstate/core";

export type BaseDashboardProps = {
  data?: any;
  title: string;
  networkGraphContext: "teams" | "employees" | "departments";
  networkGraphData: State<NetworkGraphProps>;
  stayFactorData?: State<any[]>;
  stayFactorComponentsData?: State<DailyStayFactor[]>;
  employees: State<Employee[]>;
  teams?: State<Team[]>;
  departments?: State<Department[]>;
  pieChartAsFirstPage?: boolean;
  pieChartWithViewOptions?: boolean;
  rightChildren: React.ReactNode;
  leftChildren: React.ReactNode;
};

const BaseDashboard = ({
                         data,
                         title,
                         networkGraphContext,
                         networkGraphData,
                         stayFactorData,
                         stayFactorComponentsData,
                         employees,
                         teams,
                         departments,
                         pieChartAsFirstPage = true,
                         pieChartWithViewOptions = false,
                         rightChildren,
                         leftChildren,
                       }: BaseDashboardProps) => {
  return (
    <S.Wrapper>
      <S.MainContainer>
        <S.LeftContainer>
          <S.LeftContainerItem>
            <S.NetworkGraphContainer>
              <LoadingWidget<NetworkGraphProps>
                value={networkGraphData}
                builder={(response) => (
                  <GenericCard
                    title={`${!!data ? `${data.name} ${title}` : title}`}
                    icon={
                      <>
                        {!!data ? (
                          <Avatar
                            name={
                              data.name.split(" ").length === 1
                                ? `${data.name} ${title}`
                                : data.name
                            }
                            size={1}
                          />
                        ) : (
                          <TeamIcon/>
                        )}
                      </>
                    }
                    badgeText={
                      !!data
                        ? data.active
                          ? undefined
                          : "Deactivated"
                        : undefined
                    }
                  >
                    <NetworkGraph
                      report={response.report}
                      context={networkGraphContext}
                    />
                  </GenericCard>
                )}
              />
            </S.NetworkGraphContainer>
          </S.LeftContainerItem>

          <S.LeftContainerItem>{leftChildren}</S.LeftContainerItem>
        </S.LeftContainer>

        <S.RightContainer>
          <S.RightContainerItem>

            <LoadingWidget<Employee[]>
              value={employees}
              builder={(employeesList) =>
                stayFactorComponentsData ?
                  <DoubleLoadingWidget<any[], DailyStayFactor[]>
                    values={[stayFactorData, stayFactorComponentsData]}
                    builder={(stayFactorTrend, currentSFComponents) => (
                      <RangeAndTrendChart
                        trendValues={stayFactorTrend as number[]}
                        title={"StayFactor™"}
                        numPages={2}
                        currentSFComponents={currentSFComponents}
                        employees={employeesList as Employee[]}
                        pieChartAsFirstPage={pieChartAsFirstPage}
                        pieChartWithViewOptions={pieChartWithViewOptions}
                      />
                    )}
                  />
                  :
                  stayFactorData ?
                    <LoadingWidget<any[]>
                      value={stayFactorData}
                      builder={(rangeTrend) => (
                        <RangeAndTrendChart
                          trendValues={rangeTrend}
                          title={"StayFactor™"}
                          employees={employeesList as Employee[]}
                          pieChartAsFirstPage={pieChartAsFirstPage}
                          pieChartWithViewOptions={pieChartWithViewOptions}
                        />
                      )}
                    />
                    :
                    <DoubleLoadingWidget<Team[], Department[]>
                      values={[teams, departments]}
                      builder={(teamsList, departmentsList) =>
                        <RangeAndTrendChart
                          title={"StayFactor™"}
                          employees={employeesList as Employee[]}
                          teams={teamsList}
                          departments={departmentsList}
                          pieChartAsFirstPage={pieChartAsFirstPage}
                          pieChartWithViewOptions={pieChartWithViewOptions}
                        />}
                    />

              }
            />
          </S.RightContainerItem>
          <S.RightContainerItem>{rightChildren}</S.RightContainerItem>
        </S.RightContainer>
      </S.MainContainer>
    </S.Wrapper>
  );
};

export default BaseDashboard;
