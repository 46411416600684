import {Employee, FilterStatus, Team, TeamFormData} from "model/employee";
import getOrSet from '../utils/localCache';
import multiDebounce from '../utils/multicalldebounce';
import {apiDelete, apiFetch, apiPost, apiPut} from './utils';
import lexi from "./lexi";
import {objKeysAsList} from "../utils/url";
import {Department} from "../model/department";

type TeamProjections = {
  employees?: boolean,
  skills?: boolean
}

class TeamService {

  private getProjections(projections?: TeamProjections) {
    return projections
      ? {projections: objKeysAsList(projections)}
      : undefined;
  }

  getTeams(projections?: TeamProjections) {
    const query = this.getProjections(projections);
    return apiFetch<Team[]>(`/api/xhr/teams`, query, {cache: 'no-cache'});
  }

  getActiveTeams() {
    return apiFetch<Team[]>(`/api/xhr/teams`, {teamStatus: FilterStatus.ACTIVE});
  }

  getActiveTeamsWithNoDepartment(department?: Department) {
    return apiFetch<Team[]>(`/api/xhr/teams`, {teamStatus: FilterStatus.ACTIVE})
      .then(teams => teams.filter(team => (team.department === undefined || (department && team.department?.id === department.id))));
  }

  getTeam(id: number, projections?: TeamProjections, excludeCache?: boolean) {
    const query = this.getProjections(projections);
    if (excludeCache) {
      return apiFetch<Team>(`/api/xhr/teams/${id}`, query, {cache: 'no-cache'});
    } else {
      return apiFetch<Team>(`/api/xhr/teams/${id}`, query);
    }
  }

  getAllTeamTypes() {
    return apiFetch<string[]>(`/api/xhr/teams/teamTypes`);
  }

  getActivitiesByTeam(id: number): Promise<number[]> {
    return getOrSet("teams-activity", id, () =>
      multiDebounce<number, any[]>("teams-activity", id, this.createTeamsActivityObject, this.createTeamActivityObject, {
        rejectUnresolved: false,
        defaultValue: [0]
      })
    ) as Promise<number[]>;
  }

  async createTeamActivityObject(id: number) {
    const numDays = 30;
    return lexi.getQuery('daily')
      .start.daysAgo(numDays)
      .collection("teams")
      .forTeam(id)
      .count("EVENT_COUNT")
      .fetchAsSingleSeries();
  }

  async createTeamsActivityObject(ids: number[]) {
    const numDays = 30;
    return lexi.getQuery('daily')
      .start.daysAgo(numDays)
      .collection("teams")
      .forTeams(ids)
      .count("EVENT_COUNT")
      .fetchAsSeriesMap(series => series.print["team"].id);
  }

  saveTeam(teamFormData: TeamFormData) {
    if (teamFormData.id === 0) {
      return apiPost<Team>(`/api/xhr/teams`, teamFormData);
    } else {
      return apiPut<Team>(`/api/xhr/teams/${teamFormData.id}`, teamFormData);
    }
  }

  updateTeamStatus(id: number, enable: boolean) {
    return apiPut<Team>(`/api/xhr/teams/${id}/status`, enable);
  }

  updateMultipleTeamsStatus(ids: number[], enable: boolean) {
    if (enable) {
      return apiPut<Team>(`/api/xhr/teams/status/activate`, ids);
    } else {
      return apiPut<Team>(`/api/xhr/teams/status/deactivate`, ids);
    }
  }

  deleteMultipleTeam(ids: number[]) {
    const queryParams = ids.map(id => `ids=${id}`).join('&');
    const url = `/api/xhr/teams?${queryParams}`;
    return apiDelete<Team>(url);
  }
}

export default new TeamService();
