import tw from "tailwind-styled-components";

export const MainWrapper = tw.div`
  grid
  gap-6
`;

export const Wrapper = tw.div`
  bg-pl-grayscale-white
  px-2
  pb-2
  rounded-2xl
  shadow
  h-full
  w-full
`;

export const MainContainer = tw.div`
  grid
  w-full
`;

export const SubMainContainer = tw.div`
  grid
  pt-6
  divide-y
  gap-6
`;

export const WrapperTitle = tw.div`
  w-full
  flex
  items-center
  justify-between
`;

export const TitleActions = tw.div`
  px-2
`;

export const WrapperContent = tw.div`
  pt-6
  px-2
  gap-x-4
  gap-y-2
`;

export const WrapperSelectContent = tw.div`
  grid
  gap-4
  pb-4
`;

export const WrapperSelect = tw.div`
  flex
  items-center
  justify-between
  gap-4
  pb-4
`;

export const WrapperContentMessage = tw.div`
  text-sm
  text-pl-grayscale-black
  flex
  gap-1
`;

export const ToggleWrapper = tw.div`
  flex
  flex-col
  sm:flex-row
  w-full
  items-center
`;

export const BlackParagraph = tw.p`
  text-pl-grayscale-black
`;