export enum ToastMessageType {
    SUCCESS='SUCCESS',
    ERROR='ERROR'
}

export enum ToastMessageStatus {
    OPENED,
    COUNTDOWN_START,
    CLOSED

}
export type ToastMessage = {
    type: ToastMessageType,
    message: string,
    status: ToastMessageStatus
}