import tw from "tailwind-styled-components";

export const SFColors = {
  HighlyEngaged: `
    bg-[#EFFAF2]
    text-[#00C288]
  `,

  Engaged: `
    bg-[#EFF9FB]
    text-[#00ACC2]
  `,

  Neutral: `
    bg-[#FFF3B3]
    text-[#131A21]
  `,

  Disengaged: `
    bg-[#FFF4EB]
    text-[#FF8718]
  `,

  HighlyDisengaged: `
    bg-[#FFF3F1]
    text-[#FB6C71]
  `,
};

export const Wrapper = tw.div`
  gap-4
  grid
  pb-4
`;

export const CategoryWrapper = tw.div`
  h-[120px]
  p-2
  mx-2
  rounded-2xl
  grid
  text-center
`;

export const CategoryTitleWrapper = tw.div`
  flex
  items-center
  justify-center
  gap-2
  text-[22px]
  font-bold
`;

export const CategoryIconWrapper = tw.div`
  rounded-full
  h-5
  w-5
  flex
  items-center
  justify-center
  text-white
`;

export const CategoryTextWrapper = tw.div`

`;

export const CategoryValueWrapper = tw.div`
  text-[24px]
  font-bold
  h-8
`;