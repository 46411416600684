import {Downgraded, State, StateMethods, useState} from "@hookstate/core";
import React, {useEffect} from "react";
import {Builder, DoubleBuilder, Generator, TripleBuilder} from "./types";
import Spinner, {SpinnerProps} from "components/utils/Spinner";
import {EmojiSadIcon} from "@heroicons/react/solid";
import * as ld from "lodash";
import hash from "object-hash";

type Item<T> = T | Promise<T> | State<T> | State<T | null> | null | undefined

type Props<T> = {
  value: Item<T>;
  builder: Builder<T>;
  loadingBuilder?: Generator;
  onError?: () => JSX.Element;
} & Pick<SpinnerProps, "size" | "color">;

type DoubleProps<T1, T2> = {
  values: [Item<T1>, Item<T2>];
  builder: DoubleBuilder<T1, T2>;
  loadingBuilder?: Generator;
  onError?: () => JSX.Element;
} & Pick<SpinnerProps, "size" | "color">;

type TripleProps<T1, T2, T3> = {
  values: [Item<T1>, Item<T2>, Item<T3>];
  builder: TripleBuilder<T1, T2, T3>;
  loadingBuilder?: Generator;
  onError?: () => JSX.Element;
} & Pick<SpinnerProps, "size" | "color">;

let spinnerSize: "small" | "medium" | "large";
let spinnerColor: "light" | "dark";

function isState(obj: any): obj is StateMethods<any> {
  return !!obj && obj.get !== undefined;
}

function isPromise(obj: any): obj is Promise<any> {
  return !!obj && obj.then !== undefined;
}

export function asPromise<T>(value: Item<T>): Promise<T> {
  if (isState(value) && value.promised) {
    return value.promise as Promise<T>;
  } else if (isState(value) && value.error) {
    return Promise.reject(value.error);
  } else if (isState(value)) {
    return Promise.resolve(value.get({noproxy: true}) as T);
  } else if (isPromise(value)) {
    return value;
  } else {
    return Promise.resolve(value as T);
  }
}

function LoadingView() {
  return <Spinner size={spinnerSize} color={spinnerColor}/>;
}

function ErrorView() {
  let size: string;

  switch (spinnerSize) {
    case "small":
      size = "h-6 w-6";
      break;

    case "medium":
      size = "h-12 w-12";
      break;

    default:
      size = "h-20 w-20";
      break;
  }

  return (
    <div className="w-full flex justify-center p-4">
      <EmojiSadIcon className={size}/>
    </div>
  );
}

function executeWithState<T>(
  state: State<T>,
  builder: Builder<T>,
  loadingBuilder?: Generator
) {
  try {
    if (state.promised || state.value === undefined || state.value == null) {
      if (loadingBuilder) {
        return loadingBuilder();
      } else {
        return <LoadingView/>;
      }
    }

    if (state.error) {
      return <ErrorView/>;
    }

    const clone = state.get({noproxy: true});
    return builder(clone as T);
  } catch (e) {
    return <ErrorView />;
  }
}

function PromiseWidget<T>({value, builder, onError}: Props<T>) {
  const state = useState(() => value as Promise<T>);
  const firstFetch = useState<boolean>(true);

  useEffect(() => {
    if (!firstFetch.get()) {
      state.set(value as Promise<T>);
    } else {
      firstFetch.set(false);
    }
    // eslint-disable-next-line
  }, [value]);

  try {
    return executeWithState(state, builder);
  } catch (e) {
    if (onError) {
      return onError();
    }
    return <ErrorView/>
  }
}

function GlobalStateWidget<T>({value, builder, loadingBuilder}: Props<T>) {
  const state = useState(value as State<T>);
  return executeWithState(state, builder, loadingBuilder);
}

// the idea here is that we can leverage the same widget for many types of input
// but there are two overloaded functions and we need to be able to tell
// the compiler which one we want to use, this the confusion with teh several
// components
export function LoadingWidget<T>({
                                   value,
                                   builder,
                                   loadingBuilder,
                                   size,
                                   color,
                                   onError
                                 }: Props<T>) {
  spinnerSize = size || "large";
  spinnerColor = color || "dark";

  if (isState(value)) {
    return (
      <GlobalStateWidget
        value={value}
        builder={builder}
        loadingBuilder={loadingBuilder}
      />
    );
  } else if (isPromise(value)) {
    return (
      <PromiseWidget
        value={value}
        builder={builder}
        loadingBuilder={loadingBuilder}
        onError={onError}
      />
    );
  } else if (!!value) {
    return builder(value as T);
  } else {
    return <Spinner size={spinnerSize} color={spinnerColor}/>;
  }
}

export function DoubleLoadingWidget<T1, T2>({
                                              values: [value1, value2],
                                              builder,
                                              ...props
                                            }: DoubleProps<T1, T2>) {

  return <LoadingWidget<[T1, T2]>
    value={Promise.all([asPromise(value1), asPromise(value2)])}
    builder={([v1, v2]) => builder(v1, v2)}
    {...props}
  />
}

