import {Organization, PRIVACY_LABEL_VALUE_PAIR} from "model/organization";
import {WidgetTitle} from "components/utils/WidgetTitle";
import Button from "components/generics/Button";
import organizationService from "services/organizationService";
import React from "react";
import auth from "services/auth";

import * as S from "./styles";
import {useState} from "@hookstate/core";

export function OrganizationPrivacyControls({organization
}: {
  organization: Organization
}) {

  const isSaving = useState(false);
  const privacyLevel = useState(organization.privacyLevel)

  const save = () => {
    isSaving.set(true);
    organizationService.updatePrivacyLevel(privacyLevel.value)
      .then(org => auth.privacyLevel.set(privacyLevel.value))
      .finally(() => isSaving.set(false));
  }

  return (
    <S.MainWrapper>
      <S.Wrapper>
        <S.MainContainer>
          <S.SubMainContainer>
            <S.WrapperTitle>
              <WidgetTitle>Privacy Level</WidgetTitle>
              <S.TitleActions>
                <Button type="button"
                        btntype="primary"
                        $shadow={true}
                        disabled={isSaving.value}
                        className={"w-[90px] h-[38px]"}
                        onClick={save}
                >
                  Save
                </Button>
              </S.TitleActions>
            </S.WrapperTitle>

            <S.WrapperContent>
              <S.PrivacyFieldSetWrapper>
                {PRIVACY_LABEL_VALUE_PAIR.map((pl) => (
                  <S.Label key={pl.value}>
                    {pl.label}

                    <input
                      aria-describedby={`${pl.value}-description`}
                      name="role"
                      type="radio"
                      defaultChecked={pl.value === privacyLevel.value}
                      onClick={() => privacyLevel.set(pl.value)}
                      className={"focus:ring-pl-primary-green-default h-4 w-4 text-pl-primary-green-default border-pl-grayscale-midgray"}
                    />
                  </S.Label>
                ))}
              </S.PrivacyFieldSetWrapper>
            </S.WrapperContent>
          </S.SubMainContainer>
        </S.MainContainer>
      </S.Wrapper>
    </S.MainWrapper>
  );
}