import {hookstate} from "@hookstate/core";
import {LexiReply} from "./lexi";
import {DateTime} from "luxon";

export type Message = {
    id: number,
    sender: 'user' | 'bot',
    time: DateTime,
    text: string,
    interpretText?: boolean,
    botResponse?: LexiReply
}

export type ChatSession = {
    id?: string,
    messages: Message[],
}

export type UserMessage = {
    conversationId?: string,
    message: string,
    mode: 'assistant' | 'reports',
    startNewIfNotFound: boolean
}

export const chatSession = hookstate<ChatSession>(
{
        messages:[
            {
                id: 0,
                text:`Hi there! I'm Lexi! You can ask me questions about your teams and you'll find the answers!`,
                time: DateTime.now(),
                sender: 'bot'
            }
        ]
    }
)

export const sendAsUserMessageWithBotReply = (query: string) => {
    const {messages} = chatSession;
    messages.merge([
            {id:messages.value.length, sender: 'user', time: DateTime.now(), text: query},
            {id:messages.value.length+1, sender: 'bot', time: DateTime.now(), text: query, interpretText: true}
        ]
    )
}

export const updateMessage = (updatedMessage: Message) => {
    const {messages} = chatSession;
    messages.find(messageState => messageState.value.id === updatedMessage.id)?.set(updatedMessage)
}

export type ReplyObj = {
    name: string,
    query: string,
    reply: LexiReply
}