import React from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import FocusAreas from "components/ui/Focusareas";
import KeyMetrics from "components/ui/Keymetrics";
import RecommendationTable from "components/ui/RecommendationTable";
import {LoadingWidget} from "components/utils/LoadingWidget";
import FocusedHours from "components/ui/Focusedhours";
import TabTitle from "components/structure/TabTitle";
import Breadcrumbs from "components/generics/Breadcrumbs";
import RangeAndTrendChart from "components/ui/Charts/RangeAndTrendChart";
import RecommendationShuffledCards from "components/ui/Recommendation/RecommendationShuffledCards";
import EmployeeDetailsComponent from "components/ui/EmployeeDetails";
import EmployeeModal from "routes/employeeForm/manageEmployee/ManageEmployee";
import employeeService from "services/employeeService";
import focusAreaService from "services/focusArea";
import keyMetricsService from "services/keymetrics";
import recommendationsService from "services/recommendations";
import stayfactor from "services/stayfactor";
import {RecommendationFilter} from "model/recommendations";
import {Employee} from "model/employee";
import {KeyMetricsType} from "model/keymetrics";
import {ChartLabelValuePair} from "model/chart";
import {PeriodSelectorState} from "components/utils/PeriodSelector";
import {DailyStayFactor} from "model/stayFactor";

import * as S from "./styles";
import {State, useHookstateEffect, useState} from "@hookstate/core";
import {useDependantState} from "../../utils/state";

export default function EmployeePage() {
  const id = +useParams<{ id: string }>().id!;
  const location = useLocation();
  const action = location.state as string;
  const navigate = useNavigate();

  if (action) {
    navigate("/employees/" + id);
  }

  const selectedPeriod = PeriodSelectorState.selectedPeriod.value;

  const tabTitle = useState<string>("Employee");

  const employeeInfo = useDependantState<Employee>(() => employeeService.getEmployeeInfo(id, {
      teamInfo: true,
      location: true,
      skills: true,
      integrations: true,
    }, true),
    [id]
  );

  useHookstateEffect(() => {
    employeeInfo.promise?.then(ei => ei.value.name).then(tabTitle.set);
  }, [employeeInfo]);

  TabTitle(tabTitle.value);

  const employeeStayFactor = useDependantState<DailyStayFactor[]>(() => stayfactor.getStayFactorComponentsForEmployee(id, selectedPeriod), [id]);
  const employeeKeymetrics = useDependantState<KeyMetricsType>(() => keyMetricsService.getKeyMetricsForEmployee(id, selectedPeriod), [id]);
  const employeeFocusArea = useDependantState<ChartLabelValuePair[]>(() => focusAreaService.getFocusAreaForEmployee(id, selectedPeriod), [id]);
  const employeeFocusedHours = useDependantState<ChartLabelValuePair[]>(() => employeeService.getEmployeeFocusedHours(id), [id]);

  return (
    <>
      <LoadingWidget<Employee>
        value={employeeInfo}
        builder={(employee) => {
          tabTitle.set(employee.name);

          return (
            <>
              <EmployeeModal/>
              <Breadcrumbs
                items={[
                  {label: "Employees", url: `/employees`},
                  {label: employee.name, url: `/employees/${employee.id}`},
                ]}
              />
              <S.Wrapper>
                <S.UserInfo>
                  <EmployeeDetailsComponent employee={employee}/>
                </S.UserInfo>

                <S.Content>
                  <S.TimePeriodContainer>
                    {/* future implementation */}
                  </S.TimePeriodContainer>

                  <S.Metrics>
                    <S.MetricsAndFactors>
                      <LoadingWidget<KeyMetricsType>
                        value={employeeKeymetrics}
                        builder={(metrics) => (
                          <KeyMetrics
                            keyMetricsType={metrics}
                            horizontal={true}
                          />
                        )}
                      />

                      <RecommendationShuffledCards
                        title="High Impacting Factors"
                        fetchData={() =>
                          recommendationsService.getRecommendationsByFilter({
                            pageNumber: 0,
                            pageSize: 3,
                            employeeId: id,
                          } as RecommendationFilter)
                        }
                      />

                      <LoadingWidget<ChartLabelValuePair[]>
                        value={employeeFocusedHours}
                        builder={(data) => (
                          <FocusedHours focusedHoursInfo={data}/>
                        )}
                      />

                      <RecommendationTable
                        title={"Impacting Factors"}
                        loadRecommendations={(
                          size,
                          pageIndex,
                          severityFilters
                        ) => {
                          return recommendationsService.getRecommendationsByFilter(
                            {
                              pageNumber: pageIndex,
                              pageSize: size,
                              employeeId: id,
                              severityList: severityFilters,
                            } as RecommendationFilter
                          );
                        }}
                      />
                    </S.MetricsAndFactors>

                    <S.StayFactorAndMainFocusAreas>
                      <LoadingWidget<DailyStayFactor[]>
                        value={employeeStayFactor}
                        builder={(dsfArray) => {
                          const stayFactorTrend = dsfArray.slice(0, selectedPeriod).map(dsf => dsf.value);
                          const currentSFComponents = dsfArray.slice(0, selectedPeriod);

                          return <RangeAndTrendChart
                            trendValues={stayFactorTrend as number[]}
                            title={"StayFactor™"}
                            numPages={2}
                            currentSFComponents={currentSFComponents as DailyStayFactor[]}
                            employees={[]}
                          />
                        }}
                      />

                      <LoadingWidget<ChartLabelValuePair[]>
                        value={employeeFocusArea}
                        builder={(data) => <FocusAreas eventInfo={data}/>}
                      />
                    </S.StayFactorAndMainFocusAreas>
                  </S.Metrics>
                </S.Content>
              </S.Wrapper>
            </>
          );
        }}
      />
    </>
  );
}
