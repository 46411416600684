import React from "react";
import { useState } from "@hookstate/core";
import { Dashboard, DashboardQuery } from "model/dashboards";
import { WidgetContainer } from "components/utils/WidgetContainer";
import { WidgetTitle } from "components/utils/WidgetTitle";
import InsightsDashboardDraggableContainer from "./InsightsDashboardDraggableContainer";
import { ViewGridIcon } from "@heroicons/react/solid";
import chatService from "services/chat";
import {DashboardModalState, openAddDashboardModal, openEditDashboardModal,} from "./manageDashboard/DashboardModal";
import dashboardsService from "services/dashboards";
import { useNavigate } from "react-router-dom";
import { openConfirmationModal } from "components/utils/Modal/ConfirmationModal";
import Button from "components/generics/Button";
import { ReplyObj } from 'model/chat-session';
import auth from "services/auth";
import {LoadingWidget} from "components/utils/LoadingWidget";
import {Role} from "model/employee";

import {ReactComponent as CopyIcon} from "icons/copy.svg";
import {ReactComponent as EditIcon} from "icons/edit.svg";
import {ReactComponent as TrashIcon} from "icons/trash.svg";

export default function InsightsDashboardMainContainer({
  dashboard,
}: {
  dashboard: Dashboard;
}) {
  const dashboardState = useState<Dashboard>(dashboard);
  const navigate = useNavigate();

  function duplicateDashboard() {
    openAddDashboardModal(dashboardState.value, () => handleDuplicate());
  }

  function handleDuplicate() {
    const duplicatedDashboard = DashboardModalState.dashboard.value;
    dashboardsService.saveDashboard(duplicatedDashboard).then((savedDashboard) =>
      navigate("/insights/dashboards/"+savedDashboard.id)
    );
  }

  function editDashboard() {
    openEditDashboardModal(dashboardState.value,() => handleEdit());
  }

  function handleEdit() {
    const editedDashboard = DashboardModalState.dashboard.value;
    dashboardsService.saveDashboard(editedDashboard).then((savedDashboard) =>
      dashboardState.set((p) => ({ ...savedDashboard }))
    );
  }

  function deleteDashboard() {
    openConfirmationModal(
      "Are you sure you want to delete this dashboard?",
      "Delete Dashboard",
      () => handleDeleteDashboard()
    );
  }

  function updateQuery(dashboardQuery: DashboardQuery) {
    dashboardState.dashboardQueries
      .find((dq) => dq.id.value === dashboardQuery.id)
      ?.set({ ...dashboardQuery });
  }

  function handleDeleteDashboard() {
    dashboardsService.deleteDashboard(dashboard.id).then(() => {
      navigate("/insights/dashboards");
    });
  }

  return (
    <LoadingWidget
      value={auth.me}
      builder={(me) => (
        <>
          {me && (
            <WidgetContainer textAlign={"text-left mt-5"} bgColor={"transparent"} shadow={false}>
              <div className={"w-full px-2 py-4 flex justify-between"}>
                <WidgetTitle>
                  <div className={"flex"}>
                    <ViewGridIcon className={"h-8 w-8 mr-2"}/>
                    {dashboardState.name.value}
                  </div>
                </WidgetTitle>
                {
                  (me.role === Role.MANAGER && dashboard.ownerId === me.id) ||
                  (me.role === Role.ADMIN && (dashboard.publicDash || dashboard.ownerId === me.id)) ?
                    <div className={"flex gap-4"}>
                      <Button
                        className={"!items-start w-[120px]"}
                        icon={<CopyIcon/>}
                        $shadow={false}
                        iconposition="left"
                        onClick={() => duplicateDashboard()}
                      >
                        Duplicate
                      </Button>

                      <Button
                        className={"!items-start w-[120px]"}
                        icon={<EditIcon/>}
                        $shadow={false}
                        iconposition="left"
                        onClick={() => editDashboard()}
                      >
                        Edit
                      </Button>

                      <Button
                        className={"!items-start w-[120px]"}
                        btntype="error"
                        icon={<TrashIcon/>}
                        $shadow={false}
                        iconposition="left"
                        onClick={() => deleteDashboard()}
                      >
                        Delete
                      </Button>
                    </div>
                    :
                    <></>
                }
              </div>
              <InsightsDashboardDraggableContainer
                dashboard={dashboardState}
                me={me}
              />
              {/*<LoadingWidget*/}
              {/*  value={chatResult}*/}
              {/*  builder={(results) => {*/}
              {/*    return (*/}
              {/*      <InsightsDashboardDraggableContainer*/}
              {/*        dashboard={currentDashboard()}*/}
              {/*        chatResults={results}*/}
              {/*        onUpdate={(dq) => updateQuery(dq)}*/}
              {/*      />*/}
              {/*    );*/}
              {/*  }}*/}
              {/*/>*/}
            </WidgetContainer>
          )
          }
        </>
        )}
    />
  );
}
