import tw from "tailwind-styled-components";

export const Wrapper = tw.div<{ $hasSelectedItem: boolean }>`
  ${({ $hasSelectedItem }) => `
    h-[300px]
    relative
    border-r
    border-solid
    pr-5
    overflow-auto
    no-scrollbar
    transition-smooth

    ${
      $hasSelectedItem
        ? `
        min-w-[370px]
        max-w-[370px]
      `
        : `
        min-w-[410px]
        max-w-[410px]
      `
    }
  `}
`;

export const ListWrapper = tw.div<{ $visible: boolean }>`
  ${({ $visible }) => `
    flex
    flex-col
    gap-5
    absolute
    top-0
    p-1
    transition-all
    duration-500

    ${$visible ? "translate-x-0" : "translate-x-[-200%]"}
  `}
`;

export const ConnectedWrapper = tw.div`
  flex
  flex-col
  gap-5
`;

export const UnconnectedWrapper = tw(ConnectedWrapper)`
  border-t
  border-solid
  pt-5
`;

export const InstructionsWrapper = tw.div<{ $visible: boolean }>`
  ${({ $visible }) => `
    flex
    flex-col
    absolute
    p-1
    gap-6
    transition-all
    duration-500

    ${$visible ? "translate-x-0" : "translate-x-[-200%]"}
  `}
`;

export const Title = tw.h4`
  font-semibold
`;

export const InstructionList = tw.div`
  text-sm
  flex
  flex-col
  gap-4
`;

export const Instruction = tw.div`
  flex
  gap-1
  w-full
  py-2
  px-4
  rounded-full
  bg-pl-grayscale-lightgray
  text-pl-grayscale-gray
`;
