import {getBGLightColor, StayFactor} from "utils/stayFactor";
import {LegendItem} from "chart.js";
import {capitalize} from "utils/text";
import {Employee, Team} from "model/employee";
import {Department} from "model/department";

export type SFByEntity = {
  entity: Employee | Team | Department,
  sf: StayFactor
}

export const getLabels = () => {
  return [{
    text: 'Highly Engaged'.padEnd(35, " "),
    fillStyle: getBGLightColor(80),
    strokeStyle: getBGLightColor(80),
  }, {
    text: 'Engaged'.padEnd(35, " "),
    fillStyle: getBGLightColor(65),
    strokeStyle: getBGLightColor(65),
  }, {
    text: 'Neutral'.padEnd(35, " "),
    fillStyle: getBGLightColor(50),
    strokeStyle: getBGLightColor(50),
  }, {
    text: 'Disengaged'.padEnd(35, " "),
    fillStyle: getBGLightColor(35),
    strokeStyle: getBGLightColor(35),
  }, {
    text: 'Highly Disengaged'.padEnd(35, " "),
    fillStyle: getBGLightColor(10),
    strokeStyle: getBGLightColor(10),
  }] as LegendItem[];
}

export const getBorderWidth = (numSlices: number) => {
  if (numSlices == 1) {
    return 0;
  } else if (numSlices > 20 && numSlices < 50) {
    return 2;
  } else if (numSlices >= 50) {
    return 1;
  }
  return 3;
}

export const getSliceLabel = (ids: any[], sfByEntities: SFByEntity[]) => {
  return ids.map((id) => {
    const esf = sfByEntities.find(e => e.entity.id === Number(id)) as SFByEntity;
    return capitalize(esf.entity.name as string) + '|' +
      esf.sf.currentValue + '|' +
      esf.sf.deltaPercentage;
  })
};
