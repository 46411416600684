import React from "react";
import { useState } from "@hookstate/core";
import { useNavigate } from "react-router-dom";
import { sendAsUserMessageWithBotReply } from "model/chat-session";
import { ReactComponent as SendIcon } from "icons/send.svg";
import TextField, { TextFieldProps } from "components/generics/TextField";
import * as S from "./styles";

type ChatInputProps = {
  placeholderText?: string;
  redirectToInsights?: boolean;
  smallHeight?: boolean;
} & Pick<TextFieldProps, "inputsize">;

const ChatInput = ({
  placeholderText = "Start typing to learn more about your team!",
  redirectToInsights = false,
  smallHeight = false,
  inputsize = "medium",
}: ChatInputProps) => {
  const navigate = useNavigate();
  const chatInput = useState("");

  const onSendButtonClicked = () => {
    if (chatInput.value !== "") {
      if (redirectToInsights) {
        navigate("/insights");
      }
      navigate("/insights", { replace: true });
      sendAsUserMessageWithBotReply(chatInput.value);
      chatInput.set("");
    }
  };

  const heightWidthClass = smallHeight ? "h-7 w-7" : "h-10 w-10";

  return (
    <>
      <S.ChatInputWrapper>
        <S.ChatInputContainer>
          <TextField
            placeholder={placeholderText}
            value={chatInput.value}
            rounded
            border={false}
            background={false}
            caret_color={true}
            inputsize={inputsize}
            onChange={(event) => {
              chatInput.set(event.target.value);
            }}
            onKeyUp={(event) => {
              if (event.key === "Enter" && chatInput.value.length > 0) {
                onSendButtonClicked();
              }
            }}
            className={"pr-8"}
          />

          <S.SendButtonWrapper>
            <S.SendButton
              type="button"
              className={`${heightWidthClass} ${
                chatInput.value !== ""
                  ? `text-pl-primary-green-default`
                  : `text-pl-primary-green-light`
              }`}
              onClick={onSendButtonClicked}
            >
              <SendIcon className="w-6" />
            </S.SendButton>
          </S.SendButtonWrapper>
        </S.ChatInputContainer>
      </S.ChatInputWrapper>
    </>
  );
};

export default ChatInput;
