import React from "react";
import { WidgetContainer } from "components/utils/WidgetContainer";
import { WidgetTitle } from "components/utils/WidgetTitle";
import MultistringInput from "components/utils/MultistringInput";
import { useState } from "@hookstate/core";
import Button from "components/generics/Button";

import {ReactComponent as EditIcon} from "icons/edit.svg";

export default function EmployeeSkills({
  skills,
  onUpdate,
  canUpdate = true,
}: {
  skills: string[];
  onUpdate: (updatedList: string[]) => void;
  canUpdate?: boolean;
}) {
  const editModeState = useState<boolean>(false);
  const updatedListState = useState<string[]>(skills);

  return (
    <>
      <WidgetContainer shadow={false} rounded={true} textAlign={"text-left"} padding={"p-0"}>
        <WidgetTitle textSize={"text-lg"} fontWeight={"font-bold"}>
          <div className={"flex items-center justify-between right-0 w-full"}>
            Skills
            <div className={"items-end text-right flex gap-2"}>
              {canUpdate &&
                (
                  editModeState.value ? (
                  <>
                    <Button
                      size="small"
                      btntype="neutral"
                      minimal
                      onClick={() => {
                        editModeState.set(false);
                        updatedListState.set([...skills]);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      btntype="primary"
                      minimal
                      onClick={() => {
                        editModeState.set(false);
                        onUpdate(updatedListState.get());
                      }}
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <Button
                    className={"!text-pl-grayscale-midgray ml-2"}
                    minimal
                    onClick={() => editModeState.set(true)}
                  >
                    <EditIcon />
                  </Button>
                )
                )}
            </div>
          </div>
        </WidgetTitle>
        <MultistringInput
          list={skills}
          editMode={editModeState.value}
          onUpdate={(updatedList) => updatedListState.set([...updatedList])}
          previousSkills={updatedListState.value}
        />
      </WidgetContainer>
    </>
  );
}
