import {CommonModal} from "components/utils/Modal/CommonModal";
import {Form, Formik, FormikValues} from "formik";
import {Dialog} from "@headlessui/react";
import ModalTextTitle from "components/utils/Modal/ModalTextTitle";
import Button from "components/generics/Button";
import React from "react";
import {createState, useState} from "@hookstate/core";
import {AuthData, FormField, IntegrationForm} from "model/integration";
import CustomSelect from "components/utils/CustomSelect/CustomSelect";
import {SelectSearchOption} from "components/utils/SelectSearch";
import TextField from "components/generics/TextField";
import integrationService from "services/integrationService";
import {
  addErrorToastNotification,
  addToastNotification
} from "components/utils/Toast/ToastNotificationContainer";
import {useNavigate} from "react-router-dom";

import * as S from "./styles";

export const IntegrationAdditionalDataModalState = createState({
  integrationForm: {} as IntegrationForm,
  open: false,
  isEdit: false,
});

export const openAddIntegrationAdditionalDataModal = (
  integrationForm: IntegrationForm
) => {
  IntegrationAdditionalDataModalState.integrationForm.set(integrationForm);
  IntegrationAdditionalDataModalState.open.set(true);
  IntegrationAdditionalDataModalState.isEdit.set(false);
}

export default function AdditionalDataModal() {
  const navigate = useNavigate();

  const modalState = useState(IntegrationAdditionalDataModalState);
  const initialValues = useState(getInitialData());
  const errors = useState<string[]>([]);
  const isSaving = useState(false);

  function getInitialData() {
    let formFields: FormField[] = [];

    if (modalState.open.value) {
      modalState.integrationForm.additionalConnectionData.formFields.map((formField) => {
        formFields.push({
          key: formField.key.value,
          data: "",
          required: formField.required.value ? formField.required.value : false,
        });
      });

      return {
        aggregatorType: modalState.integrationForm.aggregatorType.value,
        name: modalState.integrationForm.name.value,
        apiKey: modalState.integrationForm.apiKey.value,
        apiSecret: modalState.integrationForm.apiSecret.value,
        mode: modalState.integrationForm.mode.value,
        filter: modalState.integrationForm.apiFilter.value,
        resourceId: modalState.integrationForm.resourceId.value,
        formFields: formFields,
      } as AuthData;
    }
    return {} as AuthData;
  }

  function checkErrors(formField: FormField, value: string) {
    if (formField.required && value === "") {
      errors.merge([formField.key]);
    } else {
      errors.set((p) => p.filter((value) => value !== formField.key));
    }
  }

  const getFormFieldKey = (formField: FormField) => {
    return formField.required ? formField.key + " (required)" : formField.key;
  }

  const formFieldOptions = (formField: FormField) => {
    let options: SelectSearchOption[] = [
      { value: "", label: "Select an option" },
    ];

    if (typeof formField.data === "object") {
      const array = formField.data as Map<string, string>[];

      for (const [key, value] of Object.entries(array)) {
        options.push({
          value: key as string,
          label: value as any,
        } as SelectSearchOption);
      }
    }
    return options;
  };

  const disabled = () => {
    return (
      initialValues.formFields.filter(
        (field) => field.required.value && field.value.data === ""
      ).length > 0
    );
  };

  function handleSave() {
    integrationService
      .integrationAuthorize(initialValues.value)
      .then((result) => addToastNotification(result))
      .catch((reason) => addErrorToastNotification(reason as string))
      .finally(() => {
        modalState.open.set(false);
        navigate("/integrations");
      });
  }

  return (
    <>
      <CommonModal openState={modalState.open}>
        <S.Wrapper>
          <Formik
            initialValues={{}}
            validateOnMount={true}
            onSubmit={() => {}}
          >
            {({
                values,
              }) => (
              <Form>
                <S.MainContainer>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-pl-grayscale-black"
                  >
                    <ModalTextTitle title={"Complete the setup process"} />
                  </Dialog.Title>

                  <S.ContentWrapper>
                    <S.ContentTitleWrapper>
                      Enter the requested information below to complete the setup process
                    </S.ContentTitleWrapper>

                    {
                      modalState.integrationForm.additionalConnectionData.formFields.map((formField) => {
                        return (
                          <S.FieldWrapper>
                            <S.LabelWrapper>
                              {getFormFieldKey(formField.value)}
                            </S.LabelWrapper>

                            {
                              typeof formField.value.data === "string" && (
                                <TextField
                                  name={formField.key.value}
                                  border={false}
                                  onChange={(event) => {
                                    initialValues.formFields
                                      .find((ff) => ff.key.value === formField.key.value)
                                      ?.["data"].set(event.target.value);
                                    checkErrors(formField.value, event.target.value);
                                  }}
                                />
                              )
                            }

                            {
                              typeof formField.value.data === "object" && (
                                <CustomSelect
                                  options={formFieldOptions(formField.value)}
                                  border={false}
                                  value={
                                    initialValues.formFields.find(
                                      (ff) => ff.key.value === formField.key.value
                                    )?.value?.data
                                  }
                                  onChange={(opt) => {
                                    initialValues.formFields
                                      .find((ff) => ff.key.value === formField.key.value)
                                      ?.['data'].set(opt.value);
                                    checkErrors(formField.value, opt.value);
                                  }}
                                />
                              )
                            }

                            {
                              formField.help.value &&
                              <S.DescriptionWrapper>{formField.help.value}</S.DescriptionWrapper>
                            }

                            {
                              formField.required &&
                              errors.value.filter((obj) => obj === formField.key.value).length > 0 && (
                                <S.Error>
                                  Field {formField.key.value} is required.
                                </S.Error>
                              )
                            }
                          </S.FieldWrapper>
                        );
                      })
                    }
                  </S.ContentWrapper>

                  <S.ButtonsWrapper>
                    <Button onClick={() => {
                      modalState.open.set(false);
                      isSaving.set(false);
                    }}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      btntype="primary"
                      $shadow={true}
                      disabled={disabled() || isSaving.value}
                      onClick={() => {
                        isSaving.set(true);
                        handleSave();
                      }}
                    >
                      Complete Setup
                    </Button>
                  </S.ButtonsWrapper>
                </S.MainContainer>
              </Form>
            )}
          </Formik>
        </S.Wrapper>
      </CommonModal>
    </>
  );
}