import { DEV_CONFIG } from './dev';
import { LOCAL_CONFIG } from './local';
import { Config } from './model';
import { PROD_CONFIG } from './prod';
import { DOCKER_CONFIG } from './docker';

function getConfig(): Config {
    switch (process.env.REACT_APP_STAGE) {
        case 'prod':
            return PROD_CONFIG;
        case 'dev':
            return DEV_CONFIG;
        case 'docker':
            return DOCKER_CONFIG;
        default:
            return LOCAL_CONFIG;
    }
}
const config = getConfig();
export default config;
