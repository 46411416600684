import tw from "tailwind-styled-components";
import { BlurredContainerProps } from ".";

type WrapperProps = {
  blur: number;
}

const WrapperModifiers = {
  blurred: `
    blur-sm
    pointer-events-none	
  `,
};

export const Wrapper = tw.div`
  w-full
  h-full
  relative
`;

export const BlurredArea = tw.div<WrapperProps>`
  ${({ blur }) => `
  w-full
  h-full

  ${!!blur && WrapperModifiers.blurred}
`}
`;

export const Label = tw.span<Pick<BlurredContainerProps, "size">>`
  ${({size}) => `
    absolute
    top-0
    w-full
    h-full
    text-${size}
    italic
    flex
    items-center
    justify-center
  `}
`;
