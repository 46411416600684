import React from "react";
import { State, useState } from "@hookstate/core";
import { UnmappedIntegrationUser, UnmappedUser } from "model/integration";
import { Employee, EmployeeFormData, Location, Role, Team } from "model/employee";
import {
  getNewEmployeeFromState,
  openNewEmployeeModal,
} from "../newEmployeeModal";
import { validEmail, validEmployeeName } from "utils/text";
import Button from "components/generics/Button";
import { ReactComponent as PlusIcon } from "icons/plus.svg";

type Props = {
  user: State<UnmappedUser>;
  integration: UnmappedIntegrationUser;
  teams: Team[];
  managers: Employee[];
  locations: Location[]
};

const IntegrationsMapperNewEmployee = ({
  user,
  integration,
  teams,
  managers,
  locations,
}: Props) => {
  const userState = useState(user);

  const emptyEmployee = {
    id: null,
    role: Role.EMPLOYEE,
    name: "",
    joinDateStr: new Date().toISOString(),
    email: "",
    jobTitle: "",
    employeeIntegrations: [
      {
        integrationId: integration.sourceId,
        integrationUsername: userState.mapping.value,
      },
    ],
  } as EmployeeFormData;

  function handleSave() {
    const employeeFormData = getNewEmployeeFromState();
    const name = employeeFormData.name;
    const email = employeeFormData.email;
    if (
      name !== "" &&
      validEmployeeName(name) &&
      email !== "" &&
      validEmail(email)
    ) {
      userState.newEmployee.set({ ...employeeFormData });
      userState.previousMappedState.set(userState.mappedState.value);
      userState.mappedState.set("newEmployees");
      userState.mapped.set(true);
    }
  }

  return (
    <Button
      minimal
      btntype="primary"
      icon={<PlusIcon />}
      className="gap-1"
      onClick={() => {
        openNewEmployeeModal(
          emptyEmployee,
          teams,
          managers,
          integration.sourceName,
          locations,
          () => handleSave()
        );
      }}
    >
      Create New
    </Button>
  );
};

export default IntegrationsMapperNewEmployee;
