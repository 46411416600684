import React from "react";
import {
  UserIcon,
  AdjustmentsIcon,
} from "@heroicons/react/outline";
import { NavMenuLinkProps } from "components/structure/Navbar/NavMenu/types";
import {useNavigate} from "react-router-dom";
import {SubmenuProps} from "model/submenu";

import {ReactComponent as SettingsIcon} from "icons/cog.svg";
import {ReactComponent as RecommenderIcon} from "icons/recommender-personalization.svg";
import {ReactComponent as IntegrationsIcon} from "icons/integrations.svg";
import {ReactComponent as ImportIcon} from "icons/import.svg";

export default function useMenu() {
  const menuItems = {
    links: [
      {
        label: "Departments",
        path: "/departments",
      },
      {
        label: "Teams",
        path: "/teams",
      },
      {
        label: "Employees",
        path: "/employees",
      },
    ],
    submenus: [
      {
        label: "Insights",
        items: [] as NavMenuLinkProps[],
      },
    ],
  };

  const settingsItems = [
    {
      label: "Organization Settings",
      description:
        "Start and end time, office locations, and manage privacy controls.",
      path: "/settings/organization",
      icon: <SettingsIcon />,
    },
    {
      label: "Recommender Personalization",
      description: "Adjust activity periods, thresholds, etc.",
      path: "/settings/recommendations",
      icon: <RecommenderIcon />,
    },
    {
      label: "Integrations",
      description:
        "Add/remove integrations to Salesforce, Github, Slack and more.",
      path: "/integrations",
      icon: <IntegrationsIcon />,
    },
    {
      label: "Import Employees and Teams",
      description:
        "Add employees (with teams and integrations) in bulk.",
      path: "/employees/import",
      icon: <ImportIcon />,
    },
  ] as SubmenuProps[];

  const getUserMenuItems = (id: number) => {
    return [
      {
        label: "Your Profile",
        description: "View your own metrics and activity.",
        path: `/employees/${id}`,
        icon: <UserIcon />,
      },
      {
        label: "My Preferences",
        description: "Customize settings like notifications and more.",
        path: `/employees/${id}/preferences`,
        icon: <AdjustmentsIcon />,
      },
    ];
  };

  return { menuItems, settingsItems, getUserMenuItems };
}
