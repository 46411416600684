import tw from "tailwind-styled-components";

type FormWrapperProps = {
  horizontalDisplay: boolean;
  numColumns: number;
}

export const FormWrapper = tw.div<FormWrapperProps>`
  ${({ horizontalDisplay, numColumns }) => `
    p-2
    gap-4
    grid

    ${!!horizontalDisplay && numColumns === 4 ? "grid-cols-4" : ""}
    ${!!horizontalDisplay && numColumns === 3 ? "grid-cols-3" : ""}
    ${!!horizontalDisplay && numColumns === 2 ? "grid-cols-2" : ""}
  `}
`;

export const DoubleFieldWrapper = tw.div<FormWrapperProps>`
  ${({ horizontalDisplay, numColumns }) => `
    gap-4
    grid

    ${!!horizontalDisplay && numColumns === 2 ? "grid-cols-2" : ""}
  `}
`;


export const FieldWrapper = tw.div`
  gap-1
  grid
`;

export const LabelWrapper = tw.div`
  text-xs
  font-semibold
  text-pl-grayscale-gray
  h-4
`;

export const ElementWrapper = tw.div`
  items-top
`;

export const MembersWrapper = tw.div`
  block
  min-h-[55px]
  border
  border-pl-grayscale-lightgray
  rounded-[10px]
  bg-pl-variant-beige
`;

export const Error = tw.div`
  text-pl-primary-red-default
  text-xs
  font-semibold
  h-4
  pt-1
  border-none
`;
