import {State, useState} from '@hookstate/core';
import React, {useEffect} from "react";
import {GraphNode, GraphReport} from "model/lexi";
import NetworkListItem from "../NetworkListItem";

import * as S from "./styles";

export type NetworkListProps = {
    items: State<GraphReport>;
    context: "teams" | "employees" | "departments";
    selected: State<GraphNode | null>;
};

const NetworkList = ({items, context, selected}: NetworkListProps) => {
    const connectedItems = useState<number[]>([])

    useEffect(() => {
        if (selected.value) {
            let connectedItemsTmp = [] as { id: number, sum: number }[];
            const addToItems = (id, value) => {
                const item = connectedItemsTmp.find(ti => ti.id == id);

                if (item) {
                    item.sum += value;
                } else {
                    connectedItemsTmp.push({id, sum: value});
                }
            }

            items.links.forEach((link) => {
                addToItems(link.value.source, link.value.value);
                addToItems(link.value.target, link.value.value);
            });

            const connectedItemsUnique = connectedItemsTmp
                .filter(ti => selected.value?.id != ti.id)
                .sort((a, b) => (a.sum > b.sum) ? -1 : 1)
                .map(ti => ti.id);

            connectedItems.set([selected.value.id, ...connectedItemsUnique]);
        }
    }, [items, selected]);

    const connectedOnly = connectedItems
        .value
        .map(id => items.nodes.find(item => item.value.id == id))
        .filter(i => !!i) as State<GraphNode>[];
    const disconnectedOnly = items.nodes.filter(item => !connectedItems.value.includes(item.value.id))

    return (
        <S.Wrapper $hasSelectedItem={!!selected.value}>
            <S.ListWrapper $visible={!!selected.value}>
                <S.ConnectedWrapper>
                    {connectedOnly.map((item) => (
                        !!item.value &&
                        <NetworkListItem data={item.value} context={context} active={item.value.active}/>
                    ))}
                </S.ConnectedWrapper>

                {!!disconnectedOnly.length && (
                    <S.UnconnectedWrapper>
                        {items.nodes.filter(item => connectedItems.value.includes(item.value.id)).map((item) => (
                            !!item.value &&
                            <NetworkListItem data={item.value} context={context} active={item.value?.active}
                                             grayscale={true}/>
                        ))}
                    </S.UnconnectedWrapper>
                )}
            </S.ListWrapper>

            <S.InstructionsWrapper $visible={!selected.value}>
                <S.Title>How do I understand the Graph?</S.Title>

                <S.InstructionList>
                    <S.Instruction>
                        <strong className="text-pl-primary-green-default">Central</strong>
                        nodes share information and are influencers.
                    </S.Instruction>

                    <S.Instruction>
                        <strong className="text-pl-primary-green-default">Bridge</strong>
                        nodes share information across groups.
                    </S.Instruction>

                    <S.Instruction>
                        <strong className="text-pl-primary-green-default">Outer</strong>
                        nodes are less connected, and require help.
                    </S.Instruction>

                    <S.Instruction>
                        Outer
                        <strong className="text-pl-primary-green-default">rim</strong>
                        on each node shows their StayFactor™.
                    </S.Instruction>
                </S.InstructionList>
            </S.InstructionsWrapper>
        </S.Wrapper>
    );
};

export default NetworkList;
