import {Team} from "model/employee";
import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "@hookstate/core";
import DataTableFilters, {
  Filters,
} from "components/utils/Datatable/DataTableFilters";
import {DatatableColumn, TeamsTableData} from "model/datatable";
import authService from "services/auth";
import {contains} from "utils/text";
import {
  getAvatar,
  getDataTableColumns,
  getSparklineWidget, updateSelectionIfChanged,
} from "components/utils/Datatable/DataTableHelper";
import {EmployeeAvatarGroup} from "components/utils/Avatar";
import WaitForOnScreen from "components/utils/WaitForOnScreen";
import {LoadingWidget} from "components/utils/LoadingWidget";
import teamService from "services/teamService";
import {useBulkSelectTeams} from "hooks/useBulkSelect";
import React from "react";
import URLSortedDataTable from "components/utils/Datatable/URLSortedDataTable";
import TableItemActiveIcon from "components/utils/Datatable/TableItemActiveIcon";
import {
  openStatusConfirmationModal,
  StatusConfirmationModal,
} from "components/utils/Modal/StatusConfirmationModal";
import Button from "components/generics/Button";
import AddTeamModal, {openTeamModal} from "../teamForm/addTeam/AddTeam";
import {AddToDepartment} from "model/department";
import employeeService from "services/employeeService";
import departmentService from "services/departmentService";
import {ConfirmationModal, openConfirmationModal} from "components/utils/Modal/ConfirmationModal";
import stayFactorService from "services/stayfactor";
import privacyLevelService from "services/privacyLevelService";
import {PrivacyLevel} from "model/organization";
import {DailyStayFactor} from "model/stayFactor";
import {PeriodSelectorState} from "components/utils/PeriodSelector";
import {createTrendArray} from "utils/stayFactor";
import * as _ from "lodash";

import * as S from "./styles";

import {ReactComponent as TeamIcon} from "icons/team.svg";

const TeamsList = ({
                     teamList,
                     title,
                     icon,
                     addToDepartment,
                   }: {
  teamList: Team[];
  title: string;
  icon?: JSX.Element;
  addToDepartment?: AddToDepartment;
}) => {
  const navigate = useNavigate();

  const privacyLevel = authService.privacyLevel.value as PrivacyLevel;

  const teamsState = useState<Team[]>(teamList);
  const selectedItems = useState<Team[]>([]);
  const hasClearSelection = useState(false);

  const state = useState({
    info: {
      status: "true",
      searchWord: "",
    } as Filters,
  });

  const location = useLocation();

  const fromCreateTeamToTop = React.useCallback(
    (teamList: Team[]) => {
      const topTeamId = location.state as number;
      if (topTeamId && topTeamId > 0) {
        const filtered = teamList.filter((team) => team.id !== topTeamId);
        const team = teamList.filter((team) => team.id === topTeamId);
        return team.concat(filtered);
      }
      return teamList;
    },
    [location.state]
  );

  const columns: DatatableColumn<TeamsTableData>[] = [
    {
      id: "name",
      accessor: "teamName",
      Header: "Team Name",
      customRenderer: "teamNameRenderer",
    },
    {
      id: "manager",
      accessor: "manager",
      Header: "Manager",
      customRenderer: "managerRenderer",
    },
    {
      id: "active",
      disableSortBy: state.info.status.value !== "all",
      accessor: "active",
      Header: "Active",
      customRenderer: "activeRender",
      sortDescFirst: true,
    },
    {
      id: "stayFactor",
      disableSortBy: true,
      accessor: "stayFactor",
      Header: "StayFactor™",
    },
    {
      id: "teamSize",
      accessor: "teamSize",
      Header: "Team Size",
      customRenderer: "teamSizeRender",
      sortDescFirst: true,
    },
  ];

  const onRowSelection = React.useCallback((value) => {
    updateSelectionIfChanged(value, selectedItems);
  }, []);

  // apply selected filters to the teams list
  function filterTeamData(teamList: Team[]): Team[] {
    return fromCreateTeamToTop(
      teamList
        .filter((team) => {
          if (state.info.searchWord.get().length > 0) {
            return contains(
              team.name.concat(" Team"),
              state.info.searchWord.get()
            );
          }
          return true;
        })
        .filter((team) => {
          if (state.info.status.get() === "true") {
            return team.active;
          } else if (state.info.status.get() === "false") {
            return !team.active;
          }
          return true;
        })
    );
  }

  function getActiveComponent(team: Team) {
    return (
      <S.ActiveComponentWrapper>
        <Button
          className={`hover:border-solid
          hover:rounded-full
          hover:border-4
          ${
            team.active
              ? `hover:border-pl-primary-green-light`
              : `hover:border-pl-primary-red-light`
          }`}
          minimal
          $shadow={false}
          onClick={() =>
            openStatusConfirmationModal(
              team.active
                ? `Are you sure you want to deactivate the ${team.name} team?`
                : `Are you sure you want to activate the ${team.name} team?`,
              team.active ? "Deactivate Team" : "Activate Team",
              team.name,
              "team",
              () => {
                teamService.updateTeamStatus(team.id, !team.active).then(() => {
                  teamsState
                    .find((e) => e.id.value === team.id)
                    ?.active.set(!team.active);
                });
              },
              "Close",
              team.active ? "Deactivate" : "Activate"
            )
          }
        >
          <TableItemActiveIcon active={team.active ? true : false}/>
        </Button>
      </S.ActiveComponentWrapper>
    );
  }

  function getData(teamList: Team[]): any[] {
    let rows: TeamsTableData[] = [];
    teamList.map((team) => {
      const activeEmployees = team.employees ? team.employees.filter(emp => emp.active) : [];
      return rows.push({
        id: team.id,
        teamName: team.name.concat(" Team").toLowerCase().trim(),
        teamNameRenderer: getAvatar(
          team.name.concat(" Team"),
          `/teams/${team.id}`,
          "",
          true,
          "",
          team.active,
          true,
          true
        ),
        manager: team.manager ? team.manager.name.toLowerCase().trim() : "",
        managerRenderer: team.manager ? (
          getAvatar(
            team.manager.name,
            `/employees/${team.manager.id}`,
            team.manager.role,
            true,
            team.manager.avatar,
            team.active,
            true
          )
        ) : (
          <></>
        ),
        active: team.active ? 1 : 0,
        activeRender: getActiveComponent(team),
        stayFactor: <StayFactorSparklineWidget team={team}/>,
        teamSize: activeEmployees.length,
        teamSizeRender: activeEmployees.length > 0 ? (
          <EmployeeAvatarGroup employees={activeEmployees}
                               hoverable={true}
                               showAvatarImage={privacyLevelService.hasAccess(privacyLevel)}
          />
        ) : (
          <></>
        ),
      });
    });
    return rows;
  }

  const StayFactorSparklineWidget = ({team}: { team: Team }) => {
    const employeesStayFactor = useState(() => {
      const promises = (team.employees || []).filter(e => e.active)
        .map(emp => stayFactorService.getStayFactorByEmployee(emp.id, PeriodSelectorState.selectedPeriod.value));
      return Promise.all(promises) as Promise<DailyStayFactor[][]>;
    });

    return (
      <WaitForOnScreen
        builder={() =>
          <LoadingWidget<DailyStayFactor[][]>
            value={employeesStayFactor}
            builder={(esf: DailyStayFactor[][]) => getSparklineWidget(createTrendArray(esf), true, true)}
            size="small"
          />
        }
      />
    );
  };

  const {clearSelection, activateSelectedTeams, deactivateSelectedTeams, deleteSelectedTeams} =
    useBulkSelectTeams();

  const getBulkActions = () => {
    const selectedIds = selectedItems.value.map((t) => t.id);

    return [
      {
        label: "Activate",
        action: () =>
          activateSelectedTeams(selectedIds, teamsState, () =>
            hasClearSelection.set(!hasClearSelection.value)
          ),
      },
      {
        label: "Deactivate",
        action: () =>
          deactivateSelectedTeams(selectedIds, teamsState, () =>
            hasClearSelection.set(!hasClearSelection.value)
          ),
      },
      {
        label: "Delete",
        action: () =>
          deleteSelectedTeams(selectedIds, teamsState, () =>
            hasClearSelection.set(!hasClearSelection.value)
          ),
      },
    ];
  };

  return (
    <>
      <ConfirmationModal/>
      <AddTeamModal/>
      <StatusConfirmationModal/>
      <DataTableFilters
        title={title}
        type={'team'}
        titleIcon={
          icon ? icon : <TeamIcon className={"text-pl-grayscale-gray"}/>
        }
        titleSize={"large"}
        addLabel={authService.currentUserIsAdmin() ? "New Team" : undefined}
        state={state.info}
        selectedItems={selectedItems.value}
        bulkActions={getBulkActions()}
        onAdd={() =>
          privacyLevelService.hasAccess(privacyLevel) ?
            Promise.all([
              employeeService.getManagers(),
              teamService.getAllTeamTypes(),
              employeeService.getActiveEmployees(),
              departmentService.getActiveDepartments(),
            ]).then(([managers, teamTypes, employees, departments]) =>
              openTeamModal(
                managers,
                teamTypes,
                employees,
                departments,
                addToDepartment
              )
            )
            :
            openConfirmationModal(
              "Please select a privacy level in your organization settings that allows you to see this information.",
              "Lower Privacy Level",
              () => navigate("/settings/organization"),
              "",
              "Change Privacy Level"
            )
        }
        resetPagination={() => {
          clearSelection();
          hasClearSelection.set(!hasClearSelection.value);
        }}
      />
      <URLSortedDataTable
        columns={getDataTableColumns(columns)}
        data={getData(filterTeamData(teamsState.value))}
        pageLength={10}
        pageChangeCallback={() => clearSelection()}
        hiddenColumns={authService.currentUserIsAdmin() ? [] : ["actions"]}
        onRowSelection={onRowSelection}
        hasClearSelection={hasClearSelection.value}
      />
    </>
  );
};

export default React.memo(TeamsList);
