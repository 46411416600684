import React from "react";
import {WidgetTitle} from "components/utils/WidgetTitle";
import {WidgetContainer} from "components/utils/WidgetContainer";
import DoughnutChart from "components/ui/Charts/Doughnut/DoughnutChart";
import {ChartLabelValuePair} from "model/chart";
import {getTimeInterval} from "utils/dateFormatter";

import * as S from "./styles";

export default function FocusAreas({
                                     eventInfo,
                                     classname = '',
                                   }: {
  eventInfo: ChartLabelValuePair[];
  classname?: string;
}) {
  const events = eventInfo.reduce((a, b) => a + b.value, 0);

  return (
    <WidgetContainer
      rounded={true}
      shadow={true}
      textAlign={"left"}
      heightAuto
      className={classname}
    >
      <WidgetTitle>
        Main Focus Areas
        <S.SimpleTitle>{getTimeInterval()}</S.SimpleTitle>
      </WidgetTitle>
      <S.Wrapper>
        <S.ChartWrapper>
          <DoughnutChart labelValuePairs={eventInfo} showLegend={false}/>
          {
            events > 0 &&
            <S.ValueLabel>{events}</S.ValueLabel>
          }
        </S.ChartWrapper>
      </S.Wrapper>
    </WidgetContainer>
  );
}
