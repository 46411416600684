import { createState } from "@hookstate/core";
import config from "config";
import { Employee, Me } from "model/employee";
import { apiFetch, apiPost } from "./utils";
import Mixpanel from "services/mixpanel";
import organizationService from "./organizationService";
import {PrivacyLevel} from "model/organization";

const me = createState(null as Employee | null);
const tenantId = createState(null as string | null);
const privacyLevel = createState(null as PrivacyLevel | null);
let resolveToken: ((token: string) => void) | null = null;
let resolveCsrf: ((token: Me["csrf"]) => void) | null = null;
const jwtToken = new Promise<String>((resolve) => {
  resolveToken = resolve;
});
const csrfToken = new Promise<Me["csrf"]>((resolve) => {
  resolveCsrf = resolve;
});

function setMixpanelUser() {
  if (me.get() !== null) {
    const myEmployee = me.get() as Employee;
    Mixpanel.identify(myEmployee.id.toString());
    Mixpanel.people.set({
      '$name': myEmployee.name,
      '$email': myEmployee.email,
      'Sign up date': myEmployee.joinDate ? myEmployee.joinDate : '',
      'UserId': myEmployee.id,
      'Team': myEmployee.primaryTeam ? myEmployee.primaryTeam.name : '',
      'Organization': myEmployee.organizationName,
      'TenantId': tenantId.value
    });
  }
}

function fetchMe() {
  return apiFetch<Me>("/me");
}

async function checkAuth() {
  try {
    const curr = me.get();
    const result = await fetchMe();
    if (result.profile?.id !== curr?.id) {
      me.set(result.profile);
      tenantId.set(result.tenantId);
    }
    (resolveToken as (token: string) => void)(result.token);
    (resolveCsrf as (token: Me["csrf"]) => void)(result.csrf);
    setMixpanelUser();

    const org = await organizationService.getCurrentOrganization();
    if (org.privacyLevel !== privacyLevel.value) {
      privacyLevel.set(org.privacyLevel);
    }
  } catch (e) {
    onAuthRequired();
  }
}

function onAuthRequired() {
  window.location.href = `${config.appUrl}/auth?return=${window.location.pathname}`;
}

export function logout() {
  me.set(null);

  apiPost('/logout', undefined, {credentials: "include"})
    .then(() => window.location.href = "/", e => console.log(e));
}

export function startAuthCheck() {
  checkAuth().then(() => window.addEventListener("focus", checkAuth));
}

class AuthService {
  get me() {
    return me;
  }

  get privacyLevel() {
    return privacyLevel;
  }

  get token() {
    return jwtToken;
  }

  get csrfToken() {
    return csrfToken;
  }

  currentUserIsAdmin() {
    return me.value?.role === 'admin';
  }
}

export default new AuthService();
