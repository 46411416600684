import React from "react";
import { Link } from "react-router-dom";
import { Dashboard } from "model/dashboards";
import { Employee } from "model/employee";
import { WidgetTitle } from "components/utils/WidgetTitle";
import { WidgetContainer } from "components/utils/WidgetContainer";
import { useState } from "@hookstate/core";
import Button from "components/generics/Button";

import * as S from "./styles";

import {ReactComponent as Dots} from "icons/dots.svg";
import {ReactComponent as ArrowLeft} from "icons/arrow-left.svg";
import {ReactComponent as ArrowRight} from "icons/arrow-right.svg";
import {ReactComponent as Lock} from "icons/lock.svg";

export default function DashboardList({
  dashboardList,
}: {
  dashboardList: Dashboard[];
}) {
  const numDashboards = 7;
  const pageNumber = useState<number>(0);

  const totalPages = () => {
    if (dashboardList.length % numDashboards === 0) {
      return dashboardList.length / numDashboards;
    } else {
      return Math.floor(dashboardList.length / numDashboards) + 1;
    }
  };

  return (
    <>
      <WidgetContainer rounded={true} shadow={true}>
        <S.Wrapper>
          <WidgetTitle>Dashboards</WidgetTitle>
          <Button
            className={"!text-pl-grayscale-midgray !ml-2 hidden"}
            minimal
            $shadow={false}
          >
            <Dots/>
          </Button>
        </S.Wrapper>
        <S.WrapperArea>
          <S.List>
            {dashboardList
              .slice(numDashboards * pageNumber.value, numDashboards * pageNumber.value + numDashboards)
              .map((dashboard, index) => (
              <S.ListItem key={index}>
                <S.ListItemLink>
                  <Link
                    to={`/insights/dashboards/${dashboard.id}`}
                    key={`dashboard-button-${dashboard.id}`}
                    className={`truncate h-10 flex items-center justify-left text-md text-pl-primary-green-default`}
                  >
                    <S.ListItemTitle>{dashboard.name}</S.ListItemTitle>
                  </Link>
                </S.ListItemLink>
                {
                  !dashboard.publicDash &&
                  <Lock className="text-pl-grayscale-midgray"/>
                }
              </S.ListItem>
            ))}
          </S.List>
          <S.ArrowsSection>
            <S.ArrowContainer>
              <Button
                className={"!h-10 !w-10 !rounded-full !bg-pl-grayscale-white p-1 flex justify-center"}
                btntype="default"
                minimal
                $shadow={true}
                disabled={pageNumber.value === 0}
                onClick={() => pageNumber.set(pageNumber.value - 1)}
              >
                <ArrowLeft className={`w-4 h-4 ${
                  pageNumber.value === 0
                    ? "text-pl-grayscale-lightgray cursor-default"
                    : ""
                } `} />
              </Button>
            </S.ArrowContainer>
            <S.ArrowContainer>
              <Button
                className={"!h-10 !w-10 !rounded-full !bg-pl-grayscale-white p-1 flex justify-center"}
                btntype="default"
                minimal
                $shadow={true}
                disabled={pageNumber.value === totalPages() - 1}
                onClick={() => pageNumber.set(pageNumber.value + 1)}
              >
                <ArrowRight className={`w-4 h-4 ${
                  pageNumber.value === totalPages() - 1
                    ? "text-pl-grayscale-lightgray cursor-default"
                    : ""
                } `} />
              </Button>
            </S.ArrowContainer>
          </S.ArrowsSection>
        </S.WrapperArea>
      </WidgetContainer>
    </>
  );
}
