import React from "react";
import { LoadingWidget } from "components/utils/LoadingWidget";
import integrationService from "services/integrationService";
import {IntegrationForm} from "model/integration";
import AvailableIntegrationsList from "components/ui/IntegrationsList/AvailableIntegrationsList";
import {useLocation} from "react-router-dom";
import {openAddIntegrationAdditionalDataModal} from "../integrationForm/additionalData/AdditionalData";
import Breadcrumbs from "components/generics/Breadcrumbs";

import * as S from "./styles";

export const InstalledIntegrations = () => {
  const { search } = useLocation();

  let searchParams = new URLSearchParams(search);
  const base64 = searchParams.get("form");

  if (base64 != null) {
    const integrationForm = JSON.parse(
      decodeURIComponent(escape(window.atob(base64 as string)))
    ) as IntegrationForm;
    openAddIntegrationAdditionalDataModal(integrationForm);
  }

  const location = useLocation();
  const tab: string = location.state ? location.state : "all";

  return (
    <>
      <Breadcrumbs
        items={[
          { label: "Integrations", url: `/integrations` },
          { label: "New integration", url: `/integrations/add` },
        ]}
      />
      <S.Wrapper>
        <LoadingWidget
          value={integrationService.getAllIntegrations()}
          builder={(list) => <AvailableIntegrationsList integrations={list} tab={tab} />}
        />
      </S.Wrapper>
    </>
  );
};
