import mixpanel from "mixpanel-browser";
import config from "config";

if (process.env.REACT_APP_STAGE === "local" && !config.mixpanelToken) {
  mixpanel.init("generic_token");
} else {
  mixpanel.init(config.mixpanelToken);
}

class Mixpanel {
  identify(id: string) {
    mixpanel.identify(id);
  }

  alias(id: string) {
    mixpanel.alias(id);
  }

  track(name: string, props: Object) {
    mixpanel.track(name, props);
  }

  people = {
    set: (props: Object) => {
      mixpanel.people.set(props);
    },
  };
}

export default new Mixpanel();
