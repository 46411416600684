import React from "react";
import {useNavigate} from "react-router-dom";
import {Downgraded, useState} from "@hookstate/core";
import {LoadingWidget} from "components/utils/LoadingWidget";
import integrationService from "services/integrationService";
import {UnmappedIntegrationUser} from "model/integration";
import {ViewGridIcon as viewGrid} from "@heroicons/react/outline";
import {ViewGridIcon as viewGridSolid} from "@heroicons/react/solid";
import VerticalTabbedMenu from "components/generics/VerticalTabbedMenu";
import TabTitle from "components/structure/TabTitle";
import Heading from "components/generics/Heading";
import Breadcrumbs from "components/generics/Breadcrumbs";
import teamService from "services/teamService";
import employeeService from "services/employeeService";
import {Employee, Location, Team} from "model/employee";
import MapperReview, {
  filterResultsByMappedState,
} from "./mapperReview";
import NewEmployeeModal from "./newEmployeeModal";

import * as S from "./styles";
import IntegrationsSelector from "./integrationsSelector";
import organizationService from "services/organizationService";

export default function IntegrationsMapper() {
  const navigate = useNavigate();

  type Props = {
    unmapped: UnmappedIntegrationUser[];
    employees: Employee[];
    teams: Team[];
    managers: Employee[];
    locations: Location[];
  };

  const integrationData = useState<[UnmappedIntegrationUser[], Employee[], Team[], Employee[], Location[]]>(
    () => Promise.all([
      integrationService.getUnmappedUsers(),
      employeeService.getActiveEmployees(),
      teamService.getActiveTeams(),
      employeeService.getManagers(),
      organizationService.getLocations(),
    ]));

  function UnmappedUserMapper({unmapped, employees, teams, managers}: Props) {
    const unmappedState = useState({
      info: unmapped.map((entry) => {
        return {
          sourceId: entry.sourceId,
          ignoreExternalUnmapped: entry.ignoreExternalUnmapped,
          sourceType: entry.sourceType,
          sourceName: entry.sourceName,
          unmappedUsers: entry.unmappedUsers.map((user) => {
            return {
              mapping: user.mapping,
              mapped: false,
              mappedState: "unmapped",
              previousMappedState: "unmapped",
            };
          }),
        } as UnmappedIntegrationUser;
      }),
    });

    const subtitle = (numUnmapped: number, numTotalUnmapped: number) => {
      switch (numUnmapped) {
        case 0:
          return "All users mapped";
        case 1:
          if (numUnmapped === numTotalUnmapped) {
            return "1 unmapped user";
          } else {
            return numUnmapped + " of " + numTotalUnmapped + " unmapped users";
          }
        default:
          if (numUnmapped === numTotalUnmapped) {
            return numUnmapped + " unmapped users";
          } else {
            return numUnmapped + " of " + numTotalUnmapped + " unmapped users";
          }
      }
    };

    const tabs = () => {
      return unmapped
        .map((source) => {
          const unmappedIntegrationUserState = unmappedState.info.filter(
            (entry) => entry.sourceId.value === source.sourceId
          )[0];
          const numUnmapped =
            unmappedIntegrationUserState.value.unmappedUsers.filter(
              (users) => !users.mapped
            ).length;
          const numTotalUnmapped = source.unmappedUsers.filter(
            (users) => !users.mapped
          ).length;

          return {
            title: source.sourceName,
            subtitle: subtitle(numUnmapped, numTotalUnmapped),
            builder: () => (
              <IntegrationsSelector
                state={unmappedIntegrationUserState}
                employees={employees}
                teams={teams}
                managers={managers}
                locations={[]}
              />
            ),
          };
        })
        .concat({
          title: "Review Mappings",
          subtitle: "Review your mappings before saving",
          builder: () => (
            <MapperReview state={unmappedState.info} employees={employees}/>
          ),
        });
    };

    const save = (unmappedToStore: UnmappedIntegrationUser[]) => {
      const integrationsWithMappings = filterResultsByMappedState(
        "mappedWithEmployee",
        unmappedToStore
      );
      if (integrationsWithMappings.length > 0) {
        integrationService.saveMappedUsers(integrationsWithMappings);
        navigate("/employees");
      }

      const ignoredUsers = filterResultsByMappedState(
        "ignoredUsers",
        unmappedToStore
      );
      if (ignoredUsers.length > 0) {
        integrationService.saveIgnoredUsers(ignoredUsers);
      }

      const newEmployees = filterResultsByMappedState(
        "newEmployees",
        unmappedToStore
      );
      if (newEmployees.length > 0) {
        integrationService.saveNewEmployees(newEmployees);
      }

      // need to update UI after saves
    };

    TabTitle("Employee Integration Mapper");

    return (
      <VerticalTabbedMenu
        tabs={tabs()}
        showCancel={false}
        showSave={unmapped.length > 0}
        onSave={() => {
          const unmappedToStore: UnmappedIntegrationUser[] = unmappedState
            .attach(Downgraded)
            .get().info;

          save(unmappedToStore);
        }}
      />
    );
  }

  return (
    <>
      <Breadcrumbs
        items={[
          {label: "Home Page", url: `/`},
          {label: "Employee List", url: `/employees`},
          {label: "Employee Integration Mapper", url: `/integrations/mapper`},
        ]}
      />
      <S.Wrapper>
        <S.TitleWrapper>
          <Heading
            text={"Employee Integration Mapper"}
            size={"large"}
            weight={"font-bold"}
          />
        </S.TitleWrapper>
        <LoadingWidget<[UnmappedIntegrationUser[], Employee[], Team[], Employee[], Location[]]>
          value={integrationData}
          builder={([unmapped, employees, teams, managers, locations]) => (
            <>
              <NewEmployeeModal teams={teams} managers={managers} locations={locations}/>
              <UnmappedUserMapper
                unmapped={unmapped}
                employees={employees}
                teams={teams}
                managers={managers}
                locations={locations}
              />
            </>
          )}
        />
      </S.Wrapper>
    </>
  );
}
