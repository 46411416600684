import React from "react";
import { Link } from "react-router-dom";
import Sparkline from "components/ui/Charts/Sparkline";
import Avatar from "../Avatar";
import { BookmarkIcon, StarIcon } from "@heroicons/react/solid";
import { DatatableColumn } from "model/datatable";
import privacyLevelService from "services/privacyLevelService";
import authService from "services/auth";
import {PrivacyLevel} from "model/organization";
import {calculateVariationPercentage} from "utils/stayFactor";

import * as S from "./styles";

import {ReactComponent as ArrowUp} from "icons/arrow-up.svg";
import {ReactComponent as ArrowDown} from "icons/arrow-down.svg";
import * as _ from "lodash";
import {State} from "@hookstate/core";

export type Column = {
  accessor: string;
  Header: string | (() => JSX.Element);
  customRenderer?: string;
};

export function getDataTableColumns<D extends object>(
  header: DatatableColumn<D>[]
) {
  // const columns = header.map(col => {
  //     const definition: any = {
  //       Header: col.Header,
  //       accessor: col.accessor,
  //       customRenderer: col.customRenderer
  //     };
  //
  //     return definition;
  // });
  return header;
}

export function getSparklineWidget(values: number[], hasVariation: boolean, reverse: boolean) {
  const max = Math.max(...values);
  let newValues = values;
  if (max > 100) {
    //scale down to ratio. max is 100
    newValues = values.map((num) => num * (100 / max));
  }

  const currentValue = reverse ? values[0] : values[values.length - 1];
  const oldestValue = reverse ? values[values.length - 1] : values[0];
  const change = calculateVariationPercentage(currentValue, oldestValue);

  return (
    <S.SparklineCell>
      <S.SparklineWrapper hasVariation={hasVariation}>
        <Sparkline values={newValues} max={110} min={-10} fill={false} reverse={reverse} />
      </S.SparklineWrapper>
      {
        hasVariation && values.length > 1 &&
        <>
          <S.Value hasVariation={!!change} score={currentValue}>
            {Math.round(currentValue)}
          </S.Value>
          <S.Change change={change}>
            {change > 0 ? <ArrowUp /> : change < 0 ? <ArrowDown /> : ""}
            {Math.abs(change)}%
          </S.Change>
        </>
      }
    </S.SparklineCell>
  );
}

function getRoleIcon(role: string) {
  if (role === "admin") {
    return (
      <div className="ml-2" title="Admin">
        <StarIcon name={role} className={"h-4 w-4"}/>
      </div>
    );
  } else if (role === "manager") {
    return (
      <div className="ml-2" title="Manager">
        <BookmarkIcon name={role} className={"h-4 w-4 rotate-90"} />
      </div>
    );
  }
}

export function getAvatar(
  name: string,
  linkTo?: string,
  role?: string,
  avatarColor?: boolean,
  url?: string | null,
  active?: boolean,
  hoverable?: boolean,
  fontBold?: boolean,
  grayscale?: boolean,
) {
  const privacyLevel = authService.privacyLevel.value as PrivacyLevel;

  return (
    <>
      {
        <div className="flex items-center">
          <div className="flex-shrink-0 w-10 h-10 flex items-center">
            <Avatar
              size={1}
              name={name}
              linkTo={linkTo}
              avatarColor={avatarColor}
              url={url}
              active={active}
              hoverable={hoverable}
              grayscale={grayscale}
              showAvatarImage={privacyLevelService.hasAccess(privacyLevel)}
            />
          </div>
          <div className="ml-3">
            <Link to={linkTo || "#"}>
              <p className={`${active ? "text-pl-grayscale-black" : "text-pl-grayscale-midgray"} whitespace-no-wrap ${fontBold ? "font-bold" : ""} text-base`}>
                {name}
              </p>
            </Link>
          </div>
          {role && getRoleIcon(role)}
        </div>
      }
    </>
  );
}

export function styleCell(
  active: boolean,
  children: any,
) {
  return (
    <div>
      <p className={`${active ? "text-pl-grayscale-black" : "!text-pl-grayscale-midgray"} whitespace-no-wrap`}>
        {children}
      </p>
    </div>
  );
}

export function updateSelectionIfChanged(value: {id: number}[], selectedItems: State<any[]>) {
  const valueIds = (value ? value.map(e => e.id) : []).sort();
  const selectedItemIds = selectedItems.value.map(e => e.id).sort()
  if (!_.isEqual(valueIds, selectedItemIds)) {
    selectedItems.set(value);
  }
}
