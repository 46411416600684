import React from "react";
import "chart.js/auto";
import "chartjs-adapter-moment";
import { Chart as ChartJS } from "chart.js";
import { MatrixController, MatrixElement } from "chartjs-chart-matrix";
import { Chart } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import { GenericChartData } from "model/chart";
import { capitalize } from "utils/text";
import { CHART_COLORS, CHART_LIGHT_COLORS } from "utils/color";
import BlurredContainer from "components/generics/BlurredContainer";

import { matrixOptions } from "./options";
import mockedValues from "./mock";

import * as S from "./styles";

ChartJS.register(MatrixController, MatrixElement);

export type HeatMapProps = {
  report: GenericChartData;
};

const HeatMap = ({ report }: HeatMapProps) => {
  let hasData = true;

  const getData = () => {
    if (!hasData) {
      return getChartData(mockedValues);
    }
    return getChartData(report);
  };

  const getChartData = (values: GenericChartData) => {
    const datasets = values.datasets.map((item) => {
      return {
        ...item,
        label: capitalize(item.label),
        backgroundColor: CHART_LIGHT_COLORS,
        borderColor: CHART_COLORS,
      };
    });

    return {
      labels: !!values.labels.length
        ? values.labels.map((label) => capitalize(label))
        : [],
      datasets,
    };
  };

  const options = matrixOptions;

  return (
    <S.Wrapper>
      <BlurredContainer blur={!hasData}>
        <Chart
          type="matrix"
          data={getData()}
          options={options as unknown as ChartOptions}
        />
      </BlurredContainer>
    </S.Wrapper>
  );
};

export default React.memo(HeatMap);
