import React from "react";
import { Team } from "model/employee";
import {useLocation, useNavigate} from "react-router-dom";
import TabTitle from "components/structure/TabTitle";
import {ConfirmationModal, openConfirmationModal} from "components/utils/Modal/ConfirmationModal";
import Button from "components/generics/Button";
import {AddToDepartment, Department} from "model/department";
import TeamsList from "../teams/TeamsList";
import AddTeamModal, {openTeamModal} from "../teamForm/addTeam/AddTeam";
import departmentService from "services/departmentService";
import {LoadingWidget} from "components/utils/LoadingWidget";
import {StatusConfirmationModal} from "components/utils/Modal/StatusConfirmationModal";
import employeeService from "services/employeeService";
import teamService from "services/teamService";
import authService from "services/auth";
import {PrivacyLevel} from "model/organization";
import privacyLevelService from "services/privacyLevelService";

import {ReactComponent as DepartmentTeamsIcon} from "icons/team.svg";
import {useState} from "@hookstate/core";

const DepartmentTeams = ({ department }: { department: Department }) => {
  const location = useLocation();
  const id: number | undefined = location.state as number;

  const navigate = useNavigate();

  const privacyLevel = authService.privacyLevel.value as PrivacyLevel;

  const currentDepartment =
    useState<Department>(() => departmentService.getDepartment(department.id, {
      employees: true,
      skills: true,
      teams: true,
      kpis: false
    }, true));

  TabTitle("Department");

  const addToDepartment = {
    departmentId: department.id,
    managerId: department.manager?.id,
  } as AddToDepartment;

  const firstMember = () => {
    return (
      <>
        <AddTeamModal />
        <div className={"mt-20 w-max flex flex-col gap-5 m-auto"}>
          <h2 className="text-pl-grayscale-black text-xl">
            Add your first department team
          </h2>
          <Button
            btntype="primary"
            $shadow={true}
            onClick={() =>
              privacyLevelService.hasAccess(privacyLevel) ?
                Promise.all([
                  employeeService.getManagers(),
                  teamService.getAllTeamTypes(),
                  employeeService.getActiveEmployees(),
                  departmentService.getAllDepartments()
                ]).then(([managers, teamTypes, employees, departments]) =>
                  openTeamModal(managers, teamTypes, employees, departments, addToDepartment)
                )
                :
                openConfirmationModal(
                  "Please select a privacy level in your organization settings that allows you to see this information.",
                  "Lower Privacy Level",
                  () => navigate("/settings/organization"),
                  "",
                  "Change Privacy Level"
                )
            }
          >
            New Team
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={"mt-4"}>
        <LoadingWidget<Department>
          value={currentDepartment}
          builder={department =>
          <>
          {
            department.teams && department.teams.length > 0 ? (
              <>
                <ConfirmationModal />
                <StatusConfirmationModal/>
                <TeamsList
                  teamList={department.teams as Team[]}
                  title={department.name + " Teams"}
                  addToDepartment={addToDepartment}
                  icon={<DepartmentTeamsIcon className={"text-pl-grayscale-gray"} />}
                />
              </>
            ) : (
              firstMember()
            )
          }
          </>
        }
        />
      </div>
    </>
  );
};

export default React.memo(DepartmentTeams);
