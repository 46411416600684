import tw from "tailwind-styled-components";

export const Wrapper = tw.div`
  h-full
  w-full
  mt-6
  flex
  gap-5
`;

export const UserInfo = tw.section`
  min-w-[290px]
  max-w-[290px]
`;

export const Content = tw.section`
  w-full
  flex
  flex-col
  gap-5
`;

export const Metrics = tw.div`
  flex
  gap-5
`;

export const MetricsAndFactors = tw.div`
  w-full
  flex
  flex-col
  gap-5
`;

export const StayFactorAndMainFocusAreas = tw.div`
  min-w-[280px]
  max-w-[280px]
  flex
  flex-col
`;

export const TimePeriodContainer = tw.div`
  hidden
`;
