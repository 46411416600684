import {aggregatorFetch, apiFetch, apiPost, apiPut, ResponseStatusAndText} from "./utils";
import {
  AuthData, HrisSync,
  InstalledIntegrationInfoDto,
  Integration,
  IntegrationFormData,
  UnmappedIntegrationUser
} from "model/integration";
import {FilterStatus} from "../model/employee";
import {objKeysAsList} from "../utils/url";
import lexi from "./lexi";

type IntegrationProjections = {
  unmapped?: boolean,
  formData?: boolean
};


class IntegrationService {

  getHrisEnabled() {
    return aggregatorFetch<Integration[]>(`/api/v1/integrations/hris-enabled`, {}, {cache: 'no-cache'});
  }

  getHrisSync() {
    return apiFetch<HrisSync>(`/api/xhr/integrations/hris-sync`, {}, {cache: 'no-cache'});
  }

  saveHrisSync(hrisSync: HrisSync) {
    return apiPost<boolean>(`/api/xhr/integrations/hris-sync`, hrisSync);
  }

  getActiveIntegrations() {
    return apiFetch<Integration[]>(`/api/xhr/integrations`, {
      integrationStatus: FilterStatus.ACTIVE
    }).catch(() => []);
  }

  getInstalledIntegrations(projections?: IntegrationProjections) {
    const query = projections
      ? { projections: objKeysAsList(projections) }
      : undefined;

    return apiFetch<Integration[]>(`/api/xhr/integrations`, query, {cache: "no-cache"});
  }

  getUnmappedUsers() {
    return apiFetch<UnmappedIntegrationUser[]>(
      `/api/xhr/integrations/unmapped/users`
    );
  }

  saveMappedUsers(unmappedUsers: UnmappedIntegrationUser[]) {
    return apiPost<UnmappedIntegrationUser[]>(
      `/api/xhr/integrations/with-mapping`,
      unmappedUsers
    );
  }

  saveIgnoredUsers(unmappedUsers: UnmappedIntegrationUser[]) {
    return apiPost<UnmappedIntegrationUser[]>(
      `/api/xhr/integrations/ignore-user`,
      unmappedUsers
    );
  }

  saveNewEmployees(unmappedUsers: UnmappedIntegrationUser[]) {
    return apiPost<ResponseStatusAndText>(
      `/api/xhr/employees/new-mapping`,
      unmappedUsers
    );
  }

  updateIntegrationStatus(id: number, enable: boolean) {
    return apiPut<Integration>(`/api/xhr/integrations/${id}/status`, enable);
  }


  updateMultipleIntegrationsStatus(ids: number[], enable: boolean) {
    if (enable) {
      return apiPut<Integration>(`/api/xhr/integrations/status/activate`, ids);
    } else {
      return apiPut<Integration>(`/api/xhr/integrations/status/deactivate`, ids);
    }
  }

  getAllIntegrations() {
    return apiFetch<InstalledIntegrationInfoDto[]>(`/api/xhr/integrations/info`);
  }

  getIntegrationInfoByType(integrationType: string) {
    return apiFetch<InstalledIntegrationInfoDto>(`/api/xhr/integrations/info/by-type/${integrationType}`);
  }

  getIntegrationInfoById(integrationId: number, projections?: IntegrationProjections) {
    const query = projections
      ? { projections: objKeysAsList(projections) }
      : undefined;

    return apiFetch<InstalledIntegrationInfoDto>(`/api/xhr/integrations/info/by-id/${integrationId}`, query);
  }

  saveIntegration(isEdit: boolean, integrationFormData: IntegrationFormData) {
    if (isEdit) {
      return apiPut<ResponseStatusAndText>(`/api/xhr/integrations/${integrationFormData.id}`, integrationFormData);
    } else {
      return apiPost<ResponseStatusAndText>(`/api/xhr/integrations`, integrationFormData);
    }
  }

  testConnection(integrationFormData: IntegrationFormData) {
    return apiPost<boolean>(`/api/xhr/integrations/testConnection`, integrationFormData);
  }

  integrationAuthorize (authData: AuthData) {
    return apiPost<ResponseStatusAndText>(`/integration/${authData.aggregatorType}/authorize`, authData);
  }

  remoteSignup(integrationType: string) {
    return apiFetch<ResponseStatusAndText>(`/api/xhr/integrations/${integrationType}/remote-signup`);
  }

  getActivitiesByIntegration(integration: Integration): Promise<number[]> {
    const numDays = 30;
    return lexi
      .getQuery('daily')
      .count(`${integration.aggregatorType.toUpperCase()}_ACTIVITY`)
      .start.daysAgo(numDays)
      .fetchAsSingleSeries();
  }
}

export default new IntegrationService();
