import React, { useEffect } from "react";
import {Link, useNavigate} from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import auth from "services/auth";
import { EmployeeAvatar } from "components/utils/Avatar";
import { LoadingWidget } from "components/utils/LoadingWidget";
import Spinner from "components/utils/Spinner";
import Logo from "components/generics/Logo";
import CustomerLogo from "components/ui/CustomerLogo";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import AvatarDropdown from "./AvatarDropdown";
import SettingsDropdown from "./SettingsDropdown";
import LexiSearch from "./LexiSearch";
import NavMenu from "./NavMenu";
import useMenu from "hooks/useMenu";
import { NavMenuProps, NavMenuLinkProps } from "./NavMenu/types";
import Greetings from "components/ui/Greetings";
import privacyLevelService from "services/privacyLevelService";
import {ConfirmationModal, openConfirmationModal} from "components/utils/Modal/ConfirmationModal";
import {SubmenuProps} from "model/submenu";
import {PrivacyLevel} from "model/organization";

import * as S from "./styles";

import {ReactComponent as ImportIcon} from "icons/import.svg";
import {useState} from "@hookstate/core";
import {Employee} from "../../../model/employee";

export default function Navbar() {
  const navigate = useNavigate();

  const { menuItems, settingsItems, getUserMenuItems } = useMenu();

  const navMenuItems = useState<NavMenuProps>(menuItems);
  const submenuItems = useState<NavMenuLinkProps[]>(
    menuItems.submenus[0].items
  );

  useEffect(() => {
    navMenuItems.set((prevState) => {
      let tempMenuItems = { ...prevState };
      tempMenuItems.submenus[0].items = [...submenuItems.value];
      return tempMenuItems;
    });
  }, [submenuItems]);

  return (
    <S.Wrapper>
          <Disclosure as="nav">
            {({open}) => (
              <>
                <ConfirmationModal />
                <S.DesktopNavbarWrapper>
                  <S.LogoAndNavMenuWrapper>
                    <S.LogoWrapper>
                      <S.PeoplelogicLogo>
                        <Logo
                          src="/img/pl-logo.png"
                          alt="Peoplelogic.ai"
                          path="/"
                          target="_self"
                        />
                      </S.PeoplelogicLogo>

                      <CustomerLogo/>
                    </S.LogoWrapper>

                    <S.NavMenuWrapper>
                      <NavMenu {...navMenuItems.value} />
                    </S.NavMenuWrapper>
                  </S.LogoAndNavMenuWrapper>

                  <S.LexyAndButtonsWrapper>
                    <S.LexyAndButtonsContent>
                      <S.GreetingsWrapper>
                        <LoadingWidget<Employee>
                          value={auth.me}
                          builder={(me) => (
                            me ? <Greetings name={me.welcomeName}/> : <></>
                          )}
                        />
                      </S.GreetingsWrapper>

                      <S.LexySearchWrapper>
                        <LexiSearch/>
                      </S.LexySearchWrapper>

                      <S.ProfileAndSettingsWrapper>
                        <LoadingWidget<Employee>
                          value={auth.me}
                          builder={(me) => (
                            <>
                              {me ? (
                                <>
                                <LoadingWidget<PrivacyLevel>
                                  value={auth.privacyLevel}
                                  builder={(privacyLevel) => <>
                                    {
                                      auth.currentUserIsAdmin() &&
                                      privacyLevelService.hasAccess(privacyLevel as PrivacyLevel) &&
                                      <SettingsDropdown items={settingsItems}/>
                                    }
                                    {
                                      auth.currentUserIsAdmin() &&
                                      !privacyLevelService.hasAccess(privacyLevel as PrivacyLevel) &&
                                      <SettingsDropdown items={
                                        settingsItems.map(sett =>
                                          sett.label === "Import Employees and Teams" ?
                                            ({
                                              label: "Import Employees and Teams",
                                              description:
                                                "Add employees (with teams and integrations) in bulk.",
                                              icon: <ImportIcon/>,
                                              action: () => {
                                                openConfirmationModal(
                                                  "Please select a privacy level in your organization settings that allows you to see this information.",
                                                  "Lower Privacy Level",
                                                  () => navigate("/settings/organization"),
                                                  "",
                                                  "Change Privacy Level"
                                                )
                                              }
                                            }) : sett) as SubmenuProps[]
                                      }/>
                                    }
                                  </>
                                  }
                                  />
                                  <AvatarDropdown
                                    user={me}
                                    items={getUserMenuItems(me.id)}
                                  />
                                </>
                              ) : (
                                <Spinner size="small"/>
                              )}
                            </>
                          )}
                        />
                      </S.ProfileAndSettingsWrapper>
                    </S.LexyAndButtonsContent>
                  </S.LexyAndButtonsWrapper>

                  {/* Mobile menu button */}
                  <div className="-mr-2 flex md:hidden">
                    <Disclosure.Button
                      className="bg-pl-grayscale-white inline-flex items-center justify-center p-2 rounded-md text-pl-primary-green-default hover:text-pl-grayscale-white hover:bg-pl-primary-green-default focus:outline-none">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true"/>
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true"/>
                      )}
                    </Disclosure.Button>
                  </div>
                </S.DesktopNavbarWrapper>

                <Disclosure.Panel className="md:hidden">
                  <div className="pt-4 pb-3 border-t border-pl-grayscale-black">
                    <div className={"w-full flex justify-center mb-2"}>
                      <div className={"w-80"}>
                        <LexiSearch/>
                      </div>
                    </div>
                    <LoadingWidget<Employee>
                      value={auth.me}
                      builder={(me) => (
                        <>
                          {me ? (
                            <div className="flex items-center px-5">
                              <div className="flex-shrink-0">
                                <EmployeeAvatar
                                  employee={me}
                                  size={3}
                                  linkTo={false}
                                  hoverable={true}
                                />
                              </div>
                              <div className="ml-3">
                                <div className="text-base font-medium text-pl-grayscale-black">
                                  {me.name}
                                </div>
                                <div className="text-sm font-medium text-pl-grayscale-midgray">
                                  {me.email}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <Spinner size="small"/>
                          )}
                        </>
                      )}
                    />
                    <div className="mt-3 px-2 space-y-1">
                      {/* <NavMenu items={navMenuItems.items} /> */}

                      <Disclosure>
                        {({open}) => (
                          <>
                            <Disclosure.Button
                              className="flex justify-between w-full px-2 py-2 text-normal font-medium text-left bg-transparent rounded-lg hover:bg-pl-grayscale-lightgray">
                              <div className={"text-normal font-medium"}>
                                Settings
                              </div>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-180" : ""
                                } w-5 h-5`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-2 text-sm text-pl-grayscale-gray">
                              <Link
                                to={`/employees/edit`}
                                className={`block px-4 py-2 text-sm text-pl-grayscale-black hover:bg-pl-grayscale-lightgray`}
                              >
                                Add Employee
                              </Link>
                              <Link
                                to={`/teams/edit`}
                                className={`block px-4 py-2 text-sm text-pl-grayscale-black hover:bg-pl-grayscale-lightgray`}
                              >
                                Add Team
                              </Link>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>

                      <Disclosure>
                        {({open}) => (
                          <>
                            <Disclosure.Button
                              className="flex justify-between w-full px-2 py-2 text-normal font-medium text-left bg-transparent rounded-lg hover:bg-pl-grayscale-lightgray">
                              <div className={"text-normal font-medium"}>
                                Profile
                              </div>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-180" : ""
                                } w-5 h-5`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-2 text-sm text-pl-grayscale-gray">
                              <Link
                                to={`/employees/${auth.me.value?.id}`}
                                className={`block px-4 py-2 text-sm text-pl-grayscale-black hover:bg-pl-grayscale-lightgray`}
                              >
                                Your Profile
                              </Link>
                              <Link
                                to={`/settings/organization`}
                                className={`block px-4 py-2 text-sm text-pl-grayscale-black hover:bg-pl-grayscale-lightgray`}
                              >
                                Org Settings
                              </Link>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>

                      <div
                        className={`cursor-pointer flex justify-between w-full px-2 py-2 text-normal font-medium text-left bg-transparent rounded-lg hover:bg-pl-grayscale-lightgray`}
                      >
                        Sign Out
                      </div>
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </S.Wrapper>
  );
}
