import {
  Recommendation,
  RecommendationFilter,
  RecommendationSettings,
  Rulebook,
  RulebookStatus
} from "model/recommendations";

import {recommenderFetch, recommenderPost, recommenderPut, ResponseStatusAndText} from "./utils";

class Recommendations {
  getRecsForEmployee(id: any) {
    return recommenderFetch<Recommendation[]>(
      `/api/v1/recommendations/employees/${id}/recommendations`
    );
  }

  getRecsForTeam(teamId: any) {
    return recommenderFetch<Recommendation[]>(
      `/api/v1/recommendations/teams/${teamId}/recommendations`
    );
  }

  getRecsForManager(managerId: any) {
    return recommenderFetch<Recommendation[]>(
      `/api/v1/recommendations/manager/${managerId}/recommendations`
    );
  }

  getAllRecomendations() {
    return recommenderFetch<Recommendation[]>(`/api/v1/recommendations`);
  }

  getHighImpactingFactorsForEmployee(id: any) {
    // this needs to be removed
    return recommenderFetch<Recommendation[]>(
      `/api/v1/recommendations/employees/${id}/recommendations`
    );
  }

  updateRecommendationFeedback(id: number, feedback: string) {
    return recommenderPut<ResponseStatusAndText>(`/api/v1/recommendations/${id}`, {
      id: id,
      feedback: feedback,
    } as Recommendation);
  }

  getRulebooksAndRules() {
    return recommenderFetch<Rulebook[]>(`/api/v1/recommendations/rulebooks`);
  }

  getRecommendationSettings() {
    return recommenderFetch<RecommendationSettings[]>(
      `/api/v1/recommendations/settings`
    );
  }

  updateRuleOrRulebookStatus(name: string, ruleType: string, status: boolean) {
    return recommenderPost(`/api/v1/recommendations/rulebooks`, {
      name: name,
      ruleType: ruleType,
      enable: status,
    } as RulebookStatus);
  }

  updateRecommendationsSettings(settings: RecommendationSettings[]) {
    return recommenderPost<RecommendationSettings[]>(`/api/v1/recommendations/settings`, settings);
  }

  getRecommendationsByFilter(filter: RecommendationFilter) {
      return recommenderFetch<Recommendation[]>(`/api/v1/recommendations/by-filter`, filter);
  }

  getTopRecommendations() {
    return recommenderFetch<Recommendation[]>(`/api/v1/recommendations/top`, {}, {cache: 'no-cache'});
  }

  getTopRecommendationsForTeam(filter: RecommendationFilter) {
      return recommenderFetch<Recommendation[]>(`/api/v1/recommendations/teams/top`,filter)
  }
}

export default new Recommendations();
