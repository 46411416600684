import tw from "tailwind-styled-components";

export const DetailsItem = tw.div`
  flex
  gap-2
  font-normal
  items-center
`;

export const DetailsItemIconAndLabel = tw.div`
  flex
  gap-2
  items-center
`;

export const IconWrapper = tw.div`
  h-5
  w-5
  text-pl-grayscale-gray
`;
